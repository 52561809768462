import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import e from "express";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";

@Component({
  selector: "app-confirmation-dialog-box",
  templateUrl: "./confirmation-dialog-box.component.html",
  styleUrls: ["./confirmation-dialog-box.component.css"],
})
export class ConfirmationDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _apiCallService: ApiCallService,
    private _snackBar:SnackbarCollection,
    private tokenService: TokenStorageService
  ) {}
    fileName = "";
    emailId = '';
    errorText = "";
    emailError = '';
    namePattern = "^[a-zA-Z0-9_() \-]{2,50}$";
    emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  planUpgradeLoading: boolean = false;
  userComments = "";
  ngOnInit() {
    if (this.data["data-widget"]) {
    } else {
      this.data["data-widget"] = "CONFIRMATION";
    }
    if(this.data.segmentName){
      this.fileName = this.data.segmentName
    }
    if(this.data['downloadButton_1'] && this.data['downloadButton_2']) {
      this.getEmailError('');
      this.getErrorFilename('');
    }
  }

  submit(decision) {
    this.dialogRef.close(decision);
  }

  submitComment(decision){
    if(this.userComments.length > 160) {
      this._snackBar.openSnackBar('Please enter comment of valid length',3000);
    } else {
      let data = {
        decision: decision,
        comment: this.userComments.length > 0? this.userComments : this.data.sampleComment
      }
      this.dialogRef.close(data);
    } 
  }

  download(decision) {
    let data : any = {};
    data.decision = decision;
    data.fileName = this.fileName.trim();
    data.emailId = this.emailId.trim();
    if(this.fileName.length > 0 && this.fileName.match(this.namePattern)){
      this.dialogRef.close(data);
    }
    else if(this.fileName.length === 0){
      this.errorText = "Please enter file name";
    }
    else if(!this.fileName.match(this.namePattern)){
      this.errorText = "Please enter valid file name"
    }
  }

  saveCustomerList(){
    if(this.fileName.length > 0 && this.fileName.match(this.namePattern)){
      this.createAnalyticsCustomList();
    }
    else if(this.fileName.length === 0){
      this.errorText = "Please Enter Custom List Name";
    }
    else if(!this.fileName.match(this.namePattern)){
      this.errorText = "Please enter valid name"
    }
  }

  fileNameKeyPress(event) {
    if (50 - this.fileName.length === 0) {
      return false;
    }
  }

  emailKeyPress(event) {
    if (50 - this.emailId.length === 0) {
      return false;
    }
  }

  getErrorFilename(value){
    this.errorText = "";
        if(!this.fileName.match(this.namePattern) || this.fileName.length > 50){
          this.errorText = "Name should be 3-50 Characters long and can only contain alphabets, numbers, _, - and ()"
        }
        else{
          this.errorText = "";
        }
  }

  getEmailError(value) {
    this.emailError = "";
        if(!this.emailPattern.test(this.emailId) || this.emailId.length > 50){
          this.emailError = "Please enter valid  email"
        }
        else{
          this.emailError = "";
        }
  }

  keypresshandler(event) {
    if (160 - this.userComments.length === 0) {
      return false;
    }
  }
  
  createAnalyticsCustomList(){
    if(this.data.apiData){
      let customerListApi = this.data.apiData.payLoadData;
      customerListApi["createdBy"] = this.getCreatedByDetails();
      customerListApi["customerListName"] = this.fileName;
      this.customerListCreateApi(customerListApi).then(
        (result) => {
          if (result) {
            // this.snackBar.openSnackBar("Your Custom List is being Created", 2000);
            console.log(result);
            this.dialogRef.close(true);
          }
        },
        (error) => {
          // this.snackBar.openSnackBar("Custom List Name Already Exists", 2000);
          console.log(error.body);
          this.errorText = error.body;
        }
      );
    }
    else{
      this.dialogRef.close(this.fileName);
    }
  }

  getCreatedByDetails() {
    let data: any = {};
    if (this.tokenService.getqId() !== "null") {
      this._apiCallService.getSpecificStoreOffer().subscribe((response) => {
        // console.log(response)
        data.storeId = response["body"]["storeMetaInfo"].storeId;
        data.storeName = response["body"]["storeMetaInfo"].storeName;
        data.qid = response["body"]["storeMetaInfo"].qid;
        return JSON.stringify(data);
      });
    } else {
      data = {
        storeId: null,
        storeName: "ADMIN",
        qid: null,
      };
      return JSON.stringify(data);
    }
  }

  customerListCreateApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this._apiCallService.createAnalyticsCustomList(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }
}