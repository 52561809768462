export * from "./analyze/analyze.module";
export * from "./aquire/aquire.module";
export * from "./campaign/campaign.module";
export * from "./customers/customers.module";
export * from "./email/email.module";
export * from "./gamify/gamify.module";
export * from "./home/home.module";
export * from "./offer/offer.module";
export * from "./partnership/partnership.module";
export * from "./segments/segments.module";
export * from "./sms/sms.module";
export * from "./survey/survey.module";
export * from "./suppression-list/suppression-list.module";
export * from "./consent-recieved/consent-recieved.module";
export * from "./user-profile/user-profile.module";
export * from "./payments/payments.module";
export * from "./social-media/social-media.module";
export * from "./whatsApp/whatsapp.module";
export * from "./loyalty/loyalty.module";
export * from "./onboard/onboard.module";
export * from "./customer-list/customer-list.module";
export * from "./trend-report/trend-report.module";
export * from './customer-evolution/customer-evolution.module';
export * from "./rfm-dynamics/rfm-dynamics.module";
export * from "./product-trends/product-trends.module";
export * from "./loyalty-trend/loyalty-trend.nodule";
export * from "./wallet/wallet.module";
export * from "./reports/reports.module";
export * from "./leads/leads.module";
export * from "./sms-analytics/sms-analytics.module";
export * from "./journey-analytics/journey-analytics.module";
export * from "./reviews/reviews.module";
export * from "./offer-analytics/offer-analytics.module";