<div class="activity-spinner-container" *ngIf="dataLoading">
    <app-qubriux-loading [size]="45"></app-qubriux-loading>
</div>
<div class="lp-dashboard-container" *ngIf="!dataLoading">
    <div class="lp-dashboard-heading flex">
        <h3>{{'SEGMENT_PAGE.YOUR_LOYALITY_PROGRAM_STATUS' | i18nTranslate}}</h3>
        <div class="flex">
            <button class="primary-button reward-coin button-hover-effect" (click)="openRewardCoinsPopup()">
                <div>{{'SEGMENT_PAGE.AWARD' | i18nTranslate}} {{loyaltyProgramConfig.rewardPoints.name}}</div>
                <img width="22px" height="22px" class="coin-img-button" [src]="loyaltyProgramConfig.rewardPoints.logoPath" alt="coin Image">
            </button>
            <button class="primary-button button-hover-effect" (click)="redirectToEdit()">
                {{'SEGMENT_PAGE.EDIT_LOYALITY_PROGRAM' | i18nTranslate}}
            </button>
        </div>
    </div>
    <div *ngIf="hasInvalidRule" class="invalid-rule-indicator">
        {{'SEGMENT_PAGE.RULES_PAST_VALID_DATES' | i18nTranslate}}
    </div>
    <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'SEGMENT_PAGE.LOYALITY_PROGRAM_PREVIEW' | i18nTranslate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-expansion-panel-content>
            <app-preview [completedSteps]="3" [loyaltyPoints]="loyaltyPoints" [sortedTierMap]="tierMap" [loyaltyRules]="loyaltyRules" [currentStep]="4"></app-preview>
          </mat-expansion-panel-content>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="widgets-container">
        <div class="widgets">
            <div class="lp-dashboard-row">
                <div class="small-widget-size widget-container" >
                    <div class="activity-spinner-container" *ngIf="activityLoading">
                        <mat-spinner diameter="25"></mat-spinner>
                    </div>
                    <div *ngIf="!activityLoading">
                        <h6 class="widget-heading">{{'SEGMENT_PAGE.LOYALITY_PROGRAM_STATUS' | i18nTranslate}}</h6>
                        <div *ngIf="dashboardData.loyaltyProgramStatus" class="active-text flex">
                            <span class="material-symbols-outlined">flag</span>
                            <span>{{'SEGMENT_PAGE.ACTIVE' | i18nTranslate}}</span>
                        </div>
                        <div *ngIf="!dashboardData.loyaltyProgramStatus" class="inactive-text flex">
                            <span class="material-symbols-outlined">flag</span>
                            <span>{{'SEGMENT_PAGE.INACTIVE' | i18nTranslate}}</span>
                        </div>
                        <mat-slide-toggle [(ngModel)]="dashboardData.loyaltyProgramStatus" (change)="changeLoyaltyActivityStatus()"></mat-slide-toggle>
                    </div>
                </div>
                <div class="small-widget-size widget-container position-relative">
                    <h6 class="widget-heading">{{'SEGMENT_PAGE.TOTAL_LOYALITY_MEMBERS' | i18nTranslate}}</h6>
                    <div class="stat-val">{{dashboardData.totalLoyaltyMembers}}</div>
                    <div class="absolute-icon-container bg-1">
                        <span class="material-symbols-outlined absolute-icon">
                            group
                        </span>
                    </div>
                </div>
                <div class="small-wide-widget-size widget-container position-relative">
                    <div class="flex gap-10">
                        <h6 class="widget-heading">{{'SEGMENT_PAGE.NEW_LOYALITY_MEMBERS' | i18nTranslate}}</h6>
                        <mat-select [(ngModel)]="loyaltyMembersTimeFrame" (selectionChange)="changeNewLoyaltyMembersTimeFrame(loyaltyMembersTimeFrame)" class="margin-top--8 dash-mat-select">
                            <mat-option *ngFor="let val of loyaltyMembersTimeFrameList" [value]="val.value" selected>{{val.display | i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="stat-val">{{newLoyaltyMembers}}</div>
                    <div class="absolute-icon-container bg-2">
                        <span class="material-symbols-outlined absolute-icon">
                            person_add
                        </span>
                    </div>
                </div>
            </div>
            <div class="widget-container width-100perc">
                <div class="flex">
                    <h5 class="widget-heading">{{earnedByTierValue | i18nDynamicTranslate : 'POS_PAGE'}} {{'SEGMENT_PAGE.EARNER_TIER' | i18nTranslate}}</h5>
                    <div class="graph-options">
                        <h6 class="graph-options-text">{{'SEGMENT_PAGE.OPTIONS' | i18nTranslate}}:</h6>
                        <mat-select class="margin-right-10 dash-mat-select" [(ngModel)]="earnedByTierTimeFrame" (selectionChange)="earnedByTierDropdownHandler()">
                            <mat-option *ngFor="let val of earnedByTierTimeFrameList" [value]="val" selected>{{val | i18nDynamicTranslate : 'POS_PAGE'}}</mat-option>
                        </mat-select>
                        <mat-select [(ngModel)]="earnedByTierValue" (selectionChange)="earnedByTierDropdownHandler()" class="dash-mat-select">
                            <mat-option *ngFor="let val of earnedByTierValueList" [value]="val" selected>{{val | i18nDynamicTranslate : 'POS_PAGE'}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div echarts [options]="earnedByTierChartOptions" class="line-chart-height"></div>
            </div>
            <div class="lp-dashboard-row">
                <div class="widget-container flex-grow-1">
                    <div class="flex space-between margin-bottom-10">
                        <h6 class="widget-heading">{{'SEGMENT_PAGE.TOP_LOYALITY_MEMBERS' | i18nTranslate}}</h6>
                        <div class="primary-button button-hover-effect" (click)="openAllMembersPopup()">
                            {{'SEGMENT_PAGE.VIEW_ALL' | i18nTranslate}}
                        </div>
                    </div>
                    <div>
                        <div class="flex gap-6">
                            <div class="tlm-header">
                                {{'SEGMENT_PAGE.CUSTOMER_NAME' | i18nTranslate}}
                            </div>
                            <div class="tlm-header width-30perc">
                                {{'SEGMENT_PAGE.COINS' | i18nTranslate}}
                            </div>
                        </div>
                        <div class="overflow-auto height-275" *ngIf="dashboardData.maxEarnedRewardPointsMap.length!=0"> 
                            <div *ngFor="let member of dashboardData.maxEarnedRewardPointsMap" class="flex gap-6">
                                <div class="tlm-data">
                                    <span [matTooltip]="member.customerId">
                                        {{member.customerName | limitTo: 20}}
                                    </span>
                                </div>
                                <div class="tlm-data width-30perc">
                                    {{member.points}}
                                </div>
                            </div>
                        </div>
                        <div  class="lv-data-container-no-data" *ngIf="!dashboardData.maxEarnedRewardPointsMap || dashboardData.maxEarnedRewardPointsMap.length==0">
                            {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                        </div>
                    </div>
                </div>
                <div class="widget-container donut-chart-widget-size">
                    <h6 class="widget-heading">{{'SEGMENT_PAGE.ACTIVE_USERS' | i18nTranslate}}<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup2"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup2>
                        <div class="qb-ng-template-text">
                            {{'SEGMENT_PAGE.GRAPHN_DATA_ACTIVE_MESSAGE' | i18nTranslate}}
                        </div>
                    </ng-template>
                    <div echarts [options]="activeUsersChartOptions" class="donut-chart"></div>
                </div>
                <div class="widget-container donut-chart-widget-size">
                    <h6 class="widget-heading">{{'SEGMENT_PAGE.REVENUE_BY_TIER' | i18nTranslate}}<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup3"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup3>
                        <div class="qb-ng-template-text">
                            {{'SEGMENT_PAGE.GRAPGN_REVENUE_TIER' | i18nTranslate}}
                        </div>
                    </ng-template>
                    <div echarts [options]="tierWiseRevenueOptions" class="donut-chart"></div>
                </div>
            </div>
            <div  class="widget-container width-100perc">
                <h6 class="widget-heading">{{loyaltyPoints.name}} {{'SEGMENT_PAGE.REWARD_REDEMPTION_RATE' | i18nTranslate}}<span class="qb-i-div"
                    popoverclass="qb-my-custom-class"
                    placement="auto"
                    [ngbPopover]="popTierIamgeSetup1"
                    triggers="mouseenter:mouseleave"
                    >i
                </span></h6>
                <ng-template #popTierIamgeSetup1>
                    <div class="qb-ng-template-text">
                        {{'SEGMENT_PAGE.REWARD_REDEMPTION_RATE_INFO' | i18nTranslate}}
                    </div>
                </ng-template>
                <div echarts [options]="rewardRedemptionRateStatOptions" class="donut-chart"></div>
            </div>
        </div>
    </div>
</div>
<div class="ld-popup-container" *ngIf="showAllCustomersPopup" (click)="closeAllMembersPopup()">
    <div class="ld-popup" (click)="$event.stopPropagation()">
        <div class="acp-top-row">
            <h2>{{'SEGMENT_PAGE.MEMBER_DETAIL' | i18nTranslate}}</h2>
            <div class="acp-close" (click)="closeAllMembersPopup()">
                <span class="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
        </div>
        <div class="lp-dashboard-row">
            <div class="small-widget-size widget-container bg-bgrey">
                <h6 class="widget-heading">{{'SEGMENT_PAGE.ACTIVE_MEMBERS' | i18nTranslate}}</h6>
                <div class="stat-val">{{dashboardData.totalActiveMembers}}</div>
            </div>
            <div class="small-widget-size widget-container bg-bgrey">
                <h6 class="widget-heading">{{'SEGMENT_PAGE.REVENUE_BY_LOYALITY' | i18nTranslate}}</h6>
                <div class="stat-val">{{currencySymbol}} {{dashboardData.totalRevenue}}</div>
            </div>
        </div>
        <mat-tab-group  (selectedTabChange)="changeTierTab($event)" class="sp-height">
            <mat-tab *ngFor="let tab of acpTabList" [label]="tab.name">
                <div class="acp-tab-tc">
                    <div>
                        {{'SEGMENT_PAGE.TOTAL_CUSTOMER' | i18nTranslate}}: {{allMembersPopupConfig.count}}
                    </div>
                    <div>
                        <div class="qb-search-bar-div">
                            <div class="qb-search-bar">
                              <input
                                #search
                                type="text"
                                placeholder="Search by Customer Name"
                                (input)="onKeySearch(search.value)"
                              />
                              <span class="material-icons icons-primary" *ngIf="search.value.length==0"> search </span>
                              <span class="material-icons-outlined icons-primary cursor-pointer" style="font-size: 19px;"  *ngIf="search.value.length>0" (click)="search.value='';onKeySearch(search.value)">
                                close
                              </span>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="lv-row  header-row-style">
                    <div class="lv-header">
                        <div>{{'SEGMENT_PAGE.CUSTOMER_NAME' | i18nTranslate}}</div>
                        <button *ngIf="allMembersPopupConfig.nameOrder == 'd'" class="order-by-button material-symbols-outlined" (click)="changeCustoerNameOrder()" title="Descending">
                            arrow_drop_down
                        </button>
                        <button *ngIf="allMembersPopupConfig.nameOrder == 'a'" class="order-by-button material-symbols-outlined" (click)="changeCustoerNameOrder()" title="Ascending">
                            arrow_drop_up
                        </button>
                    </div>
                    <div class="lv-header">{{'SEGMENT_PAGE.TIER' | i18nTranslate}}</div>
                    <div class="lv-header">
                        <div>{{'SEGMENT_PAGE.COINS' | i18nTranslate}}</div>
                        <button *ngIf="allMembersPopupConfig.order == 'd'" class="order-by-button material-symbols-outlined" (click)="changeOrder()" title="Decending">
                            arrow_drop_down
                        </button>
                        <button *ngIf="allMembersPopupConfig.order == 'a'" class="order-by-button material-symbols-outlined" (click)="changeOrder()" title="Ascending">
                            arrow_drop_up
                        </button>
                    </div>
                </div>
                <div class="lv-data-container">
                    <div class="activity-spinner-container" style="height: 100px;" *ngIf="!loyaltyDashboardDataDetched">
                        <mat-spinner diameter="25"></mat-spinner>
                    </div>
                    <div *ngIf="allCustomersListViewData.length!=0">
                        <div *ngFor="let row of allCustomersListViewData" class="lv-row">
                            <div class="lv-data">
                                {{row.name}}
                            </div>
                            <div class="lv-data">
                                {{row.tier || "-"}}
                            </div>
                            <div class="lv-data">
                                {{row.points}}
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="lv-data-container-no-data" *ngIf="(!allCustomersListViewData || allCustomersListViewData.length==0) && loyaltyDashboardDataDetched">
                    {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                </div>
                <div class="lv-options">
                    <mat-paginator
                    #paginator 
                      [pageIndex]=0
                      [length]="allMembersPopupConfig.paginationCount"
                      [pageSize]="allMembersPopupConfig.recordsPerPage"
                      [pageSizeOptions]="[5, 10, 25, 100]"
                      (page)="refreshPaginatedData($event,'allMembers')"
                    >
                    </mat-paginator>
                  </div>
            </mat-tab>
          </mat-tab-group>  
    </div> 
</div>
<div class="ld-popup-container" *ngIf="showRewardCoinsPopup" (click)="closeRewardCoinPopUp()">
    <div class="ld-popup" (click)="$event.stopPropagation()">
        <div class="acp-top-row">
            <h2>Award Loyalty Points</h2>
            <div class="acp-close" (click)="closeRewardCoinPopUp()">
                <span class="material-symbols-outlined cursor-pointer">
                    close
                </span>
            </div>
        </div>
        <div id="step">
            <mat-tab-group class="custom-tab" (selectedTabChange)="changeRewardCoinTab($event)">
                <mat-tab label="To Customers">
                    <div class="qb-search-div">
                        <div class="qb-search-bar-div">
                          <div class="qb-search-bar">
                            <input
                              type="text"
                              #search
                              class="qb-search-input"
                              placeholder="Search by Customer Name/Number/Email (Min 3 characters)"
                              [(ngModel)]="customerSearchString"
                              (input)="searchForCustomer(search.value, true)"
                            />
                            <span class="material-icons icons-primary" *ngIf="search.value.length==0"> search </span>
                            <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19" *ngIf="search.value.length>0" (click)="customerSearchString='';searchForCustomer(customerSearchString)">
                              close
                            </span>
                          </div>
                        </div>
                    </div>
                    <div class="lv-row header-row-style">
                        <div class="blank-header"><mat-checkbox color="primary" (change)="selectAllCustomersInPage($event)"  [checked]="areAllSelected()"></mat-checkbox></div>
                        <div class="lv-header">{{'CUSTOMER_PAGE.NAME' | i18nTranslate}}</div>
                        <div class="lv-header">{{'CUSTOMER_PAGE.CUSTOMER_PHONE_NUMBER' | i18nTranslate}}</div>
                        <div class="lv-header">{{'CUSTOMER_PAGE.EMAIL_ADDRESS' | i18nTranslate}}</div>
                    </div>
                    <div class="lv-data-container-2" *ngIf="allCustomersListViewData.length!=0 && !allCustomersListViewDataLoading">
                        <div *ngFor="let row of allCustomersListViewData" class="lv-row">
                            <div class="blank-data">
                                <mat-checkbox color="primary" [checked]="isSelected(row.id)" (change)="onCheckboxChange($event,row.id,'customer')"></mat-checkbox>
                            </div>
                            <div class="lv-data">
                                {{row.name || '----'}}
                            </div>
                            <div class="lv-data">
                                {{row.number || '----'}}
                            </div>
                            <div class="lv-data">
                                {{row.email || '----'}}
                            </div>
                        </div>
                    </div>
                    <div class="activity-spinner-container height-50" *ngIf="allCustomersListViewDataLoading">
                        <mat-spinner diameter="45"></mat-spinner>
                    </div>
                    <div  class="lv-data-container-no-data" *ngIf="!allCustomersListViewDataLoading && (!allCustomersListViewData || allCustomersListViewData.length==0)">
                        {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                    </div>
                    <div class="lv-options">
                        <mat-paginator
                        #paginator 
                          [pageIndex]=rewardCoinsPopupConfig.pageNumber
                          [length]="rewardCoinsPopupConfig.totalCustomers"
                          [pageSize]="rewardCoinsPopupConfig.recordsPerPage"
                          [pageSizeOptions]="[5, 10, 25, 100]"
                          (page)="refreshPaginatedData($event,'rewardCoins')"
                          [disabled]="allCustomersListViewDataLoading"
                        >
                        </mat-paginator>
                    </div>
                    <div class="rc-popup-footer" *ngIf="!allCustomersListViewDataLoading">
                        <div> 
                            <span class="reward-coin-text">Number of {{loyaltyProgramConfig.rewardPoints.name}}(s)</span>
                            <img width="23px" height="23px" class="coin-img-button" [src]="loyaltyProgramConfig.rewardPoints.logoPath" alt="coin Image">
                            <span class="reward-coin-text"> to be awarded : </span>
                        </div>
                        <div class="rp-name-inp-container-2">
                            <input class="rp-name-inp" type="number" placeholder="Points" [(ngModel)]="coinsToGive" (keydown)="numberInputHandler($event,8)" #inp2 maxlength="8" min="0" max="99999999">
                            <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                        <button class="primary-button create-button reward-button button-hover-effect" (click)="toggleConfirmation('fromCustomerIds')" [disabled]="!coinsToGive || coinsToGive<0 || selectedIdsForRewardCoin.length < 1">Award points</button>
                    </div>
                </mat-tab>
                <mat-tab label="To Groups">
                    <div class="qb-search-div">
                        <div class="qb-search-bar-div">
                          <div class="qb-search-bar">
                            <input
                              type="text"
                              [(ngModel)]="segmentFilter"
                              class="qb-search-input"
                              placeholder="Search by Segment/Tier Name"
                            />
                            <span class="material-icons icons-primary" *ngIf="search.value.length==0"> search </span>
                            <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19" *ngIf="search.value.length>0" (click)="search.value='';onKeySearch(search.value, true)">
                              close
                            </span>
                          </div>
                        </div>
                    </div>
                    <div class="lv-row header-row-style">
                        <div class="blank-header"></div>
                        <div class="lv-header">{{'CUSTOMER_PAGE.NAME' | i18nTranslate}}</div>
                        <div class="lv-header">{{'CAMPAIGN_PAGE.GRP_NAME' | i18nTranslate}}</div>
                    </div>
                    <div class="lv-data-container-2" *ngIf="allCustomersListViewData.length!=0 && !allCustomersListViewDataLoading">
                        <div *ngFor="let row of allCustomersListViewData | labelPipe: segmentFilter:'name'" class="lv-row">
                            <div class="blank-data">
                                <mat-checkbox color="primary" [checked]="isSelected(row.id)" (change)="onCheckboxChange($event,row.id,row.type)" [disabled]="row.id != -1 && isAllCustomersSegmentSelected" ></mat-checkbox>
                            </div>
                            <div class="lv-data">
                                {{row.name}}
                            </div>
                            <div class="lv-data">
                                {{row.type}}
                            </div>
                        </div>
                    </div>
                    <div class="activity-spinner-container height-50" *ngIf="allCustomersListViewDataLoading">
                        <mat-spinner diameter="45"></mat-spinner>
                    </div>
                    <div  class="lv-data-container-no-data" *ngIf="!allCustomersListViewDataLoading && (!allCustomersListViewData || allCustomersListViewData.length==0)">
                        {{'SHARED_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                    </div>
                    <div class="rc-popup-footer" *ngIf="!allCustomersListViewDataLoading">
                        <div> 
                            <span class="reward-coin-text">Number of {{loyaltyProgramConfig.rewardPoints.name}}(s) </span>
                            <img width="23px" height="23px" class="coin-img-button" [src]="loyaltyProgramConfig.rewardPoints.logoPath" alt="coin Image">
                            <span class="reward-coin-text"> to be awarded : </span>
                        </div>
                        <div class="rp-name-inp-container-2">
                            <input class="rp-name-inp" type="number" placeholder="Points" [(ngModel)]="coinsToGive" (keydown)="numberInputHandler($event,8)" #inp2 maxlength="8" min="0" max="99999999">
                            <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                        <button class="primary-button create-button reward-button button-hover-effect" (click)="toggleConfirmation('fromGroupIds')" [disabled]="!coinsToGive || coinsToGive<0 || selectedIdsForRewardCoin.length < 1">Award Points</button>
                    </div>
                </mat-tab>
              </mat-tab-group>
        </div>
    </div> 
</div>



