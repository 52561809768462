<div class="qb-segment-job-container" *ngIf="!iscustomersSegmented&&!isSegmentOnboarding">
  <div class="qb-d-flex qb-fd-coloumn qb-ta-center qb-jc-center qb-ai-center">
    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/segment-loading.gif"
      alt="Segment Loading" class="qb-segment-loading-div">
    <div class="qb-segment-job-title">{{'SEGMENT_PAGE.PREPARING_SEGMENTS' | i18nTranslate}}</div>
    <div class="qb-segment-job-label">{{'SEGMENT_PAGE.PREPARING_SEGMENTS_MESSAGE' | i18nTranslate}}</div>
  </div>
</div>

<div class="qb-segment-job-container" *ngIf="isSegmentOnboarding">
  <app-qubriux-loading [size]="75" [loadingText]="'Onboarding your Segments...'"></app-qubriux-loading>
</div>
<div class="content-center" *ngIf="loadingSegments">
  <app-qubriux-loading [size]="75" [loadingText]="'Fetching your Segments...'"></app-qubriux-loading>
</div>
<div class="qb-segment-container" #container data-widget-name="SEGMENT LISTING"
  *ngIf="iscustomersSegmented&&!isSegmentOnboarding && !loadingSegments">
  <div>
    <div class="qb-header" #header>
      <app-breadcrumb></app-breadcrumb>
      <div *ngIf="validWidgets" class="qb-header-contents qb-create-segment-button">
        <div *ngIf="validWidgets | widgetConfig: 9" class="primary-button button-hover-effect" (click)="createSegment()" data-button-name="Create New Segment">
            {{'SEGMENT_PAGE.CREATE_NEW_SEGMENT' | i18nTranslate}}
        </div>
      </div>
    </div>
  </div>
  <div class="qb-segment-content" *ngIf="validWidgets">
    <div class="qb-list-view-container">
      <div class="qb-side-bar main-white-panel" *ngIf="(validWidgets | widgetConfig: 8) && this.originalSegmentList.length>0">
        <div *ngIf="segmentList.length !== 0" class="qb-h-100 qb-d-flex qb-fd-coloumn qb-ai-stretch">
          <div class="qb-header-contents qb-segment-type-container qb-select-segment-text"  *ngIf="presetCount>0 && createdCount>0">
            <div class="button-stat-container"> <span [ngClass]="{ active: segmentType === 'ALL' }" (click)="selectSegmentType('ALL')" class="qb-segment-types"
              data-button-name="All">{{'SEGMENT_PAGE.ALL' | i18nTranslate}}</span><span class="count-text">{{allCount}}</span></div>
            <div class="button-stat-container"> <span [ngClass]="{ active: segmentType === 'PRESET' }" (click)="selectSegmentType('PRESET')" class="qb-segment-types"
              data-button-name="Preset">{{'SEGMENT_PAGE.PRESET' | i18nTranslate}}</span><span class="count-text">{{presetCount}}</span></div>
            <div class="button-stat-container"><span [ngClass]="{ active: segmentType === 'CREATED' }" (click)="selectSegmentType('CREATED')"
              class="qb-segment-types" data-button-name="Custom">{{'SEGMENT_PAGE.CUSTOM' | i18nTranslate}}</span><span class="count-text">{{createdCount}}</span></div>
          </div>
          <div class="qb-formDiv qb-segment-filter-ip-form">
            <input class="qb-formInput" [(ngModel)]="filter.label" type="text" placeholder="Search Segment" />
            <span class="material-icons-outlined icons-primary" *ngIf="filter.label.length==0"> search </span>
            <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
              *ngIf="filter.label.length>0" (click)="filter.label=''">
              close
            </span>
          </div>
          <div class="qb-segment-list-content-list">
            <!-- <div *ngIf="qId ==null">
              <div *ngFor="
                let layout of segmentList
                  | labelPipe: filter.label:'segmentName';
                let i = index
              ">
              <div class="qb-top-list" [class.active]="
                  selectedSegmentId
                    ? selectedSegmentId == layout['id']
                    : false
                " [class.noBorder]="i === selectedStandardTemplateIndex - 1" (click)="
                  this.mobileMedia.matches
                    ? null
                    : viewSegment(layout['id'], i, layout['segmentType'])
                " data-button-name="View" [attr.data-item-name]="
                  '(' + layout['id'] + ')' + layout['segmentName']
                ">
                <div class="qb-list-class">
                  <div class="qb-template-list-container" [id]="'segment-list-container' + layout['id']" (click)="
                      this.mobileMedia.matches
                        ? getSegmentAnalytics(layout, $event)
                        : null
                    ">
                    <div class="qb-template-list-content" [id]="'segment-list' + layout['id']">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4062 6C14 3.78125 12.2188 2 10 1.59375V6H14.4062ZM10 7.5H8.5V6V0.53125C8.5 0.25 8.71875 0 9 0C12.8438 0 16 3.15625 16 7C16 7.28125 15.75 7.5 15.4688 7.5H10ZM5.9375 10.0625C5.65625 9.78125 5.5 9.40625 5.5 9V2.84375C3.15625 3.6875 1.5 5.90625 1.5 8.5C1.5 11.8125 4.1875 14.5 7.5 14.5C8.34375 14.5 9.15625 14.3438 9.875 14.0312L5.9375 10.0625ZM6.46875 1.09375C6.75 1.03125 7 1.28125 7 1.5625V9L11.875 13.9062C12.0938 14.125 12.0625 14.4688 11.8438 14.625C10.5938 15.5 9.09375 16 7.5 16C3.34375 16 0 12.6562 0 8.5C0 4.71875 2.8125 1.59375 6.46875 1.09375ZM16.4375 9V9.03125C16.7188 9.03125 16.9688 9.25 16.9062 9.5625C16.6875 11.2812 15.8438 12.8438 14.5938 14C14.4062 14.1562 14.125 14.1562 13.9375 13.9688L9 9H16.4375Z" fill="#4379EF"/>
                      </svg>
                      <div class="qb-segment-name-div" [matTooltip]="layout['segmentName']" matTooltipPosition="right" matTooltipShowDelay="500">
                        <span [id]="layout['id']" class="qb-segment-name-text">{{
                          layout["segmentName"] }}</span>
                      </div>
                      <div *ngIf="segmentType == 'ALL'" class="type-tag" [matTooltip]="layout['segmentType']=='STANDARD'?'Preset':'Custom'">
                        {{layout['segmentType']=='STANDARD'?'P':'C'}}
                      </div>
                      <div class="qb-mobile-segment-delete-expand-icon-container">
                        <span class="
                            material-icons
                            qb-segment-list-item-delete-icon
                            icons-primary
                          " *ngIf="layout['segmentType'] === 'CUSTOM'" (click)="deleteSegment(layout['id'])"
                          [id]="'segment-list-delete-icon' + layout['id']">
                          delete_outline
                        </span>
                        <span [id]="'segment-list-status-arrow' + layout['id']" class="
                            material-icons
                            icons-primary
                            qb-segment-list-expand-icon
                          ">
                          expand_more
                        </span>
                      </div>
                    </div>

                    <div class="qb-segment-mobile-analytics-container" *ngIf="mobileMedia.matches && onboarding">
                      <span *ngIf="onboarding" class="qb-segment-status-onboard">{{'SEGMENT_PAGE.DATA_DISPLAYED_ONCE_ONBOARDED' | i18nTranslate}}</span>
                    </div>
                    <div class="qb-segment-mobile-analytics-container">
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.CUSTOMERS' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{
                          layout["Analytics"]["customerCount"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.REVENUE' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{ currencySymbol }}&nbsp;{{
                          layout["Analytics"]["totalRevenue"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.ORDER_VALUE' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{ currencySymbol }}&nbsp;{{
                          layout["Analytics"]["avgOrderValue"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
            <div>
              <div *ngIf="(segmentsCreatedByStore | labelPipe: filter.label:'segmentName').length > 0">
                <div class="category-text-container">
                  <div class="category-line"></div>
                  <span class="category-text">{{ qId ==null ? 'Admin Created Segments' : 'Store Created Segments' }}</span>
                  <div class="category-line"></div>
                </div>
              <div *ngFor="
              let layout of segmentsCreatedByStore | labelPipe: filter.label:'segmentName';
              let i = index">
              <div class="qb-top-list" [class.active]="
                  selectedSegmentId
                    ? selectedSegmentId == layout['id']
                    : false
                " [class.noBorder]="i === selectedStandardTemplateIndex - 1" (click)="
                  this.mobileMedia.matches
                    ? null
                    : viewSegment(layout['id'], i, layout['segmentType'])
                " data-button-name="View" [attr.data-item-name]="
                  '(' + layout['id'] + ')' + layout['segmentName']
                ">
                <div class="qb-list-class">
                  <div class="qb-template-list-container" [id]="'segment-list-container' + layout['id']" (click)="
                      this.mobileMedia.matches
                        ? getSegmentAnalytics(layout, $event)
                        : null
                    ">
                    <div class="qb-template-list-content" [id]="'segment-list' + layout['id']">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4062 6C14 3.78125 12.2188 2 10 1.59375V6H14.4062ZM10 7.5H8.5V6V0.53125C8.5 0.25 8.71875 0 9 0C12.8438 0 16 3.15625 16 7C16 7.28125 15.75 7.5 15.4688 7.5H10ZM5.9375 10.0625C5.65625 9.78125 5.5 9.40625 5.5 9V2.84375C3.15625 3.6875 1.5 5.90625 1.5 8.5C1.5 11.8125 4.1875 14.5 7.5 14.5C8.34375 14.5 9.15625 14.3438 9.875 14.0312L5.9375 10.0625ZM6.46875 1.09375C6.75 1.03125 7 1.28125 7 1.5625V9L11.875 13.9062C12.0938 14.125 12.0625 14.4688 11.8438 14.625C10.5938 15.5 9.09375 16 7.5 16C3.34375 16 0 12.6562 0 8.5C0 4.71875 2.8125 1.59375 6.46875 1.09375ZM16.4375 9V9.03125C16.7188 9.03125 16.9688 9.25 16.9062 9.5625C16.6875 11.2812 15.8438 12.8438 14.5938 14C14.4062 14.1562 14.125 14.1562 13.9375 13.9688L9 9H16.4375Z" fill="#4379EF"/>
                      </svg>
                      <div class="qb-segment-name-div" [matTooltip]="layout['segmentName']" matTooltipPosition="right" matTooltipShowDelay="500">
                        <span [id]="layout['id']" class="qb-segment-name-text">{{
                          layout["segmentName"] }}</span>
                      </div>
                      <div *ngIf="segmentType == 'ALL'" class="type-tag" [matTooltip]="layout['segmentType']=='STANDARD'?'Preset':'Custom'">
                        {{layout['segmentType']=='STANDARD'?'P':'C'}}
                      </div>
                      <div class="qb-mobile-segment-delete-expand-icon-container">
                        <span class="
                            material-icons
                            qb-segment-list-item-delete-icon
                            icons-primary
                          " *ngIf="layout['segmentType'] === 'CUSTOM'" (click)="deleteSegment(layout['id'])"
                          [id]="'segment-list-delete-icon' + layout['id']">
                          delete_outline
                        </span>
                        <span [id]="'segment-list-status-arrow' + layout['id']" class="
                            material-icons
                            icons-primary
                            qb-segment-list-expand-icon
                          ">
                          expand_more
                        </span>
                      </div>
                    </div>

                    <div class="qb-segment-mobile-analytics-container" *ngIf="mobileMedia.matches && onboarding">
                      <span *ngIf="onboarding" class="qb-segment-status-onboard">{{'SEGMENT_PAGE.DATA_DISPLAYED_ONCE_ONBOARDED' | i18nTranslate}}</span>
                    </div>
                    <div class="qb-segment-mobile-analytics-container">
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.CUSTOMERS' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{
                          layout["Analytics"]["customerCount"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.REVENUE' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{ currencySymbol }}&nbsp;{{
                          layout["Analytics"]["totalRevenue"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.ORDER_VALUE' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{ currencySymbol }}&nbsp;{{
                          layout["Analytics"]["avgOrderValue"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
              
          <div *ngIf="(segmentsNotCreatedByStore | labelPipe: filter.label:'segmentName').length > 0">
            <div class="category-text-container" *ngIf="selectedSegmentType!='Preset Segments'">
              <div class="category-line"></div>
              <span class="category-text">Other Segments</span>
              <div class="category-line"></div>
            </div>
            <div *ngFor="
            let layout of segmentsNotCreatedByStore | labelPipe: filter.label:'segmentName';
            let i = index">
              <div class="qb-top-list" [class.active]="
                  selectedSegmentId
                    ? selectedSegmentId == layout['id']
                    : false
                " [class.noBorder]="i === selectedStandardTemplateIndex - 1" (click)="
                  this.mobileMedia.matches
                    ? null
                    : viewSegment(layout['id'], i, layout['segmentType'])
                " data-button-name="View" [attr.data-item-name]="
                  '(' + layout['id'] + ')' + layout['segmentName']
                ">
                <div class="qb-list-class">
                  <div class="qb-template-list-container" [id]="'segment-list-container' + layout['id']" (click)="
                      this.mobileMedia.matches
                        ? getSegmentAnalytics(layout, $event)
                        : null
                    ">
                    <div class="qb-template-list-content" [id]="'segment-list' + layout['id']">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.4062 6C14 3.78125 12.2188 2 10 1.59375V6H14.4062ZM10 7.5H8.5V6V0.53125C8.5 0.25 8.71875 0 9 0C12.8438 0 16 3.15625 16 7C16 7.28125 15.75 7.5 15.4688 7.5H10ZM5.9375 10.0625C5.65625 9.78125 5.5 9.40625 5.5 9V2.84375C3.15625 3.6875 1.5 5.90625 1.5 8.5C1.5 11.8125 4.1875 14.5 7.5 14.5C8.34375 14.5 9.15625 14.3438 9.875 14.0312L5.9375 10.0625ZM6.46875 1.09375C6.75 1.03125 7 1.28125 7 1.5625V9L11.875 13.9062C12.0938 14.125 12.0625 14.4688 11.8438 14.625C10.5938 15.5 9.09375 16 7.5 16C3.34375 16 0 12.6562 0 8.5C0 4.71875 2.8125 1.59375 6.46875 1.09375ZM16.4375 9V9.03125C16.7188 9.03125 16.9688 9.25 16.9062 9.5625C16.6875 11.2812 15.8438 12.8438 14.5938 14C14.4062 14.1562 14.125 14.1562 13.9375 13.9688L9 9H16.4375Z" fill="#4379EF"/>
                      </svg>
                      <div class="qb-segment-name-div" [matTooltip]="layout['segmentName']" matTooltipPosition="right" matTooltipShowDelay="500">
                        <span [id]="layout['id']" class="qb-segment-name-text">{{
                          layout["segmentName"] }}</span>
                      </div>
                      <div *ngIf="segmentType == 'ALL'" class="type-tag" [matTooltip]="layout['segmentType']=='STANDARD'?'Preset':'Custom'">
                        {{layout['segmentType']=='STANDARD'?'P':'C'}}
                      </div>
                      <div class="qb-mobile-segment-delete-expand-icon-container">
                        <span class="
                            material-icons
                            qb-segment-list-item-delete-icon
                            icons-primary
                          " *ngIf="layout['segmentType'] === 'CUSTOM'" (click)="deleteSegment(layout['id'])"
                          [id]="'segment-list-delete-icon' + layout['id']">
                          delete_outline
                        </span>
                        <span [id]="'segment-list-status-arrow' + layout['id']" class="
                            material-icons
                            icons-primary
                            qb-segment-list-expand-icon
                          ">
                          expand_more
                        </span>
                      </div>
                    </div>

                    <div class="qb-segment-mobile-analytics-container" *ngIf="mobileMedia.matches && onboarding">
                      <span *ngIf="onboarding" class="qb-segment-status-onboard">{{'SEGMENT_PAGE.DATA_DISPLAYED_ONCE_ONBOARDED' | i18nTranslate}}</span>
                    </div>
                    <div class="qb-segment-mobile-analytics-container">
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.CUSTOMERS' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{
                          layout["Analytics"]["customerCount"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.REVENUE' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{ currencySymbol }}&nbsp;{{
                          layout["Analytics"]["totalRevenue"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                      <div class="qb-segment-mobile-analytics-block">
                        <span class="qb-segment-mobile-analytics-header">{{'SEGMENT_PAGE.ORDER_VALUE' | i18nTranslate}}</span><span
                          *ngIf="layout['Analytics']">{{ currencySymbol }}&nbsp;{{
                          layout["Analytics"]["avgOrderValue"]
                          }}</span>
                        <span *ngIf="!layout['Analytics']">-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </div>
           
            
            <div *ngIf=" !loading && ((segmentList | labelPipe: filter.label:'segmentName').length === 0)">
              <div class="qb-empty-segment-list-message-container">{{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="qb-segment-description main-white-panel" *ngIf="validWidgets | widgetConfig: 9" [style.width]="this.originalSegmentList.length==0?'100%':'74%'">
        <!-- <router-outlet *ngIf="!this.mobileMedia.matches"></router-outlet> -->
        <div *ngIf="(segmentList | labelPipe: filter.label:'segmentName').length !== 0" class="height-100">
          <app-view-segments [segmentId]="selectedSegmentId" (segmentDataFromChild)="segmentDataFromChild($event)"
            *ngIf="!this.mobileMedia.matches"></app-view-segments>
        </div>
        <div *ngIf="
        (segmentList | labelPipe: filter.label:'segmentName').length === 0
      " class="qb-no-data-area">
            <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt="" style="padding-top: 100px;width: 500px;">
            <div style="margin-top: 15px;color: orangered;">
                No Segments Available
            </div>
        </div>
      </div>
    </div>
  </div>
</div>