import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog"; 
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { environment } from "src/environments/environment";
import { getCurrencySymbol } from "@angular/common";
import { DatePipe } from '@angular/common';
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { PopUpDialogService } from "src/app/core/services/pop-up-dialog.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent, DialogOverviewComponent, NotDeletedListDialogBoxComponent, SocialMediaConnectChannelDialogComponent, SuccessDialogComponent, TargetingDialogComponent } from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ExportSegmentComponent } from "../export-segment/export-segment.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { EChartsOption } from 'echarts';



@Component({
  selector: "app-view-segments",
  templateUrl: "./view-segments.component.html",
  styleUrls: ["./view-segments.component.css"],
})
export class ViewSegmentsComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private router: Router,
    private popUp: PopUpDialogService,
    private _WidgetConfigService: WidgetConfigService,
    private snackBar: SnackbarCollection,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private globalService: Globals,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService
  ) {
    window["screen-name"] = "SEGMENTS";
  }

  segments;
  dataSource;
  exportTip = this._i18nDynamicTranslate.transform("Export to Facebook", ['POS_PAGE']);
  exportTipInProgress = this._i18nDynamicTranslate.transform("Exporting to Facebook", ['POS_PAGE']);
  exportTipDone = this._i18nDynamicTranslate.transform("Segement exported to Facebook", ['POS_PAGE']);
  segment;
  analyticsLoaded = false;
  endDate = new FormControl(new Date());
  startDate = new FormControl(new Date());
  period: String = "MONTH";
  campaignDataset = [];
  segmentVariables;
  s3BucketUrl = environment.s3BucketUrl;
  validWidgets = null;
  validScreens = null;
  adAccountId;
  segmentAnalytics = {
    "customerCount": 0
  };
  revCurrency;
  currencySymbol;
  appSubscriptionPlan;
  onboarding = false;
  store; 
  sub;
  exportStatus = null;
  monthWiseCustomerCountChartOptions: EChartsOption;
  @Input() segmentId;
  @Output() segmentDataFromChild = new EventEmitter<any>();
  ngOnInit() {
    this.store = this.tokenStorage.getMerchantOnlineStore();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
    this.getWidgets();
    this.sub = this.globalService.isOnboarding.subscribe((data) => {
      if (data) {
        this.onboarding = data;
      } else {
        this.onboarding = false;
        if (this.sub) this.sub.unsubscribe();
      }
    });

    this.apiCall.getCustomerSegmentVariables().subscribe(
      (response) => {
        this.segmentVariables = response["body"]
      },
      (err) => {}
    );
    this.startDate.value.setDate(this.endDate.value.getDate() - 360);
    this.endDate.value.setDate(this.endDate.value.getDate() - 1);
  }

  ngOnChanges() {
    if (this.segmentId) {
      this.getSegmentData(this.segmentId);
    }
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.validScreens = await this._WidgetConfigService.getScreen();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  openConnectSocialProfilesDialog() {
    const dialogref = this.dialog.open(
      ConfirmationDialogBoxComponent,
      {
        panelClass: "customer-dialog-popup",
        width: "380px",
        data: {
          subject: `No Social media account found`,
          message: `Do you want to go and add a social media account?`,
          cancelButtonText: this._i18nDynamicTranslate.transform("No", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Yes", ['POS_PAGE']),
        }
      }
    );
    dialogref.afterClosed().subscribe((result) => {
      if(result) {
        this.router.navigate(['/app/social-eng']);
      }
    });
    // const dialogref = this.dialog.open(
    //   SocialMediaConnectChannelDialogComponent,
    //   {
    //     panelClass: "customer-dialog-popup",
    //     height: "auto",
    //     disableClose: true,
    //     width: "50%",
    //     data: {
    //       connectAds: true,
    //       refresh: false,
    //       textMsg: null
    //     }
    //   }
    // );

    // dialogref.afterClosed().subscribe((result) => {
    //   if (result) {
    //     console.log(result);
    //   //  this.authenticateFB();
    //   this.redirectForFbOauth(); // this is for redirecting to facebook oauth
    //   }
    // });
  }

  redirectForFbOauth(){
    let url = `https://www.facebook.com/v14.0/dialog/oauth?client_id=`+environment.socialFBAppId+`&redirect_uri=
    `+window.location.protocol +
    "//" +
    environment.Origin +`/app/segments&state=state8564&&display=popup&scope=`+environment.fbScope;
        window.open(url,"_self");
  }


  exportSegment() {
    this.apiCall.getMerchantProfileData().subscribe(response => {
      if(response['body']['facebookAdAccountId'] === null)
      this.openConnectSocialProfilesDialog();
else {
this.adAccountId = response['body']['facebookAdAccountId'];
const dialogref = this.dialog.open(
  ExportSegmentComponent,
  {
    panelClass: "customer-dialog-popup",
    height: "auto",
    disableClose: true,
    width: "35%",
    data: {
      "merchantId": this.tokenStorage.getMerchantId(),
      "audienceName": "",
      "description": "",
      "module": "Segmentation",
      "moduleId":this.segmentId,
      "adAccountId": this.adAccountId
    }
  }
);
dialogref.afterClosed().subscribe((result) => {
  if(result !== false) {
    this.getSegmentData(this.segmentId);
  }
});
}
    })
  }

  getSegmentData(id) {
    this.apiCall.getSegmentAnalytics(id).subscribe((response) => {
      this.segmentAnalytics = response["body"];
    });
    this.analyticsLoaded = false;
    this.apiCall.getCustomerSegment(id).subscribe(
      (response) => {
        this.exportStatus = response["body"]["customAudienceExportStatus"];
        this.segment = response["body"]
        let indexData = {
          id: this.segment["id"],
          type: this.segment["segmentType"],
        };
        this.apiCall
          .getIndividualSegmentTimeseries(this.segment["segmentName"])
          .subscribe(
            (response) => {
              let data = response["body"][
                this.segment["segmentName"]
              ];
              this.setLinechartData(data["dataSet"], "Campaign", "days");
              this.analyticsLoaded = true;
            },
            (err) => {}
          );
      },
      (err) => {
        this.popUp.openPopup("failure", err.error.body);
      }
    );
    
    // this.apiCall.getSegmentAnalytics(id).subscribe((response) => {
    //   this.segmentAnalytics = response["body"];
    // });
  }

  setLinechartData(data, yType, xType) {
    let monthWiseCustomerCountX = [];
    let monthWiseCustomerCountY = [];
    switch (yType) {
      case "Campaign":
        this.campaignDataset = [];
        let datePipe = new DatePipe("en-US");
        for (let i in data) {
          let name = datePipe.transform(i.split(" ")[0], "MMM").toString();
          monthWiseCustomerCountX.push(name);
          monthWiseCustomerCountY.push(data[i]);
          this.campaignDataset.push({ Month: name, Count: data[i] });
        }
        break;
    }
    this.monthWiseCustomerCountChartOptions = {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '15%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: monthWiseCustomerCountX,
      },
      yAxis: {
        type: 'value',
        minInterval: 1,
      },
      series: [
        {
          data: monthWiseCustomerCountY,
          type: 'line'
        }
      ]
    };
  }

  ngOnDestroy() {}

  async deleteSegment2() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Delete Segment", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you wish to delete this segment?", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
          successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
          "data-widget": "SEGMENT LISTING",
          "data-button": "Confirm: Delete",
          "data-item": this.segmentId,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.apiCall.deleteCustomerSegment(this.segmentId).subscribe(
            (response) => {
              this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Segment successfully deleted", ['POS_PAGE']), 2000);
              this.segmentDataFromChild.emit(this.segmentId);
            },

            (err) => {
              const errorBody1 = err.error.body;
              if (errorBody1.includes("Cannot delete this segment: it belongs to a different store")) {
                this.snackBar.openSnackBar("Cannot delete segment as it belongs to a different store", 2000);
              } 
              else{
                try{
                  const errorBody = JSON.parse(err.error.body);
                  const entityMap = errorBody.entityMap || null;
                  const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
                    panelClass: "customer-dialog-popup",
                    width: "540px",
                    disableClose: false,
                    data:{
                      type: "Segments",
                      heading: "OOPS!",
                      entityMap: entityMap,
                    },
                  });
                }
                catch{
                  this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something went wrong", ['POS_PAGE']), 2000);
                }
              
              }
             
            }
          );
        }
      });
    }
  }

  deleteSegment() {
    var id = this.segmentId;
    // let paramId = this.parentToChildService.getId()
    const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Segment", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Do you wish to delete this segment?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.apiCall.deleteCustomerSegment(id).subscribe(
          (response) => {
            //  this.popUp.openPopup('success', 'Delete successfull');
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Segment successfully deleted", ['POS_PAGE']), 2000);

            // if (id.toString() === paramId) {
            this.router.navigate(["/app/customers/edit-segments"]);
            // }
            //this.ngOnInit()
            //    this.parentToChildService.deletedSegment();
            //this.parentToChildService.changeData('Deleted');
            // this.parentToChildService.on('call-parent');
          },
          (err) => {
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform(err.error["body"], ['POS_PAGE']), 2000);
            // this.popUp.openPopup('failure', err.errpr.body);
          }
        );
      }
    });
  }
  viewSegment(segmentId) {
    this.apiCall.getCustomerSegment(segmentId).subscribe(
      (response) => {
        let segment = response["body"]
        const dialogRef = this.dialog.open(DialogOverviewComponent, {
          width: "25%",
          minHeight: "40px",
          data: {
            type: "viewSeg",
            data: segment,
          },
        });
      },
      (err) => {
        this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), err.error.body);
      }
    );
  }

  oldViewSegment(segmentId) {
    this.apiCall.getCustomerSegment(segmentId).subscribe(
      (response) => {
        let segment = response["body"];
        const dialogRef = this.dialog.open(DialogOverviewComponent, {
          width: "50%",
          data: {
            type: "viewSeg",
            data: segment["segmentDefinition"],
          },
        });
      },
      (err) => {
        this.popUp.openPopup(this._i18nDynamicTranslate.transform("failure", ['POS_PAGE']), err.error.body);
      }
    );
  }
  Targeting_Tactics(){
    this.dialog.open(TargetingDialogComponent,{
      panelClass:"no-padding-dialog-popup",
      width:"471px",
      height:"350px",
      data:{
        subject: this.segment["segmentName"],
        // message: "Sample"
      }
    })
  }


  async editSegment() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {

      if (this.segment["segmentType"] === "STANDARD") {
        let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
          panelClass: "no-padding-dialog-popup",
          width: "20%",
          data: {
            subject: this._i18nDynamicTranslate.transform("Edit segment", ['POS_PAGE']),
            message:
              this._i18nDynamicTranslate.transform("You can not edit a preset segment. You can instead clone and create a new segment.", ['POS_PAGE']),
            cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
            successButtonText: this._i18nDynamicTranslate.transform("Clone", ['POS_PAGE']),
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.router.navigate([
              "/app/segments/create-segment",
              { id: this.segmentId, clone: true },
            ]);
          }
        });
      } else {
        this.router.navigate([
          "/app/segments/create-segment",
          { id: this.segmentId, clone: false },
        ]);
      }
    }
  }

  async cloneSegment() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/segments/create-segment",
        { id: this.segmentId, clone: true },
      ]);
    }
  }

  getDisplayName(name) {
    for (let key in this.segmentVariables) {
      let index = this.segmentVariables[key].findIndex((s) => s.name === name);
      if (index > -1) {
        return this.segmentVariables[key][index]["displayName"];
      }
    }
  }
  valueSuffix(name,number){
    switch(name){
      // case "$customer_created_date":
      case "$num_days_from_last_tran":
      if(number>1)
      return  "Days";
      else return "Day";
      break;
      case "$avg_monthly_spend":
      case "$avg_weekly_spend":
      case "$life_time_value":
      case "$avg_spend_per_purchase":
        return this.currencySymbol;
      break;
      case "$avg_monthly_order_count":
      case "$avg_weekly_order_count":
      case "$life_time_order_count":
      case "$num_purchases_in_last_365_days":
      case "$num_purchases_last_6_months":
      case "$num_purchases_this_year":
      case "$num_purchases_last_year":
      case "$num_purchases_last_month":
      case "$num_purchases_last_week":
        if(number>1)
        return  "Orders";
        else return "Order";
        break;
      default:
        return "";

    }
  }
  downloadCustomerInSegment(name){
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "30%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Export List Of Customers", ['POS_PAGE']),
        segmentName: name,
        downloadButton_1: this._i18nDynamicTranslate.transform("Export", ['POS_PAGE']),
        downloadButton_2: "CSV",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let prefix = "";
        if(result.decision === 'EXCEL'){
          prefix = ".xlsx"
        }
        else{
          prefix = ".csv"
        }
       let filePath = "output/"+result.fileName + prefix;
      //  let storeName = window.sessionStorage.getItem("storeName");
      let sendToEmailId = result.emailId;
       let storeName = this.tokenStorage.getStoreName();
       this.apiCall.exportCustomerData(this.segmentId, result.decision, filePath, storeName === 'ADMIN' ? null : storeName, sendToEmailId ).subscribe((response) =>{
          console.log(response);
            if (response['body']) {
          // window.open("www.qubriux.skellam.com");
          // window.location.href = response['body'];
          // window.open(response['body'])
          this.dialog.open(SuccessDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "25%",
            data: {
              message: response['body'],
              buttonText: "Ok",
            },
          });
        }
        else{
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something Went Wrong"), 2000);
        }
       },(err)=>{
            this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Something Went Wrong"), 2000);
       })
      }
    });
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }

  isDateString(value) {
    // RE to check if operand has date
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return dateFormatRegex.test(value);
  }
  
}
