import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Event, NavigationEnd, Router } from "@angular/router";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SendTestEmailDialogComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: "app-offer-report",
  templateUrl: "./offer-report.component.html",
  styleUrls: ["./offer-report.component.css"],
})
export class OfferReportComponent implements OnInit {
  constructor(
    private tokenStorage: TokenStorageService,
    private snackBar: SnackbarCollection,
    private apiCall: ApiCallService,
    private date_format: getdateformatService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url.split(";")[0];
      }
    });
  }

  selectedStatus = [];
  selectedOffer = "";
  loading = false;
  offerList = [];
  page = 1;
  size = 10;
  sortBy = ["DATE"];
  order = ["DESC"];
  reportType = "";
  typeOfStatus = [
    {
      type: "ACTIVE",
      name: "Active",
    },
    {
      type: "REDEEMED",
      name: "Redeemed",
    },
    {
      type: "EXPIRED",
      name: "Expired",
    },
  ];
  filter = {
    label: "",
  };
  range = new FormGroup({
    start: new FormControl(this.getStartingMonthOfOffer()),
    end: new FormControl(new Date()),
  });
  currentUrl = "";

  selectedAbNodeId;
  selectedStrategyId;
  kpiOptions: { key: string, value: string }[] = [];
  selectedKpi: string;
  pathOptions: { key: string, subStrategyPath: string }[] = [];
  selectedPath;
  @Input() selectedTab: string = "";

  ngOnInit(): void {
    if(this.selectedTab.length !== 0){
      // this.reportType = this.selectedTab === "offerReport" ? "offer" : "";
      if(this.selectedTab === 'offerReport'){
        this.reportType = "offer";
        this.getOffers(this.page, this.size, this.sortBy, this.order);
        this.selectAllOptions(this.typeOfStatus);
      }
    }
    else{
      if(this.currentUrl.includes("/offer/report")){
        this.reportType = "offer";
        this.getOffers(this.page, this.size, this.sortBy, this.order);
        this.selectAllOptions(this.typeOfStatus);
      } else{
        this.reportType = "journey";
        this.getAbTestingCampaigns(this.page, this.size);
      }
    }
  }

  getOffers(page, size, sortBy, order) {
    let data: any = {};

    data.page = page;
    data.size = size;
    data.status = [];
    data.channel = [];
    data.sortby = sortBy;
    data.order = order;

    this.getOffersApi(data).then(
      (result) => {
        if (result["message"] == "success") {
          let listData = result["body"]["offers"];
          let offerCount = result["body"]["totalCount"];
          this.offerList = [...this.offerList, ...listData];
          if (this.size < offerCount) {
            this.size = this.size + 10;
            this.page = this.page + 1;
            this.getOffers(this.page, size, this.sortBy, this.order);
          }
        }
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  getAbTestingCampaigns(page, size) {
    let data: any = {};
    data.page = page;
    data.size = size;

    this.getABtestingCampaignsApi(data).then(
      (result) => {
        if (result["message"] == "success") {
          let listData = result["body"];
          let offerCount = result["body"][0]["totalCount"];
          this.offerList = [...this.offerList, ...listData];
          if (this.size < offerCount) {
            this.size = this.size + 10;
            this.page = this.page + 1;
            this.getAbTestingCampaigns(this.page, size);
          }
        }
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  getStartingMonthOfOffer(): Date {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  }

  selectAllOptions(value) {
    if (this.selectedStatus.length === value.length) {
      this.selectedStatus = [];
    } else {
      let statusList = [];
      value.forEach((data) => {
        statusList.push(data.type);
      });
      this.selectedStatus = statusList;
    }
  }

  getMaxDate() {
    var currentDate = new Date();
    let startDateMinValue = new Date(
      currentDate.toLocaleString("en-US", {
        timeZone: this.tokenStorage.getMerchantTimeZone(),
      })
    );
    return startDateMinValue;
  }

  onEndDateChange(event) {}

  sendReportViaEmail() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      data: {
        subject: "Send report via email",
        message: "Please enter the email address to which the report should be sent.",
        reportEmail: true,
        downloadOption: ["Excel"],
        buttonNmae: "Send",
      },
      disableClose: true,
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
        let prefix = "";
        if (response["type"].toUpperCase() === "EXCEL") {
          prefix = ".xlsx";
        } else {
          prefix = ".csv";
        }
        let filePath = "output/" + this.selectedOffer + prefix;
        if(this.reportType === 'offer')
          this.sendReport(filePath, response.email, response["type"]);
        else
          this.sendABTestingReport(filePath, response.email, response["type"])
      }
    });
  }

  sendReport(filePath, email, fileType) {
    this.snackBar.openSnackBar(
      "Report download will start in a few minutes.",
      2000
    );
    let data = {
      featureName: "OFFER",
      type: fileType.toUpperCase(),
      filePath: filePath,
      sendToEmailId: email,
      offerExport: {
        offerId: this.selectedOffer,
      },
      isPreview: false,
      reportType: "OFFER"
    };
    if (this.selectedStatus.length > 0) {
      data["offerExport"]["status"] = this.selectedStatus;
    }
    if (this.range?.value?.start) {
      data["fromDate"] = this.datepipe.transform(
        this.range?.value?.start,
        "yyyy-MM-dd"
      );
    }
    if (this.range?.value?.end) {
      data["toDate"] = this.datepipe.transform(
        this.range?.value?.end,
        "yyyy-MM-dd"
      );
    }
    this.getReportsApi(data).then(
      (result) => {
        this.snackBar.openSnackBar(result["body"], 2000);
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  sendABTestingReport(filePath, email, fileType) {
    this.snackBar.openSnackBar(
      "Report download will start in a few minutes.",
      2000
    );
    let data = {
      featureName: "ABTESTING",
      type: fileType.toUpperCase(),
      filePath: filePath,
      sendToEmailId: email,
      featureId: this.selectedStrategyId.toString(),
      isPreview: false,
      reportType: "ABTESTING",
      abTestingPath: this.selectedPath === "null" ? null : this.selectedPath,
      abKpiType : this.selectedKpi
    };
    if (this.range?.value?.start) {
      data["fromDate"] = this.datepipe.transform(
        this.range?.value?.start,
        "yyyy-MM-dd"
      );
    }
    if (this.range?.value?.end) {
      data["toDate"] = this.datepipe.transform(
        this.range?.value?.end,
        "yyyy-MM-dd"
      );
    }
    this.getReportsApi(data).then(
      (result) => {
        this.snackBar.openSnackBar(result["body"], 2000);
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }

  getReportsApi(data) {
    return new Promise((resolve, reject) => {
      this.apiCall.getReportSampleData(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }

  getOffersApi(data) {
    return new Promise((resolve, reject) => {
      this.apiCall
        .getAllOfferListWithPagination(
          data.page,
          data.size,
          data.status,
          data.channel,
          data.sortby,
          data.order
        )
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err.error);
            reject(err.error);
          }
        );
    });
  }

  getABtestingCampaignsApi(data){
    return new Promise((resolve, reject) => {
      this.apiCall
        .getABTestingCampaignList(
          data.size,
          data.page,
          ''
        )
        .subscribe(
          (res) => {
            console.log(res);
            resolve(res);
          },
          (err) => {
            console.log(err.error);
            reject(err.error);
          }
        );
    });
  }

  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }

  onCampaignSelection(val: any) {
    this.selectedOffer = val.abNodeId.toString();
    this.selectedAbNodeId = val.abNodeId;
    this.selectedStrategyId = val.strategyId;
    this.getKpiList();
    this.getPathList();
  }

  compareByAbNodeId = (option: any, selected: string) => option?.abNodeId.toString() === selected;

  getKpiList() {
    this.apiCall.getABNodeMetrices(this.selectedStrategyId).subscribe((res: any) => {
      if (res.message === 'success') {
        this.kpiOptions = Object.keys(res.body).map(key => ({
          key,
          value: res.body[key]
        }));
        if (this.kpiOptions.length > 0) {
          this.selectedKpi = this.kpiOptions[0].key;
        }
      }
    }, (error) => {
      console.log("Error fetching KPI list:", error);
    });
  }

  getPathList() {
    this.apiCall.getABPathAnalytics(this.selectedStrategyId, this.selectedAbNodeId).subscribe((res: any) => {
      if (res.message === 'SUCCESS') {
        this.pathOptions = Object.keys(res.body).map(key => ({
          key,
          subStrategyPath: res.body[key].subStrategyPath
        }));
        this.pathOptions.unshift({
          key: 'All',
          subStrategyPath: "null" //value to represent "All"
      });
      if(this.pathOptions.length > 0) {
        this.selectedPath =  this.pathOptions[0].subStrategyPath;
      }
    }
    }, (error) => {
      console.log("Error fetching Path list:", error);
    });
  }


  
}
