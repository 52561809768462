import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { ProductListDialogComponent } from "../product-list-dialog/product-list-dialog.component";
import { CollectionListDialogComponent } from "../collection-list-dialog/collection-list-dialog.component";
import e from "express";

@Component({
  selector: "app-default-excluysion-dialog-box",
  templateUrl: "./default-excluysion-dialog-box.component.html",
  styleUrls: ["./default-excluysion-dialog-box.component.css"],
})
export class DefaultExcluysionDialogBoxComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DefaultExcluysionDialogBoxComponent>,
    private apiCall: ApiCallService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  @ViewChild("productList") productList: ProductListDialogComponent;
  @ViewChild("categoryList") categoryList: CollectionListDialogComponent;

  mainTopic = "product";
  dataBeanProduct;
  dataBeanCategory;
  dataBeanShow;
  productCategoryType = null;

  ngOnInit(): void {
    if (this.data.actionType.toUpperCase() === "EXCLUSION") {
      this.dataBeanProduct = {
        totalProducts: [],
        selectedProductList: this.data.selectedProductList,
        section: this.data.section,
        listType: this.data.list,
        searchString: null,
        selectedCategory: null,
        actionType: "EXCLUSION",
      };
      // this.setConstants();
      this.dataBeanCategory = {
        allCollection: this.data.productCategoryType,
        selectedCollectionList: this.getCategoryFormat(
          this.data.selectedCategoryList
        ),
        searchString: null,
        actionType: "EXCLUSION",
      };
    } else {
      this.dataBeanShow = {
        type: this.data.type,
        list: this.getInShowFormat(this.data.excludedProductList, 'productName'),
      };
    }
  }

  changeTab(value) {
    this.mainTopic = value;
    if (this.data.actionType.toUpperCase() === "EXCLUSION") {
      if (value === "category") {
        document.getElementById("productListDiv").style.display = "none";
        document.getElementById("categoryListDiv").style.display = "block";
      } else {
        document.getElementById("productListDiv").style.display = "block";
        document.getElementById("categoryListDiv").style.display = "none";
      }
    } else {
      if (value === "category") {
        this.dataBeanShow = {
          type: "excludedCategory",
          list: this.getInShowFormat(this.data.excludedCategoryList, 'productCategory'),
        };
      } else {
        this.dataBeanShow = {
          type: "excludedProduct",
          list: this.getInShowFormat(this.data.excludedProductList, 'productName'),
        };
      }
    }
  }

  getInShowFormat(data, productName) {
    return data.map((product) => ({
      product_name: product[productName],
    }));
  }

  cancel() {
    this.dialogRef.close(false);
  }

  setConstants() {
    this.productCategoryType = [];
    this.apiCall.getConstantProducts().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let result: any = response["body"];
          result = JSON.parse(result);
          for (let i = 0; i < result["productCategories"].length; i++) {
            if (
              this.productCategoryType.indexOf(
                result["productCategories"][i] === -1
              )
            ) {
              this.productCategoryType.push({
                collectionName:
                  result["productCategories"][i].productCategoryName,
                collectionId: result["productCategories"][i].productCategoryId,
              });
            }
          }
        }
      },
      (error) => {}
    );
  }

  getData(data, isProduct) {
    let sendData = {
      excludedProductList: [],
      excludedCategoryList: [],
    };
    if (isProduct) {
      sendData.excludedCategoryList = this.getInExclusionFormat(
        this.categoryList.selectedObj
      );
      sendData.excludedProductList = data.variantIdArray;
    } else {
      sendData.excludedCategoryList = this.getInExclusionFormat(
        data.variantIdArray
      );
      sendData.excludedProductList = this.productList.selectedVariantObj;
    }
    this.dialogRef.close(sendData);
  }

  getInExclusionFormat(allData) {
    let selectedVariantObj = [];
    allData.forEach((element) => {
      let data = {};
      data["productId"] = null;
      data["productName"] = null;
      data["productCategoryId"] = element.collectionId;
      data["productCategory"] = element.collectionName;
      selectedVariantObj.push(data);
    });
    return selectedVariantObj;
  }

  getCategoryFormat(allData) {
    let selectedVariantObj = [];
    allData.forEach((element) => {
      let data = {};
      data["collectionId"] = element.productCategoryId;
      data["collectionName"] = element.productCategory;
      selectedVariantObj.push(data);
    });
    return selectedVariantObj;
  }
}
