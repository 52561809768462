import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared/shared-module";
import { OfferAnalyticsViewComponent } from "./pages";
import { OfferAnalyticsRoutingModule } from "./offer-analytics-routing.module";

@NgModule({
  declarations: [OfferAnalyticsViewComponent],
  imports: [SharedModule, OfferAnalyticsRoutingModule],
})
export class OfferAnalyticsModule {}
