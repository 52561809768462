<div class="w-100" style="height: 100%;">
    <ng-container *ngIf="selectedTab === 'offerReport'">
        <!-- Component 1 -->
        <app-offer-report [selectedTab] = "selectedTab"></app-offer-report>
    </ng-container>
    <ng-container *ngIf="selectedTab === 'loyaltyReport'">
        <!-- Component 1 -->
        <app-report></app-report>
    </ng-container>
    <ng-container *ngIf="selectedTab === 'walletReport'">
        <!-- Component 1 -->
        <app-wallet-report></app-wallet-report>
    </ng-container>
</div>