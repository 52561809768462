<div>
    <div class="qb-main">
        <div class="qb-communication-div">
            <div class="qb-communication-title">Redemption Communication</div>
            <div class="qb-communication-text">
              Add your channel how you want to communicate with your customer and turn on the toggle for the case you want the communication to happen.
            </div>
            <div style="padding-top: 35px; font-size: 18px; font-weight: 600;"
            [ngClass]="{'error-checkbox': errorOnSave && channel.length === 0}">
                Select Channel
            </div>
            <div class="qb-select-channel">
                <span class="parent-product-ln">
                    <mat-checkbox class="example-margin"
                        [checked]="channel.indexOf('EMAIL') !== -1"
                        (change)="changeCommunication($event.checked,'EMAIL')" color="primary">
                        Email
                    </mat-checkbox>
                    <mat-checkbox class="example-margin"
                        [checked]="channel.indexOf('SMS') !== -1"
                        (change)="changeCommunication($event.checked,'SMS')" color="primary">
                        SMS
                    </mat-checkbox>
                    <mat-checkbox class="example-margin"
                        [checked]="channel.indexOf('WHATSAPP') !== -1"
                        (change)="changeCommunication($event.checked,'WHATSAPP')" color="primary">
                        WhatsApp
                    </mat-checkbox>
                </span>
            </div>
            <div style="padding-top: 35px; font-size: 18px; font-weight: 600;"
            [ngClass]="{'error-checkbox': errorOnSave && !isToggleSelected()}">
                Select Communication
            </div>
            <div
            class="qb-communication-step-container" *ngIf="(validWidgets | widgetConfig: 80)"
           >
             <div class="qb-toggle-communication">
                 <div class="qb-d-flex">
                  <div class="qb-communication-heading qb-mr-10">
                    Require OTP for offer redemption
                  </div>
                  <div class="qb-i-container">
                      <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="offerRedemption">
                          <ng-template #offerRedemption class="qb-ng-template-background">
                              <div class="qb-description-text">
                                  <div class="qb-d-inline-flex">Send an OTP to the customer when they try to redeem an offer. The customer will need to enter/supply the OTP during order placement for the offer to be applicable. </div>
                              </div>
                          </ng-template>
                          <div class="qb-i">i</div>
                      </div>
                  </div>
                </div>                 
               <span>
                 <mat-slide-toggle
                   color="accent"
                   [checked]="otpBasedRedemption"
                   (change)="toggleChange($event.checked, 'otpBased')"
                   [(ngModel)]="otpBasedRedemption"
                 ></mat-slide-toggle>
               </span>
             </div>
           </div>
           <div
           class="qb-communication-step-container"
          >
            <div class="qb-toggle-communication">
              <!-- <span class="qb-communication-heading qb-mr-10"
                >Communication on offer redemption</span> -->
                <div class="qb-d-flex">
                  <div class="qb-communication-heading qb-mr-10">
                    Communication on offer redemption
                  </div>
                  <div class="qb-i-container">
                      <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview">
                          <ng-template #popStorePrview class="qb-ng-template-background">
                              <div class="qb-description-text">
                                  <div class="qb-d-inline-flex"> Send communication on redemption of any offer</div>
                              </div>
                          </ng-template>
                          <div class="qb-i">i</div>
                      </div>
                  </div>
                </div>
              <span>
                <mat-slide-toggle
                  color="accent"
                  [checked]="offerBasedCommunication"
                  (change)="toggleChange($event.checked, 'offerBasedCommunication')"
                  [(ngModel)]="offerBasedCommunication"
                ></mat-slide-toggle>
              </span>
            </div>
          </div>

          <div class="padding-left-20">
            <div class="qb-communication-step-container loyalty-coms-title">Send loyalty related communication -</div>
            <div class="qb-communication-step-container">
              <div class="qb-toggle-communication">
                  <div class="qb-d-flex">
                    <div class="qb-communication-heading qb-mr-10">
                      On points earned
                    </div>
                    <div class="qb-i-container">
                        <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview2">
                            <ng-template #popStorePrview2 class="qb-ng-template-background">
                                <div class="qb-description-text">
                                    <div class="qb-d-inline-flex"> Send communication on earning loyalty points</div>
                                </div>
                            </ng-template>
                            <div class="qb-i">i</div>
                        </div>
                    </div>
                  </div>
                <span>
                  <mat-slide-toggle
                    color="accent"
                    [checked]="onPointsEarned"
                    (change)="toggleChange($event.checked, 'onPointsEarned')"
                    [(ngModel)]="onPointsEarned"
                  ></mat-slide-toggle>
                </span>
              </div>
            </div>
  
            <div class="qb-communication-step-container">
              <div class="qb-toggle-communication">
                  <div class="qb-d-flex">
                    <div class="qb-communication-heading qb-mr-10">
                      On points redeemed
                    </div>
                    <div class="qb-i-container">
                        <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview3">
                            <ng-template #popStorePrview3 class="qb-ng-template-background">
                                <div class="qb-description-text">
                                    <div class="qb-d-inline-flex"> Send communication on redemption of loyalty points</div>
                                </div>
                            </ng-template>
                            <div class="qb-i">i</div>
                        </div>
                    </div>
                  </div>
                <span>
                  <mat-slide-toggle
                    color="accent"
                    [checked]="onPointsRedeemed"
                    (change)="toggleChange($event.checked, 'onPointsRedeemed')"
                    [(ngModel)]="onPointsRedeemed"
                  ></mat-slide-toggle>
                </span>
              </div>
            </div>
  
            <div class="qb-communication-step-container">
              <div class="qb-toggle-communication">
                  <div class="qb-d-flex">
                    <div class="qb-communication-heading qb-mr-10">
                      On points expired due to inactivity
                    </div>
                    <div class="qb-i-container">
                        <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview4">
                            <ng-template #popStorePrview4 class="qb-ng-template-background">
                                <div class="qb-description-text">
                                    <div class="qb-d-inline-flex"> Send communication on points expired due to inactivity</div>
                                </div>
                            </ng-template>
                            <div class="qb-i">i</div>
                        </div>
                    </div>
                  </div>
                <span>
                  <mat-slide-toggle
                    color="accent"
                    [checked]="onPointsBurnt"
                    (change)="toggleChange($event.checked, 'onPointsBurnt')"
                    [(ngModel)]="onPointsBurnt"
                  ></mat-slide-toggle>
                </span>
              </div>
            </div>
  
            <div class="qb-communication-step-container">
              <div class="qb-toggle-communication">
                  <div class="qb-d-flex">
                    <div class="qb-communication-heading qb-mr-10">
                      On tier upgrade
                    </div>
                    <div class="qb-i-container">
                        <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview5">
                            <ng-template #popStorePrview5 class="qb-ng-template-background">
                                <div class="qb-description-text">
                                    <div class="qb-d-inline-flex"> Send communication on upgrade of loyalty tier</div>
                                </div>
                            </ng-template>
                            <div class="qb-i">i</div>
                        </div>
                    </div>
                  </div>
                <span>
                  <mat-slide-toggle
                    color="accent"
                    [checked]="onTierUpgrade"
                    (change)="toggleChange($event.checked, 'onTierUpgrade')"
                    [(ngModel)]="onTierUpgrade"
                  ></mat-slide-toggle>
                </span>
              </div>
            </div>
  
            <div class="qb-communication-step-container">
              <div class="qb-toggle-communication">
                  <div class="qb-d-flex">
                    <div class="qb-communication-heading qb-mr-10">
                      On tier downgrade
                    </div>
                    <div class="qb-i-container">
                        <div class="qb-i-div" placement="right" popoverclass="qb-my-custom-class" triggers="mouseenter:mouseleave" [ngbPopover]="popStorePrview6">
                            <ng-template #popStorePrview6 class="qb-ng-template-background">
                                <div class="qb-description-text">
                                    <div class="qb-d-inline-flex"> Send communication on downgrade of loyalty tier</div>
                                </div>
                            </ng-template>
                            <div class="qb-i">i</div>
                        </div>
                    </div>
                  </div>
                <span>
                  <mat-slide-toggle
                    color="accent"
                    [checked]="onTierDowngrade"
                    (change)="toggleChange($event.checked, 'onTierDowngrade')"
                    [(ngModel)]="onTierDowngrade"
                  ></mat-slide-toggle>
                </span>
              </div>
            </div>
          </div>
     
              <div class="info-item" *ngIf="(validWidgets | widgetConfig: 51)">
                <label class="label-bold">Customer Login Page:</label>
      
                <!-- <div class="qb-value-length">
                  <div class="label-value">
                    <span class="cursor-pointer" [matTooltip]="customerLoginUrl" (click)="redirectToCustomerPage(customerLoginUrl)">Customer Loyalty Login</span>
                  </div>
                  <span class="material-icons-outlined qb-value-copy-url" (click)="copyToClipboard(customerLoginUrl)">copy</span>
                </div> -->
                <div class="qb-d-flex qb-login-url">
                  <span class="qb-login-url-text cursor-pointer" (click)="redirectToCustomerPage(customerLoginUrl)">Customer Loyalty Login URL</span>
                  <span class="material-icons-outlined qb-login-icon" matTooltip = "Copy Link"  (click)="copyToClipboard(customerLoginUrl)">copy</span>
                </div>
              </div>

          <div class="qb-save-button" (click) = "saveSelection()">
            <div class="primary-button">
              Save
            </div>          
          </div>
        </div>
    </div>
</div>