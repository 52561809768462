<div>
    <div style="width: 100%;display:flex;justify-content: space-between;align-items: center;">
        <div style="font-weight: bold;font-size: 16px;">Rule preview</div>
        <div>
          <mat-icon (click)="closedialog()" style="cursor: pointer;">close</mat-icon>
        </div>
    </div>
    <div class="rule-container">
        <div class="rule-heading margin-bottom-10">
            <div class="heading-pair">
                <label class="label-style-2">{{'SEGMENT_PAGE.NAME' | i18nTranslate}}</label>
                <div>{{rule.ruleName}}</div>
            </div>
            <div class="rule-heading gap-7">
                <div class="heading-pair">
                    <label class="label-style-2">{{'SEGMENT_PAGE.VALID_FROM' | i18nTranslate}}</label>
                    <div>{{getDateInFormatForDisplay(rule.validFrom)}}</div>
                </div>
                <div class="heading-pair">
                    <label class="label-style-2">{{'SEGMENT_PAGE.TILL' | i18nTranslate}}</label>
                    <div>{{getDateInFormatForDisplay(rule.validTill)}}</div>
                </div>
            </div>
        </div>
        
        <div *ngIf="rule.ruleCategory=='BASIC'" class="heading-pair gap-7 align-center">
            <div class="label-style-3">{{'SEGMENT_PAGE.RULE_PREVIEW' | i18nTranslate}}</div>
            <div class="bold-text">
                {{'SEGMENT_PAGE.FOR_EVERY' | i18nTranslate}} {{rule.ruleDefinition.eventCount || '__'}} {{rule.ruleDefinition.eventDropdownObj.displayName}} <span [style.color]="rule.ruleType=='EXPIRE'?'#d43a2f':'#97cb64'"> {{rule.ruleType.toLowerCase()}} </span><span *ngIf="rule.rewardPoints"> {{rule.rewardPoints}} {{loyaltyPoints.name}} </span>  
                <span *ngIf="rule.rewardPoints && rule.offerIds?.length>0"> and </span><span *ngIf="rule.offerIds && rule.offerIds.length>0"><span *ngFor="let id of rule.offerIds" class="small-box-container"> {{getOfferNameFromId(id)}} </span> Offer(s)</span>
                <!-- Special case for amount spent-->
                <span *ngIf="rule.ruleDefinition.event == '$amount_spent' || rule.ruleDefinition.event == '$order_placed'" class="margin-bottom-10">
                    <span *ngIf="((getExplicitlyExcludedProductNames(rule)?.length > 0 || getExplicitlyExcludedCategoryNames(rule)?.length > 0)) ||  rule.inclusionRule?.length>0 || rule.excludedOrderCategories?.length>0"> But </span>
                    <span *ngIf="rule.excludedOrderCategories?.length>0">
                        earn no points for 
                        <ng-container *ngFor="let cat of rule.excludedOrderCategories">
                            <span class="small-box-container">{{ cat }}</span>
                        </ng-container>
                        Order Category(s)
                    </span>
                    <span *ngIf="rule.excludedOrderCategories?.length>0 && (((getExplicitlyExcludedProductNames(rule)?.length > 0 || getExplicitlyExcludedCategoryNames(rule)?.length > 0)) ||  rule.inclusionRule?.length>0)"> and </span>
                    <span *ngIf="(getExplicitlyExcludedProductNames(rule)?.length > 0 || getExplicitlyExcludedCategoryNames(rule)?.length > 0)">
                        Earn no points on the purchase of 
                      <span *ngIf="getExplicitlyExcludedProductNames(rule).length > 0">
                        <ng-container *ngFor="let productName of getExplicitlyExcludedProductNames(rule)">
                          <span class="small-box-container position-relative">{{ productName }}</span>
                        </ng-container>
                        Products(s)
                      </span>
                      <span *ngIf="getExplicitlyExcludedProductNames(rule).length > 0 && getExplicitlyExcludedCategoryNames(rule).length > 0"> and </span>
                      <span *ngIf="getExplicitlyExcludedCategoryNames(rule).length > 0">
                        <ng-container *ngFor="let categoryName of getExplicitlyExcludedCategoryNames(rule)">
                          <span class="small-box-container">{{ categoryName }}</span>
                        </ng-container>
                        Category(s)
                      </span>
                      <span *ngIf="rule.inclusionRule?.length>0"> and </span>
                    </span>
                    <ng-container *ngFor="let innerRule of rule.inclusionRule;let k = index">
                        <span *ngIf="k>0"> and </span>
                        [ {{'SEGMENT_PAGE.FOR_EVERY' | i18nTranslate}} {{innerRule.ruleDefinition.eventCount}} {{rule.ruleDefinition.eventDropdownObj.displayName}}
                        <span *ngIf="innerRule.includedProducts.length > 0">
                            <ng-container *ngFor="let productName of innerRule.includedProducts">
                              <span class="small-box-container position-relative">{{ productName }}</span>
                            </ng-container>
                            Products(s)
                          </span>
                          <span *ngIf="innerRule.includedProducts.length > 0 && innerRule.includedProductCategories.length > 0"> and </span>
                          <span *ngIf="innerRule.includedProductCategories.length > 0">
                            <ng-container *ngFor="let categoryName of innerRule.includedProductCategories">
                              <span class="small-box-container">{{ categoryName }}</span>
                            </ng-container>
                            Category(s)
                          </span>
                        <span [style.color]="innerRule.ruleType=='EXPIRE'?'#d43a2f':'#97cb64'"> {{innerRule.ruleType.toLowerCase()}} </span><span *ngIf="innerRule.rewardPoints != null"> {{innerRule.rewardPoints || '__'}} {{loyaltyPoints.name}} </span> 
                        <span *ngIf="innerRule.offerIds?.length>0 && innerRule.rewardPoints != null"> and </span><span *ngIf="innerRule.offerIds && innerRule.offerIds.length>0"><span *ngFor="let id of innerRule.offerIds" class="small-box-container"> {{getOfferNameFromId(id)}} </span> Offer(s)</span>
                        <span>]</span>
                    </ng-container>
                </span>
            </div>
        </div>
        <div *ngIf="rule.ruleCategory=='ADVANCE_CUSTOM'" class="heading-pair gap-7">
            <div class="label-style-3">{{'SEGMENT_PAGE.RULE_PREVIEW' | i18nTranslate}}</div>
            <div class="bold-text">
                <span>
                    <span *ngIf="rule.ruleType === 'EARN'"><label class="label-style">{{'CAMPAIGN_PAGE.RULE' | i18nTranslate}}:</label> {{'SEGMENT_PAGE.FOR' | i18nTranslate}} </span><span *ngIf="rule.ruleType === 'EXPIRE'"><label class="label-style">Rule:</label> If </span>
                    <span>
                        <span *ngFor="let logicalGroup of rule?.ruleDefinition?.loyaltyRuleSegmentDefinition?.logicalExpressions;let logicalExpIndex = index;">
                            <span *ngIf="logicalGroup.logicalOperator"> {{logicalGroup.logicalOperator}} </span>
                            <span *ngIf="rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.length>1">[</span>
                            <span *ngFor="let comparisonStatement of logicalGroup.comparisonStatements;let compIndex = index" >
                                <span *ngIf="comparisonStatement.logicalOperator"> {{comparisonStatement.logicalOperator}} </span>
            
                                <span *ngIf="rule.ruleType === 'EARN'"> {{comparisonStatement.operand2 || '__'}} {{comparisonStatement.eventDropdownObj.displayName}} </span>
                                <span *ngIf="rule.ruleType === 'EXPIRE'"> {{comparisonStatement.eventDropdownObj.displayName}} {{'SEGMENT_PAGE.LESS_THAN' | i18nTranslate}} {{comparisonStatement.operand2 || '__'}}  </span>
                            </span>
                            <span *ngIf="rule.ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions.length>1">]</span>
                        </span> 
                        <span> {{'SEGMENT_PAGE.IN_A' | i18nTranslate}} {{rule.ruleFrequencyDisplay}}</span>
                        <span [style.color]="rule.ruleType=='EXPIRE'?'#d43a2f':'#97cb64'"> {{rule.ruleType.toLowerCase()}} </span>
                        <span *ngIf="rule.rewardPoints"> {{rule.rewardPoints || '__'}} {{loyaltyPoints.name}}</span><span *ngIf="rule.rewardPoints && rule.offerIds?.length>0"> and </span><span *ngIf="rule.offerIds && rule.offerIds.length>0"><span *ngFor="let id of rule.offerIds" class="small-box-container"> {{getOfferNameFromId(id)}} </span> Offer(s)</span>
                    </span>
                </span>
            </div>
        </div>
        <div class="heading-pair gap-7" *ngIf="rule.tiersApplicableTo?.length>0">
            <label class="label-style-2">{{'SEGMENT_PAGE.TIERS_APPLICABLE' | i18nTranslate}}</label>
            <div>
                <div class="small-box-container" *ngFor="let tier of rule.tiersApplicableTo">
                    {{tier.name}}
                </div>
            </div>
        </div>
        <div class="heading-pair gap-7" *ngIf="rule.segmentsApplicableTo?.length>0">
            <label class="label-style-2">Segments Applicable</label>
            <div>
                <div class="small-box-container" *ngFor="let segmentId of rule.segmentsApplicableTo">
                    {{getGroupName(segmentId)}}
                </div>
            </div>
        </div>
        <div class="heading-pair gap-7" *ngIf="rule.customerListApplicableTo?.length>0">
            <label class="label-style-2">CustomerList Applicable</label>
            <div>
                <div class="small-box-container" *ngFor="let clId of rule.customerListApplicableTo">
                    {{getGroupName(clId)}}
                </div>
            </div>
        </div>
        <div class="heading-pair-2 gap-7" *ngIf="rule.employeeListApplicableTo?.length>0">
            <label class="label-style">EmployeeList Applicable</label>
            <div>
                <div class="small-box-container" *ngFor="let elId of rule.employeeListApplicableTo">
                    {{getGroupName(elId)}}
                </div>
            </div>
        </div>
        <div class="heading-pair-2 gap-7" *ngIf="rule.isAllCustomers">
            <label class="label-style">Applicable</label>
            <div>
                <div class="small-box-container">
                    My Customers
                </div>
            </div>
        </div>
    </div>
</div>



