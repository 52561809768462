<div class="qb-main-wrapper" data-widget-name="SUPPRESSION LISTS"
  (scroll)="onScroll($event)">
  <div class="qb-mobile-add-contact-div">
    <div class="qb-mobile-contact-length">{{'CUSTOMER_PAGE.SUPPRESSION_LIST' | i18nTranslate}} - {{ length }}</div>
    <div class="qb-mobile-add-button" (click)="openAddContactDialog()" data-widget-name="SUPPRESSION LISTS"
      data-button-name="Add New Contact">
      <div class="material-icons-outlined icons-primary mobile-add-icon">
        add
      </div>
      <div>{{'CUSTOMER_PAGE.ADD' | i18nTranslate}}</div>
    </div>
  </div>
  <div>
    <div class="header-title">
      <app-breadcrumb></app-breadcrumb>
    <div class="add-new-contact-btn">
      <div class="qb-add-button-div">
        <div>
          <div (click)="openAddContactDialog()" data-widget-name="SUPPRESSION LISTS" data-button-name="Add New Contact">
            <div class="primary-button" style="width: 182px;">
              <span class="qb-addContactText">{{'CUSTOMER_PAGE.ADD_NEW_CONTACT' | i18nTranslate}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="qb-heading-div">
    <div class="qb-filterDiv">
      <div>
        <span class="qb-filterTitle">{{'CUSTOMER_PAGE.SUPPRESSION_LIST_FOR' | i18nTranslate}}</span>
        <div class="qb-formDiv">
          <mat-select (selectionChange)="change()" [(ngModel)]="domain" data-widget-name="SUPPRESSION LISTS"
            data-button-name="Suppression List for" disableOptionCentering panelclass="qb-myPanelClass">
            <!-- <mat-option [value]="defaultDomain" data-widget-name="SUPPRESSION LISTS">{{defaultDomain}}</mat-option> -->
            <mat-option *ngFor="let domainObj of domainsArray" data-widget-name="SUPPRESSION LISTS"
              data-button-name="Suppression SMS List for" [attr.data-item-name]="domainObj['domainName']"
              [value]="domainObj['headerId']">{{ domainObj["name"] }}</mat-option>
              <mat-option [value]="'opt_out'">
                Opted Out 
              </mat-option>
          </mat-select>
        </div>
      </div>
      <div class="qb-selectShowFor">
        <span class="qb-filterTitle">{{'CUSTOMER_PAGE.REASON' | i18nTranslate}}</span>
        <div class="qb-formDiv">
          <mat-select (selectionChange)="change()" [(ngModel)]="type" data-widget-name="SUPPRESSION LISTS" name="SUPPRESSION LISTS"
            data-button-name="Show For" disableOptionCentering panelclass="qb-myPanelClass">
            <mat-option data-widget-name="SUPPRESSION LISTS" data-button-name="Show For" data-item-name="All"
              value="All">{{'CUSTOMER_PAGE.ALL' | i18nTranslate}}</mat-option>
            <mat-option data-widget-name="SUPPRESSION LISTS" data-button-name="Show For" data-item-name="Unsubscribed"
              value="Unsubscribed">{{'CUSTOMER_PAGE.UNSUBSCRIBED' | i18nTranslate}}</mat-option>
            <mat-option data-widget-name="SUPPRESSION LISTS" data-button-name="Show For" data-item-name="Manually Added"
              value="Manually Added">{{'CUSTOMER_PAGE.MANUALLY_ADDED' | i18nTranslate}}</mat-option>
            <mat-option data-widget-name="SUPPRESSION LISTS" data-button-name="Show For"
              data-item-name="Consent not given " value="Consent not given">{{'CUSTOMER_PAGE.CONSENT_NOT_GIVEN_IMPORTED_SHOPIFY' | i18nTranslate}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
 
  </div>
  </div>
  <!-- </div> -->
  <div class="qb-tableContent">
    <div>
    <div class="qb-tableDiv">
      <div>
        <span class="qb-supressedText">{{'CUSTOMER_PAGE.SUPPRESSED_CONTACTS' | i18nTranslate}} | {{ length }}</span>
      </div>
      <div class="qb-supressionRectangle">
        <div class="qb-searchBar">
          <input type="text" #search placeholder=" Search by name or phone number"
            (input)="onKeySearch($event,search.value)" />
          <span class="material-icons icons-primary qb-search-icon-padding" *ngIf="search.value.length==0">
            search
          </span>
          <span class="material-icons-outlined icons-primary cursor-pointer qb-fs-19"
            *ngIf="search.value.length>0" (click)="search.value='';onKeySearch(search.value,search.value)">
            close
          </span>
        </div>
      </div>
    </div>
    </div>
    <div class="qb-table-main">
      <div>
      <div class="qb-table-header">
        <div class="qb-header qb-border-div qb-w-27">
          {{'CUSTOMER_PAGE.PHONE_NUMBER' | i18nTranslate}}
        </div>
        <div class="qb-header qb-border-div qb-w-25">
          {{'CUSTOMER_PAGE.NAME' | i18nTranslate}}
        </div>
        <div class="qb-header qb-border-div qb-w-23">
          {{'CUSTOMER_PAGE.DATE_TIME_SUPPRESSION' | i18nTranslate}}
        </div>
        <div class="qb-header">{{'CUSTOMER_PAGE.REASON' | i18nTranslate}}</div>
        <div class="qb-header qb-w-m-0"></div>
      </div>
      </div>
      <!-- </div> -->
      <div *ngIf="contactsLoading" class="qb-mat-spinner-div">
        <app-qubriux-loading  [size]="45" [loadingText]="'Fetching your Customers...'"></app-qubriux-loading>
      </div>
      <!-- <div
        style="
          display: flex;
          justify-content: center;
          margin-top: 25px;
          color: grey;
          font-style: italic;
        "
        *ngIf="
          suppressedContactsArray.length == 0 && !noContact && !contactsLoading
        "
      >
        No contact found
      </div> -->
      <!-- <div
          *ngIf="contactsLoading && !mobileMedia.matches"
          style="
            display: flex;
            justify-content: center;
            padding: 20px 20px 14px;
          "
        >
          <mat-spinner diameter="25"></mat-spinner>
        </div> -->
      <div class="qb-no-contact-div" 
         *ngIf="
            suppressedContactsArray.length == 0 &&
            !noContact &&
            !contactsLoading
          ">
        {{'CUSTOMER_PAGE.NO_CONTACT_FOUND' | i18nTranslate}}
      </div>
      <div class="qb-no-contact-div" 
      *ngIf="noContact && !contactsLoading ">
        {{'CUSTOMER_PAGE.NO_CONTACT_FOUND' | i18nTranslate}}
      </div>
      <div *ngIf="!contactsLoading && suppressedContactsArray.length> 0" class="qb-email-phone-div">
        <div class="qb-contact-details" *ngFor="let contact of suppressedContactsArray">
          <div [matTooltipShowDelay]="500" matTooltip="{{ contact['emailOrPhone'] }}"
            class="qb-contact textOverflow phone-number-div" *ngIf="contact['emailOrPhone'] !== null" style="width: 29%;">
            {{ contact["emailOrPhone"] }}
          </div>
          <div class="qb-contact textOverflow phone-number-div" *ngIf="contact['emailOrPhone'] === null" style="width: 29%;">
            -
          </div>
          <div [matTooltipShowDelay]="500" matTooltip="{{ contact['name'] }}" class="qb-contact textOverflow name-div"
            *ngIf="contact['name'] !== 'null'" style="width: 27%;">
            {{ contact["name"] }}
          </div>
          <div class="qb-contact textOverflow name-div" *ngIf="contact['name'] === 'null'" style="width: 27%;">
            -
          </div>
          <div class="qb-contact date-time-div" style="width: 25%;">
            <span class="qb-date-time">{{ contact["date"] }}</span>
            <span class="qb-date-time-divider"> | </span>
            <span class="qb-date-time time">{{ contact["time"] }}</span>
          </div>
          <div [matTooltipShowDelay]="500" matTooltip="{{ contact['type'] }}" class="qb-contact textOverflow reason-div" style="width: 16%;">
            {{ contact["type"] }}
          </div>

          <span class="material-icons-outlined icons-primary delete-not-allowed"
            *ngIf="contact['type'] === 'Consent not given'" [matTooltipShowDelay]="500"
            [matTooltip]="'COMMON_PAGE.CANNOT_REMOVE_IMP_SHOPIFY' | i18nTranslate" data-button-name="Delete"
            [attr.data-item-name]="contact['emailOrPhone']">
            delete
          </span>
          <span class="material-icons-outlined icons-primary delete-allowed"
            *ngIf="contact['type'] !== 'Consent not given'" [matTooltipShowDelay]="500" [matTooltip]="'COMMON_PAGE.DELETE' | i18nTranslate" (click)="
                openDeleteContactDialog(
                  contact['emailOrPhone'],
                  contact['type'],
                  contact['custId']
                )
              " data-button-name="Delete" [attr.data-item-name]="contact['emailOrPhone']">
            delete
          </span>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <div>
      <mat-paginator [pageIndex]="pageIndex - 1" [length]="length" [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 100]" (page)="refreshPaginatedData($event)">
    </mat-paginator>
    </div>
  </div>
  <!-- <div *ngIf="
      suppressedContactsArray.length == 0 && !contactsLoading && !searching
      ">
    <div>
      <div class="qb-no-contact-added-container">
        <img
          src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/suppression-list-assets/no+contact+illu.png" />
      </div>
      <div class="qb-no-contact">
        {{'CUSTOMER_PAGE.NO_CONTACTS_ADDED' | i18nTranslate}}
      </div>
      <div class="qb-no-contact-text">
        {{'CUSTOMER_PAGE.NO_CONTACTS_IN_SUPPRESSION_LIST' | i18nTranslate}}
      </div>
      <div class="qb-addButton qb-button-div">
        <div (click)="openAddContactDialog()" data-widget-name="SUPPRESSION LISTS" data-button-name="Add New Contact">
          <div class="primary-button" style="width: 182px">
            <span class="material-icons button-icon">add</span>
            <span class="qb-addContactText">{{'CUSTOMER_PAGE.ADD_NEW_CONTACT' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
</div>
<!-- </div> -->