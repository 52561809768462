
<div class="activity-spinner-container" *ngIf="dataLoading">
    <mat-spinner diameter="45"></mat-spinner>
</div>
<div class="qb-h-100" *ngIf="!isNoDataAvailable">
    <app-last-sync-barriers [type]="'analytics_dashboard'" [dataLoading]="dataLoading"></app-last-sync-barriers>
    <div class="qb-analytics-main-container" *ngIf="!dataLoading">
        <div class="qb-analytics-main-sub-container">
            <app-breadcrumb></app-breadcrumb>
            <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Advanced Filters
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-expansion-panel-content>
                    <div class="qb-d-flex" style="justify-content: space-between; flex-direction: column;">
                        <div class="graph-filters">
                            <div *ngFor="let filter of availableFilter | keyvalue">
                                <div class="qb-each-filter" *ngIf="filter.key !== 'Segment'">
                                    <h6 class="graph-options-text">{{getDisplayFilterName(filter.key)}}</h6>
                                    <div class="matSelect-filters">
                                        <mat-select placeholder="Select" multiple class="margin-right-10" [(ngModel)]="selectedFilter[filter.key]" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                                            <div class="qb-all-select" (click)="selectAllOptions(filter.key, filter.value)">
                                                <mat-checkbox color="primary" style="pointer-events: none;" [checked]="selectedFilter[filter.key].length === filter.value.length">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let val of filter.value" [value]="val" selected>{{val}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                                <div class="qb-each-filter" *ngIf="filter.key === 'Segment'">
                                    <h6 class="graph-options-text">{{getDisplayFilterName(filter.key)}}</h6>
                                    <div class="matSelect-filters">
                                        <mat-select placeholder="Select" multiple class="margin-right-10" [(ngModel)]="selectedFilter[filter.key]" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                                            <div class="qb-all-select" (click)="selectAllOptions(filter.key, filter.value)">
                                                <mat-checkbox color="primary" style="pointer-events: none;" [checked]="selectedFilter[filter.key].length === filter.value.length">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let val of filter.value" [value]="val.id" selected>{{val.name}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="apply-filterbutton">
                            <button class="primary-button" (click)="getAnalytics(true)">
                                Apply Filter
                            </button>
                        </div>
                    </div></mat-expansion-panel-content>
                </mat-expansion-panel>
            </mat-accordion>
            
            <!-- <div class="graph-options">
                <h6 class="graph-options-text">View By:</h6>
                <div class="matSelect-options">
                    <mat-select class="margin-right-10" [(ngModel)]="totalCustomerRevenueTimeFrame" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                        <mat-option *ngFor="let val of totalCustomerRevenueFrameList" [value]="val" selected>{{val}}</mat-option>
                    </mat-select>
                </div>
            </div> -->
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'rfmAnalysis'">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">RFM Analysis<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup1"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('scatterPlot')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            Explore customer segments effortlessly with our RFM scatter plot. Recency is on the x-axis, Frequency on the y-axis, and Monetary value is represented by point size. Quickly grasp customer behavior patterns and tailor your strategies for optimal engagement
                        </div>
                    </ng-template>
                    <div class="qb-d-flex qb-fd-coloumn">
                        <div (chartClick)="onChartClick($event)" echarts [options]="scatterPlot" style="height: 300px; width: 100%;"></div>               
                        <div class="qb-d-flex qb-representation-div">
                            <div>
                                <div class="qb-main-container">
                                    <div class="heading qb-mb-15">Customer Count</div>
                                    <div class="container-rfm">
                                      <div class="text-first fs-12">Low</div>
                                      <div class="square s1"></div>
                                      <div class="square s2"></div>
                                      <div class="square s3"></div>
                                      <div class="square s4"></div>
                                      <div class="square s5"></div>
                                      <div class="text-first fs-12">High</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="qb-main-container" style="top: 20px;">
                                    <div class="heading">Revenue</div>
                                    <div class="circle-container">
                                      <div class="text-first fs-12">Low</div>
                                      <div class="circle circle-1"></div>
                                      <div class="circle circle-2"></div>
                                      <div class="circle circle-3"></div>
                                      <div class="circle circle-4"></div>
                                      <div class="text-first fs-12">High</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="qb-analytics-data-container" *ngIf="selectedTab === 'frequencyRecency'">
                <div  class="qb-analytics-graph-container qb-w-49">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Average revenue per Recency<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup1"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('recency_bar')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            Discover spending trends with Total Revenue by Recency. Visualizing customer engagement impact on revenue, it's a powerful tool for targeted strategies and growth insights.
                        </div>
                    </ng-template>
                    <div class="qb-d-flex qb-fd-coloumn">
                        <div (chartClick)="onChartClickRecency($event)" echarts [options]="recencyBucketChart" style="height: 300px; width: 100%;"></div>               
                        <div class="qb-d-flex">
                            <div class="qb-main-container qb-bar">
                                <div class="heading">Customer Count</div>
                                <div class="container">
                                  <div class="text-first fs-12">Low</div>
                                  <div class="square s1"></div>
                                  <div class="square s2"></div>
                                  <div class="square s3"></div>
                                  <div class="square s4"></div>
                                  <div class="square s5"></div>
                                  <div class="text-first fs-12">High</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  class="qb-analytics-graph-container qb-w-49">
                    <div class="qb-d-flex qb-jc-space-between qb-ai-baseline">
                        <div>
                            <h6 class="qb-analytics-heading">Average revenue per Frequency<span class="qb-i-div"
                                popoverclass="qb-my-custom-class"
                                placement="auto"
                                [ngbPopover]="popTierIamgeSetup2"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span></h6>
                        </div>
                        <div class="qb-targeting-tactics-icon">
                            <span
                            class="qb-button"
                            appButtonDebounce
                            [throttleTime]="600"
                            (throttledClick)="openInsight('frequency_bar')"
                            >
                            <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                            &nbsp;&nbsp; Insights
                            </span>
                            <!-- <span class="qb-with-icon">
                              <md-icon>wb_incandescent</md-icon>Targeting Tactics
                            </span> -->
                        </div>
                    </div>
                   
                    <ng-template #popTierIamgeSetup2>
                        <div class="qb-ng-template-text">
                            Unveil spending patterns through Total Revenue by Frequency. This graph showcases revenue across customer interaction frequencies, providing key insights for strategic growth and targeted marketing.
                        </div>
                    </ng-template>
                    <div class="qb-d-flex qb-fd-coloumn">
                        <div (chartClick)="onChartClickFrequency($event)" echarts [options]="frequencyBucketChart" style="height: 300px; width: 100%;"></div>
                        <div class="qb-d-flex">
                            <div class="qb-main-container qb-bar">
                                <div class="heading">Customer Count</div>
                                <div class="container">
                                  <div class="text-first fs-12">Low</div>
                                  <div class="square s1"></div>
                                  <div class="square s2"></div>
                                  <div class="square s3"></div>
                                  <div class="square s4"></div>
                                  <div class="square s5"></div>
                                  <div class="text-first fs-12">High</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div style="width: 100%; height: 2px; background: #22222299;"></div>
            <div class="qb-analytics-data-container">
                <div  class="qb-analytics-graph-container">
                    <h6 class="qb-analytics-heading">Total Revenue by Frequency Bucket<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup2"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup2>
                        <div class="qb-ng-template-text">
                            Unveil spending patterns through Total Revenue by Frequency. This graph showcases revenue across customer interaction frequencies, providing key insights for strategic growth and targeted marketing.
                        </div>
                    </ng-template>
                    <div echarts [options]="frequencyBucketChart" style="height: 300px; width: 900px;"></div>               
                </div>
            </div> -->
        </div>
    </div>
</div>
<div class="loading-indicator" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
    <span>{{'CAMPAIGN_PAGE.LOADING' | i18nTranslate}}</span>
</div>
<div class="no-program-created" *ngIf="isNoDataAvailable">
    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt=""
        style="padding-top: 100px;" height="400px">
    <div style="margin-top: 15px;">
        No Data Available.
    </div>
</div>