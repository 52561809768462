<div class="loyalty-program-container" *ngIf="dataLoaded">
    <div class="loyalty-program-config-panel" [style.width]="(currentStep===4)?'100%':'68%'">

        <div class="main-white-panel">
            <h3 class="loyalty-program-config-panel-heading">{{'SEGMENT_PAGE.PROGRAM_CONFIG' | i18nTranslate}}</h3>
            <div class="loyalty-program-steps-block-container" id="scroll">
                <div class="loyalty-program-step-block">
                    <div class="loyalty-program-step-icon" (click)="goToStep(1,false);" 
                        [style.backgroundColor]="(currentStep==1)?'#4379EE':(maxStepReached>=1)?'#97cb64':'#636466'"
                        [style.cursor]="(currentStep==1)?'not-allowed':(maxStepReached>=1)?'pointer':'not-allowed'">
                        <span *ngIf="completedSteps<1">1</span>
                        <span *ngIf="completedSteps>=1" class="material-symbols-outlined">done</span>
                    </div>
                    <div class="step-text">{{'SEGMENT_PAGE.REWARD_SETUP' | i18nTranslate}}</div>
                </div>
                <div class="loyalty-program-stepper-horizontal-line"></div>
                <div class="loyalty-program-step-block">
                    <div class="loyalty-program-step-icon" (click)="goToStep(2,false);" 
                        [style.backgroundColor]="(currentStep==2)?'#4379EE':(maxStepReached>=2)?'#97cb64':'#636466'"
                        [style.cursor]="(currentStep==2)?'not-allowed':(maxStepReached>=2)?'pointer':'not-allowed'">
                        <span *ngIf="completedSteps<2">2</span>
                        <span *ngIf="completedSteps>=2" class="material-symbols-outlined">done</span>
                    </div>
                    <div class="step-text">{{'SEGMENT_PAGE.TIER_SETUP' | i18nTranslate}}</div>
                </div>
                <div class="loyalty-program-stepper-horizontal-line"></div>
                <div class="loyalty-program-step-block">
                    <div class="loyalty-program-step-icon" (click)="goToStep(3,true);"
                        [style.backgroundColor]="(currentStep==3)?'#4379EE':(maxStepReached>=3)?'#97cb64':'#636466'"
                        [style.cursor]="(currentStep==3)?'not-allowed':(maxStepReached>=3)?'pointer':'not-allowed'">
                        <span *ngIf="completedSteps<3">3</span>
                        <span *ngIf="completedSteps>=3" class="material-symbols-outlined">done</span>
                    </div>
                    <div class="step-text">{{'SEGMENT_PAGE.RULE_SETUP' | i18nTranslate}}</div>
                </div>
                <div class="loyalty-program-stepper-horizontal-line"></div>
                <div class="loyalty-program-step-block">
                    <div class="loyalty-program-step-icon" (click)="goToStep(4,true);"
                        [style.backgroundColor]="(currentStep==4)?'#4379EE':(maxStepReached>=4)?'#97cb64':'#636466'"
                        [style.cursor]="(currentStep==4)?'not-allowed':(maxStepReached>=4)?'pointer':'not-allowed'">
                        <span *ngIf="completedSteps<4">4</span>
                        <span *ngIf="completedSteps>=4" class="material-symbols-outlined">done</span>
                    </div>
                    <div class="step-text">{{'SEGMENT_PAGE.CONFIRMATION' | i18nTranslate}}</div>
                </div>           
            </div>

            <div *ngIf="currentStep===1" class="custom-padding-1">
                <div class="flex" >
                    <div class="title-label">{{'SEGMENT_PAGE.REWARD_COIN_SETUP' | i18nTranslate}}</div>
                </div>
                <div class="reward-point-text">{{'SEGMENT_PAGE.REWARD_POINT_INFO_MSG' | i18nTranslate}}</div>
            </div>
            
            <div *ngIf="currentStep===2" class="custom-padding-1">
                <div class="flex-with-gap">
                    <div>
                        <div class="title-label">{{'SEGMENT_PAGE.TIER_SETUP' | i18nTranslate}}</div>
                    </div>
                    <div class="flex">
                        <button *ngIf="tierMap?.length>1" class="primary-button nav-button margin-right-5 button-hover-effect" (click)="scrollToNext('tier')" [matTooltip]="'Scroll to next tier'">
                            <span class="material-symbols-outlined">
                                keyboard_arrow_down
                            </span>
                        </button>
                        <button *ngIf="tierMap?.length>1" class="primary-button nav-button button-hover-effect" (click)="scrollToPrev('tier')" [matTooltip]="'Scroll to previous tier'">
                            <span class="material-symbols-outlined">
                                keyboard_arrow_up
                            </span>
                        </button>
                    </div>    
                </div>
                <div class="reward-point-text">{{'SEGMENT_PAGE.TIER_SETUP_INFO_MSG' | i18nTranslate}}</div>
            </div>
            
            <div *ngIf="currentStep===3" class="custom-padding-1">
                <div class="flex-with-gap" >
                    <div>
                        <div class="title-label">{{'SEGMENT_PAGE.RULE_SETUP' | i18nTranslate}}
                        </div>
                    </div>
                    <div class="flex">
                        <button *ngIf="loyaltyRules?.length>1" class="primary-button nav-button margin-right-5 button-hover-effect" (click)="scrollToNext('rule')" [matTooltip]="'Scroll to next rule'">
                            <span class="material-symbols-outlined">
                                keyboard_arrow_down
                            </span>
                        </button>
                        <button *ngIf="loyaltyRules?.length>1" class="primary-button nav-button button-hover-effect" (click)="scrollToPrev('rule')" [matTooltip]="'Scroll to previous rule'">
                            <span class="material-symbols-outlined">
                                keyboard_arrow_up
                            </span>
                        </button>
                        <mat-slide-toggle class="margin-left-10 label-style-2" [(ngModel)]="showOnlyCampaignRules">Show Only Journey Rules</mat-slide-toggle>
                    </div>
                </div>
                <div class="reward-point-text">{{'SEGMENT_PAGE.RULE_SETUP_MESSAGE' | i18nTranslate}} {{'SEGMENT_PAGE.RULE_SETUP_INFO_MSG' | i18nTranslate}}</div>
            </div>
        </div>

        <div class="step-1 main-white-panel" *ngIf="currentStep===1">

            <label class="label-style">{{'SEGMENT_PAGE.REWARD_COIN_NAME' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
            <div class="rp-name-inp-container" id="coinNameInput">
                <input class="rp-name-inp" type="text" placeholder="Enter your loyalty point name" [(ngModel)]="loyaltyPoints.name" maxlength="30" #inp (keydown)="resetErrorState('coinNameInput')">
                <div  class="inp-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
            </div>

            <div class="title-label-smaller">{{'SEGMENT_PAGE.COIN_ICON' | i18nTranslate}}<span class="qb-imp-color">*</span></div>
            <label class="label-style">{{'SEGMENT_PAGE.SELECT_REWARD_COIN_ICON' | i18nTranslate}}</label>
            <div class="default-coin-containers" id="coinImgInput">
                <img width="48px" height="48px" *ngFor="let imgUrl of coinImgUrls" class="coin" [src]="imgUrl" (click)="selectImg(imgUrl,'coin')" alt="coinImg"
                    [style.borderColor]="loyaltyPoints.logoPath==imgUrl?'#0183FB':''" [style.borderWidth]="loyaltyPoints.logoPath==imgUrl?'3px':''" [style.borderStyle]="loyaltyPoints.logoPath==imgUrl?'solid':''">
                <div class="upload-image-square" (click)="fileInput.click()">
                    <span class="material-symbols-outlined upload-icon">
                        cloud_upload 
                    </span>
                </div>
            </div>
            <p class="or-text">---OR---</p>
            <label class="label-style">{{'SEGMENT_PAGE.UPLOAD_ICON_REWARD_COIN' | i18nTranslate}}</label>
            <div class="upload-coin-img" (click)="fileInput.click()">
                <p class="margin-0">
                    {{'SEGMENT_PAGE.UPLOAD_YOUR_ICON' | i18nTranslate}}
                </p>
                <span class="material-symbols-outlined">
                    cloud_upload
                </span>
            </div>
            <input type="file" accept=".png, .jpg" #fileInput (change)="setUploadedFile($event,'coin')" hidden="true"/>

        </div>

        <div class="step-2" *ngIf="currentStep===2">
            
            <div class="each-tier main-white-panel" *ngFor="let tier of tierMap;let tierIndex = index">
                <div class="flex space-between">
                    <div>
                        <div class="title-label-smaller">{{'SEGMENT_PAGE.TIER_INDEX_SETUP' | i18nTranslate : tierIndex+1}} </div>
                        <div class="label-style margin-botton-20">{{'SEGMENT_PAGE.FILL_DETAILS_BELOW' | i18nTranslate}}</div>
                    </div>
                    <div>
                        <svg class="cursor-pointer" *ngIf="tierMap.length>1" (click)="openDeleteConfirmDialog('tier',tierIndex)" [matTooltip]="'Delete tier'" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.03125 1.5C5.9375 1.5 5.875 1.5625 5.8125 1.625L5.21875 2.5H9.75L9.15625 1.625C9.125 1.5625 9.03125 1.5 8.9375 1.5H6.03125ZM11.5625 2.5H11.9688H13.5H13.75C14.1562 2.5 14.5 2.84375 14.5 3.25C14.5 3.6875 14.1562 4 13.75 4H13.375L12.625 14.1562C12.5312 15.2188 11.6875 16 10.625 16H4.34375C3.28125 16 2.4375 15.2188 2.34375 14.1562L1.59375 4H1.25C0.8125 4 0.5 3.6875 0.5 3.25C0.5 2.84375 0.8125 2.5 1.25 2.5H1.5H3H3.40625L4.5625 0.78125C4.875 0.3125 5.4375 0 6.03125 0H8.9375C9.53125 0 10.0938 0.3125 10.4062 0.78125L11.5625 2.5ZM11.875 4H3.09375L3.84375 14.0625C3.875 14.3125 4.09375 14.5 4.34375 14.5H10.625C10.875 14.5 11.0938 14.3125 11.125 14.0625L11.875 4Z" fill="#ff4500"/>
                        </svg>
                    </div>
                </div>
                

                <div class="flex rc-conversion-conatainer  max-width-100">
                    <div class="rc-values-container margin-right-40">
                        <label class="label-style">{{'SEGMENT_PAGE.TIER_NAME' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container" [id]="'tierNameInput'+tierIndex">
                            <input class="rp-name-inp" type="text" placeholder="Enter tier name" [(ngModel)]="tier.name" maxlength="30" (keydown)="resetErrorState()" #inp>
                            <div class="inp-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                    <div class="rc-values-container">
                        <label class="label-style">{{'SEGMENT_PAGE.REWARD_COIN_GET_STARTED' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container smaller-inp-container" [id]="'tierPointsInput'+tierIndex">
                            <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                            <input class="rp-name-inp" type="number" placeholder="Points" [(ngModel)]="tier.entryPoints" (keydown)="numberInputHandler($event,10,'number')" (keyup)="resetErrorState()" #inp2 maxlength="10" min="0" max="9999999999">
                            <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                </div>

                <div class="flex rc-conversion-conatainer  max-width-100">
                    <div class="rc-values-container flex-80  margin-right-40">
                        <label class="label-style">{{'SEGMENT_PAGE.TIER_DESCRIPTION' | i18nTranslate}}</label>
                        <div class="rp-name-inp-container max-width-100">
                            <input class="rp-name-inp" type="text" placeholder="Enter your tier description" [(ngModel)]="tier.description" maxlength="200" #inp3>
                            <div class="inp-text-left">{{inp3.getAttribute('maxlength') - inp3.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                    <div class="rc-values-container flex-20">
                        <label class="label-style">{{'SEGMENT_PAGE.LEVEL' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container smaller-inp-container" [id]="'levelInput'+tierIndex">
                            <mat-select [(ngModel)]="tier.level" [disabled]="tierMap.length == 1" [ngClass]="{'no-arrow': tierMap.length == 1}" (ngModelChange)="resetErrorState()">
                                <mat-option *ngFor="let tier of tierMap;let tierIndex2 = index" [value]="tierIndex2+1" selected>{{tierIndex2+1}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="title-label-smaller">{{'SEGMENT_PAGE.TIER_ICON' | i18nTranslate}}<span class="qb-imp-color">*</span><span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="right"
                        [ngbPopover]="popTierIamgeSetup"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span>
                </div>  
                <ng-template #popTierIamgeSetup>
                    <div class="qb-ng-template-text">
                        {{'SEGMENT_PAGE.TIER_IMG_INFO_MSG' | i18nTranslate}}
                    </div>
                </ng-template>
                <label class="label-style">{{'SEGMENT_PAGE.SELECT_TIER_ICON' | i18nTranslate}}</label>
                <div class="default-coin-containers" [id]="'tierImgInput'+tierIndex">
                    <ng-container *ngFor="let imgObj of tierImgUrls;let imageIndex = index">
                        <!-- *ngIf="imgObj.chosen == -1 || imgObj.chosen == tierIndex" -->
                        <img width="48px" height="48px"  class="coin" [src]="imgObj.url" (click)="selectImg(imgObj,'tier',tierIndex,imageIndex)" alt="tierImg"
                        [style.borderColor]="tier.logoPath==imgObj.url?'#0183FB':''" 
                        [style.borderWidth]="tier.logoPath==imgObj.url?'3px':''" 
                        [style.borderStyle]="tier.logoPath==imgObj.url?'solid':''"
                        >
                    </ng-container>
                    <div class="upload-image-square" (click)="fileInput2.click()">
                        <span class="material-symbols-outlined upload-icon">
                            cloud_upload 
                        </span>
                    </div>
                </div>
                <p class="or-text">---OR---</p>
                <label class="label-style">{{'SEGMENT_PAGE.UPLOAD_ICON_TIER' | i18nTranslate}}</label>
                <div class="upload-coin-img" (click)="fileInput2.click()">
                    <p class="margin-0">
                        {{'SEGMENT_PAGE.UPLOAD_YOUR_ICON' | i18nTranslate}}
                    </p>
                    <span class="material-symbols-outlined">
                        cloud_upload
                    </span>
                </div>
                <input type="file" accept=".png, .jpg" #fileInput2 (change)="setUploadedFile($event,'tier',tierIndex)" hidden="true"/>

                <div class="flex">
                    <div class="title-label-smaller">{{'SEGMENT_PAGE.REWARD_COIN_CONV' | i18nTranslate}}</div>
                    <span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="right"
                        [ngbPopover]="popRcSetup"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span>
                    <ng-template #popRcSetup>
                        <div class="qb-ng-template-text">
                            {{'SEGMENT_PAGE.HOW_MUCH_REDEEMED_INFO' | i18nTranslate}}
                        </div>
                    </ng-template>
                </div>
                <div class="flex rc-conversion-conatainer">
                    <div class="rc-values-container">
                        <label class="label-style">{{'SEGMENT_PAGE.NUMBER_REWARD_COINS' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container smaller-inp-container" [id]="'pointsInput'+tierIndex">
                            <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                            <input class="rp-name-inp" type="number" placeholder="Enter Points" [(ngModel)]="tier.conversion.points" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp4 maxlength="10">
                            <div class="inp-text-left">{{inp4.getAttribute('maxlength') - inp4.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                    <p class="rc-conversion-equals">=</p>
                    <div class="rc-values-container">
                        <label class="label-style">{{'SEGMENT_PAGE.AMT_IN_CURRENCY' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                        <div class="rp-name-inp-container smaller-inp-container" [id]="'amountInput'+tierIndex">
                            {{currencySymbol}}
                            <input class="rp-name-inp" type="number" placeholder="Amount" [(ngModel)]="tier.conversion.amount" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp5 maxlength="10" min="0" max="9999999999">
                            <div class="inp-text-left">{{inp5.getAttribute('maxlength') - inp5.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="flex">
                        <div class="title-label-smaller margin-bottom-10">{{'CAMPAIGN_PAGE.COIN_REDEMPTION_LIMIT' | i18nTranslate}}</div>
                        <span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="right"
                        [ngbPopover]="popRl"
                        triggers="mouseenter:mouseleave"
                        >i
                        </span>
                        <ng-template #popRl>
                            <div class="qb-ng-template-text">
                                {{'SEGMENT_PAGE.LIMIT_COINS_PER_ORDER' | i18nTranslate}}
                            </div>
                        </ng-template>
                    </div>
                    <div>
                        <mat-radio-group [(ngModel)]="tier.coinRedemptionLimit.type" >
                            <mat-radio-button value="coins" color="primary">{{'SEGMENT_PAGE.NUMBER_COINS' | i18nTranslate}}</mat-radio-button>
                            <mat-radio-button value="percentage" color="primary">{{'SEGMENT_PAGE.PER_TOTAL_BILL_AMT' | i18nTranslate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div>
                        <div class="rp-name-inp-container smaller-inp-container margin-top-12" *ngIf="tier.coinRedemptionLimit.type == 'coins'" [id]="'coinRedemptionLimitInput'+tierIndex">
                            <input class="rp-name-inp" type="number" placeholder="Enter Points" [(ngModel)]="tier.coinRedemptionLimit.value" (keydown)="numberInputHandler($event,10,'number')" (keyup)="resetErrorState()" #inp5 maxlength="10" min="0" max="9999999999">
                            <div class="inp-text-left">{{inp5.getAttribute('maxlength') - inp5.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                        <div class="rp-name-inp-container smaller-inp-container margin-top-12" *ngIf="tier.coinRedemptionLimit.type == 'percentage'" [id]="'coinRedemptionLimitInput'+tierIndex">
                            <input class="rp-name-inp" type="number" placeholder="Enter Percentage" [(ngModel)]="tier.coinRedemptionLimit.value" (keydown)="numberInputHandler($event,3,'number')" (keyup)="resetErrorState()" #inp6 maxlength="3" min="0" max="100">
                            <div class="inp-text-left">{{inp6.getAttribute('maxlength') - inp6.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                        </div>
                    </div>
                </div>

                <div class="flex max-width-100 exp-div">
                    <div class="margin-right-20">
                        <div class="flex">
                            <div class="title-label-smaller margin-bottom-10">{{'SEGMENT_PAGE.EXPIRE_COINS' | i18nTranslate}}</div>
                            <mat-checkbox class="margin-left-10" color="primary" [checked]="tier.loyaltyPointsExpiryConfig" (change)="checkExpiry(tier)">
                            </mat-checkbox>
                        </div>
                        <div *ngIf="tier.loyaltyPointsExpiryConfig" class="points-expiry-container">
                            <div>{{'SEGMENT_PAGE.AFTER' | i18nTranslate}}</div>
                            <div class="rp-name-inp-container smaller-inp-container margin-top-12 margin-left-10" [id]="'expDurationInput'+tierIndex">
                                <input class="rp-name-inp" type="number" placeholder="Enter Duration" [(ngModel)]="tier.loyaltyPointsExpiryConfig.duration" (keydown)="numberInputHandler($event,2,'number')" 
                                    (keyup)="resetErrorState()" #inp9 maxlength="2" min="0" max="99">
                                <div class="inp-text-left">{{inp9.getAttribute('maxlength') - inp9.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                            </div>
                            <div class="rp-name-inp-container smaller-inp-container margin-left-10">
                                <mat-select [(ngModel)]="tier.loyaltyPointsExpiryConfig.timeFrame">
                                    <mat-option *ngFor="let timeFrame of timeFrameOptions1;" [value]="timeFrame.value" selected>{{timeFrame.display | i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="margin-right-20 notify-div">
                        <div class="flex">
                            <div class="title-label-smaller margin-bottom-10">Notify Points Expiry</div>
                            <mat-checkbox class="margin-left-10" color="primary" [checked]="tier.expiryConfigForPushNotification" (change)="checkNotification(tier)" [disabled]="!tier.loyaltyPointsExpiryConfig" 
                                [matTooltip]="!tier.loyaltyPointsExpiryConfig?'Notification can be enabled only if tier points expiry is enabled':''">
                            </mat-checkbox>
                        </div>
                        <div *ngIf="tier.expiryConfigForPushNotification" class="points-expiry-container">
                            <div>Before</div>
                            <div class="rp-name-inp-container smaller-inp-container margin-top-12 margin-left-10" [id]="'notiDurationInput'+tierIndex">
                                <input class="rp-name-inp" type="number" placeholder="Enter Duration" [(ngModel)]="tier.expiryConfigForPushNotification.duration" (keydown)="numberInputHandler($event,2,'number')" 
                                    (keyup)="resetErrorState()" #inp31 maxlength="2" min="0" max="99">
                                <div class="inp-text-left">{{inp31.getAttribute('maxlength') - inp31.value.length}} {{'SEGMENT_PAGE.CHAR_LEFT' | i18nTranslate}}</div>
                            </div>
                            <div class="rp-name-inp-container smaller-inp-container margin-left-10">
                                <mat-select [(ngModel)]="tier.expiryConfigForPushNotification.timeFrame">
                                    <mat-option *ngFor="let timeFrame of timeFrameOptions2;" [value]="timeFrame.value" selected>{{timeFrame.display | i18nDynamicTranslate: 'POS_PAGE'}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="add-new-tier" (click)="addNewTier()" >
                <span class="material-symbols-outlined">
                    add_circle
                </span> <span>  {{'SEGMENT_PAGE.ADD_NEW_TIER' | i18nTranslate}}</span>
            </div>
             

        </div>

        <div class="step-3" *ngIf="currentStep===3">

            <div *ngFor="let rule of loyaltyRules;let ruleIndex = index" [style.cursor]="rule.strategyId ? 'not-allowed' : 'unset'" [title]="rule.strategyId ? 'Cannot edit as rule is currently active in '+rule.strategyName+' journey.' : ''">
                <div *ngIf="!showOnlyCampaignRules || (showOnlyCampaignRules && rule.isCampaignRule)" class="each-rule main-white-panel" 
                        [style.backgroundColor]="rule.validTillDateObj && rule.validTillDateObj < minDate?'#e87d72':'white'"
                        [ngClass]="{'disabled-container': rule.strategyId}"
                    >
                    <div class="flex">
                        <div>
                            <div class="title-label-smaller">{{'SEGMENT_PAGE.RULE_INDEX_SETUP' | i18nTranslate : ruleIndex+1}} </div>
                            <div class="label-style margin-botton-20">{{'SEGMENT_PAGE.FILL_RULE_DETAILS_BELOW' | i18nTranslate}}</div>
                        </div>
                        <div class="margin-left-auto rule-heading-right">
                            <svg class="cursor-pointer" *ngIf="loyaltyRules.length>1" (click)="openDeleteConfirmDialog('rule',ruleIndex)" [matTooltip]="'Delete rule'" width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.03125 1.5C5.9375 1.5 5.875 1.5625 5.8125 1.625L5.21875 2.5H9.75L9.15625 1.625C9.125 1.5625 9.03125 1.5 8.9375 1.5H6.03125ZM11.5625 2.5H11.9688H13.5H13.75C14.1562 2.5 14.5 2.84375 14.5 3.25C14.5 3.6875 14.1562 4 13.75 4H13.375L12.625 14.1562C12.5312 15.2188 11.6875 16 10.625 16H4.34375C3.28125 16 2.4375 15.2188 2.34375 14.1562L1.59375 4H1.25C0.8125 4 0.5 3.6875 0.5 3.25C0.5 2.84375 0.8125 2.5 1.25 2.5H1.5H3H3.40625L4.5625 0.78125C4.875 0.3125 5.4375 0 6.03125 0H8.9375C9.53125 0 10.0938 0.3125 10.4062 0.78125L11.5625 2.5ZM11.875 4H3.09375L3.84375 14.0625C3.875 14.3125 4.09375 14.5 4.34375 14.5H10.625C10.875 14.5 11.0938 14.3125 11.125 14.0625L11.875 4Z" fill="#ff4500"/>
                            </svg>
                            <div>
                                <mat-slide-toggle class="margin-left-10 label-style-2" [(ngModel)]="rule.isCampaignRule" (change)="onToggleChange($event,rule)">Journey Builder Rule</mat-slide-toggle>
                                <span class="qb-i-div"
                                    style="margin-top: 0px;"
                                    popoverclass="qb-my-custom-class"
                                    placement="left"
                                    [ngbPopover]="popRuleRankCampaignRule"
                                    triggers="mouseenter:mouseleave"
                                    >i
                                </span>
                                <ng-template #popRuleRankCampaignRule>
                                    <div class="qb-ng-template-text">Journey rules are available only in campaign loyalty node. These rules dont have a priority as they always take priority over other loyalty rules when present in an active campaign.</div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="rc-values-container  margin-right-40">
                            <label class="label-style">{{'SEGMENT_PAGE.RULE_NAME' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container max-width-100" [id]="'ruleNameInput'+ruleIndex">
                                <input class="rp-name-inp" type="text" placeholder="Enter your rule name" [(ngModel)]="rule.ruleName" maxlength="30" (keydown)="resetErrorState()" #inp>
                                <div class="inp-text-left">{{inp.getAttribute('maxlength') - inp.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                            </div>
                        </div>
                        <div class="margin-right-20">
                            <div class="qb-d-flex">
                                <label class="label-style">Applicable Groups</label><span class="qb-imp-color">*</span>
                                <span class="qb-i-div"
                                style="margin-top: 0px;"
                                    popoverclass="qb-my-custom-class"
                                    placement="left"
                                    [ngbPopover]="popApplicableTier"
                                    triggers="mouseenter:mouseleave"
                                    >i
                                </span>
                                <ng-template #popApplicableTier>
                                    <div class="qb-ng-template-text">{{'SEGMENT_PAGE.NOT_QUALIFIED_INFO' | i18nTranslate}}</div>
                                </ng-template>
                            </div>
                            <!-- <div class="rp-name-inp-container medium-inp-container" [id]="'appliTierInput'+ruleIndex">
                                <mat-select [(ngModel)]="rule.tiersApplicableTo" multiple placeholder="Select tier(s)" (ngModelChange)="resetErrorState()">
                                    <mat-option *ngFor="let tier of applicableTiers" [value]="tier">{{ tier.name }}</mat-option>
                                </mat-select>
                            </div> -->
                            <div class="rp-name-inp-container medium-inp-container" [id]="'appliGroupInput'+ruleIndex">
                                <div (click)="showMultiselectDropdown('appliGroupInput'+ruleIndex);$event.stopPropagation();getFilteredApplicableCustomerGroupList();setListFlags(rule,null,'applicableGroups',null);" class="offer-select-container">
                                    <div class="offer-select-disp" [matTooltip]="getRuleApplicableGroupsDisplay(rule)">
                                        {{getRuleApplicableGroupsDisplay(rule)}}
                                    </div>
                                    <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'appliGroupInput'+ruleIndex}">keyboard_arrow_down</mat-icon>
                                    <div class="offer-dropdown width-320" *ngIf="showMultiselectDropDownFor == 'appliGroupInput'+ruleIndex">
                                        <input class="op-search" placeholder="Search Applicable Groups" type="text" [(ngModel)]="dropdownSearch" (input)="getFilteredApplicableCustomerGroupList();" (click)="$event.stopPropagation()"/>
                                        <div class="search-filtered-dropdown">
                                            <!-- <div>
                                                <mat-checkbox class="padding-5-10" color="primary" [checked]="rule.isAllCustomers" (change)="selectApplicableGroup('all_customers',rule);" (click)="$event.stopPropagation()"></mat-checkbox>
                                                <div class="dropdown-opt-text" (click)="selectApplicableGroup('all_customers',rule);$event.stopPropagation()" [matTooltip]="ALl Customers">
                                                    All Customers
                                                </div>
                                            </div> -->
                                            <div *ngFor="let groupObj of filteredApplicableCustomerGroupList" [ngClass]="{'disabled-list-item':groupObj.disabled}" class="dropdown-opt">
                                                <mat-checkbox class="padding-5-10" color="primary" 
                                                    [checked]="groupObj.checked" 
                                                    (change)="!groupObj.disabled && selectApplicableGroup(groupObj,rule);" 
                                                    (click)="$event.stopPropagation()"
                                                    [disabled]="groupObj.disabled || groupObj.isActive == false"></mat-checkbox>
                                                <div class="dropdown-opt-text" (click)="!groupObj.disabled && selectApplicableGroup(groupObj,rule);$event.stopPropagation()" [matTooltip]="groupObj.name">
                                                    {{groupObj.name}}
                                                </div>
                                                <div [matTooltip]="groupObj.category" class="list-cat-text">
                                                    {{groupObj.categoryAbbreviation}}
                                                </div>
                                            </div>
                                            <div class="no-offer" *ngIf="filteredApplicableCustomerGroupList.length==0" (click)="$event.stopPropagation()">
                                                {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="flex gap-30">
                        <div>
                            <label class="label-style">{{'SEGMENT_PAGE.VALID_FROM_DATE' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container medium-inp-container" [id]="'validFromInput'+ruleIndex">
                                <input
                                    [(ngModel)] = "rule.validFromDateObj"
                                    [min]="minDate"
                                    class="rp-name-inp"
                                    matInput
                                    [matDatepicker]="picker1"
                                    placeholder="Choose a date"
                                    readonly
                                    (dateChange)="datePicked(rule,'from')"
                                    [ngStyle]="rule.validFromDateObj ? { opacity: 0 , width: '0px' } : { width: '100px' }"
                                    (ngModelChange)="resetErrorState()"
                                />
                                <div class="rp-name-inp">
                                    {{rule.validFromDateObj | date: getDateFormat() }}
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker1">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </div>
                        </div>
                        <div>
                            <label class="label-style">{{'SEGMENT_PAGE.VALID_TILL_DATE' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                            <div class="rp-name-inp-container medium-inp-container" [id]="'validTillInput'+ruleIndex">
                                <input
                                    [(ngModel)] = "rule.validTillDateObj"
                                    [min]="rule.validFromDateObj"
                                    class="rp-name-inp"
                                    matInput
                                    [matDatepicker]="picker2"
                                    placeholder="Choose a date"
                                    readonly
                                    (dateChange)="datePicked(rule,'till')"
                                    [ngStyle]="rule.validTillDateObj ? { opacity: 0 , width: '0px' } : { width: '100px' }"
                                    (ngModelChange)="resetErrorState()"
                                />
                                <div class="rp-name-inp">
                                    {{rule.validTillDateObj | date: getDateFormat() }}
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>
                        <div class="rc-values-container flex-20" *ngIf="!rule.isCampaignRule">
                            <label class="label-style">Rule Priority</label><span class="qb-imp-color">*</span>
                            <span class="qb-i-div"
                                style="margin-top: 0px;"
                                popoverclass="qb-my-custom-class"
                                placement="left"
                                [ngbPopover]="popRuleRank"
                                triggers="mouseenter:mouseleave"
                                >i
                            </span>
                            <ng-template #popRuleRank>
                                <div class="qb-ng-template-text">Priority rank determines which rules is applied in case multiple rules with same events are evaluated at the same time with rank 1 having the highest priority</div>
                            </ng-template>
                            <div class="rp-name-inp-container smaller-inp-container" [id]="'rankInput'+ruleIndex">
                                <mat-select [(ngModel)]="rule.priorityId" placeholder="Select a rank" (ngModelChange)="resetErrorState()">
                                    <mat-option *ngFor="let number of getRankNumberArray();" [value]="number" selected>{{number}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
    
                    <div class="flex">
                        <div class="title-label">{{'SEGMENT_PAGE.CONDITION_SETUP' | i18nTranslate}}</div>
                        <span class="qb-i-div"
                            popoverclass="qb-my-custom-class"
                            placement="right"
                            [ngbPopover]="popConditionSetup"
                            triggers="mouseenter:mouseleave"
                            >i
                        </span>
                        <ng-template #popConditionSetup>
                            <div class="qb-ng-template-text">
                                {{'SEGMENT_PAGE.CONDITION_SETUP_INFO_MSG' | i18nTranslate}}
                            </div>
                        </ng-template>
                    </div>
                    <div class="reward-point-text">{{'SEGMENT_PAGE.CONDITION_SETUP_MESSAGE' | i18nTranslate}} </div>
                    
                    <mat-accordion *ngIf="this.dataLoaded">
                        <mat-expansion-panel [expanded]="rule.ruleCategory=='BASIC'" (opened)="onBasicPanelOpened(rule)" #basicPanelRef>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="title-label-smaller">
                                        <span class="material-symbols-outlined qb-imp-color">family_history</span>  {{'SEGMENT_PAGE.BASIC_CONFIG' | i18nTranslate}}
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div>
                                <div *ngIf="rule.ruleCategory=='BASIC'">
                                    <div class="flex basic-rule-box">
                                        <div class="rule-text">{{'SEGMENT_PAGE.FOR_EVERY' | i18nTranslate}}</div>
                                        <div class="basic-values-container">
                                            <label class="label-style">{{'SEGMENT_PAGE.EVENT_FREQ' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                            <div class="rp-name-inp-container rule-value" [id]="'eventCountInput'+ruleIndex">
                                                <input class="rp-name-inp" type="number" placeholder="eg. 2" [(ngModel)]="rule.ruleDefinition.eventCount" [disabled]="!rule.ruleDefinition.eventDropdownObj.valueEditable" (keydown)="numberInputHandler($event,7,rule.ruleDefinition.eventDropdownObj.dataType)"
                                                (keyup)="resetErrorState()" #inp2 maxlength="7" min="0" max="9999999">
                                                <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                            </div>
                                        </div>
                                        <div class="basic-values-container width-24-perc">
                                            <label class="label-style">{{'SEGMENT_PAGE.EVENT_NAME' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                            <div class="rp-name-inp-container rule-value">
                                                <mat-select [(ngModel)]="rule.ruleDefinition.eventDropdownObj" (selectionChange)="setBasicRuleEventDetail(rule)" >
                                                    <mat-option *ngFor="let ruleEvent of ruleEventDropdownValues" [value]="ruleEvent" selected>{{ruleEvent.displayName}}</mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <div class="earn-container">
                                            <div class="rule-text rule-text-4">{{'SEGMENT_PAGE.EARN' | i18nTranslate}}</div>
                                            <div class="rewards-container" [id]="'checkBoxInput'+ruleIndex">
                                                <div class="display-flex-2">
                                                    <mat-checkbox class="padding-top-34" color="primary" [(ngModel)]="rule.hasOffers" (ngModelChange)="onHasOffersChange($event,rule)" [disabled]="this.offerList?.length == 0"></mat-checkbox>
                                                    <div class="width-135">
                                                        <label class="label-style">Offers</label><span *ngIf="rule.hasOffers" class="qb-imp-color">*</span>
                                                        <div class="rp-name-inp-container rule-value padding-5"  [ngClass]="{ 'disabled-div': !rule.hasOffers }" [id]="'offerInput'+ruleIndex">
                                                            <div (click)="rule.hasOffers && showMultiselectDropdown('offer'+ruleIndex);$event.stopPropagation();getFilteredOfferList()" class="offer-select-container"  [ngClass]="{ 'disabled-div': !rule.hasOffers }">
                                                                <div class="offer-select-disp" [title]="getRuleOfferDisplay(rule.offerIds)">
                                                                    {{getRuleOfferDisplay(rule.offerIds)}}
                                                                </div>
                                                                <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'offer'+ruleIndex}">keyboard_arrow_down</mat-icon>
                                                                <div class="offer-dropdown" *ngIf="showMultiselectDropDownFor == 'offer'+ruleIndex">
                                                                    <input class="op-search" placeholder="Search Offer" type="text" [(ngModel)]="dropdownSearch" (input)="getFilteredOfferList();" (click)="$event.stopPropagation()"/>
                                                                    <div class="search-filtered-dropdown">
                                                                        <div *ngFor="let offer of filteredOfferList" class="dropdown-opt" [title]="offer.offerName">
                                                                            <mat-checkbox class="padding-5-10" color="primary" [checked]="rule.offerIds?.includes(offer.id)" (change)="selectRuleOffer(offer,rule);" (click)="$event.stopPropagation()"></mat-checkbox>
                                                                            <div class="dropdown-opt-text" (click)="selectRuleOffer(offer,rule);$event.stopPropagation()">
                                                                                {{offer.offerName}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="no-offer" *ngIf="filteredOfferList.length==0" (click)="$event.stopPropagation()">
                                                                            {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!rule.hasOffers}" >Offers</div>
                                                </div>
                                                <div class="display-flex-2">
                                                    <mat-checkbox color="primary" class="padding-top-34" [(ngModel)]="rule.hasRewardPoints" (ngModelChange)="onHasRewardPointsChange($event,rule)"></mat-checkbox>
                                                    <div class="width-135">
                                                        <label class="label-style">Loyalty Point</label><span *ngIf="rule.hasRewardPoints" class="qb-imp-color">*</span>
                                                        <div class="rp-name-inp-container rule-value" [id]="'rewardPointInput'+ruleIndex">
                                                            <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                                                            <input class="rp-name-inp" type="number" placeholder="eg. 100" [(ngModel)]="rule.rewardPoints" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp3 maxlength="10" [disabled]="!rule.hasRewardPoints" min="0" max="9999999999">
                                                            <div class="inp-text-left">{{inp3.getAttribute('maxlength') - inp3.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!rule.hasRewardPoints}" >{{'SEGMENT_PAGE.POINTS' | i18nTranslate}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SPECIAL CONFIG FOR ORDER CATEGORIES OF AMOUNT SPENT AND ORDER PLACED RULE-->
                                     <div *ngIf="orderCategoriesList?.length>0 && (rule.ruleDefinition.event == '$amount_spent' || rule.ruleDefinition.event == '$order_placed')" class="margin-bottom-10">
                                        <div class="inner-rule-container">
                                            <div class="padding-13">
                                                <div class="title-label-smaller">Excluded Order Categories</div>
                                                <div class="label-style">Order Categories selected here do not receive points from the parent rule.
                                                </div>
                                            </div>
                                            <div class="margin-right-20 center-flex-column">
                                                <div class="qb-d-flex">
                                                    <label class="label-style">Order Categories</label>
                                                    <!-- <span class="qb-i-div"
                                                    style="margin-top: 0px;"
                                                        popoverclass="qb-my-custom-class"
                                                        placement="right"
                                                        [ngbPopover]="popApplicableTier"
                                                        triggers="mouseenter:mouseleave"
                                                        >i
                                                    </span>
                                                    <ng-template #popApplicableTier>
                                                        <div class="qb-ng-template-text">{{'SEGMENT_PAGE.NOT_QUALIFIED_INFO' | i18nTranslate}}</div>
                                                    </ng-template> -->
                                                </div>
                                                <div class="rp-name-inp-container" [id]="'orderCategoryInput'+ruleIndex">
                                                    <div (click)="showMultiselectDropdown('orderCategoryInput'+ruleIndex);$event.stopPropagation();getFilteredCategoryList();setListFlags(rule,null,'orderCategories',null);" class="offer-select-container">
                                                        <div class="offer-select-disp" [matTooltip]="getOrderCategoriesDisplay(rule.excludedOrderCategories)">
                                                            {{getOrderCategoriesDisplay(rule.excludedOrderCategories)}}
                                                        </div>
                                                        <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'orderCategoryInput'+ruleIndex}">keyboard_arrow_down</mat-icon>
                                                        <div class="offer-dropdown sp-pos width-320" *ngIf="showMultiselectDropDownFor == 'orderCategoryInput'+ruleIndex">
                                                            <input class="op-search" placeholder="Search order categories" type="text" [(ngModel)]="dropdownSearch" (input)="getFilteredOrderCategoriesList();" (click)="$event.stopPropagation()"/>
                                                            <div class="search-filtered-dropdown">
                                                                <div *ngFor="let orderCat of filteredOrderCategoriesList" [ngClass]="{'disabled-list-item':orderCat.disabled}" class="dropdown-opt">
                                                                    <mat-checkbox class="padding-5-10" color="primary" 
                                                                        [checked]="orderCat.checked" 
                                                                        (change)="selectOrderCategories(orderCat,rule);" 
                                                                        (click)="$event.stopPropagation()"></mat-checkbox>
                                                                    <div class="dropdown-opt-text" (click)="selectOrderCategories(orderCat,rule);$event.stopPropagation()" [matTooltip]="orderCat.name">
                                                                        {{orderCat.name}}
                                                                    </div>
                                                                </div>
                                                                <div class="no-offer" *ngIf="filteredOrderCategoriesList.length==0" (click)="$event.stopPropagation()">
                                                                    {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                     </div>
                                    <!-- SPECIAL CONFIG FOR AMOUNT SPENT RULE-->
                                    <div *ngIf="rule.ruleDefinition.event == '$amount_spent'">
    
                                        <!-- exclusion logic is maintained in parent rule and doesn't have inner rule in json-->
                                        <div class="inner-rule-container" *ngIf="rule.hasExclusion">
                                            <div class="display-flex-3">
                                                <div>
                                                    <div class="title-label-smaller">Exclusion Rule</div>
                                                    <div class="label-style">Products/Categories selected here do no receive points from the parent rule. 
                                                        They receive no points or receive points defined in any inclusion rule.
                                                    </div>
                                                </div>
                                                <div class="margin-left-auto" *ngIf="rule.inclusionRule?.length==0">
                                                    <button class="delete-button button-hover-effect" title="Delete Exclusion" (click)="deleteExclusion(rule)">
                                                        <span class="material-symbols-outlined">
                                                            delete
                                                            </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="flex basic-rule-box">
                                                <div class="rule-text">Earn no points on the purchase of</div>
                                                <div class="list-selection-container position-relative" [id]="'listInput'+ruleIndex+'-'+'exclusion'">
                                                    <div class="position-relative">
                                                        <label class="label-style">Products</label><span class="qb-imp-color">*</span>
                                                        <div class="rp-name-inp-container rule-value padding-5 width-164">
                                                            <div (click)="showMultiselectDropdown('product'+ruleIndex+'exclusion');$event.stopPropagation();setListFlags(rule,innerRule,'product','exclusion');" class="offer-select-container">
                                                                <div class="offer-select-disp" [matTooltip]="getProductListDisplay(rule.excludedProducts)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                                                    {{getProductListDisplay(rule.excludedProducts)}}
                                                                </div>
                                                                <mat-icon class="down-arrow sp-pos" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'product'+ruleIndex+'exclusion'}" >keyboard_arrow_down</mat-icon>
                                                                <div class="offer-dropdown" *ngIf="showMultiselectDropDownFor == 'product'+ruleIndex+'exclusion'">
                                                                    <input class="op-search" placeholder="Search Products" type="text" 
                                                                           [(ngModel)]="dropdownSearch" (input)="getFilteredProductList()" 
                                                                           (click)="$event.stopPropagation()"/>
                                                                    <cdk-virtual-scroll-viewport itemSize="filteredProductList.length>40?40:filteredProductList.length" class="virtual-scroll-viewport">
                                                                        <div *cdkVirtualFor="let product of filteredProductList; trackBy: trackByProductName" 
                                                                             class="dropdown-opt" 
                                                                             [title]="product.disabled ? 'Already selected in inclusion rule' : product.name"
                                                                             [ngClass]="{'disabled-list-item': product.disabled}">
                                                                          <mat-checkbox class="padding-5-10" color="primary"
                                                                                        [checked]="product.checked"
                                                                                        (change)="!product.disabled && selectProduct(product, rule, null, false);"
                                                                                        [disabled]="product.disabled"
                                                                                        (click)="$event.stopPropagation()">
                                                                          </mat-checkbox>
                                                                          <div class="dropdown-opt-text"
                                                                               (click)="!product.disabled && selectProduct(product, rule, null, false); $event.stopPropagation()">
                                                                            {{product.name}}
                                                                          </div>
                                                                        </div>
                                                                        <div class="no-offer" *ngIf="filteredProductList.length==0" (click)="$event.stopPropagation()">
                                                                            {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                        </div>
                                                                    </cdk-virtual-scroll-viewport>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="rule-text-2"> OR </div>
                                                    <div class="position-relative">
                                                        <label class="label-style">Categories</label><span class="qb-imp-color">*</span>
                                                        <div class="rp-name-inp-container rule-value padding-5 width-164">
                                                            <div (click)="showMultiselectDropdown('category'+ruleIndex+'exclusion');$event.stopPropagation();setListFlags(rule,innerRule,'category','exclusion');" class="offer-select-container">
                                                                <div class="offer-select-disp" [matTooltip]="getCategoryListDisplay(rule.excludedProductCategories)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                                                    {{getCategoryListDisplay(rule.excludedProductCategories)}}
                                                                </div>
                                                                <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'category'+ruleIndex+'exclusion'}">keyboard_arrow_down</mat-icon>
                                                                <div class="offer-dropdown sp-pos" *ngIf="showMultiselectDropDownFor == 'category'+ruleIndex+'exclusion'">
                                                                    <input class="op-search" placeholder="Search categories" type="text" 
                                                                        [(ngModel)]="dropdownSearch" (input)="getFilteredCategoryList()" 
                                                                        (click)="$event.stopPropagation()"/>
                                                                    <cdk-virtual-scroll-viewport [itemSize]="filteredCategoryList.length>40?40:filteredCategoryList.length" class="virtual-scroll-viewport">
                                                                        <div *cdkVirtualFor="let category of filteredCategoryList;trackBy: trackByCategoryName" 
                                                                            class="dropdown-opt" 
                                                                            [title]="category.disabled?'Already selected in inclusion rule':category.name"
                                                                            [ngClass]="{'disabled-list-item':category.disabled}">
                                                                            <mat-checkbox class="padding-5-10" color="primary" 
                                                                                [checked]="category.checked" 
                                                                                (change)="!category.disabled && selectProductCategories(category,rule,null,false);"
                                                                                [disabled]="category.disabled"
                                                                                (click)="$event.stopPropagation()">
                                                                            </mat-checkbox>
                                                                            <div class="dropdown-opt-text" (click)="!category.disabled && selectProductCategories(category,rule,null,false);$event.stopPropagation()">
                                                                                {{category.name}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="no-offer" *ngIf="filteredCategoryList.length==0" (click)="$event.stopPropagation()">
                                                                            {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                        </div>
                                                                    </cdk-virtual-scroll-viewport>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div *ngFor="let innerRule of rule.inclusionRule;let i = index;" class="inner-rule-container">
                                            <div class="display-flex-3">
                                                <div>
                                                    <div class="title-label-smaller">Inclusion Rule {{i+1}}</div>
                                                    <div class="label-style">Products/Categories selected here do no receive points from the parent rule. 
                                                        They receive points based on the below definition and are automatically added to exclusion from parent.
                                                    </div>
                                                </div>
                                                <div class="margin-left-auto">
                                                    <button class="delete-button button-hover-effect" title="Delete Inner Rule" (click)="deleteInclusionRule(rule,i)">
                                                        <span class="material-symbols-outlined">
                                                            delete
                                                            </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="flex basic-rule-box">
                                                <div class="rule-text">{{'SEGMENT_PAGE.FOR_EVERY' | i18nTranslate}}</div>
                                                <div class="basic-values-container small-width">
                                                    <label class="label-style">Amount</label><span class="qb-imp-color">*</span>
                                                    <div class="rp-name-inp-container rule-value" [id]="'eventCountInput'+ruleIndex+'-'+i">
                                                        <input class="rp-name-inp" type="number" placeholder="eg. 2" [(ngModel)]="innerRule.ruleDefinition.eventCount" (keydown)="numberInputHandler($event,7,rule.ruleDefinition.eventDropdownObj.dataType)"
                                                        (keyup)="resetErrorState()" #inp2 maxlength="7" min="0" max="9999999">
                                                        <div class="inp-text-left">{{inp2.getAttribute('maxlength') - inp2.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                                    </div>
                                                </div>
                                                <div class="rule-text">{{rule.ruleDefinition.eventDropdownObj.displayName}} of</div>
                                                <div class="list-selection-container position-relative" [id]="'listInput'+ruleIndex+'-'+i">
                                                    <div class="position-relative">
                                                        <label class="label-style">Products</label><span class="qb-imp-color">*</span>
                                                        <div class="rp-name-inp-container rule-value padding-5 width-164" >
                                                            <div (click)="showMultiselectDropdown('product'+ruleIndex+'-'+i);$event.stopPropagation();setListFlags(rule,innerRule,'product','inclusion');" class="offer-select-container">
                                                                <div class="offer-select-disp" [matTooltip]="getProductListDisplay(innerRule.includedProducts)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                                                    {{getProductListDisplay(innerRule.includedProducts)}}
                                                                </div>
                                                                <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'product'+ruleIndex+'-'+i}">keyboard_arrow_down</mat-icon>
                                                                <div class="offer-dropdown sp-pos" *ngIf="showMultiselectDropDownFor == 'product'+ruleIndex+'-'+i">
                                                                    <input class="op-search" placeholder="Search Products" type="text" 
                                                                        [(ngModel)]="dropdownSearch" (input)="getFilteredProductList()" 
                                                                        (click)="$event.stopPropagation();"/>
                                                                    <cdk-virtual-scroll-viewport itemSize="filteredProductList.length>40?40:filteredProductList.length" class="virtual-scroll-viewport">
                                                                        <div *cdkVirtualFor="let product of filteredProductList;trackBy: trackByProductName"
                                                                            class="dropdown-opt" 
                                                                            [title]="product.disabled?'Already selected':product.name" 
                                                                            [ngClass]="{'disabled-list-item':product.disabled}">
                                                                            <mat-checkbox class="padding-5-10" color="primary" 
                                                                                [checked]="product.checked" 
                                                                                (change)="!product.disabled && selectProduct(product,rule,innerRule,true);"
                                                                                [disabled]="product.disabled"
                                                                                (click)="$event.stopPropagation();">
                                                                            </mat-checkbox>
                                                                            <div class="dropdown-opt-text" (click)="!product.disabled && selectProduct(product,rule,innerRule,true);$event.stopPropagation();">
                                                                                {{product.name}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="no-offer" *ngIf="filteredProductList.length==0" (click)="$event.stopPropagation();">
                                                                            {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                        </div>
                                                                    </cdk-virtual-scroll-viewport>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="rule-text-2"> OR </div>
                                                    <div class="position-relative">
                                                        <label class="label-style">Categories</label><span class="qb-imp-color">*</span>
                                                        <div class="rp-name-inp-container rule-value padding-5 width-164">
                                                            <div (click)="showMultiselectDropdown('category'+ruleIndex+'-'+i);$event.stopPropagation();setListFlags(rule,innerRule,'category','inclusion');" class="offer-select-container">
                                                                <div class="offer-select-disp" [matTooltip]="getCategoryListDisplay(innerRule.includedProductCategories)" [matTooltipPosition]="'left'" [matTooltipShowDelay]="500">
                                                                    {{getCategoryListDisplay(innerRule.includedProductCategories)}}
                                                                </div>
                                                                <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'category'+ruleIndex+'-'+i}">keyboard_arrow_down</mat-icon>
                                                                <div class="offer-dropdown sp-pos" *ngIf="showMultiselectDropDownFor == 'category'+ruleIndex+'-'+i">
                                                                    <input class="op-search" placeholder="Search Categories" type="text" 
                                                                        [(ngModel)]="dropdownSearch" (input)="getFilteredCategoryList()" 
                                                                        (click)="$event.stopPropagation()"/>
                                                                    <cdk-virtual-scroll-viewport [itemSize]="filteredCategoryList.length>40?40:filteredCategoryList.length" class="virtual-scroll-viewport">
                                                                        <div *cdkVirtualFor="let category of filteredCategoryList;trackBy: trackByCategoryName" 
                                                                            class="dropdown-opt"  
                                                                            [title]="category.disabled?'Already selected':category.name"
                                                                            [ngClass]="{'disabled-list-item':category.disabled}"
                                                                            (click)="$event.stopPropagation()">
                                                                            <mat-checkbox class="padding-5-10" color="primary" 
                                                                                [checked]="category.checked" 
                                                                                (change)="!category.disabled && selectProductCategories(category,rule,innerRule,true);"
                                                                                [disabled]="category.disabled"
                                                                                (click)="$event.stopPropagation()">
                                                                            </mat-checkbox>
                                                                            <div class="dropdown-opt-text" (click)="!category.disabled && selectProductCategories(category,rule,innerRule,true);event.stopPropagation()">
                                                                                {{category.name}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="no-offer" *ngIf="filteredCategoryList.length==0" (click)="$event.stopPropagation()">
                                                                            {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                        </div>
                                                                    </cdk-virtual-scroll-viewport>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="earn-container">
                                                    <div class="rule-text rule-text-4">{{'SEGMENT_PAGE.EARN' | i18nTranslate}}</div>
                                                    <div class="rewards-container" [id]="'checkBoxInput'+ruleIndex+'-'+i">
                                                        <div class="display-flex-2">
                                                            <mat-checkbox class="padding-top-34" color="primary" [(ngModel)]="innerRule.hasOffers" (ngModelChange)="onHasOffersChange($event,innerRule)" [disabled]="this.offerList?.length == 0"></mat-checkbox>
                                                            <div class="width-135">
                                                                <label class="label-style">Offers</label><span *ngIf="innerRule.hasOffers" class="qb-imp-color">*</span>
                                                                <div class="rp-name-inp-container rule-value padding-5"  [ngClass]="{ 'disabled-div': !innerRule.hasOffers }" [id]="'offerInput'+ruleIndex+'-'+i">
                                                                    <div (click)="innerRule.hasOffers && showMultiselectDropdown('offer'+ruleIndex+'-'+i);$event.stopPropagation();getFilteredOfferList()" class="offer-select-container"  [ngClass]="{ 'disabled-div': !innerRule.hasOffers }">
                                                                        <div class="offer-select-disp" [title]="innerRule.offerIds" [title]="getRuleOfferDisplay(innerRule.offerIds)">
                                                                            {{getRuleOfferDisplay(innerRule.offerIds)}}
                                                                        </div>
                                                                        <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'offer'+ruleIndex+'-'+i}">keyboard_arrow_down</mat-icon>
                                                                        <div class="offer-dropdown" *ngIf="showMultiselectDropDownFor == 'offer'+ruleIndex+'-'+i">
                                                                            <input class="op-search" placeholder="Search Offer" type="text" [(ngModel)]="dropdownSearch" (input)="getFilteredOfferList();" (click)="$event.stopPropagation()"/>
                                                                            <div class="search-filtered-dropdown">
                                                                                <div *ngFor="let offer of filteredOfferList" class="dropdown-opt" [title]="offer.offerName">
                                                                                    <mat-checkbox class="padding-5-10" color="primary" [checked]="innerRule.offerIds?.includes(offer.id)" (change)="selectRuleOffer(offer,innerRule);" (click)="$event.stopPropagation()"></mat-checkbox>
                                                                                    <div class="dropdown-opt-text" (click)="selectRuleOffer(offer,innerRule);$event.stopPropagation()">
                                                                                        {{offer.offerName}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="no-offer" *ngIf="filteredOfferList.length==0" (click)="$event.stopPropagation()">
                                                                                    {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!innerRule.hasOffers}" >Offers</div>
                                                        </div>
                                                        <div class="display-flex-2">
                                                            <mat-checkbox color="primary" class="padding-top-34" [(ngModel)]="innerRule.hasRewardPoints" (ngModelChange)="onHasRewardPointsChange($event,innerRule)"></mat-checkbox>
                                                            <div class="width-135">
                                                                <label class="label-style">Loyalty Point</label><span *ngIf="innerRule.hasRewardPoints" class="qb-imp-color">*</span>
                                                                <div class="rp-name-inp-container rule-value" [id]="'rewardPointInput'+ruleIndex+'-'+i">
                                                                    <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                                                                    <input class="rp-name-inp" type="number" placeholder="eg. 100" [(ngModel)]="innerRule.rewardPoints" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp13 maxlength="10" [disabled]="!innerRule.hasRewardPoints" min="0" max="9999999999">
                                                                    <div class="inp-text-left">{{inp13.getAttribute('maxlength') - inp13.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                                                </div>
                                                            </div>
                                                            <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!rule.hasRewardPoints}" >{{'SEGMENT_PAGE.POINTS' | i18nTranslate}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="display-flex-3">
                                            <button class="primary-button button-hover-effect margin-auto" *ngIf="apiProductList.length>0 || apiProductCategoryList.length>0" (click)="addInclusionRule(rule)">
                                                <span class="material-symbols-outlined">
                                                    add_circle
                                                </span>{{ rule.hasExclusion ? 'Add Inclusion sub rule' : 'Add Inclusion/Exclusion sub rule' }}
                                            </button>
                                        </div>
                                    </div>
    
                                    <app-loyalty-rule-preview [rule]="rule" [loyaltyPoints]="loyaltyPoints" [loyaltyRules]="loyaltyRules" [offerList]="offerList" [applicableCustomerGroupList]="applicableCustomerGroupList"></app-loyalty-rule-preview>
                                </div>
                            </div>
                            
                        </mat-expansion-panel>
                        <mat-expansion-panel [expanded]="rule.ruleCategory=='ADVANCE_CUSTOM'" (opened)="onAdvancePanelOpened(rule)" #advancePanelRef>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="title-label-smaller">
                                        <span class="material-symbols-outlined qb-imp-color">account_tree</span>  {{'SEGMENT_PAGE.CUSTOM_ADVANCE_RULE' | i18nTranslate}}
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="flex rc-conversion-conatainer">
                                <div class="basic-values-container margin-right-20">
                                    <label class="label-style">{{'SEGMENT_PAGE.RULE_TYPE' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                    <div class="rp-name-inp-container rule-value">
                                        <mat-select [(ngModel)]="rule.ruleType" (selectionChange)="changeOperandValue(rule)">
                                            <mat-option *ngFor="let ruleType of ruleTypeDropdownValues" [value]="ruleType" selected>{{ruleType}}</mat-option>
                                        </mat-select>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="rule.ruleCategory=='ADVANCE_CUSTOM'">
                                <div class="logical-expressions" *ngFor="let logicalGroup of loyaltyRules[ruleIndex].ruleDefinition.loyaltyRuleSegmentDefinition.logicalExpressions;let logicalExpIndex = index;">
                                    <div class="top-options">
                                        <div>
                                            <div class="rule-text" *ngIf="rule.ruleType == 'EARN' && logicalExpIndex==0">{{'SEGMENT_PAGE.FOR' | i18nTranslate}}</div>
                                            <div class="rule-text" *ngIf="rule.ruleType == 'EXPIRE' && logicalExpIndex==0">{{'SEGMENT_PAGE.IF' | i18nTranslate}}</div>
                                        </div>
                                        <div class="add-new-condition" (click)="addNewComparisonStatement(logicalGroup,rule)" title="Delete Condition">
                                            <span class="material-symbols-outlined">
                                                add_circle
                                            </span> <span>  {{'SEGMENT_PAGE.ADD_NEW_CONDITION' | i18nTranslate}}</span>
                                        </div>
                                        <div *ngIf="logicalExpIndex>=1" class="margin-left-10">
                                            <span (click)="deleteLogicalGroup(ruleIndex,logicalExpIndex)" class="material-symbols-outlined cursor-pointer" title="Delete Group">
                                                delete_forever
                                            </span>
                                        </div>
                                    </div>
                                    
                                    
                                    <div class="comparison-statement-container" 
                                        *ngFor="let comparisonStatement of logicalGroup.comparisonStatements;let compIndex = index">
                                            <div *ngIf="compIndex>=1" class="delete-condition">
                                                <span (click)="deleteComparisonStatement(compIndex,logicalGroup)" class="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </div>
                                            <div class="rule-value-container" *ngIf="rule.ruleType == 'EARN'">
                                                <label class="label-style">{{'SEGMENT_PAGE.FREQ' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                                <div class="rp-name-inp-container rule-value" [id]="'csOp2Input'+ruleIndex+'-'+logicalExpIndex+'-'+compIndex">
                                                    <input class="rp-name-inp" type="number" placeholder="eg. 2" [(ngModel)]="comparisonStatement.operand2" [disabled]="!comparisonStatement.eventDropdownObj.valueEditable"
                                                     (keydown)="numberInputHandler($event,7,comparisonStatement.eventDropdownObj.dataType)" (keyup)="resetErrorState()" #inp4 maxlength="7" min="0" max="9999999">
                                                    <div class="inp-text-left">{{inp4.getAttribute('maxlength') - inp4.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                                </div>
                                            </div>
                                            <div class="rule-value-container">
                                                <label class="label-style">{{'SEGMENT_PAGE.SELECT_EVENT' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                                <div class="rp-name-inp-container rule-value">
                                                    <mat-select [(ngModel)]="comparisonStatement.eventDropdownObj"
                                                    (selectionChange)="setEventForRule(comparisonStatement)">
                                                        <mat-option *ngFor="let ruleEvent of ruleEventDropdownValues" [value]="ruleEvent" selected>{{ruleEvent.displayName}}</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <!-- TO HANDLE CHANGE IN THE ORDER OF EVENT AND FREQUENCY-->
                                            <div class="rule-text text-align-center" *ngIf="rule.ruleType == 'EXPIRE'">{{'SEGMENT_PAGE.LESS_THAN' | i18nTranslate}}</div>
                                            <div class="rule-value-container" *ngIf="rule.ruleType == 'EXPIRE'">
                                                <label class="label-style">{{'SEGMENT_PAGE.FREQ' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                                <div class="rp-name-inp-container rule-value" [id]="'csOp2Input'+ruleIndex+'-'+logicalExpIndex+'-'+compIndex">
                                                    <input class="rp-name-inp" type="number" placeholder="eg. 2" [(ngModel)]="comparisonStatement.operand2" [disabled]="!comparisonStatement.eventDropdownObj.valueEditable" 
                                                        (keydown)="numberInputHandler($event,7,comparisonStatement.eventDropdownObj.dataType)" (keyup)="resetErrorState()" #inp4 maxlength="7" min="0" max="9999999">
                                                    <div class="inp-text-left">{{inp4.getAttribute('maxlength') - inp4.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                                </div>
                                            </div>
                                            <div class="condition-linker" *ngIf="compIndex!=0">
                                                    <div class="flex and-or-selector-container margin-0" *ngIf="logicalGroup.comparisonStatements.length > 1">
                                                        <div class="and-selector"
                                                         (click)="selectCondition('AND',logicalGroup)"
                                                         [style.background]="logicalGroup.comparisonStatements[1].logicalOperator=='AND'?'#99CDFD':''">
                                                            {{'SEGMENT_PAGE.AND' | i18nTranslate}}
                                                        </div>
                                                        <div class="or-selector" 
                                                        (click)="selectCondition('OR',logicalGroup)"
                                                        [style.background]="logicalGroup.comparisonStatements[1].logicalOperator=='OR'?'#99CDFD':''">
                                                            {{'SEGMENT_PAGE.OR' | i18nTranslate}}
                                                        </div>
                                                    </div>
                                            </div>
                                    </div>
                                    <div class="and-or-container logical-expressions-operators flex" *ngIf="logicalExpIndex>=1">
                                        <div class="and-selector"
                                         (click)="selectConditionForLogicalGroup('AND',logicalGroup)"
                                         [style.background]="logicalGroup.logicalOperator=='AND'?'#99CDFD':''">
                                         {{'SEGMENT_PAGE.AND' | i18nTranslate}}
                                        </div>
                                        <div class="or-selector" 
                                        (click)="selectConditionForLogicalGroup('OR',logicalGroup)"
                                        [style.background]="logicalGroup.logicalOperator=='OR'?'#99CDFD':''">
                                        {{'SEGMENT_PAGE.OR' | i18nTranslate}}
                                        </div>
                                    </div>
                                </div>
                                
                                <button class="loyalty-program-config-panel-continue-button primary-button margin-bottom-40-auto" (click)="addNewLogicalGroup(rule)">
                                    <span class="material-symbols-outlined">
                                        add
                                        </span>
                                          {{'SEGMENT_PAGE.ADD_NEW_GROUP' | i18nTranslate}}
                                </button>
                                <div class="flex align-items-center">
                                    <div class="rule-text margin-right-20">{{'SEGMENT_PAGE.IN_A' | i18nTranslate}}</div>
                                    <div class="rc-values-container margin-right-20">
                                        <label class="label-style">{{'SEGMENT_PAGE.RULE_FREQ' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                        <div class="rp-name-inp-container medium-inp-container">
                                            <mat-select [(ngModel)]="rule.ruleFrequency" (selectionChange)="setRuleFrequencyDisplay(rule)">
                                                <mat-option *ngFor="let frequency of ruleFrequencies" [value]="frequency.value" selected>{{frequency.key}}</mat-option>
                                            </mat-select>
                                        </div>
                                    </div>
                                    <div class="margin-right-20">
                                        <div class="rule-text" *ngIf="rule.ruleType == 'EXPIRE'">Expire</div>
                                    </div>
                                    <div class="rc-values-container margin-right-20" *ngIf="rule.ruleType == 'EXPIRE'">
                                        <label class="label-style">{{'SEGMENT_PAGE.ASSIGN_REWARD_COIN' | i18nTranslate}}</label><span class="qb-imp-color">*</span>
                                        <div class="rp-name-inp-container medium-inp-container" [id]="'rewardPointInput'+ruleIndex">
                                            <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                                            <input class="rp-name-inp" type="number" placeholder="eg. 100" [(ngModel)]="rule.rewardPoints" (keydown)="numberInputHandler($event,10,'float')" (keyup)="resetErrorState()" #inp5 maxlength="10" min="0" max="9999999999">
                                            <div class="inp-text-left">{{inp5.getAttribute('maxlength') - inp5.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                        </div>
                                    </div>
                                    <div class="earn-container" *ngIf="rule.ruleType == 'EARN'">
                                        <div class="rule-text rule-text-4">{{'SEGMENT_PAGE.EARN' | i18nTranslate}}</div>
                                        <div class="rewards-container" [id]="'checkBoxInput'+ruleIndex">
                                            <div class="display-flex-2">
                                                <mat-checkbox class="padding-top-34" color="primary" [(ngModel)]="rule.hasOffers" (ngModelChange)="onHasOffersChange($event,rule)"></mat-checkbox>
                                                <div class="width-135">
                                                    <label class="label-style">Offers</label><span *ngIf="rule.hasOffers" class="qb-imp-color">*</span>
                                                    <div class="rp-name-inp-container rule-value"  [ngClass]="{ 'disabled-div': !rule.hasOffers }" [id]="'offerInput'+ruleIndex">
                                                        <div (click)="rule.hasOffers && showMultiselectDropdown('offer'+ruleIndex);$event.stopPropagation();getFilteredOfferList()" class="offer-select-container"  [ngClass]="{ 'disabled-div': !rule.hasOffers }">
                                                            <div class="offer-select-disp" [title]="getRuleOfferDisplay(rule.offerIds)">
                                                                {{getRuleOfferDisplay(rule.offerIds)}}
                                                            </div>
                                                            <mat-icon class="down-arrow" [ngClass]="{'down-arrow-rotated':showMultiselectDropDownFor == 'offer'+ruleIndex}">keyboard_arrow_down</mat-icon>
                                                            <div class="offer-dropdown" *ngIf="showMultiselectDropDownFor == 'offer'+ruleIndex">
                                                                <input class="op-search" placeholder="Search Offer" type="text" [(ngModel)]="dropdownSearch" (input)="getFilteredOfferList();" (click)="$event.stopPropagation()"/>
                                                                <div class="search-filtered-dropdown">
                                                                    <div *ngFor="let offer of filteredOfferList" class="dropdown-opt" [title]="offer.offerName">
                                                                        <mat-checkbox class="padding-5-10" color="primary" [checked]="rule.offerIds?.includes(offer.id)" (change)="selectRuleOffer(offer,rule);" (click)="$event.stopPropagation()"></mat-checkbox>
                                                                        <div class="dropdown-opt-text" (click)="selectRuleOffer(offer,rule);$event.stopPropagation()">
                                                                            {{offer.offerName}}
                                                                        </div>
                                                                    </div>
                                                                    <div class="no-offer" *ngIf="filteredOfferList.length==0" (click)="$event.stopPropagation()">
                                                                        {{'SEGMENT_PAGE.NO_DATA_AVAILABLE' | i18nTranslate}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!rule.hasOffers}" >Offers</div>
                                            </div>
                                            <div class="display-flex-2">
                                                <mat-checkbox color="primary" class="padding-top-34" [(ngModel)]="rule.hasRewardPoints" (ngModelChange)="onHasRewardPointsChange($event,rule)"></mat-checkbox>
                                                <div class="width-135">
                                                    <label class="label-style">Loyalty Point</label><span *ngIf="rule.hasRewardPoints" class="qb-imp-color">*</span>
                                                    <div class="rp-name-inp-container rule-value" [id]="'rewardPointInput'+ruleIndex">
                                                        <img class="margin-right-5" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/loyalty/inputFieldCoin.svg" alt="coinImg" width="20px" height="20px">
                                                        <input class="rp-name-inp" type="number" placeholder="eg. 100" [(ngModel)]="rule.rewardPoints" keyup="resetErrorState()"
                                                            (keydown)="numberInputHandler($event,10,'float')" #inp3 maxlength="10" [disabled]="!rule.hasRewardPoints" min="0" max="9999999999">
                                                        <div class="inp-text-left">{{inp3.getAttribute('maxlength') - inp3.value.length}} {{'SEGMENT_PAGE.CHAEACTER_LEFT' | i18nTranslate}}</div>
                                                    </div>
                                                </div>
                                                <div class="rule-text padding-top-34" [ngClass]="{'disabled-text':!rule.hasRewardPoints}" >{{'SEGMENT_PAGE.POINTS' | i18nTranslate}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="rule-text" *ngIf="rule.ruleType == 'EXPIRE'">{{'SEGMENT_PAGE.POINTS' | i18nTranslate}}</div>
                                </div>
                                <app-loyalty-rule-preview [rule]="rule" [loyaltyPoints]="loyaltyPoints" [loyaltyRules]="loyaltyRules"  [applicableCustomerGroupList]="applicableCustomerGroupList" [offerList]="offerList"></app-loyalty-rule-preview>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>    
                </div>  
            </div>
            <div class="add-new-tier" (click)="!showOnlyCampaignRules && addNewRule()" [ngClass]="{'disabled-div-2':showOnlyCampaignRules}" [matTooltip]="showOnlyCampaignRules?'Toggle show only campaign rules off to enable':null">
                <span class="material-symbols-outlined">
                    add_circle
                </span> <span>  {{'SEGMENT_PAGE.ADD_NEW_RULE' | i18nTranslate}}</span>
            </div>
        </div>
        
        <div class="step-4-confirmation main-white-panel" *ngIf="currentStep===4">
            <app-preview [completedSteps]="completedSteps" [loyaltyPoints]="loyaltyPoints" [sortedTierMap]="sortedTierMap" [loyaltyRules]="loyaltyRules" [currentStep]="currentStep"></app-preview>
        </div>

        <div class="flex form-flow-buttons margin-0">
            <button class="loyalty-program-config-panel-discard-button primary-button" (click)="discardForm()" >
                {{'SEGMENT_PAGE.DISCARD' | i18nTranslate}}
            </button>
            <button class="loyalty-program-config-panel-save-and-exit-button primary-button" (click)="saveForm()" >
                {{'SEGMENT_PAGE.SAVE_EXIT' | i18nTranslate}}
            </button>
            <button *ngIf="currentStep<=3" class="loyalty-program-config-panel-continue-button primary-button" (click)="goToStep(currentStep+1,true);" >
                {{'SEGMENT_PAGE.CONTINUE' | i18nTranslate}}
            </button>
            <button *ngIf="currentStep==4" class="loyalty-program-config-panel-continue-button primary-button" (click)="validateAllStepsAndSave();" >
                {{'SEGMENT_PAGE.CONFIRM_DETAILS' | i18nTranslate}}
            </button>
        </div>
        
    </div>
    <!-- <div class="main-white-panel loyalty-program-preview-panel" *ngIf="dataLoaded && currentStep!=4">
        <app-preview [completedSteps]="completedSteps" [loyaltyPoints]="loyaltyPoints" [sortedTierMap]="tierMap" [loyaltyRules]="loyaltyRules" [currentStep]="currentStep"></app-preview>
    </div> -->
    <div class="activity-spinner-container" *ngIf="!dataLoaded">
        <mat-spinner diameter="25"></mat-spinner>
    </div>
</div>





