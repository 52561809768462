<div class="qb-w-100 qb-h-100">
    <div class="qb-main-header-wrapper">
        <div>
            <app-breadcrumb></app-breadcrumb>
        </div>
        <div class="qb-d-flex qb-ai-center">
            <button class="primary-button qb-create-new-offer-button" (click)="searchOffer()"
                data-button-name="Create New Offer">
                Search Offer
            </button>
        </div>
    </div>
    <div class="qb-data-main-container">
        <div class="qb-data-main-wrapper">
            <div>
                <h4>Search offer for customer:</h4>
            </div>
            <div style="display: flex; gap: 80px;">
                <div class="qb-mb-25">
                    <mat-radio-group [(ngModel)]="searchType" class="qb-d-inline-flex" style="gap: 50px;">
                        <div class="qb-radio-wrapper">
                            <mat-radio-button value="EMAIL" color="primary">
                                <div class="qb-radio-heading">
                                    Email
                                </div>
                            </mat-radio-button>
                            <div>
                                <input class="qb-form-inp" style="width: 300px;" type="email" placeholder=" " [(ngModel)]="emailInput"
                                            (keydown)="keypressEmail($event)" (click)="changeType('EMAIL')">
                            </div>
                        </div>
                        <div class="qb-radio-wrapper">
                            <mat-radio-button value="NUMBER" color="primary">
                                <div class="qb-radio-heading">
                                    Phone Number
                                </div>
                            </mat-radio-button>
                            <div>
                                <input class="qb-form-inp" style="width: 300px;" type="text" [(ngModel)]="numberInput" placeholder=" "
                                (keydown)="keypressPhone($event)" (click)="changeType('NUMBER')">
                            </div>
                        </div>
                    </mat-radio-group>
                
                </div>
            </div>
            <div *ngIf="cards.length !== 0">
                <div class="obj-accordion-content-wrapper">
                    <div class="obj-accordion-content">
                        <!-- <h3 class="accordion-content-title">{{accordion.content_title}}</h3> -->
                        <div class="accordion-content-cards-container" style="padding: 2%;">
                            <div *ngIf="activeOffers.length > 0">
                                Active Offers:
                            </div>
                            <div class="accordion-scroll-container">
                                <div *ngFor="let card of activeOffers; let j=index" class="objectives-card-offer">
                                    <div style="margin-bottom: 20px;">
                                        <div style="display: flex; flex-direction: column;">
                                            <div class="qb-percentage-off-div">
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'percentage'">
                                                    {{card.discount_value}}% Off
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'percentage_reward_coins_gain'">
                                                    {{card.discount_value}}% Loyalty Point
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'fixed'">
                                                    {{card.discount_value | customCurrency: revCurrency: true}} Off
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'fixed_reward_coins_gain'">
                                                    {{card.discount_value}} Loyalty Point
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'buy_x_get_y'">
                                                   Buy  {{card.buy_x_get_y.prerequisiteQuantity}} and get {{card.buy_x_get_y.entitledQuantity}}
                                                </div>
                                            </div>
                                            <div class="qb-order-type" *ngIf="card.offer_barrier && card.offer_barrier?.length === 0">
                                                <div *ngIf="card.discount_on?.toLowerCase() === 'order'">
                                                    For whole order
                                                </div>
                                                <div *ngIf="card.discount_on?.toLowerCase() === 'category'">
                                                    For specific category
                                                </div>
                                                <div *ngIf="card.discount_on?.toLowerCase() === 'product'">
                                                    For specific order
                                                </div>
                                            </div>
                                            <div class="qb-order-type" *ngIf="card.discount_type === 'buy_x_get_y'">
                                                <div *ngIf="card.buy_x_get_y.entitledItemDiscountPercent === 100">
                                                    For free
                                                </div>
                                                <div *ngIf="card.buy_x_get_y.entitledItemDiscountPercent !== 100 && card.buy_x_get_y.entitledItemDiscountPercent">
                                                    For {{card.buy_x_get_y.entitledItemDiscountPercent}} % OFF
                                                </div>
                                                <div *ngIf="card.buy_x_get_y.entitledItemDiscountAmount">
                                                    For {{ currencySymbol }}{{card.buy_x_get_y.entitledItemDiscountAmount}} OFF
                                                </div>
                                            </div>
                                            <div class="qb-order-type" *ngIf="card.offer_barrier && card.offer_barrier?.length !== 0">
                                                {{card.offer_barrier[0].name}}&nbsp;{{card.offer_barrier[0].name === 'Min Bill Value' ? (card.offer_barrier[0].value | customCurrency: revCurrency: true ) : (card.offer_barrier[0].value)}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-content-container-offer">
                                        <div class="qb-d-flex" style="flex-direction: row; font-size: 14px;">
                                            <div style="width: 85px;">
                                                Offer Name:
                                            </div>
                                            <div style="width: calc(100% - 85px);" [matTooltip]="card.offer_name">
                                                {{card.offer_name | limitTo: 18}}
                                            </div>
                                        </div>
                                        <div class="qb-d-flex"
                                            style="flex-direction: row; color: #163ED4;; font-size: 12px;">
                                            <div>Code: {{card.offer_code}}</div>
                                        </div>
                                        <div class="qb-d-flex" style="flex-direction: row; font-size: 12px;">
                                            <div style="width: 210px;">
                                                Remaining Redemption:
                                            </div>
                                            <div style="width: calc(100% - 85px);" [matTooltip]="card.offer_name">
                                                {{card.remainingRedemption}} out of {{card.usageLimit}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="qb-product-included">
                                        <div *ngIf="card.discount_on?.toLowerCase() === 'order'">
                                            For all products
                                        </div>
                                        <div *ngIf="card.discount_on?.toLowerCase() === 'category'">
                                            For Specific Category <span class="qb-view-list" (click)="viewList('category', card.products_info)">view list</span>
                                        </div>
                                        <div *ngIf="card.discount_on?.toLowerCase() === 'item'">
                                            For Specific Product <span class="qb-view-list" (click)="viewList('product', card.products_info)">view list</span>
                                        </div>
                                        <div *ngIf="card.prerequisiteExcludedProducts !== null && card.prerequisiteExcludedProducts?.length > 0">
                                            Excluded For Specific Product <span class="qb-view-list" (click)="viewList('excludedProduct', card.prerequisiteExcludedProducts)">view list</span>
                                        </div>
                                        <div *ngIf="card.prerequisiteExcludedCategories !== null && card.prerequisiteExcludedCategories?.length > 0">
                                            Excluded For Specific Categories <span class="qb-view-list" (click)="viewList('excludedProduct', card.prerequisiteExcludedCategories)">view list</span>
                                        </div>
                                        <div *ngIf="card.discount_type === 'buy_x_get_y'">
                                            <div *ngIf="card.buy_x_get_y.prerequisiteType === 'COLLECTION'">
                                                Eligible Category <span class="qb-view-list" (click)="viewList('buyxgetycategory', card.buy_x_get_y.prerequisiteCollection)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.prerequisiteType === 'PRODUCT'">
                                                Eligible Product <span class="qb-view-list" (click)="viewList('buyxgetyproduct', card.buy_x_get_y.prerequisiteVariants)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.prerequisiteExcludedProducts && card.buy_x_get_y.prerequisiteExcludedProducts.length > 0">
                                                Excluded Products <span class="qb-view-list" (click)="viewList('excludedProduct', card.buy_x_get_y.prerequisiteExcludedProducts)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.entitledType === 'COLLECTION'">
                                                Discounted Category <span class="qb-view-list" (click)="viewList('buyxgetycategory', card.buy_x_get_y.entitledCollection)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.entitledType === 'PRODUCT'">
                                                Discounted Product <span class="qb-view-list" (click)="viewList('buyxgetyproduct', card.buy_x_get_y.entitledVariants)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.entitledExcludedProducts && card.buy_x_get_y.entitledExcludedProducts.length > 0">
                                                Excluded Discounted Products <span class="qb-view-list" (click)="viewList('excludedProduct', card.buy_x_get_y.entitledExcludedProducts)">view list</span>
                                            </div>
                                        </div>
                                    
                                        <div>
                                            Expiry date:  {{ card?.date | date: getDateFormat("MMM dd yyyy") }} &nbsp;{{
                                                card?.time | customTime
                                              }}
                                        </div>
                                    </div>
                                    <button class="obj-offer-btn obj-secondary-btn" [id]="'button' + j" *ngIf="card.status.toUpperCase() === 'ACTIVE'"
                                        (click)="onRedeemClicked(card.offer_code, j)">
                                        <span [id]="'text' + j">
                                            Redeem Offer
                                        </span>
                                        <mat-spinner [id]="'spinner' + j" diameter="20" style="display: none;" class="qb-spinner-size"></mat-spinner>
                                    </button>
                                </div>
    
                            </div>
                        </div>
                        <div class="accordion-content-cards-container" style="padding: 2%;">
                            <div *ngIf="inactiveOffers.length > 0">
                                Redeemed/Expired Offers:
                            </div>
                            <div class="accordion-scroll-container">
                                <div *ngFor="let card of inactiveOffers; let j=index" class="objectives-card-offer">
                                    <div style="margin-bottom: 20px;">
                                        <div style="display: flex; flex-direction: column;">
                                            <div class="qb-percentage-off-div">
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'percentage'">
                                                    {{card.discount_value}}% Off
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'percentage_reward_coins_gain'">
                                                    {{card.discount_value}}% Loyalty Point
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'fixed'">
                                                    {{card.discount_value | customCurrency: revCurrency: true}} Off
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'fixed_reward_coins_gain'">
                                                    {{card.discount_value}} Loyalty Point
                                                </div>
                                                <div class="qb-percentage-off" *ngIf="card.discount_type === 'buy_x_get_y'">
                                                    Buy  {{card.buy_x_get_y.prerequisiteQuantity}} and get {{card.buy_x_get_y.entitledQuantity}}
                                                 </div>
                                            </div>
                                            <div class="qb-order-type" *ngIf="card.offer_barrier && card.offer_barrier?.length === 0">
                                                <div *ngIf="card.discount_on?.toLowerCase() === 'order'">
                                                    For whole order
                                                </div>
                                                <div *ngIf="card.discount_on?.toLowerCase() === 'category'">
                                                    For specific category
                                                </div>
                                                <div *ngIf="card.discount_on?.toLowerCase() === 'product'">
                                                    For specific order
                                                </div>
                                            </div>
                                            <div class="qb-order-type" *ngIf="card.discount_type === 'buy_x_get_y'">
                                                <div *ngIf="card.buy_x_get_y.entitledItemDiscountPercent === 100">
                                                    For free
                                                </div>
                                                <div *ngIf="card.buy_x_get_y.entitledItemDiscountPercent !== 100 && card.buy_x_get_y.entitledItemDiscountPercent">
                                                    For {{card.buy_x_get_y.entitledItemDiscountPercent}} % OFF
                                                </div>
                                                <div *ngIf="card.buy_x_get_y.entitledItemDiscountAmount">
                                                    For {{ currencySymbol }}{{card.buy_x_get_y.entitledItemDiscountAmount}} OFF
                                                </div>
                                            </div>
                                            <div class="qb-order-type" *ngIf="card.offer_barrier && card.offer_barrier?.length !== 0">
                                                {{card.offer_barrier[0].name}}&nbsp;{{card.offer_barrier[0].name === 'Min Bill Value' ? (card.offer_barrier[0].value | customCurrency: revCurrency: true ) : (card.offer_barrier[0].value)}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-content-container-offer">
                                        <div class="qb-d-flex" style="flex-direction: row; font-size: 14px;">
                                            <div style="width: 85px;">
                                                Offer Name:
                                            </div>
                                            <div style="width: calc(100% - 85px);" [matTooltip]="card.offer_name">
                                                {{card.offer_name | limitTo: 18}}
                                            </div>
                                        </div>
                                        <div class="qb-d-flex"
                                            style="flex-direction: row; color: #163ED4;; font-size: 12px;">
                                            <div>Code: {{card.offer_code}}</div>
                                        </div>
                                        <div class="qb-d-flex" style="flex-direction: row; font-size: 12px;">
                                            <div style="width: 210px;">
                                                Remaining Redemption:
                                            </div>
                                            <div style="width: calc(100% - 85px);" [matTooltip]="card.offer_name">
                                                {{card.remainingRedemption}} out of {{card.usageLimit}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="qb-product-included">
                                        <div *ngIf="card.discount_on?.toLowerCase() === 'order'">
                                            For all products
                                        </div>
                                        <div *ngIf="card.discount_on?.toLowerCase() === 'category'">
                                            For Specific Category <span class="qb-view-list" (click)="viewList('category', card.products_info)">view list</span>
                                        </div>
                                        <div *ngIf="card.discount_on?.toLowerCase() === 'item'">
                                            For Specific Product <span class="qb-view-list" (click)="viewList('product', card.products_info)">view list</span>
                                        </div>
                                        <div *ngIf="card.discount_type === 'buy_x_get_y'">
                                            <div *ngIf="card.buy_x_get_y.prerequisiteType === 'COLLECTION'">
                                                Eligible Category <span class="qb-view-list" (click)="viewList('buyxgetycategory', card.buy_x_get_y.prerequisiteCollection)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.prerequisiteType === 'PRODUCT'">
                                                Eligible Product <span class="qb-view-list" (click)="viewList('buyxgetyproduct', card.buy_x_get_y.prerequisiteVariants)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.prerequisiteExcludedProducts && card.buy_x_get_y.prerequisiteExcludedProducts.length > 0">
                                                Excluded Products <span class="qb-view-list" (click)="viewList('excludedProduct', card.buy_x_get_y.prerequisiteExcludedProducts)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.entitledType === 'COLLECTION'">
                                                Discounted Category <span class="qb-view-list" (click)="viewList('buyxgetycategory', card.buy_x_get_y.entitledCollection)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.entitledType === 'PRODUCT'">
                                                Discounted Product <span class="qb-view-list" (click)="viewList('buyxgetyproduct', card.buy_x_get_y.entitledVariants)">view list</span>
                                            </div>
                                            <div *ngIf="card.buy_x_get_y.entitledExcludedProducts && card.buy_x_get_y.entitledExcludedProducts.length > 0">
                                                Excluded Discounted Products <span class="qb-view-list" (click)="viewList('excludedProduct', card.buy_x_get_y.entitledExcludedProducts)">view list</span>
                                            </div>
                                        </div>
                                        <div>
                                            Expiry date:  {{ card?.date | date: getDateFormat("MMM dd yyyy") }} &nbsp;{{
                                                card?.time | customTime
                                              }}
                                        </div>
                                    </div>
                                    <button class="obj-offer-btn qb-redeemed-button" [id]="'button' + j" *ngIf="card.status.toUpperCase() === 'REDEEMED'">
                                    <span [id]="'text' + j">
                                        Offer Redeemed
                                    </span>
                                    </button>
                                    <button class="obj-offer-btn qb-expired-button" [id]="'button' + j" *ngIf="card.status.toUpperCase() === 'EXPIRED'">
                                        <span [id]="'text' + j">
                                            Expired
                                        </span>
                                    </button>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="qb-ilustration-add-customer" *ngIf="cards.length === 0 && loading === false && !dataNotAvailable">
                <img src="https://greyfox-cdn.s3.us-east-2.amazonaws.com/greyfox-assets/assets2/illustrations/customer_offer.jpg" alt=""style="width: 350px; height:300px; margin-top: 30px">
            </div>
            <div *ngIf="loading === true" style="width: 100%; height: 350px; display: flex; align-items: center; justify-content: center;">
                <mat-spinner diameter="45"></mat-spinner>
            </div>
            <div *ngIf="dataNotAvailable" style="width: 100%; height: 300px;">
                <div class="qb-empty-offer-list">No offers available</div>
              </div>
        </div>
    </div>
</div>