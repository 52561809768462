import { Injectable } from "@angular/core";
import { ApiCallService } from "./api-call-service";

@Injectable({
  providedIn: "root",
})
export class EventIconMappingService {
  constructor(private apiCallService: ApiCallService) {}
  customerEvents: any[] = [];
  isDataFetched = false;
  getEventLabel(eventName, store,nforNthLifetimeOrder?) {
    if(eventName === 'time_event'){
      return 'Time based Journey';
    }
    if(eventName == 'nth lifetime order count') {
      if(nforNthLifetimeOrder==1) return '1st Lifetime Order';
        if(nforNthLifetimeOrder==2) return '2nd Lifetime Order';
        if(nforNthLifetimeOrder==3) return '3rd Lifetime Order';
        if(nforNthLifetimeOrder>3) return nforNthLifetimeOrder+'th Lifetime Order';
        if(nforNthLifetimeOrder == null) return 'Nth Lifetime Order'
    }
    const index = this.customerEvents.findIndex(event => event.eventName === eventName);
    if(index !== -1){
      return this.customerEvents[index].displayName;
    }
    // switch (eventName) {
    //   case "add_cart":
    //     return "Adds item to cart";
    //   case "create_checkout":
    //     return "Begins Checkout";
    //   case "create_orders":
    //   case "ORDER":
    //     return store ==='flipoffice' ? "Places an order for first time" : "Places an order";
    //   case "recentlyviewed_products":
    //     return "Views a Product";
    //   case "search_products":
    //     return "Searches for a Product";
    //   case "time_event":
    //     return "Time based Campaign";
    //   case "create_customer":
    //     return store ==='flipoffice' ? "Signs Up For Loyalty" : "Signs up";
    //   case "sign_up":
    //     return store ==='flipoffice' ? "Signs Up For Loyalty" : "Signs up";
    //   case "update_checkout":
    //     return "Updates Checkout";
    //   case "segment":
    //     return "Segment Based";
    //   case "BIRTHDAY":
    //   case "BIRTHDAY_WEEK":
    //   case "BIRTHDAY_MONTH":
    //   case "CUSTOM_BIRTHDAY":
    //     return "Birthday";
    //   case "ANNIVERSARY":
    //   case "ANNIVERSARY_WEEK":
    //   case "ANNIVERSARY_MONTH":
    //   case "CUSTOM_ANNIVERSARY":
    //     return "Anniversary";
    // }
  }

  getEventIconName(eventName) {
    switch (eventName) {
      case "add_cart":
        return "add_shopping_cart";
      case "create_checkout":
        return "credit_card";
      case "sign_up":
        return "person_add";
      case "create_orders":
        return "library_add_check";
      case "recentlyviewed_products":
        return "visibility";
      case "search_products":
        return "search";
      case "no_event":
        return "apps";
      case "create_customer":
        return "person_add";
      case "update_checkout":
        return "update_checkout";
      case "BIRTHDAY":
      case "BIRTHDAY_WEEK":
      case "BIRTHDAY_MONTH":
      case "CUSTOM_BIRTHDAY":
        return "cake";
      case "ANNIVERSARY":
      case "ANNIVERSARY_WEEK":
      case "ANNIVERSARY_MONTH":
      case "CUSTOM_ANNIVERSARY":
        return "celebration";
      case "lead_created":
        return "group";
      case "APPOINTMENT":
        return "calendar_clock";
      case "tier_upgrade":
        return "hotel_class";
      case "proposal_won":
        return "beenhere";
      case "proposal_active":
        return "domain_verification";
      case "proposal_lost":
        return "contract_delete";
      default:
        return "apps";
    }
  }

  getBlockDisplayName(nodeType) {
    switch (nodeType) {
      case "WAIT":
        return "Time Delay";
      case "CHANNEL":
        return "Channel";
      case "OFFER":
        return "Offer";
      case "SURVEY":
        return "Survey";
      case "TRIGGER":
        return "Trigger";
      case "RESPONSE_EVENT":
        return "If/Else";
      case "ABTESTING":
        return "A/B Testing";
      case "AUDIENCESPLIT":
        return "Audience Split";
      case "CUSTOM_AUDIENCE":
        return "Custom Audience"
      case "LOYALTY":
        return "Loyalty Program";
      case "COMARKETING_OFFER":
        return "Co-Marketing"
    }
  }

  setCustomerEvents(isAdmin) {
    // if(this.isDataFetched) return;
    this.apiCallService.getCustomerEvents(isAdmin).subscribe(
      (response) => {
        if( response["body"]) {

          this.isDataFetched = true;
          this.customerEvents = Object.keys(response["body"]).map((firstLevelKey) => {
            const secondLevel = response["body"][firstLevelKey];
            return Object.keys(secondLevel).map((secondLevelKey) => {
              const eventList = secondLevel[secondLevelKey];
              return {
                type: firstLevelKey,  
                eventName: secondLevelKey,
                displayName: eventList[0].eventDisplayName, 
                subEventList: eventList
              };
            });
          }).flat()
          console.log(this.customerEvents);

        }
      },
      (err) => {}
    );
  }

  getCustomerEvents() {
    return this.customerEvents;
  }
}
