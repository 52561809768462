import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator, MatPaginatorIntl } from "@angular/material/paginator";
import { MatDialog, DialogPosition } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { ConfirmationDialogBoxComponent, ContactUsDialogComponent, DefaultExcluysionDialogBoxComponent, NotDeletedListDialogBoxComponent, OfferSummaryDialogComponent } from "src/app/shared/components/dialog-box";
import { OfferFilterOverlayComponent } from "../../../component";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { PersonalOfferListViewDialogBoxComponent } from "src/app/shared/components/dialog-box/personal-offer-list-view-dialog-box/personal-offer-list-view-dialog-box.component";


@Component({
  selector: "app-offer-all-listing",
  templateUrl: "./offer-all-listing.component.html",
  styleUrls: ["./offer-all-listing.component.css"],
})
export class OfferAllListingComponent implements OnInit, OnDestroy {
  @ViewChild("paginator") paginator: MatPaginator;
  offerList = [];
  filter = {
    label: "",
  };
  rightMargin;
  page = 1;
  size = 10;
  status = [];
  channel = [];
  statusDraft: boolean = false;
  statusSent: boolean = false;
  statusScheduled: boolean = false;
  sortBy = ["DATE"];
  order = ["DESC"];
  totalOfferCount;
  loading = false;
  sortByTagText = "Date";
  timeout;
  sub: Subscription;
  appSubscriptionPlan;
  validWidgets;
  messageReSchedule;
  openReScheduleOverlay = false;
  mobileView;
  mobileMedia = window.matchMedia("(max-width: 896px)");
  segementMap = {};
  searchedValue = undefined;
  numberOfCustomers;
  selectedOfferListPageWise = {
    1 : []
  };
  excludedProductList = [];
  excludedCategoryList = [];
  qid = this.tokenStorage.getqId();
  productCategoryType = null;

  constructor(
    private apiCall: ApiCallService,
    private snackbar: SnackbarCollection,
    private dialog: MatDialog,
    public router: Router,
    private _WidgetConfigService: WidgetConfigService,
    private snackBar: SnackbarCollection,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private date_format: getdateformatService,
    private _MatPaginatorIntl: MatPaginatorIntl
  ) {
    window["screen-name"] = "CHOOSE OFFER";
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: any) {
    if (this.mobileView)
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight - 1
      ) {
        this.page += 1;
        this.getOfferList(
          this.page,
          this.size,
          this.status,
          this.channel,
          this.sortBy,
          this.order,
          this.filter.label
        );
      }
  }

  ngOnInit() {
    if (this.mobileMedia.matches) {
      this.mobileView = true;
    } else {
      this.mobileView = false;
    }
    this.getOfferList(
      this.page,
      this.size,
      this.status,
      this.channel,
      this.sortBy,
      this.order
    );
    this.getWidgets();
    this.getDefaultExclusion();
    this.setConstants();
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getOfferList(page, size, status, channel, sortby, order, offerName?) {
    this.loading = true;
    if (!this.mobileView) {
      this.offerList = [];
    }
    if (offerName !== "") {
      if (offerName !== this.searchedValue) {
        this.offerList = [];
        this.searchedValue = offerName;
      }
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.apiCall
      .getAllOfferListWithPagination(
        page,
        size,
        status,
        channel,
        sortby,
        order,
        offerName
      )
      .subscribe(
        (response) => {
          this.loading = false;
          if (response["message"] == "success") {
            let body = (response["body"]);
            if (body.length != 0) {
              this.totalOfferCount = JSON.parse(body["totalCount"]);
              if (this.mobileView) {
                body["offers"].forEach((element) => {
                  this.numberOfCustomers = 0;
                  if (element["segments"] !== null) {
                    this.apiCall
                      .getCustomerCountSegmentList(element["segments"])
                      .subscribe((response) => {
                        this.numberOfCustomers = JSON.parse(response["body"])[
                          "totalCustomerCount"
                        ];
                        element["customerCount"] = this.numberOfCustomers;
                      });
                  } else if (
                    element["isSegmented"] == false &&
                    element["status"] != "Draft"
                  ) {
                    let isCustomer = (element['isSegmented'] == false && element['status'] != 'Draft' && element['allCustomer']) || (element['isSegmented'] == false && element['status'] != 'Draft' && !element['allCustomer'] && !element['allLead']) ? true : false;
                    let isLead = element['status'] != 'Draft' && element['allLead'] ? true : false;
                    this.apiCall
                      .getAnalytics(new Date(), new Date(), isLead, isCustomer)
                      .subscribe((response) => {
                        this.numberOfCustomers = JSON.parse(
                          response["body"]
                        ).numberOfCustomers;
                        element["customerCount"] = this.numberOfCustomers;
                      });
                  } else {
                    element["customerCount"] = this.numberOfCustomers;
                  }
                  this.offerList.push(element);
                });
              } else {
                this.offerList = body["offers"];
              }
              let sheader = document.getElementById("tableHeads");
              let interval = setInterval(() => {
                let sideMenu = document.getElementById("side-menu-offer");
                let sideMenuParent = document.getElementById(
                  "side-menu-offer-parent"
                );
                if (sideMenu && sheader) {
                  clearInterval(interval);
                  if (
                    sideMenuParent.clientHeight < sideMenuParent.scrollHeight
                  ) {
                    this.rightMargin = "14px"; //5 is the scroller width
                    sheader.style.paddingRight = this.rightMargin;
                  } else {
                    sheader.style.paddingRight = "0px";
                  }
                }
              });
            } else {
              this.totalOfferCount = 0;
            }
          }
        },
        (err) => {
          this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Error in fetching offer details", ['POS_PAGE']), 2000);
          this.loading = false;
        }
      );
  }

  async createOffer() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate(["app/offer/create-offer"]);
    }
  }

  getNextData(pageEvent) {
    if(this.size !== pageEvent.pageSize){
      this.clearList();
    }
    this.page = pageEvent.pageIndex + 1;
    this.size = pageEvent.pageSize;
    if(Object.keys(this.selectedOfferListPageWise).indexOf(this.page.toString()) === -1){
      this.selectedOfferListPageWise[this.page] = [];
    }
    this.getOfferList(
      this.page,
      this.size,
      this.status,
      this.channel,
      this.sortBy,
      this.order,
      this.filter.label
    );
  }

  statusChange(event) {
    this.status = ["DRAFT", "SENT", "SCHEDULED"];
    this.status = [];
    if (this.statusDraft) {
      this.status.push("DRAFT");
    }
    if (this.statusSent) {
      this.status.push("SENT");
    }
    if (this.statusScheduled) {
      this.status.push("SCHEDULED");
    }

    this.executeListing();
  }

  statusChange123(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  channelSelect(value) {
    this.channel = value;
    this.getOfferList(
      this.page,
      this.size,
      this.status,
      this.channel,
      this.sortBy,
      this.order,
      this.filter.label
    );
  }

  sortByChanges() {
    this.getOfferList(
      this.page,
      this.size,
      this.status,
      this.channel,
      this.sortBy,
      this.order,
      this.filter.label
    );
  }

  async editOffer(idnumber, adminCreated, qid) {
    console.log(qid)
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate(["app/offer/edit-offer", { id: idnumber, admin: adminCreated, qid: qid }]);
    }
  }

  async cloneOffer(idnumber, adminCreated, qid) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "app/offer/create-offer",
        { id: idnumber, clone: "true", admin: adminCreated, qid: qid },
      ]);
    }
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    var $this = this;
      this.timeout = setTimeout(function () {
        if(event.data!==' ')
        $this.executeListing();
      }, 600);
    }

  executeListing() {
    this.paginator.pageIndex = 0;
    this.page = 1;
    this.getOfferList(
      this.page,
      this.size,
      this.status,
      this.channel,
      this.sortBy,
      this.order,
      this.filter.label.trim()
    );
  }

  deleteOffer(id, qid, storeName) {
    if(this.tokenStorage.getqId() === qid || qid === undefined || (this.tokenStorage.getqId() ==='null' && qid === null)){
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: this.mobileMedia.matches ? "90%" : "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Delete offer", ['POS_PAGE']),
          message: this._i18nDynamicTranslate.transform("Do you want to delete offer?", ['POS_PAGE']),
          "data-widget": "OFFER LISTING",
          "data-button": "Confirm: Delete",
          "data-item": id,
          successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.apiCall.deleteParticularOffer(id).subscribe(
            (response: Response) => {
              this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Offer deleted", ['POS_PAGE']), 2000);
              this.offerList.forEach((element) => {
                if (element["id"] == id) {
                  const index: number = this.offerList.indexOf(element);
                  if (index !== -1) {
                    this.offerList.splice(index, 1);
                  }
                }
              });
            },
            (err) => {
              if (err.error.message == "failed" && err.error.body != null) {
                this.openErrorDialog(err);
              } else {
                this.snackbar.openSnackBar(err.error.message, 2000);
              }
            }
          );
        }
      });
    }
    else{
      // const dialogRef = this.dialog.open(ContactUsDialogComponent, {
      //   panelClass: "no-padding-dialog-popup",
      //   height: "auto",
      //   disableClose: true,
      //   width: "380px",
      //   data: {
      //     header: this._i18nDynamicTranslate.transform("Error", ['POS_PAGE']),
      //     body: qid === null ? this._i18nDynamicTranslate.transform("Admin created, You don't have the permission to delete.", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("This Offer is created by", ['POS_PAGE']) + " " + storeName + " " + this._i18nDynamicTranslate.transform("You don't have the permission to delete.", ['POS_PAGE']),
      //   },
      // });
      // dialogRef.afterClosed().subscribe((result) => {
      //   // this.disabled = false;
      // });

      const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
        panelClass: "customer-dialog-popup",
        width: "540px",
        disableClose: false,
        data:{
          type: "OFFERS",
          heading: "OOPS!",
          subHeading: qid === null ? this._i18nDynamicTranslate.transform("Admin created, You don't have the permission to delete.", ['POS_PAGE']) : this._i18nDynamicTranslate.transform("This Offer is created by", ['POS_PAGE']) + " " + storeName + " " + this._i18nDynamicTranslate.transform("You don't have the permission to delete.", ['POS_PAGE']),
        },
      });
    }
  }

  openErrorDialog(err) {
    // const dialogRef = this.dialog.open(ContactUsDialogComponent, {
    //   panelClass: "no-padding-dialog-popup",
    //   height: "auto",
    //   disableClose: true,
    //   width: "380px",
    //   data: {
    //     header: "Error",
    //     body: JSON.parse(err.error.body).detailMessage,
    //   },
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   return;
    // });
    const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
      panelClass: "customer-dialog-popup",
      width: "540px",
      disableClose: false,
      data:{
        type: "OFFERS",
        heading: "OOPS!",
        subHeading: JSON.parse(err.error.body).detailMessage,
      },
    });
  }

  openViewOfferSummary(id) {
    const dialogRefer = this.dialog.open(OfferSummaryDialogComponent, {
      panelClass: "customAttachEmail",
      width: "40%",
      maxWidth: "546px",
      maxHeight: "90vh",
      data: id,
    });
    dialogRefer.afterClosed().subscribe((result) => {});
  }

  async previewOffer(idnumber) {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "app/offer/preview-offer",
        { id: idnumber, preview: true },
      ]);
    }
  }

  previewOrEditOffer(status, offerid, isNoneBarrierOffer, isBuyXGetYOffer, isAdminCreated, qid) {
    if (isNoneBarrierOffer || isBuyXGetYOffer) {
      if ("Draft" == status) {
        this.editOffer(offerid, isAdminCreated, qid);
      } else {
        this.previewOffer(offerid);
      }
    } else {
      this.snackBar.openSnackBar(this._i18nDynamicTranslate.transform("Offer is not ready for preview", ['POS_PAGE']), 2000);
    }
  }

  reOrder() {
    if (this.order[0] == "ASC") {
      this.order = ["DESC"];
      this.sortByChanges();
    } else {
      this.order = ["ASC"];
      this.sortByChanges();
    }
  }

  receiveMessage($event) {
    this.messageReSchedule = $event;
    this.cancelAllSchedule();
    if (this.messageReSchedule == "updated") {
      this.getOfferList(
        this.page,
        this.size,
        this.status,
        this.channel,
        this.sortBy,
        this.order,
        this.filter.label
      );
    }
    this.openReScheduleOverlay = false;
  }

  modifyConfig(status) {
    document.getElementById("viewconfigDiv").style.zIndex = "1";
    document.getElementById("overlay").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.display = "flex";
    this.on();
  }

  cancelAllSchedule() {
    this.off();
    document.getElementById("viewconfigDiv").style.zIndex = "2";
    document.getElementById("configDiv").style.zIndex = "-1";
    document.getElementById("configDiv").style.display = "none";
    document.getElementById("overlay").style.zIndex = "-1";
    document.getElementById("overlay").style.display = "none";
  }

  on() {
    document.getElementById("overlay").style.display = "block";
  }

  off() {
    document.getElementById("overlay").style.display = "none";
  }

  selectedOfferId = null;
  selectedChannel = null;
  openReScheduleOffer(offerId, channel) {
    this.selectedOfferId = offerId;
    this.selectedChannel = channel;
    this.openReScheduleOverlay = true;
    this.modifyConfig(true);
  } 

  openFilterDialog(): void {
    const dialogPosition: DialogPosition = {
      top: "0px",
      right: "0px",
    };
    const dialogRef = this.dialog.open(OfferFilterOverlayComponent, {
      width: "230px",
      height: "100%",
      position: dialogPosition,
      panelClass: "custom-dialog-container",
      data: {
        status: this.status,
        channel: this.channel,
      },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.status = response.status;
        this.channel = response.channel;
        this.executeListing();
      }
    });
  }
  getDateFormat(type?){
    return this.date_format.getDateFormat(type);
  }
  getDate(dateString: string){
    if(dateString){
      const parts = dateString.split(' | ');
      const datePart = parts[0];
      return datePart;
    }
    else{
      return '-'
    }
  }
  getTime(dateString: string){
    if(dateString){
      const parts = dateString.split(' | ');
      const datePart = parts[1];
      return datePart;
    }
    else{
      return
    }
  }
  test(){
    this._MatPaginatorIntl.itemsPerPageLabel = this._i18nDynamicTranslate.transform("Items per Pagesssdsdsdsds:", ['POS_PAGE']);
  }
  getTooltipOffer(channel){
    var tooltip = "";
    tooltip = "Channel: " + channel
    return tooltip;
  }
  checkChannel(type, list){
    let channelList = JSON.parse(list); 
    if (channelList) {
      const lowercaseChannelList = Object.fromEntries(
          Object.entries(channelList).map(([key, value]) => [key.toLowerCase(), value])
      );
      return type.toLowerCase() in lowercaseChannelList;
  }
  return false;
  }
  checkIfDraft(status, channelList, isPersonalized, channel){
    if(channelList){
      if(channelList === null && status ==='Draft' && isPersonalized){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      if(channel === null && status ==='Draft' && isPersonalized){
        return true;
      }
      else{
        return false;
      }
    }
  }
  checkIfSaved(status, channelList, isPersonalized, channel){
    if(channelList){
      if(channelList !== null && status ==='Draft' && !isPersonalized){
        return true;
      }
      else if(channelList === null && status ==='Draft' && !isPersonalized ){
        return true;
      }
      else if(channelList !== null && status ==='Draft' && isPersonalized){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      if(channel !== null && status ==='Draft' && !isPersonalized){
        return true;
      }
      else if(channel === null && status ==='Draft' && !isPersonalized ){
        return true;
      }
      else if(channel !== null && status ==='Draft' && isPersonalized){
        return true;
      }
      else{
        return false;
      }
    }
  }
  async getCustomerOffer(){
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate(["app/offer/customer-offer"]);
    }
  }

  deleteSelectedOffer(){
    let dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
      panelClass: "no-padding-dialog-popup",
      width: this.mobileMedia.matches ? "90%" : "20%",
      data: {
        subject: this._i18nDynamicTranslate.transform("Delete Offers", ['POS_PAGE']),
        message: this._i18nDynamicTranslate.transform("Do you wish to delete these Offers?", ['POS_PAGE']),
        cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", ['POS_PAGE']),
        successButtonText: this._i18nDynamicTranslate.transform("Delete", ['POS_PAGE']),
        "data-widget": "OFFER LISTING",
        "data-button": "Confirm: Delete",
        "data-item": null,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      let selectedOfferList = Object.values(this.selectedOfferListPageWise).flat();
      if (result) {
        this.loading = true;
        this.deleteSelectOfferApi(selectedOfferList).then(
          (res) => {
            this.loading = false;
            if (res && res["body"]) {
              const notDeletedList: any[] = res['body'].filter(item => !item.deleted);
              if(notDeletedList.length === 0){
                this.clearList();
                this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Offer deleted", ['POS_PAGE']), 2000);
                this.getOfferList(
                  this.page,
                  this.size,
                  this.status,
                  this.channel,
                  this.sortBy,
                  this.order
                );
              }
              else{
                const dialogRefDeleted = this.dialog.open(NotDeletedListDialogBoxComponent, {
                  panelClass: "customer-dialog-popup",
                  width: "540px",
                  disableClose: false,
                  data:{
                    notDeletedList: notDeletedList,
                    type: "OFFERS",
                    heading: "OOPS!",
                    subHeading: notDeletedList.length + "/" + selectedOfferList.length + " Offers can't be deleted:"
                  },
                });
                dialogRefDeleted.afterClosed().subscribe((res)=>{
                  if(notDeletedList.length !== selectedOfferList.length){
                    this.getOfferList(
                      this.page,
                      this.size,
                      this.status,
                      this.channel,
                      this.sortBy,
                      this.order
                    );
                  }
                  this.clearList();
                });
              }
            } else {
            }
          },
          (err) => {
            this.loading = false;
            console.log(JSON.parse(err));
          }
        );
      }
    });
  }

  checkOfferList(event, type, id){
    if(event){
      if(type === 'single'){
        this.selectedOfferListPageWise[this.page].push(id);
      }
      else{
        // for(let data in this.offerList){
        //   console.log(data);
        //   let i = this.selectedOfferList.indexOf(data);
        //   if(i === -1){
        //     this.selectedOfferList.push(data);
        //   }
        // }
        this.selectedOfferListPageWise[this.page] = [];
        this.offerList.forEach((data)=>{
          this.selectedOfferListPageWise[this.page].push(data.id);
        });
      }
    }
    else{
      if(type === 'single'){
        let index = this.selectedOfferListPageWise[this.page].indexOf(id);
        this.selectedOfferListPageWise[this.page].splice(index, 1);
      }
      else{
        this.selectedOfferListPageWise[this.page] = [];
      }
    }
  }

  checkIfPresent(id){
    return this.selectedOfferListPageWise[this.page]?.length > 0 && (this.selectedOfferListPageWise[this.page].includes(id.toString()) || this.selectedOfferListPageWise[this.page].includes(id));
  }

  deleteSelectOfferApi(data){
    return new Promise((resolve, reject) => {
      this.apiCall.deleteOffers(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error.body);
        }
      );
    });
  }

  clearList(){
    for (const key in this.selectedOfferListPageWise) {
      if (this.selectedOfferListPageWise.hasOwnProperty(key)) {
        this.selectedOfferListPageWise[key] = [];
      }
    }
  }

  getDefaultExclusion(){
    this.apiCall.getDefaultProductExclusion().subscribe((response)=>{
      console.log(response);
      this.excludedProductList = response['body']['excludedProductList'];
      this.excludedCategoryList = response['body']['excludedCategoryList'];
    })
  }

  browseProducts(list, section){
    let dataOption;
    dataOption = {
      selectedProductList: this.excludedProductList,
      selectedCategoryList: this.excludedCategoryList,
      productCategoryType: this.productCategoryType,
      section: section,
      listType: list,
      actionType: "EXCLUSION"
    };
    const dialogRef = this.dialog.open(DefaultExcluysionDialogBoxComponent, {
      panelClass: "o-item-dialog",
      height: "440px",
      width: "540px",
      data: dataOption,
      maxHeight: "80vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.excludedProductList = result.variantIdArray
        // let data = {
        //   excludedProductList : this.excludedProductList
        // };
        let data = result;
        this.apiCall.saveDefaultProductExclusion(data).subscribe((response)=>{
          console.log(response);
          this.getDefaultExclusion();
        });
      }
      else{
        this.getDefaultExclusion(); 
      }
    });
  }

  showProducts(type){
    // let finalData = [];
    // if (type === "excludedProduct") {
    //   finalData = this.excludedProductList.map((product) => ({
    //     product_name: product.productName,
    //   }));
    // }
    let dataOption;
    dataOption = {
      excludedProductList: this.excludedProductList,
      excludedCategoryList: this.excludedCategoryList,
      type: type,
      actionType: "SHOW"
    };
    // const dialogRef = this.dialog.open(
    //   PersonalOfferListViewDialogBoxComponent,
    //   {
    //     panelClass: "no-padding-dialog-popup",
    //     width: "30%",
    //     data: {
    //       type: type,
    //       list: finalData,
    //     },
    //   }
    // );
    const dialogRef = this.dialog.open(DefaultExcluysionDialogBoxComponent, {
      panelClass: "o-item-dialog",
      height: "540px",
      width: "640px",
      data: dataOption,
      maxHeight: "80vh",
    });
  }

  setConstants() {
    this.productCategoryType = [];
    this.apiCall.getConstantProducts().subscribe(
      (response) => {
        if (response["message"] === "success") {
          let result: any = response["body"];
          result = JSON.parse(result);
          for (let i = 0; i < result["productCategories"].length; i++) {
            if (
              this.productCategoryType.indexOf(
                result["productCategories"][i] === -1
              )
            ) {
              this.productCategoryType.push({
                collectionName:
                  result["productCategories"][i].productCategoryName,
                collectionId: result["productCategories"][i].productCategoryId,
                subCategory: result["productCategories"][i].subCategory,
              });
            }
          }
        }
      },
      (error) => {}
    );
  }
}
