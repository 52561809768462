import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, UrlTree } from "@angular/router";
import { environment } from "src/environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { Globals } from "src/app/core/services/global.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import {
  ConfirmationDialogBoxComponent,
  ContactUsDialogComponent,
} from "src/app/shared/components/dialog-box";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";
import { Location } from "@angular/common";

@Component({
  selector: "app-standard-email-template",
  templateUrl: "./standard-email-template.component.html",
  styleUrls: ["./standard-email-template.component.css"],
})
export class StandardEmailTemplateComponent implements OnInit {
  constructor(
    private apiCall: ApiCallService,
    private router: Router,
    private route: ActivatedRoute,
    private globalService: Globals,
    private _WidgetConfigService: WidgetConfigService,
    private dialog: MatDialog,
    private snackBar: SnackbarCollection,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private tokenStorage: TokenStorageService,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private location: Location
  ) {}
  s3BucketUrl = environment.s3BucketUrl;
  layouts = [];
  filter = {
    label: "",
  };
  validWidgets = null;
  finalTemplates;
  tabSelected;
  loading = false;
  emailtemplateId;
  templateType = "CUSTOM";
  rewardTemplateList = [];
  selectedLayout;
  selectedTemplate;
  appSubscriptionPlan;
  emailCount;
  customGenerictemplateList = [];
  customRewardtemplateList = [];
  standardGenerictemplateList = [];
  standardRewardtemplateList = [];
  filterSelected;
  headingTextKey;
  @ViewChild("search") searchElement: ElementRef;

  ngOnInit() {
    this.getWidgets();
    this.getTemplates();
    this.filterSelected = "All";
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  getrewardemail() {
    this.apiCall.getRewardEmailList().subscribe((result) => {
      this.rewardTemplateList = result["body"];
    });
  }

  showCustomtempalteListFilter = false;
  showStandardtempalteListFilter = false;

  updateShowFilter(selectListType) {
    if (
      this.standardGenerictemplateList.length > 0 &&
      this.standardRewardtemplateList.length > 0
    ) {
      this.showStandardtempalteListFilter = true;
    } else {
      this.showStandardtempalteListFilter = false;
    }
    if (
      this.customGenerictemplateList.length > 0 &&
      this.customRewardtemplateList.length > 0
    ) {
      this.showCustomtempalteListFilter = true;
    } else {
      this.showCustomtempalteListFilter = false;
    }
    if (
      ((selectListType == "preset" || selectListType == "All") &&
        this.showStandardtempalteListFilter) ||
      ((selectListType == "custom" || selectListType == "All") &&
        this.showCustomtempalteListFilter)
    ) {
      this.showFilters = true;
    } else {
      this.showFilters = false;
    }
  }
  getTemplates(selectListType?) {
    this.loading = true;
    this.customGenerictemplateList = [];
    this.customRewardtemplateList = [];
    this.standardGenerictemplateList = [];
    this.standardRewardtemplateList = [];
    let rewardAPI = this.apiCall.getRewardEmailList().subscribe((result) => {
      this.rewardTemplateList = result["body"];
      this.rewardTemplateList.sort((a, b) => {
        const dateA: Date = new Date(a.lastModifiedTime);
        const dateB: Date = new Date(b.lastModifiedTime);
        return dateB.getTime() - dateA.getTime();
      });
      this.rewardTemplateList.map((layout) => {
        if (layout["emailType"] != "STANDARD") {
          this.customRewardtemplateList.push({
            ...layout,
            apiToCallForDetails: "reward",
            isDeletable: true,
          });
        } else {
          this.standardRewardtemplateList.push({
            ...layout,
            apiToCallForDetails: "reward",
            isDeletable: false,
          });
        }
      });
    });
    let layoutAPI = this.apiCall.listEmailLayout().subscribe((response) => {
      this.layouts = response["body"];
      this.layouts.sort((a, b) => {
        const dateA: Date = new Date(a.lastModifiedTime);
        const dateB: Date = new Date(b.lastModifiedTime);
        return dateB.getTime() - dateA.getTime();
      });
      this.layouts.map((layout) => {
        if (layout["emailType"] != "STANDARD") {
          this.customGenerictemplateList.push({
            ...layout,
            apiToCallForDetails: "layout",
            isDeletable: true,
          });
        } else {
          this.standardGenerictemplateList.push({
            ...layout,
            apiToCallForDetails: "layout",
            isDeletable: true,
          });
        }
      });
    });
    let interval = setInterval(() => {
      if (rewardAPI.closed === true && layoutAPI.closed === true) {
        this.route.params.subscribe((param) => {
          if (param["id"] && param["id"] != "null") {
            this.emailtemplateId = param["id"];
            this.finalTemplates = this.layouts.concat(this.rewardTemplateList);
            if (param["type"] && param["type"] === "CUSTOM") {
              if (
                this.customRewardtemplateList.some((x) => x.id === +param["id"])
              ) {
                this.selectList("custom", null, false);
                this.viewTemplateDetail(
                  this.customRewardtemplateList.find(
                    (x) => x.id === +param["id"]
                  )
                );
              } else {
                this.selectList("custom", null, false);
                let req = this.customGenerictemplateList.find(
                  (x) => x.id === +param["id"]
                );
                if (req) {
                  this.viewTemplateDetail(req);
                } else {
                  this.selectDefault(selectListType);
                }
              }
            } else {
              if (
                this.standardRewardtemplateList.some(
                  (x) => x.id === +param["id"]
                )
              ) {
                this.selectList("preset", null, false);
                this.viewTemplateDetail(
                  this.standardRewardtemplateList.find(
                    (x) => x.id === +param["id"]
                  )
                );
              } else {
                this.selectList("preset", null, false);
                let req = this.standardGenerictemplateList.find(
                  (x) => x.id === +param["id"]
                );
                if (req) {
                  this.viewTemplateDetail(req);
                } else {
                  this.selectDefault(selectListType);
                }
              }
            }
          } else {
            this.selectDefault(selectListType);
          }
          this.loading = false;
        });
        clearInterval(interval);
      }
    });
  }

  selectDefault(selectListType) {
    if (selectListType) {
      if (
        selectListType == "custom" &&
        (this.customRewardtemplateList.length > 0 ||
          this.customGenerictemplateList.length > 0)
      ) {
        this.selectList(selectListType, null, true);
      } else {
        this.selectList("preset", null, true);
      }
    } else {
      if (
        this.customRewardtemplateList.length > 0 ||
        this.customGenerictemplateList.length > 0
      ) {
        this.selectList("custom", null, true);
      } else {
        this.selectList("preset", null, true);
      }
    }
  }

  showFilters = false;
  selectList(type, filter, selectFirst?) {
    this.loading = true;
    this.tabSelected = type;
    this.filterSelected = filter ? filter : "All";
    switch (type) {
      case "preset":
        this.headingTextKey = this._i18nDynamicTranslate.transform(
          "Preset Template",
          ["POS_PAGE"]
        );
        if (this.filterSelected == "generic") {
          this.filterSelected = "generic";
          this.finalTemplates = this.standardGenerictemplateList;
        } else if (this.filterSelected == "reward") {
          this.filterSelected = "reward";
          this.finalTemplates = this.standardRewardtemplateList;
        } else {
          this.filterSelected = "All";
          this.finalTemplates = this.standardGenerictemplateList.concat(
            this.standardRewardtemplateList
          );
        }
        break;
      case "custom":
        this.headingTextKey = this._i18nDynamicTranslate.transform(
          "Custom Template",
          ["POS_PAGE"]
        );
        if (this.filterSelected == "generic") {
          this.filterSelected = "generic";
          this.finalTemplates = this.customGenerictemplateList;
        } else if (this.filterSelected == "reward") {
          this.filterSelected = "reward";
          this.finalTemplates = this.customRewardtemplateList;
        } else {
          this.filterSelected = "All";
          this.finalTemplates = this.customGenerictemplateList.concat(
            this.customRewardtemplateList
          );
        }
        break;
    }
    this.updateShowFilter(type);
    if (selectFirst) {
      this.viewTemplateDetail(this.finalTemplates[0]);
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 500);
  }

  showSearch() {
    this.searchElement.nativeElement.focus();
  }

  templateLoading = false;
  viewTemplateDetail(layout) {
    if (!layout) {
      this.selectedLayout = "";
      this.selectedTemplate = "";
      this.templateLoading = false;
      return;
    }
    this.selectedTemplate = layout;
    if (this.selectedTemplate?.apiToCallForDetails === "reward") {
      this.templateLoading = true;
      this.apiCall.getRewardEmailDetail(layout["id"]).subscribe(
        (response) => {
          if (response["message"] === "success") {
            this.selectedLayout = JSON.parse(response["body"])["layoutHtml"];
          }
          this.templateLoading = false;
        },
        (err) => {}
      );
    } else {
      this.templateLoading = true;
      this.apiCall.getEmailLayoutDetail(layout["id"]).subscribe(
        (response) => {
          if (response["message"] === "success") {
            this.selectedLayout = JSON.parse(response["body"])["layoutHtml"];
            // console.log("EMAILLAYOUT2", this.selectedLayout);
          }
          this.templateLoading = false;
        },
        (err) => {}
      );
    }
  }

  selectTileView() {
    this.router.navigate(["/app/email/choose-layout"]);
  }

  async createStandardTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/email/layout-editor",
        {
          id: null,
          type: "CUSTOM",
          clone: false,
          return: "/app/email/layout",
        },
      ]);
    }
  }

  filterChange(event) {
    if (this.filter.label.length === 0) {
      let interval = setInterval(() => {
        let element = document.getElementById(
          "eTemp-" + this.selectedTemplate["id"]
        );
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
          clearInterval(interval);
        }
      });
    }
  }

  searchEmails() {
    this.emailCount = 0;
    this.finalTemplates.forEach((element) => {
      if (element.layoutName.toLowerCase().includes(this.filter.label))
        this.emailCount++;
    });
  }

  async editTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.checkfUserCanEditApi(
        this.selectedTemplate["id"],
        this.selectedTemplate["type"] === "GENERIC" ||
          this.selectedTemplate["type"] === "SURVEY"
          ? "CUSTOM"
          : "REWARD"
      ).then((result) => {
        console.log(result);
        if (
          result["message"] == "failed" &&
          result["body"]["isEditInProgress"] == true
        ) {
          let storeName = JSON.parse(result["body"]["edited_By"])["storeName"];
          const dialogRef = this.dialog.open(ContactUsDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            height: "auto",
            disableClose: true,
            width: "380px",
            data: {
              acess: this._i18nDynamicTranslate.transform(
                "Template Locked for Editing",
                ["POS_PAGE"]
              ),
              body:
                "This email template is currently being edited by " +
                storeName +
                " user. Please try again later or contact your team for further details.",
            },
          });
          dialogRef.afterClosed().subscribe((result) => {});
        } else {
          this.router.navigate([
            "/app/email/layout-editor",
            {
              id: this.selectedTemplate["id"],
              type: this.selectedTemplate["type"],
              clone: "false",
              emailType:
                this.selectedTemplate["type"] === "GENERIC" ||
                this.selectedTemplate["type"] === "SURVEY"
                  ? this.selectedTemplate["type"]
                  : "REWARD",
              apiForDetails: this.selectedTemplate["apiToCallForDetails"],
              return: "/app/email/layout",
            },
          ]);
        }
      });
    }
  }

  checkfUserCanEditApi(id, type) {
    return new Promise((resolve, reject) => {
      this.apiCall.checkIfUserCanEdit(id, type).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  async cloneTemplate() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.router.navigate([
        "/app/email/layout-editor",
        {
          id: this.selectedTemplate["id"],
          type: this.selectedTemplate["type"],
          clone: "true",
          apiForDetails: this.selectedTemplate["apiToCallForDetails"],
          return: "/app/email/layout",
          emailType:
            this.selectedTemplate["type"] === "GENERIC" ||
            this.selectedTemplate["type"] === "SURVEY"
              ? this.selectedTemplate["type"]
              : "REWARD",
        },
      ]);
    }
  }

  async deleteEmail() {
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogBoxComponent, {
        panelClass: "no-padding-dialog-popup",
        width: "20%",
        data: {
          subject: this._i18nDynamicTranslate.transform("Delete Template", [
            "POS_PAGE",
          ]),
          message: this._i18nDynamicTranslate.transform(
            "Do you wish to delete this Template?",
            ["POS_PAGE"]
          ),
          cancelButtonText: this._i18nDynamicTranslate.transform("Cancel", [
            "POS_PAGE",
          ]),
          successButtonText: this._i18nDynamicTranslate.transform("Delete", [
            "POS_PAGE",
          ]),
          "data-widget": "EMAIL LISTING",
          "data-button": "Confirm: Delete",
          "data-item": this.selectedTemplate["id"],
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.apiCall.deleteEmailLayout(this.selectedTemplate["id"]).subscribe(
            (response) => {
              this.snackBar.openSnackBar(
                this._i18nDynamicTranslate.transform(
                  "Email Delete Successfull",
                  ["POS_PAGE"]
                ),
                2000
              );
              this.getTemplates(this.tabSelected);
            },
            (err) => {
              const dialogRef = this.dialog.open(ContactUsDialogComponent, {
                panelClass: "no-padding-dialog-popup",
                height: "auto",
                disableClose: true,
                width: "380px",
                data: {
                  header: "Cannot Delete Email",
                  body: err.error["body"]
                    ? err.error["body"]
                    : "Something Went Wrong",
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                event.stopPropagation();
              });
            }
          );
        }
      });
    }
  }

  createSharableLink(layout) {
    console.log(layout);
    const urlTree: UrlTree = this.router.createUrlTree(["view-template"], {
      queryParams: {
        id: layout.id,
        mid: this.tokenStorage.getMerchantId(),
        type: layout.apiToCallForDetails.toLowerCase(),
      },
    });

    const relativeUrl: string = this.router.serializeUrl(urlTree);

    const baseUrl = window.location.origin;
    const fullUrl: string = `${baseUrl}${relativeUrl}`;

    console.log(fullUrl);

    this.copyToClipboard(fullUrl);
  }

  copyToClipboard(url: string) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    //alert("URL copied to clipboard: " + url); // Optional: show an alert or other UI feedback
    this.snackBar.openSnackBar("Email sharable link copied to clipboard", 5000);
  }
}
