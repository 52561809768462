<div class="conversion-rate-curve">
  <div class="header">
    <h2 class="title">{{data.title}}</h2>
    <mat-icon class="close-icon" (click)="closeDialog(false)">close</mat-icon>
  </div>
  
  <p class="description" *ngIf="data.title === 'Initial conversion rate'">
    The percentage of conversions from the initially unique customers who explored the path.
  </p>
  <p class="description" *ngIf="data.title === 'Total conversion rate'">
    The percentage of conversions from all unique customers who engaged with the path.
  </p>

  <div class="curve-container">
    <div class="curve-item">
      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/probability-curve-with-overlap+(1).svg" alt="With Overlap" class="curve-image" />
      <div class="text-content">
        <h3 class="curve-title">With Overlap</h3>
        <p class="curve-description">
          Overlap of the curves means that the probability of the expected conversion rate is shared between the variations. The area of overlap plays a significant role in deciding the winner.
        </p>
      </div>
    </div>
    <div class="curve-item">
      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/probability-curve-no-overlap.svg" alt="No Overlap" class="curve-image" />
      <div class="text-content">
        <h3 class="curve-title">No Overlap</h3>
        <p class="curve-description">
          When the curves appear so far apart from each other that there is no overlap between them, the curve at the right would be the one with the higher expected conversion rate.
        </p>
      </div>
    </div>
    <div class="curve-item">
      <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/assets/probability-curve-three-curves.svg" alt="When Three Curves" class="curve-image" />
      <div class="text-content">
        <h3 class="curve-title">When Three Curves</h3>
        <p class="curve-description">
          In this graph, you can see that the green curve has no overlap with the orange or blue curves. You can declare the green curve as the winner, as it outperforms the others.
        </p>
      </div>
    </div>
  </div>
</div>
