import { MapsAPILoader } from '@agm/core';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/token-storage.service';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { StoreService } from 'src/app/core/services/store.service';
import { StoreInfo } from 'src/app/shared/models/store-details.model';
import {countries} from 'src/app/shared/json-asset/countries'
import {timezones} from 'src/app/shared/json-asset/timezones'
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent } from "src/app/shared/components/dialog-box";

@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.css']
})
export class StoreDetailsComponent implements OnInit,AfterViewChecked,AfterViewInit {

  @Input() data: { storeId: string; storeName: string };
  @Output() sendDataEvent = new EventEmitter<{ storeId: string; storeName: string,action:string }>();
  @ViewChild('search', { read: ElementRef, static: false }) searchElementRef!: ElementRef;
  @ViewChild('scrollContainerStore') private scrollContainer!: ElementRef;
  // @ViewChild('search')public searchElementRef: ElementRef;
  // @ViewChild('mapContainer') mapContainer: ElementRef;

  storeQId=null;
  storeInfo: StoreInfo | null = null;
  tempStoreInfo: StoreInfo | null = null;

  editMode:boolean=false;
  zoom = 12;

  // Variable to toggle the accordion content
  showMap = false;
  constructor(private _apicall:ApiCallService,private _route:ActivatedRoute,private _tokenStorage:TokenStorageService,
    private _storeService: StoreService,    private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,
    private _activatedRoute: ActivatedRoute,private _router:Router, private _i18nDynamicTranslate: TranslateDynamicText, private snackbar: SnackbarCollection,private dialog: MatDialog) { 
    this._route.queryParams
    .subscribe(params => {
      this.storeQId=params.id;
      this._tokenStorage.saveStoreId(params.id);
      if(!this.storeQId){
        this.enableStoreEdit('NEW');
      }
      // this.editMode=false;
      // this._tokenStorage.saveStoreId(this.storeQId);
    }
  );
  }

  isloading:boolean=true;
  googleMapType = 'roadmap';
  lat ;
  long;
  location_url;
  placeId;
  geoCoder;

address;
// data;
longitude = "";
latitude = "";
category = "";
allTags = [];
selectedTags = [];

categories = {
  "MG0000000004": {
      "categoryId": "MG0000000004",
      "categoryName": "Clothing retail"
  },
  "MG0000000005": {
      "categoryId": "MG0000000005",
      "categoryName": "Restaurant"
  },
  "MG0000000006": {
      "categoryId": "MG0000000006",
      "categoryName": "Electronics"
  },
  "MG0000000007": {
      "categoryId": "MG0000000007",
      "categoryName": "Jewelry"
  },
  "MG0000000008": {
      "categoryId": "MG0000000008",
      "categoryName": "Kitchen/Cooking"
  },
  "MG0000000009": {
      "categoryId": "MG0000000009",
      "categoryName": "Pet Supplies"
  },
   "MG0000000010": {
      "categoryId": "MG0000000010",
      "categoryName": "Appliance"
  },
  "MG0000000011": {
      "categoryId": "MG0000000011",
      "categoryName": "Furniture"
  },
  "MG0000000012": {
      "categoryId": "MG0000000012",
      "categoryName": "Classes"
  },
   "MG0000000013": {
      "categoryId": "MG0000000013",
      "categoryName": "Health/Spas/Beauty/Personal Care"
  },
  "MG0000000014": {
      "categoryId": "MG0000000014",
      "categoryName": "Retail and Consumer Merchandise"
  },
  "MG0000000015": {
      "categoryId": "MG0000000015",
      "categoryName": "Travel/Leisure"
  }
}
filteredBanks=countries;
country;
selectedCountry=null;
isApiCalling:boolean=false;
isTagsLoading:boolean=false;
private map: google.maps.Map;
timezoneList=timezones;
selectedTimezone="Asia/Kolkata";
operatons = 'create';
googleReviewLink = '';
emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
mobilenumberPattern = /^\d{4,13}$/;
urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w\-.,@?^=%&:\/~+#]*)?(\?[\w\-.,@?^=%&:\/~+#]*)?$/i;
timezoneSearch = '';
qid;
ngOnInit(): void {
  console.log(this.timezoneList)
  this.qid = this._tokenStorage.getqId();
    if(this.storeQId!=null&&this.storeQId!=''){
      
      this.getStoreData();

    }
   else{
    this.sendDataToParent(null,null,null);
   }
    // this.initEditMap();
  }
  ngAfterViewChecked() {
    if (this.searchElementRef) {
      const myElement = this.searchElementRef.nativeElement;
    }
  }

  ngAfterViewInit() {
    // Ensure both isEdit and searchElementRef are available before initializing the map
    if (this.editMode && this.searchElementRef.nativeElement) {
      this.initEditMap();
    }
  }
  sendDataToParent(storeId:string,storeName:string,action:string) {
    this.sendDataEvent.emit({ storeId: storeId, storeName: storeName ,action:action});
  }
//   getStoreData(){
// console.log(this.storeQId);
//     this._apicall.getBasicStoreData(this.storeQId).subscribe((response)=>{
// console.log(response);
//     })
//   }
getStoreData() {
  this.isApiCalling=true;
  this._storeService.getStoreData(this.storeQId).subscribe(
    (storeInfo: StoreInfo) => {
      console.log(this.storeInfo);
      this.storeInfo = storeInfo;
      this.tempStoreInfo=storeInfo;
      
      console.log(this.storeInfo);
      this.category=storeInfo?.storeMetaInfo?.category;
      this.prefilleCategory(this.category);
      // this.data=this.storeInfo.storeMetaInfo;
      // this.initEditMap();
      localStorage.setItem("storeServiceCountry", storeInfo.storeMetaInfo.country);
      this.searchAlpha2Code(storeInfo.storeMetaInfo.country);
      this.isloading=false;
      this.isApiCalling=false;
      this.sendDataToParent(storeInfo.storeMetaInfo.qid,storeInfo.storeMetaInfo.storeName,null);
      this.selectedTimezone = storeInfo.timezone;
      if(this.operatons == 'edit') {
        this.address = this.parseAddressLocationJson(storeInfo.storeMetaInfo.addressLocationJson).address;
        this.placeId = storeInfo.placeId;
      }
    },
    (error) => {
      console.error('Error fetching store data:', error);
    }
  );
}
parseAddressLocationJson(jsonString: string): any {
  try {
    const parsedJson = JSON.parse(jsonString);
    return parsedJson;
  } catch (error) {
    console.error('Error parsing addressLocationJson:', error);
    return null;
  }
}
enableStoreEdit(type:String){
  this.scrollContainer.nativeElement.scrollTop = 0;
  if('EDIT'==type){
    this.tempStoreInfo = JSON.parse(JSON.stringify(this.storeInfo));
    this.operatons = 'edit';
    this.getStoreData();
  }else if('NEW'==type){
    this.isloading = false;
    this.tempStoreInfo= new StoreInfo();
    this.category = "";
    this.allTags = [];
    this.selectedTags = [];
    this.latitude = "";
    this.longitude = "";
    this.operatons = 'create';
    this.country =  { name: '',currencies:[{code:''}],callingCodes:[]};
    this.selectedCountry =  { name: '',currencies:[{code:''}],callingCodes:[]};
    this.selectedTimezone = null;
  }
  this.editMode=true;
  let ele = document.getElementById("qb-edit-page");
  ele.style.display = "block";
  if (this.editMode && this.searchElementRef.nativeElement){
    this.initEditMap();
  } 
  this.prefilleCategory(this.category);
}
saveStoreMetaInfo(): void {
  console.log(this.tempStoreInfo)

  console.log(this.tempStoreInfo?.storeMetaInfo);
  console.log(this.selectedCountry);
  console.log("PLACID", this.placeId);
  console.log("LOCATION", this.location_url);
  console.log("tags",this.selectedTags);
  console.log("category",this.category);
  if(this.checkValidation()){
  let addressLocationJson=
   {"locationUrl":this.location_url,
   "address":this.address,
   "latitude":this.lat,
   "longitude":this.long
  }

  let storeInfoRequestBody=this.tempStoreInfo;
  storeInfoRequestBody.storeMetaInfo.addressLocationJson=JSON.stringify(addressLocationJson);
  storeInfoRequestBody.storeMetaInfo.category=this.category;
  storeInfoRequestBody.storeMetaInfo.tags=this.selectedTags?.toString();
  storeInfoRequestBody.storeMetaInfo.country=this.selectedCountry?.alpha2Code;
  storeInfoRequestBody.storeMetaInfo.currency=this.selectedCountry?.currencies[0]["code"];
  storeInfoRequestBody.storeMetaInfo.latitude=this.lat;
  storeInfoRequestBody.storeMetaInfo.longitude=this.long;
  storeInfoRequestBody.storeMetaInfo.platform=this._tokenStorage.getMerchantOnlineStore();
  storeInfoRequestBody.storeMetaInfo.chainId=this._tokenStorage.getMerchantId();
  if(storeInfoRequestBody.phoneNumber) {
    storeInfoRequestBody.phoneNumber = this.selectedCountry?.callingCodes[0]+storeInfoRequestBody.phoneNumber;
  } else {
    storeInfoRequestBody.phoneNumber = null;
  }
  
  storeInfoRequestBody.placeId=this.placeId;
  storeInfoRequestBody.operation = this.operatons
  storeInfoRequestBody.googleReviewLink = this.tempStoreInfo.storeMetaInfo.googleReviewLink || null;

if(!this.storeQId){
  storeInfoRequestBody.storeMetaInfo.qid=null;
}
if(!this.tempStoreInfo?.storeMetaInfo.storeId){
  storeInfoRequestBody.storeMetaInfo.storeId = this.storeQId;
}
storeInfoRequestBody.timezone=this.selectedTimezone;
  console.log(storeInfoRequestBody);
    this.createBasicStoreData(storeInfoRequestBody);
  }
  else{
    return;
  }

 
}
toggleAccordion() {
  this.showMap = !this.showMap;
}
parseString(coordinate: string | undefined): number {
  if (coordinate) {
    return parseFloat(coordinate);
  }
  return 0;
}
addMarker(event) {
  this.lat = event.coords.lat;
  this.long = event.coords.lng
}

markerDragEnd(event) {
  console.log(event);
  this.lat = event.coords.lat;
  this.long = event.coords.lng;
  this.getAddress(this.lat, this.long);
}
getAddress(latitude, longitude) {
  this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        this.address = results[0].formatted_address;
        this.searchElementRef.nativeElement = this.address;
      } else {
        window.alert('No results found');
      }
    } else {
      window.alert('Geocoder failed due to: ' + status);
    }
  
  });
}
// initEditMap(){
//   console.log("map loading");
//   this.mapsAPILoader.load().then(() => {
//     this.setCurrentLocation();
//     this.geoCoder = new google.maps.Geocoder;
//   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
//     autocomplete.addListener("place_changed", () => {
//       this.ngZone.run(() => {
//         let place: google.maps.places.PlaceResult = autocomplete.getPlace();
//         this.address = place.formatted_address
//         console.log(place.formatted_address)
//         if (place.geometry === undefined || place.geometry === null) {
//           return;
//         }
//           this.lat = place.geometry.location.lat();
//           this.long = place.geometry.location.lng();
//           this.placeId = place.place_id;
//           this.location_url = place.url;
//           this.zoom = 15.5;
//           console.log("PLACID",place.place_id);
//           console.log("LOCATION",place.url)
//       });
//     });
//   });
// }
initEditMap() {
  // if(!this.editMode){
  //   return;
  // }
  setTimeout(() => {
    console.log("Delayed execution after 2 seconds");
   }, 2000);
  console.log("map loading");
    // // Check if google.maps.places namespace is available
    // if (typeof google === 'undefined' || typeof google.maps === 'undefined' || typeof google.maps.places === 'undefined') {
    //   // The Google Maps API is not fully loaded yet, so return or handle the situation accordingly
    //   this.map = new Maps(this.mapContainer.nativeElement, {
    //     center: { lat: this.lat, lng: this.long },
    //     zoom: 15.5,
    //   });
    // }

     // Create the map using the MapsAPILoader
  const mapOptions: google.maps.MapOptions = {
    center: { lat: this.lat, lng: this.long },
    zoom: 15.5,
  };

  this.mapsAPILoader.load().then(() => {
      // this.map = new google.maps.Map(this.mapContainer.nativeElement, mapOptions);
// console.log(this.searchElementRef.nativeElement);

    this.setCurrentLocation();
    this.geoCoder = new google.maps.Geocoder();
    console.log(this.searchElementRef?.nativeElement)
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement);
    console.log(autocomplete);
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        this.address = place.formatted_address;
        console.log(place.formatted_address);
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.lat = place.geometry.location.lat();
        this.long = place.geometry.location.lng();
        this.placeId = place.place_id;
        this.location_url = place.url;
        this.zoom = 15.5;
        console.log("PLACID", place.place_id);
        console.log("LOCATION", place.url);
      });
    });
  });
}
setCurrentLocation() {
console.log("setCurrentLocation");
  if(this.storeInfo?.storeMetaInfo['latitude']!=null && this.storeInfo?.storeMetaInfo['longitude']!=null){
    this.lat=parseFloat(this.storeInfo.storeMetaInfo['latitude'].toString());
    this.long=parseFloat(this.storeInfo.storeMetaInfo['longitude'].toString());
  }
  else if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.long = position.coords.longitude;
      this.zoom = 8;
      this.getAddress(this.latitude, this.longitude);
    });
  }else{
    this.lat="47.608013",
    this.long=" -122.335167"
    this.getAddress(this.lat,this.long);
  }
}
prefilleCategory(category){
  Object.keys(this.categories).forEach((key,index) => {
    if(this.categories[key].categoryId === category) {
      this._apicall.getTags(key).subscribe(response => {
        this.allTags = response['body'];
      })
    } 
});
this.selectedTags = this.storeInfo?.storeMetaInfo?.['tags']?.split(',').filter(tag => tag);
}
selectCategory(event) {
  this.selectedTags = [];
  console.log(event)
  Object.keys(this.categories).forEach((key,index) => {
    if(this.categories[key].categoryId === event.value) {
      this.isTagsLoading=true;
      this._apicall.getTags(key).subscribe(response => {
        this.allTags = response['body'];
        this.isTagsLoading=false;
      })
    } 
});
}
onCountrySelected(country: any) {
  this.selectedCountry = country.value;
}

removeTag(tag) {
  const index: number = this.selectedTags.indexOf(tag);
  if (index !== -1) {
      this.selectedTags.splice(index, 1);
  }     
}

addTag(tag) {
  this.selectedTags.push(tag);
}

addOrRemoveTag(tag){
  const index: number = this.selectedTags.indexOf(tag);
  if (index !== -1) {
      this.selectedTags.splice(index, 1);
  } else{
    console.log('Before push:', this.selectedTags);
    this.selectedTags.push(tag);
    console.log('After push:', this.selectedTags);

  }
}
createBasicStoreData(data:StoreInfo){
  this.isApiCalling=true;
  this._apicall.createBasicStoreData(data).subscribe((response)=>{
  console.log(response);

  data.storeMetaInfo.qid=response["body"];
  this.storeQId=response["body"]
  this.storeInfo=data;
  this.editMode=false;
  this.isApiCalling=false;
  this.sendDataToParent(data.storeMetaInfo.qid,data.storeMetaInfo.storeName,"ACCESS_KEYS");
  this._tokenStorage.saveStoreName(data.storeMetaInfo.storeName);
  if(this.operatons == 'edit') {
    this.dialog.open(SuccessDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "25%",
      data: {
        message: "Store data updated successfully",
        buttonText: "Ok",
      },
    });
  }
  if(this._tokenStorage.getMerchantOnlineStore() != 'bybetter') {
    this._activatedRoute.queryParams.subscribe(params => {
      const updatedParams = { ...params, id: this.storeQId };
      this._router.navigate(['/app/user-profile/store-info/store-keys'], { queryParams: updatedParams });
    });
  } else {
    this.isloading = true;
    setTimeout(()=>{
        this._activatedRoute.queryParams.subscribe(params => {
        const updatedParams = { ...params, id: this.storeQId };
        this._router.navigate(['/app/user-profile/store-info/store-details'], { queryParams: updatedParams });
        this.isloading = false;
      });
    },500);
  }

  },
  (error)=>{
console.log(error);
this.snackbar.openSnackBar(error['error']['body'], 2000);
this.isApiCalling=false;
  })
}

getCategoryName(categoryId:string){
return this.categories[categoryId]?.categoryName;
}
backToBasicDetails(){
  if(this.storeQId == null){
    this.isloading = true;
    let ele = document.getElementById("qb-edit-page");
    ele.style.display = "none";
  }
  this.getStoreData();
  this.editMode=false;
}
searchAlpha2Code(countryCode) {
  if(countryCode==null||countryCode==''){
    return;
  }
  const foundCountry = this.filteredBanks.find(country => country.alpha2Code === countryCode);
  if (foundCountry) {
    this.country= foundCountry;
    this.selectedCountry=foundCountry;
    let countryCallingCodeLength = this.selectedCountry.callingCodes[0].length;
    this.tempStoreInfo.phoneNumber = this.tempStoreInfo.phoneNumber!=null?this.tempStoreInfo.phoneNumber.substring(countryCallingCodeLength):null;
  }
}
addNewCustomer(){
  this._router.navigate(["/app/customers/add-new-customer"]);
}
createNewStore(){
  this._router.navigate(["/app/user-profile/store-info/store-details"]);
}
errorMail = "";
urlMessage = "";
phoneNumberErrorText = "";
errorStoreName = '';
errorCountry = '';
checkValidation() {
  let isValid = true;

  // Reset previous error messages
  this.errorStoreName = '';
  this.errorCountry = '';
  this.urlMessage = '';
  this.errorMail = '';
  this.phoneNumberErrorText = '';

  // Validate store name
  if (!this.tempStoreInfo?.storeMetaInfo?.storeName || this.tempStoreInfo.storeMetaInfo.storeName.length === 0) {
    this.errorStoreName = "Please enter the Store Name";
    isValid = false;
  }

  // Validate country selection
  if (!this.country?.name || this.country.name === '') {
    this.errorCountry = 'Please select the country';
    isValid = false;
  }

  // Validate Google review link
  if (this.tempStoreInfo?.storeMetaInfo?.googleReviewLink && 
      this.tempStoreInfo.storeMetaInfo.googleReviewLink !== '' && 
      !this.tempStoreInfo.storeMetaInfo.googleReviewLink.match(this.urlPattern)) {
    this.urlMessage = this._i18nDynamicTranslate.transform("Please enter a valid URL", ['POS_PAGE']);
    isValid = false;
  }

  // Additional validations if the store data is valid so far
  if (this.checkIfValid()) {
    // Validate email
    if(this.tempStoreInfo.email.match(this.emailPattern.trim())==null){
      this.errorMail = this._i18nDynamicTranslate.transform("Please enter a valid email address", ['POS_PAGE']);
      isValid = false;
    }

    // Validate phone number
    if (this.tempStoreInfo.phoneNumber && this.tempStoreInfo.phoneNumber.toString().length > 0) {
      const phoneLength = this.tempStoreInfo.phoneNumber.toString().length;
      const validPhoneLength = Array.isArray(this.selectedCountry.phoneLength)
        ? this.selectedCountry.phoneLength.includes(phoneLength)
        : phoneLength === this.selectedCountry.phoneLength;

      if (!this.mobilenumberPattern.test(this.tempStoreInfo.phoneNumber) || !validPhoneLength) {
        this.phoneNumberErrorText = `Please enter a valid Phone Number of length ${this.selectedCountry.phoneLength}`;
        isValid = false;
      }
    }
  }

  // If any validation failed, return false
  return isValid;
}
numberpress(event: KeyboardEvent,maxLength){
  this.phoneNumberErrorText = "";
  const invalidCharacters = ['e', '-', '+','.'];
    const inputValue = (event.target as HTMLInputElement).value;
    if ((inputValue.length >= maxLength && event.key !== 'Backspace')|| invalidCharacters.includes(event.key)) {
      event.preventDefault();
    }
}
emailpress(){
  this.errorMail = "";
}
urlpress(){
  this.urlMessage = "";
}
storenamepress() {
  this.errorStoreName = "";
}
checkIfValid(){
  if(this.tempStoreInfo.email.match(this.emailPattern.trim())==null 
      || (this.tempStoreInfo.phoneNumber && this.tempStoreInfo.phoneNumber.toString().length>0 &&
       (!this.mobilenumberPattern.test(this.tempStoreInfo.phoneNumber) 
      || (Array.isArray(this.selectedCountry.phoneLength) && !this.selectedCountry.phoneLength.includes(this.tempStoreInfo.phoneNumber.length))
      || this.tempStoreInfo.phoneNumber.toString().length !== this.selectedCountry.phoneLength ))){
    return true;
  }
  else{
    return false;
  }
}

coutrySearchValue = '';
timezoneSearchValue = '';
selectTimezone(timezone) {
  this.selectedTimezone = timezone;
}
selectCountry(country) {
  this.country = country;
  this.selectedCountry = country;
  this.errorCountry = '';
}
closeDropdown(dropdownElement: HTMLElement | null) {
  if (dropdownElement) {
    setTimeout(() => {
      const activeElement = document.activeElement as HTMLElement;
      if (activeElement && dropdownElement.contains(activeElement)) {
        // If the active element is inside the dropdown, don't close it
        return;
      }
      dropdownElement.style.display = 'none';
    }, 100); // Adjust the delay as needed
  }
}
}
