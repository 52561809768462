import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  NgZone,
  OnInit,
  AfterContentInit,
  AfterViewInit,
} from "@angular/core";
import Bee from "@mailupinc/bee-plugin";
import { MatDialog } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  Router,
  ActivatedRoute,
  UrlTree,
  NavigationStart,
} from "@angular/router";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import { SubscriptionPlanHandlerService } from "src/app/core/services/subscription-plan-handler.service";
import { WidgetConfigService } from "src/app/core/services/widget-config.service";
import { template1, template2, template3, template4 } from "../../json-asset";
import { fontList } from "../../json-asset/font-list";
import {
  chefDrivenTemplate1,
  chefDrivenTemplate2,
  chefDrivenTemplate3,
  chefDrivenTemplate4,
} from "../../json-asset/chef-driven-templates";
import {
  ConfirmationDialogBoxComponent,
  ContactUsDialogComponent,
  EmailEditorDialogComponent,
  EmailTemplateNameDialogComponent,
  SendTestEmailDialogComponent,
} from "../dialog-box";
import { TranslateDynamicText } from "../../pipes/dynamic-translation.pipe";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
interface urlData {
  id: string;
  return: string;
  type: string;
  clone: string;
  apiForDetails: string;
}

@Component({
  selector: "app-email-template-creation-dialog",
  templateUrl: "./email-template-creation-dialog.component.html",
  styleUrls: ["./email-template-creation-dialog.component.css"],
})
export class EmailTemplateCreationDialogComponent implements AfterViewInit {
  @HostListener("window:resize", ["$event"])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
  }

  @ViewChild("fullContainer") fullContainer: ElementRef;
  @ViewChild("inpName") inpName: ElementRef;
  @ViewChild("inpSubject") inpSubject: ElementRef;

  scrHeight;
  scrWidth;
  finalScreenHeight;
  finalScreenWidth;
  finalHeight;
  beeTest;
  beeToken;
  beeConfig;
  returnUrl;
  mergeTags = [];
  dialogNeeded;
  payload = {
    client_id: environment.beepluginClientId,
    client_secret: environment.beepluginClientSecretId,
    grant_type: "password",
  };
  specialLinks = [
    {
      type: "Unsubscribe",
      label: "Unsubscribe",
      link: "%unsubscribe_url%",
    },
  ];
  selectedTemplate = "T1";
  finalContent;
  templatesArray = [];
  templateName;
  templateForm: FormGroup = new FormGroup({
    TemplateName: new FormControl({ value: "", disabled: false }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
    ]),
    subject: new FormControl({ value: "", disabled: false }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
    ]),
  });
  subjectError = false;
  nameError = false;
  submitClicked = false;
  responseMsg: string = null;
  templateIdNew = 130;
  templateType;
  urlData: urlData = {
    id: null,
    type: null,
    return: null,
    clone: null,
    apiForDetails: null,
  };
  layout;
  renderStatus = false;
  appSubscriptionPlan;
  validWidgets;
  html;
  loadingDialog = false;
  sendTestEmail;
  emailType = "";
  tabselected = "";
  template1;
  template2;
  template3;
  template4;
  newTemplate = {};
  isEditorLoaded: boolean = true;
  isTemplateNameEdit: boolean = false;
  editorSatus = "Loading...";
  jsonFile;
  htmlFile;
  inActive = false;

  constructor(
    private apiCall: ApiCallService,
    private snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorage: TokenStorageService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private token: TokenStorageService,
    private _WidgetConfigService: WidgetConfigService,
    private _SubscriptionPlanHandlerService: SubscriptionPlanHandlerService,
    private snack: SnackbarCollection,
    private _i18nDynamicTranslate: TranslateDynamicText,
    private location: Location
  ) {
    this.getScreenSize();
  }

  response;
  private visibilityChangeListener: any;
  private timer: any;
  private readonly TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
  private routerSubscription!: Subscription;

  @HostListener("window:beforeunload", ["$event"])
  handleBeforeUnload(event: BeforeUnloadEvent): void {
    this.saveAsHtml("onClose", this.jsonFile, this.htmlFile, true);
    event.preventDefault(); // Required for Chrome
    event.returnValue = ""; // Standard for most browsers
  }

  ngOnDestroy(): void {
    // Clean up event listener and timer
    if (this.visibilityChangeListener) {
      document.removeEventListener(
        "visibilitychange",
        this.visibilityChangeListener
      );
    }
    this.clearTimer();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.getSampleTemplates();
    this.getWidgets();
    this.route.params.subscribe((param) => {
      this.urlData.id = param.id;
      this.urlData.type = param.type;
      this.urlData.return = param.return;
      this.urlData.clone = param.clone;
      this.urlData.apiForDetails = param.apiForDetails;
      this.emailType = param.emailType;
      this.tabselected = param.tabSelected;
      this.checkAction();
      if (this.fullContainer.nativeElement.offsetWidth < 1050) {
        this.dialogNeeded = true;
        this.openDialogBox();
      } else {
        this.dialogNeeded = false;
        this.apiCall
          .getAllMessageTemplateVariablesforOffer(null)
          .subscribe((response) => {
            let variables = JSON.parse(response["body"]);
            for (let key in variables) {
              this.mergeTags.push({
                name: variables[key],
                value: key,
              });
            }
            if (this.urlData.id !== "null") {
              if (this.urlData.apiForDetails === "reward") {
                this.apiCall
                  .getRewardEmailDetail(this.urlData.id)
                  .subscribe((response) => {
                    this.layout = JSON.parse(response["body"]);
                    this.jsonFile = this.layout["layoutJson"];
                    this.htmlFile = this.layout["layoutHtml"];
                    this.saveAsHtml(
                      "onSave",
                      this.jsonFile,
                      this.htmlFile,
                      false
                    );
                    this.initializeForm();
                  });
              } else {
                this.apiCall
                  .getEmailLayoutDetail(this.urlData.id)
                  .subscribe((response) => {
                    this.layout = JSON.parse(response["body"]);
                    this.jsonFile = this.layout["layoutJson"];
                    this.htmlFile = this.layout["layoutHtml"];
                    this.saveAsHtml(
                      "onSave",
                      this.jsonFile,
                      this.htmlFile,
                      false
                    );
                    this.initializeForm();
                  });
              }
            } else {
              this.initializeForm();
            }
          });
      }
    });
    this.visibilityChangeListener = () => {
      if (document.hidden) {
        this.startTimer(); // Start the timer when the user switches tabs or minimizes
      } else {
        this.clearTimer(); // Clear the timer when the user returns
      }
    };
    document.addEventListener(
      "visibilitychange",
      this.visibilityChangeListener
    );
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && !this.inActive) {
        // Call your function before navigating to a new component
        if(this.urlData.id !== "null"){
          this.saveAsHtml("onSave", this.jsonFile, this.htmlFile, true);
        }
      }
    });
  }

  startTimer(): void {
    this.clearTimer(); // Ensure no duplicate timers are running
    // If already inactive, don't start the timer again
    if (this.inActive) {
      return;
    }
    this.timer = setTimeout(() => {
      this.inActive = true;
      this.returnBack();
    }, this.TIMEOUT_DURATION);
  }

  clearTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  async getWidgets() {
    this.validWidgets = await this._WidgetConfigService.getWidget();
    this.appSubscriptionPlan =
      await this._WidgetConfigService.getAppSubscriptionPlan();
  }

  initializeForm() {
    let disabledStatus;
    if (this.urlData.id !== "null") {
      if (
        this.layout["emailType"] === "STANDARD" &&
        this.urlData.clone === "false"
      ) {
        disabledStatus = true;
      }
    }
    this.templateForm = new FormGroup({
      TemplateName: new FormControl({ value: "", disabled: disabledStatus }, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),
      ]),
      subject: new FormControl({ value: "", disabled: disabledStatus }, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    });
    this.renderStatus = true;
    this.finalHeight = this.scrHeight;
    this.beeTest = new Bee(this.beeToken);
    this.beeConfig = {
      uid: "user-id-" + this.tokenStorage.getMerchantId(),
      container: "bee-plugin-container",
      language: "en-US",
      autosave: 30,
      readOnly: true,
      onSave: (jsonFile, htmlFile) => {
        this.ngZone.run(() => {
          this.editorSatus = "Saving";
          let emailTemplateName = this.templateForm.get("TemplateName").value;
          if (emailTemplateName && emailTemplateName.length) {
            this.jsonFile = jsonFile;
            this.htmlFile = htmlFile;
            this.saveAsHtml("onSave", jsonFile, htmlFile, false);
          }
        });
      },
      onLoad: () => {
        console.log("BEE editor is ready. Executing function...");
        this.ngZone.run(() => {
          this.editorSatus = "Loading...";
          this.save();
        });
      },
      onAutoSave: (jsonFile) => {
        console.log("AutoSave is working", jsonFile);
        this.ngZone.run(() => {
          this.editorSatus = "Autosaving";
          if (!this.inActive) this.save();
        });
        console.log(this.editorSatus);
        // this.save();
      },
      onChange: (jsonFile, response) => {
        console.log("onChange is working", jsonFile, response);
        this.ngZone.run(() => {
          this.editorSatus = "Editing";
          this.save();
        });
        console.log(this.editorSatus);
      },
      loadingSpinnerDisableOnSave: true,
      onSaveAsTemplate: (jsonFile) => {},
      onSend: (htmlFile) => {
        let emailTemplateName = this.templateForm.get("TemplateName").value;
        if (emailTemplateName && emailTemplateName.length) {
          this.apiCall
            .sendTestEngageEmailHTML(
              "EMAIL_LAYOUT_HTML",
              "EMAIL",
              null,
              this.sendTestEmail,
              null,
              false,
              null,
              htmlFile
            )
            .subscribe(
              (response) => {
                this.snack.openSnackBar(
                  this._i18nDynamicTranslate.transform("Test email sent", [
                    "SHARED_PAGE",
                  ]),
                  2000
                );
              },
              (err) => {
                this.snack.openSnackBar(
                  this._i18nDynamicTranslate.transform(
                    "Failed to send test email",
                    ["SHARED_PAGE"]
                  ),
                  2000
                );
              }
            );
        } else
          this.snack.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Email Template Name is required",
              ["SHARED_PAGE"]
            ),
            2000
          );
      },
      onError: (errorMessage) => {},
      // onChange: (jsonFile, response) => {
      //   let i = -1;
      //     Object.keys(JSON.parse(this.response['body']).socialMediaUrls).map(key => {
      //       i = -1;
      //       JSON.parse(jsonFile).page.rows[5].columns[0].modules[1].descriptor["iconsList"]["icons"].forEach(element => {
      //         i++;
      //         if(element["image"]["alt"] === key) {
      //           JSON.parse(jsonFile).page.rows[5].columns[0].modules[1].descriptor["iconsList"]["icons"][i]["image"]["href"] = JSON.parse(this.response['body']).socialMediaUrls[key];
      //         }
      //       });
      //       return (JSON.parse(jsonFile));
      //     });
      // },
      editorFonts: {
        showDefaultFonts: false,
        customFonts: fontList,
      },
    };
    this.beeConfig.specialLinks = this.specialLinks;
    if (this.urlData.id !== "null") {
      if (this.urlData.clone === "true") {
        this.layout["name"] = this.layout["name"] + "_copy";
      }
      this.templateForm.get("TemplateName").setValue(this.layout["name"]);
      this.templateName = this.layout["name"];
    }
    if (this.urlData.type === "REWARD" || this.emailType === "REWARD") {
      if (this.urlData.clone === "true") {
        this.layout["name"] = this.layout["name"] + "_copy";
      } else {
        this.templateForm.get("subject").setValue(this.layout["subject"]);
      }
    }
    this.beeConfig.mergeTags = this.mergeTags;
    if (this.urlData.id !== "null") {
      this.startBeeEditor();
    }
    // this.beeTest
    //   .getToken(this.payload.client_id, this.payload.client_secret)
    //   .then(() =>{
    //     this.beeTest.start(
    //       this.beeConfig,
    //       this.urlData.id !== "null"
    //         ? JSON.parse(this.layout["layoutJson"])
    //         : this.newTemplate
    //     )
    //   }
    //   );
  }

  save() {
    this.beeTest.save();
  }

  change() {
    this.isEditorLoaded = true;
    console.log(this.isEditorLoaded);
  }

  async saveAsHtml(type, json, html, isClose) {
    this.html = html;
    if (
      await this._SubscriptionPlanHandlerService.IsValidOperation(
        this.appSubscriptionPlan
      )
    ) {
      this.submitClicked = true;
      let mId = this.tokenStorage.getMerchantId();
      if (this.templateForm.controls.TemplateName.errors) {
        this.snackBar.open(
          "Template name too long or has invalid characters",
          "",
          { duration: 2000 }
        );
        return 0;
      }
      this.finalContent = {
        id:
          this.urlData.id !== "null" || this.urlData.clone === "false"
            ? this.urlData.id
            : null,
        merchantId: mId,
        name: this.templateForm.get("TemplateName").value,
        layoutHtml: html,
        layoutJson: json,
        type: this.emailType === "REWARD" ? this.urlData.type : "GENERIC",
        editedBy: {
          storeId:
            this.tokenStorage.getqId() === "null"
              ? null
              : this.tokenStorage.getqId(),
          storeName: this.tokenStorage.getStoreName(),
        },
      };
      if (this.urlData.id !== "null" && this.urlData.clone === "false") {
        if (this.urlData.apiForDetails === "reward") {
          let data = {
            merchantId: mId,
            subject: this.templateForm.get("subject").value,
            name: this.templateForm.get("TemplateName").value
              ? this.templateForm.get("TemplateName").value
              : null,
            layoutHtml: html,
            layoutJson: json,
            id: this.urlData.id,
            editedBy: {
              storeId:
                this.tokenStorage.getqId() === "null"
                  ? null
                  : this.tokenStorage.getqId(),
              storeName: this.tokenStorage.getStoreName(),
            },
          };
          this.apiCall.updateRewardEmail(data, isClose).subscribe(
            (response) => {
              // this.returnToPreviousScreen(this.urlData.id, this.urlData.type);
              this.editorSatus = "Saved!";
            },
            (err) => {
              this.snackBar.open(
                this._i18nDynamicTranslate.transform(err["error"]["body"], [
                  "SHARED_PAGE",
                ]),
                "",
                { duration: 2000 }
              );
            }
          );
        } else {
          this.apiCall.updateEmailLayout(this.finalContent, isClose).subscribe(
            (response) => {
              this.editorSatus = "Saved!";
              // this.returnToPreviousScreen(response["body"], this.urlData.type);
            },
            (err) => {
              this.snackBar.open(
                this._i18nDynamicTranslate.transform(err["error"]["body"], [
                  "SHARED_PAGE",
                ]),
                "",
                { duration: 2000 }
              );
            }
          );
        }
      } else {
        this.apiCall.createEmailLayout(this.finalContent).subscribe(
          (response) => {
            this.editorSatus = "Saved!";
            const currentParams = {
              ...this.route.snapshot.params,
              id: response["body"],
              clone: false,
            };

            // Construct the new URL path with the updated parameters
            const newUrl = this.router
              .createUrlTree(["app/email/layout-editor", currentParams])
              .toString();

            // Use replaceState to update the URL without reloading the page
            this.location.replaceState(newUrl);
            this.urlData.id = response["body"];
            this.urlData.clone = "false";

            // this.returnToPreviousScreen(response["body"], "CUSTOM");
          },
          (err) => {
            this.snackBar.open(
              this._i18nDynamicTranslate.transform(err["error"]["body"], [
                "SHARED_PAGE",
              ]),
              "",
              { duration: 2000 }
            );
          }
        );
      }
    }
  }

  returnToPreviousScreen(id, type) {
    this.router.navigate([this.urlData.return, { id: id, type: type }]);
  }

  closeEditor() {
    this.router.navigate([
      this.urlData.return,
      {
        id: this.urlData.id === "null" ? null : this.urlData.id,
        type: this.urlData.type,
        apiForDetails: this.urlData.apiForDetails
          ? this.urlData.apiForDetails
          : null,
      },
    ]);
  }

  selectTemplate(template) {
    this.selectedTemplate = template;
    switch (template) {
      case "T1": {
        this.addBrandingDetails(this.template1);
        return this.template1;
      }
      case "T2":
        this.addBrandingDetails(this.template2);
        return this.template2;
      case "T3":
        this.addBrandingDetails(this.template3);
        return this.template3;
      case "T4":
        this.addBrandingDetails(this.template4);
        return this.template4;
      case "newTemplate":
        return this.newTemplate;
    }
  }

  openDialogBox() {
    this.finalScreenHeight = this.scrHeight - 61;
    this.finalScreenWidth = this.scrWidth;
    let sh = this.finalScreenHeight * (90 / 100);
    let sw = this.finalScreenWidth * (70 / 100);
    const dialogRef = this.dialog.open(EmailEditorDialogComponent, {
      panelClass: "customAttachEmail",
      height: sh + "px",
      width: this.finalScreenWidth < 1500 ? "1024px" : sw + "px",
      minWidth: "1024px",
      maxWidth: "99vw",
      minHeight: "620px",
      disableClose: true,
      data: {
        type: this.urlData.type,
        id: this.urlData.id,
        clone: this.urlData.clone,
        return: this.urlData.return,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.snackBar.open(
          this._i18nDynamicTranslate.transform("Successfull", ["SHARED_PAGE"]),
          ""
        );
      }
    });
  }

  openSendTestEmailDialog() {
    const dialogRef = this.dialog.open(SendTestEmailDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      height: "auto",
      disableClose: true,
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response != false) {
        this.sendTestEmail = response;
        let emailTemplateName = this.templateForm.get("TemplateName").value;
        if (emailTemplateName && emailTemplateName.length) {
          this.snack.openSnackBar(
            this._i18nDynamicTranslate.transform("Sending test email", [
              "SHARED_PAGE",
            ]),
            2000
          );
          this.beeTest.send();
        } else
          this.snack.openSnackBar(
            this._i18nDynamicTranslate.transform(
              "Email Template Name is required",
              ["SHARED_PAGE"]
            ),
            2000
          );
      }
    });
  }

  getSampleTemplates() {
    if (this.tokenStorage.getMerchantOnlineStore() === "restomail") {
      this.template1 = chefDrivenTemplate1;
      this.template2 = chefDrivenTemplate2;
      this.template3 = chefDrivenTemplate3;
      this.template4 = chefDrivenTemplate4;
    } else {
      this.template1 = template1;
      this.template2 = template2;
      this.template3 = template3;
      this.template4 = template4;
    }
    this.templatesArray = [
      this.template1,
      this.template2,
      this.template3,
      this.template4,
    ];
  }

  // addBrandingDetails() {
  //   this.apiCall.getBrandingDetails().subscribe((response) => {
  //     this.response = response;
  //     let i = -1;
  //     this.template1.page.rows[0].columns[0].modules[0].descriptor[
  //       "image"
  //     ].src = JSON.parse(response["body"]).logoUrl;
  //     this.template2.page.rows[0].columns[0].modules[0].descriptor[
  //       "image"
  //     ].src = JSON.parse(response["body"]).logoUrl;
  //     this.template3.page.rows[0].columns[0].modules[0].descriptor[
  //       "image"
  //     ].src = JSON.parse(response["body"]).logoUrl;
  //     this.template4.page.rows[0].columns[0].modules[0].descriptor[
  //       "image"
  //     ].src = JSON.parse(response["body"]).logoUrl;
  //     Object.keys(JSON.parse(response["body"]).socialMediaUrls).map((key) => {
  //       i = -1;
  //       this.template1.page.rows[5].columns[0].modules[1].descriptor[
  //         "iconsList"
  //       ]["icons"].forEach((element) => {
  //         i++;
  //         if (element["image"]["alt"] === key) {
  //           if (
  //             this.template1.page.rows[5].columns[0].modules[1].descriptor[
  //               "iconsList"
  //             ]["icons"][i] !== undefined
  //           )
  //             this.template1.page.rows[5].columns[0].modules[1].descriptor[
  //               "iconsList"
  //             ]["icons"][i]["image"]["href"] = JSON.parse(
  //               response["body"]
  //             ).socialMediaUrls[key];
  //         }
  //       });
  //     });
  //     Object.keys(JSON.parse(response["body"]).socialMediaUrls).map((key) => {
  //       i = -1;
  //       this.template2.page.rows[3].columns[0].modules[2].descriptor[
  //         "iconsList"
  //       ]["icons"].forEach((element) => {
  //         i++;
  //         if (element["image"]["alt"] === key) {
  //           this.template2.page.rows[3].columns[0].modules[2].descriptor[
  //             "iconsList"
  //           ]["icons"][i]["image"]["href"] = JSON.parse(
  //             response["body"]
  //           ).socialMediaUrls[key];
  //         }
  //       });
  //     });
  //     Object.keys(JSON.parse(response["body"]).socialMediaUrls).map((key) => {
  //       i = -1;
  //       this.template3.page.rows[5].columns[0].modules[0].descriptor[
  //         "iconsList"
  //       ]["icons"].forEach((element) => {
  //         i++;
  //         if (element["image"]["alt"] === key) {
  //           this.template3.page.rows[5].columns[0].modules[0].descriptor[
  //             "iconsList"
  //           ]["icons"][i]["image"]["href"] = JSON.parse(
  //             response["body"]
  //           ).socialMediaUrls[key];
  //         }
  //       });
  //     });
  //     Object.keys(JSON.parse(response["body"]).socialMediaUrls).map((key) => {
  //       i = -1;
  //       this.template4.page.rows[6].columns[0].modules[0].descriptor[
  //         "iconsList"
  //       ]["icons"].forEach((element) => {
  //         i++;
  //         if (element["image"]["alt"] === key) {
  //           this.template4.page.rows[6].columns[0].modules[0].descriptor[
  //             "iconsList"
  //           ]["icons"][i]["image"]["href"] = JSON.parse(
  //             response["body"]
  //           ).socialMediaUrls[key];
  //         }
  //       });
  //     });
  //   });
  // }

  addBrandingDetails(template) {
    this.apiCall.getBrandingDetails().subscribe((response) => {
      this.response = response;
      let i = -1;
      let logoData = JSON.parse(response["body"]);
      template.page.rows[0].columns[0].modules[0].descriptor["image"].src =
        logoData.logoUrl;
      let brandingIcons = [];
      Object.keys(logoData.socialMediaUrls).map((key) => {
        let iconData = {
          image: {
            alt: "",
            href: "",
            prefix: "",
            src: "",
            target: "_self",
            title: "",
          },
          name: "",
          text: "",
          type: "follow",
        };
        iconData.image.alt = key;
        iconData.image.href = logoData.socialMediaUrls[key];
        iconData.image.title = key;
        iconData.name = key;
        if (key === "Facebook") {
          iconData.image.src =
            "https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/facebook@2x.png";
          iconData.image.prefix = "https://www.facebook.com/";
        }
        if (key === "Twitter") {
          iconData.image.src =
            "https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/twitter@2x.png";
          iconData.image.prefix = "https://www.twitter.com/";
        }
        if (key === "LinkedIn") {
          iconData.image.src =
            "https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/linkedin@2x.png";
          iconData.image.prefix = "https://www.linkedin.com/";
        }
        if (key === "Instagram") {
          iconData.image.src =
            "https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/instagram@2x.png";
          iconData.image.prefix = "https://www.instagram.com/";
        }
        if (key === "TikTok") {
          iconData.image.src =
            "https://app-rsrc.getbee.io/public/resources/social-networks-icon-sets/circle-color/tiktok@2x.png";
          iconData.image.prefix = "https://www.tiktok.com/";
        }
        brandingIcons.push(iconData);
      });
      template.page.rows.forEach((row) => {
        row.columns.forEach((column) => {
          column.modules.forEach((module) => {
            if (
              module.type === "mailup-bee-newsletter-modules-social" &&
              module.descriptor.iconsList
            ) {
              module.descriptor.iconsList.icons = brandingIcons;
            }
          });
        });
      });
    });
  }

  checkAction() {
    if (this.urlData.id === "null") {
      let email_list = document.getElementById("sample-listing");
      email_list.style.display = "block";
      return;
    } else {
      let lel = document.getElementById("qb-bee-editor-container");
      let email_list = document.getElementById("sample-listing");
      email_list.style.display = "none";
      lel.style.display = "block";
    }
  }

  shwoTemplate(template) {
    const dialogRef = this.dialog.open(EmailTemplateNameDialogComponent, {
      panelClass: "no-padding-dialog-popup",
      width: "30%",
      data: {
        title: "Email Template Name",
        field1Name: this._i18nDynamicTranslate.transform("Template Name", [
          "POS_PAGE",
        ]),
        cancelButtonName: this._i18nDynamicTranslate.transform("Cancel", [
          "POS_PAGE",
        ]),
        submitButtonName: this._i18nDynamicTranslate.transform("Continue", [
          "POS_PAGE",
        ]),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.startBeeEditor(template);
        this.templateName = result;
        this.templateForm.get("TemplateName").setValue(result);
        let lel = document.getElementById("qb-bee-editor-container");
        let email_list = document.getElementById("sample-listing");
        email_list.style.display = "none";
        lel.style.display = "block";
      }
    });
  }

  nameChange(type) {
    if (type) {
      if (this.templateForm.controls.TemplateName.errors) {
        return;
      }
      this.templateName = this.templateForm.get("TemplateName").value;
      this.isTemplateNameEdit = false;
      this.save();
    } else {
      this.templateForm.get("TemplateName").setValue(this.templateName);
      this.isTemplateNameEdit = false;
    }
  }

  previewEmailTemplate() {
    this.beeTest.preview();
  }

  returnBack() {
    this.saveAsHtml("onSave", this.jsonFile, this.htmlFile, true);
    if (this.inActive) {
      const dialogRef = this.dialog.open(ContactUsDialogComponent, {
        panelClass: "no-padding-dialog-popup",
        height: "auto",
        disableClose: true,
        width: "380px",
        data: {
          header: this._i18nDynamicTranslate.transform("Template Inactive", [
            "POS_PAGE",
          ]),
          body: "This template has been inactive for more than 5 minutes, Redirecting back to listing page after saving the latest changes.",
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.router.navigate([
          this.urlData.return,
          { id: this.urlData.id, type: this.urlData.type },
        ]);
      });
    } else {
      this.router.navigate([
        this.urlData.return,
        { id: this.urlData.id, type: this.urlData.type },
      ]);
    }
  }

  async startBeeEditor(template?) {
    await this.beeTest
      .getToken(this.payload.client_id, this.payload.client_secret)
      .then(() => {
        this.beeTest.start(
          this.beeConfig,
          this.urlData.id !== "null"
            ? JSON.parse(this.layout["layoutJson"])
            : this.selectTemplate(template)
        );
      });
  }

  createSharableLink() {
    event.stopPropagation();
    const urlTree: UrlTree = this.router.createUrlTree(["view-template"], {
      queryParams: {
        id: this.urlData.id,
        mid: this.tokenStorage.getMerchantId(),
        type: this.emailType
          ? this.emailType.toLowerCase()
          : this.urlData.type.toLowerCase(),
      },
    });

    const relativeUrl: string = this.router.serializeUrl(urlTree);

    const baseUrl = window.location.origin;
    const fullUrl: string = `${baseUrl}${relativeUrl}`;

    console.log(fullUrl);

    this.copyToClipboard(fullUrl);
  }

  copyToClipboard(url: string) {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // alert("URL copied to clipboard: " + url); // Optional: show an alert or other UI feedback
    this.snack.openSnackBar("Email sharable link copied to clipboard", 5000);
  }
}
