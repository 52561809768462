import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WidgetRouteGaurdService } from 'src/app/core/services/widget-route-gaurd.service';
import { CampaignAddFilterComponent, CampaignHowToCreateComponent } from './component';
import { CampaignListComponent, CampaignOverviewComponent, CampaignStrategyComponent, CampaignTemplateCreationComponent, CampaignTemplateListingComponent, CampaignTemplatesComponent, SetUpCampaignComponent, CampaignAbTestingComponent, CampaignAbTestingAnalyticsComponent } from './pages';
import { OfferReportComponent } from '../offer/pages';

const routes: Routes = [
    { path: "", redirectTo: "overview", pathMatch: "full" },
    {
      path: "ab-testing-analytics",
      component: CampaignAbTestingAnalyticsComponent,
      data: { titleheading: "Journeys", title: "AB Testing Analytics"},
    },
    {
      path: "ab-testing",
      component: CampaignAbTestingComponent,
      data: { titleheading: "Journeys", title: "AB Testing Journeys"},
    },
    {
      path: "how-to-create-a-campaign",
      component: CampaignHowToCreateComponent,
      data: { titleheading: "Journeys", title: "How to Create Journey"},
    },
    {
      path: "create-campaign",
      component: SetUpCampaignComponent,
      data: { titleheading: "Journeys", title: "Create Journey" },
    },
    { 
      path: "overview", 
      component: CampaignOverviewComponent,
      data: {
        titleheading: "Journeys",
        title: "Journey overview",
        id: 5,
      }
    },
    {
      path:"filter",
      component: CampaignAddFilterComponent
    },
    {
      path: "creation",
      component: CampaignStrategyComponent,
      canActivate: [WidgetRouteGaurdService],
      data: {
        titleheading: "Journey",
        title: "Journey creation",
        id: 6,
      },
    },
    {
      path: "list",
      component: CampaignListComponent,
      canActivate: [WidgetRouteGaurdService],
      data: {
        titleheading: "Journey",
        title: "Journey list",
        id: 7,
      },
    },
    {
      path: "report",
      component: OfferReportComponent,
      data: {
          titleheading: "A/B Testing Report",
          title: "A/B Testing Report",
      }
    },
    {
      path: "campaign-templates",
      component: CampaignTemplatesComponent,
      children: [
        { path: "", redirectTo: "creation", pathMatch: "full" },
        {
          path: "creation",
          component: CampaignTemplateCreationComponent,
          data: {
            titleheading: " ",
            title: "Journey creation",
          },
        },
        {
          path: "listing",
          component: CampaignTemplateListingComponent,
          data: {
            titleheading: " ",
            title: "Available Journey templates",
          },
        },
      ],

      data: {
        titleheading: " ",
        title: "Journeys",
        planPath: "CampaignCreation",
      },
    },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CampaignRoutingModule { }
