<div #fullContainer style="
    height: 100%;
    padding: 20px;
    margin-bottom: 5%;
    overflow-x: hidden;
    width: 100%;
  " id="full-container-email">
   <app-breadcrumb></app-breadcrumb>
  <div id="sample-listing" style="display: none;">
    <app-email-sample-listing (templateData)="shwoTemplate($event)"
      [isEditorLoaded]="isEditorLoaded"></app-email-sample-listing>
  </div>
  <form *ngIf="urlData" [formGroup]="templateForm" id="qb-bee-editor-container"
    style="width: 100%; height: 100%; display: none;">
    <div class="header-container">
      <div style="
          width: 50%;
          margin-left: 2%;
          display: flex;
          flex-direction: column;
        " *ngIf="urlData.type !== 'REWARD' || urlData.clone === 'true'">
        <div style="display: flex; height: 100%">
          <div class="qb-d-flex qb-ai-center">
            <button class="qb-back-button" (click)="returnBack()" [disabled] = "editorSatus.toLowerCase() !== 'saved!'" [matTooltip] = "editorSatus.toLowerCase() !== 'saved!' ? 'Saving in progress.' : 'Go Back'"  [ngClass]="{
              'qb-not-allowed':
              editorSatus.toLowerCase() !== 'saved!'
            }">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.9419 4.55806C13.186 4.80214 13.186 5.19786 12.9419 5.44194L8.38388 10L12.9419 14.5581C13.186 14.8021 13.186 15.1979 12.9419 15.4419C12.6979 15.686 12.3021 15.686 12.0581 15.4419L7.05806 10.4419C6.81398 10.1979 6.81398 9.80214 7.05806 9.55806L12.0581 4.55806C12.3021 4.31398 12.6979 4.31398 12.9419 4.55806Z"
                  fill="#080A0A" />
              </svg>
            </button>
          </div>
          <div *ngIf="!isTemplateNameEdit" class="qb-d-flex">
            <button class="qb-stale" (click)="isTemplateNameEdit = true">
              <span class="qb-name-stale">
                {{templateName}}
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                fill="#5F6368">
                <path
                  d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z" />
              </svg>
            </button>
          </div>

          <div *ngIf="isTemplateNameEdit" class="qb-d-flex qb-ai-center">
            <div>
              <div class="qb-template-name-container">
                <input matInput #inpName style="text-align: center" class="fontExo2 qb-template-name-class" type="text"
                  formControlName="TemplateName" />

                <mat-error class="validationText" *ngIf="
                    !submitclicked &&
                    templateForm.controls.TemplateName.touched &&
                    templateForm.controls.TemplateName.hasError('required')
                  ">
                  {{'SHARED_PAGE.EMAIL_TEMPLATE_NAME_REQUIRED' | i18nTranslate}}</mat-error>
                <mat-error class="validationText" *ngIf="
                    submitclicked &&
                    templateForm.controls.TemplateName.hasError('required')
                  ">{{'SHARED_PAGE.NAME_REQUIRED' | i18nTranslate}}
                </mat-error>
                <mat-error class="validationText" *ngIf="
                    templateForm.controls.TemplateName.dirty &&
                    templateForm.controls.TemplateName.errors?.minlength
                  ">
                  {{'SHARED_PAGE.MINIMIMUM_3_CHARACTER' | i18nTranslate}}
                </mat-error>
                <mat-error class="validationText" *ngIf="
                    templateForm.controls.TemplateName.dirty &&
                    templateForm.controls.TemplateName.errors?.maxlength
                  ">
                  {{'SHARED_PAGE.MAXIMUM_50_CHARACTER' | i18nTranslate}}
                </mat-error>
                <mat-error class="validationText" *ngIf="
                  templateForm.controls.TemplateName.dirty &&
                  templateForm.controls.TemplateName.errors?.pattern
                ">
                  {{'SHARED_PAGE.VALID_TEMPLATE_NAME' | i18nTranslate}}
                </mat-error>
              </div>
            </div>
            <div class="qb-actions">
              <button class="qb-action-confirm" (click)="nameChange(true)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
                  <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                </svg>
              </button>
              <button class="qb-action-cancel" (click)="nameChange(false)">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                  fill="#5f6368">
                  <path
                    d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                </svg>
              </button>
            </div>
          </div>

          <div class="qb-d-flex qb-ai-center qb-action-icons">
            <div class="qb-icon-divider"></div>
            <span>
              <button (click)="previewEmailTemplate()" class="qb-preview-email" matTooltip="Preview Template">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                  fill="#5F6368">
                  <path
                    d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z" />
                </svg>
              </button>
            </span>
            <span>
              <button (click)="openSendTestEmailDialog()" class="qb-send-test-email" matTooltip="Send Test Email">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                  fill="#5F6368">
                  <path
                    d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z" />
                </svg>
              </button>
            </span>
            <span>
              <button (click)="createSharableLink()" class="qb-send-test-email" matTooltip="Email Preview Link">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"
                  fill="#5f6368">
                  <path
                    d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z" />
                </svg>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div style="
          width: 50%;
          margin-left: 2%;
          display: flex;
          flex-direction: column;
        " *ngIf="urlData.type === 'REWARD' && urlData.clone === 'false'">
        <div style="display: flex; align-items: center; width: 65%">
          <span class="fontExo2"
            style="color: #fff; font-size: 13px; margin-right: 1%; width: 35%;">{{'SHARED_PAGE.EMAIL_SUBJECT' |
            i18nTranslate}}</span>
          <div style="width: 100%" class="formDiv fontExo2" [ngClass]="{
              active: templateForm['controls']['subject']['errors']
                ? (templateForm['controls']['subject']['errors']['required'] &&
                    templateForm['controls']['subject']['touched']) ||
                  submitClicked
                : false
            }">
            <input #inpSubject class="formInput fontExo2" type="text" formControlName="subject" maxlength="50" />
          </div>
        </div>
        <div style="display: flex; height: 30px; width: 50%; align-items: flex-end">
          <span class="fontExo2" style="
              margin-left: 5px;
              font-size: 12px;
              color: #ff5f31;
              justify-content: flex-end;
            " *ngIf="
              templateForm['controls']['subject']['errors']
                ? (templateForm['controls']['subject']['errors']['required'] &&
                    templateForm['controls']['subject']['touched']) ||
                  submitClicked
                : false
            ">{{'SHARED_PAGE.FIELD_CANNOT_BE_EMPTY' | i18nTranslate}}</span>
        </div>
      </div>
      <!-- <div
        style="width: 50%; display: flex; justify-content: flex-end"
        *ngIf="urlData.id === 'null'"
      >
      
      </div> -->

      <div class="close-container qb-save-status">
        {{editorSatus}}
      </div>
    </div>
    <div style="
        width: 100%;
        height: calc(100% - 80px);
        min-height: 570px;
        position: relative;
      " id="bee-plugin-container">

      <!-- <button
        *ngIf="showSendTestEmailButton && !loadingDialog"
        (click)="openSendTestEmailDialog()"
        style="
          position: absolute;
          background: #4cb9ea;
          border: none;
          color: white;
          right: 136px;
          top: 12px;
          padding: 7px 12px;
          border-radius: 5px;
          font-size: 12px;
        "
      >
        <div>{{'SHARED_PAGE.SEND_TEST_EMAIL' | i18nTranslate}}</div>
      </button>
      <button
        *ngIf="showSendTestEmailButton && loadingDialog"
        (click)="openSendTestEmailDialog()"
        style="
          position: absolute;
          background: #4cb9ea;
          border: none;
          color: white;
          right: 136px;
          top: 12px;
          padding: 7px 12px;
          border-radius: 5px;
          font-size: 12px;
        "
      >
        <div><mat-spinner diameter="12"></mat-spinner></div>
      </button> -->

    </div>
  </form>
</div>