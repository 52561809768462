import { DatePipe,getCurrencySymbol } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TokenStorageService } from "src/app/core/auth/token-storage.service";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { SnackbarCollection } from "src/app/core/services/snackbar.service";
import {
  ContactUsDialogComponent,
  SuccessDialogComponent,
} from "src/app/shared/components/dialog-box";
import { PersonalOfferListViewDialogBoxComponent } from "src/app/shared/components/dialog-box/personal-offer-list-view-dialog-box/personal-offer-list-view-dialog-box.component";
import { TranslateDynamicText } from "src/app/shared/pipes/dynamic-translation.pipe";

@Component({
  selector: "app-get-customer-personal-offer",
  templateUrl: "./get-customer-personal-offer.component.html",
  styleUrls: ["./get-customer-personal-offer.component.css"],
})
export class GetCustomerPersonalOfferComponent implements OnInit {
  searchType = "EMAIL";
  numberInput = "";
  emailInput = "";
  cards = [];
  revCurrency = "";
  loading = false;
  searchData: any = {
    type: "",
    value: "",
  };
  redeemapiLoading = false;
  emailPattern = "^[a-zA-Z0-9_._%+-]+@[a-zA-Z0-9_.-]+\\.[a-zA-Z]{2,5}$";
  mobilenumberPattern = /^\d{10}$/;
  dataNotAvailable = false;
  activeOffers = [];
  inactiveOffers = [];
  currencySymbol;
  constructor(
    private _i18nDynamicTranslate: TranslateDynamicText,
    private apiCall: ApiCallService,
    private dialog: MatDialog,
    private tokenService: TokenStorageService,
    private snackBar: SnackbarCollection,
    public datepipe: DatePipe,
    private date_format: getdateformatService
  ) {}

  ngOnInit(): void {
    // this.searchOffer();
    this.revCurrency = sessionStorage.getItem("serviceCurrency");
    this.currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  }
  changeType(type) {
    this.searchType = type;
    if (type === "EMAIL") {
      this.numberInput = "";
      if (this.emailInput.length === 0) {
        this.cards = [];
        this.activeOffers = [];
        this.inactiveOffers = [];
        this.searchData.type = "";
        this.searchData.value = "";
        this.dataNotAvailable = false;
      }
    } else {
      this.emailInput = "";
      if (this.numberInput.length === 0) {
        this.cards = [];
        this.activeOffers = [];
        this.inactiveOffers = [];
        this.searchData.type = "";
        this.searchData.value = "";
        this.dataNotAvailable = false;
      }
    }
  }
  keypressEmail(event) {
    this.cards = [];
    this.activeOffers = [];
    this.inactiveOffers = [];
    this.searchData.type = "";
    this.searchData.value = "";
    this.dataNotAvailable = false;
  }
  keypressPhone(event) {
    this.cards = [];
    this.activeOffers = [];
    this.inactiveOffers = [];
    this.searchData.type = "";
    this.searchData.value = "";
    this.dataNotAvailable = false;
    const input = event.target;
    const value = input.value;

    // Use a regular expression to match digits only
    const digitsOnly = value.replace(/[^0-9]/g, "");

    // Check if the input starts with a non-numeric character
    if (value.charAt(0) !== digitsOnly.charAt(0)) {
      input.value = digitsOnly;
    } else {
      input.value = digitsOnly;
      this.numberInput = digitsOnly;
    }
  }
  searchOffer() {
    this.loading = true;
    let data: any = {};
    if (this.searchType === "EMAIL") {
      if (!this.checkIfValid(this.searchType, this.emailInput)) {
        this.loading = false;
        return;
      } else {
        data.customerId = this.emailInput;
        this.searchData.value = this.emailInput;
        this.searchData.type = this.searchType;
      }
    } else {
      if (!this.checkIfValid(this.searchType, this.numberInput)) {
        this.loading = false;
        return;
      } else {
        data.customerId = this.numberInput;
        this.searchData.value = this.numberInput;
        this.searchData.type = this.searchType;
      }
    }
    data.storeId = this.tokenService.getqId();
    this.getCustomerOfferApi(data).then(
      (result) => {
        // this.loading =  false;
        if (result) {
          console.log(result);
          this.cards = result["body"]["offers"];
          this.activeOffers = [];
          this.inactiveOffers = [];
          this.addDate();
          for (var i = 0; i < this.cards.length; i++) {
            if (this.cards[i].status.toUpperCase() === "ACTIVE") {
              this.activeOffers.push(this.cards[i]);
            } else {
              this.inactiveOffers.push(this.cards[i]);
            }
          }
          if (this.cards.length === 0) {
            this.dataNotAvailable = true;
          }
          this.loading = false;
        }
      },
      (err) => {
        this.loading = false;
        this.snackBar.openSnackBar(err.body, 2000);
      }
    );
  }
  getCustomerOfferApi(data): Promise<object> {
    return new Promise((resolve, reject) => {
      this.apiCall.getCustomerOffer(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
      //   let res = {
      //     "message": "success",
      //     "body": {
      //         "loyalty_tier": 20,
      //         "loyalty_tier_name": "Ruby",
      //         "offers": [
      //             {
      //                 "products_info": [],
      //                 "discount_value": 10.0,
      //                 "offer_barrier": [],
      //                 "offer_code": "10PERCENTOFF",
      //                 "discount_on": "order",
      //                 "discount_type": "percentage",
      //                 "offer_name": "10% OFF on ALL Order"
      //             },
      //             {
      //                 "products_info": [],
      //                 "discount_value": 50.0,
      //                 "offer_barrier": [
      //                     {
      //                         "name": "Min Bill Value",
      //                         "value": 100.0
      //                     }
      //                 ],
      //                 "offer_code": "50OFF",
      //                 "discount_on": "order",
      //                 "discount_type": "fixed",
      //                 "offer_name": "8716_##50 KD Off On ALL Oder above 100##_DELETED"
      //             },
      //             {
      //               "products_info": [
      //                 {
      //                 "product_id": "12079",
      //                 "product_name": 'Tets1'
      //                 },
      //                 {
      //                 "product_id": "5043",
      //                 "product_name": 'Test2'
      //                 }
      //                 ],
      //                 "discount_value": 49.0,
      //                 "offer_barrier": [],
      //                 "offer_code": "50OFFALOrder",
      //                 "discount_on": "category",
      //                 "discount_type": "fixed",
      //                 "offer_name": "50 KD Off on ALL Product"
      //             }
      //         ],
      //         "max_percentage_bill_value": 15,
      //         "customer_mobile": "7404630034",
      //         "loyalty_points": 6000,
      //         "loyalty_points_multiplier": 1,
      //         "customer_email": "abhishekk@skellam.ai",
      //         "max_loyalty_points": 6000,
      //         "loyalty_points_bracket": 100
      //     }
      // }
      // resolve(res);
    });
  }
  viewList(type, list) {
    let finalData = [];
    if (type === "buyxgetyproduct") {
      finalData = list.map((product) => ({
        product_name: product.variantName,
      }));
    }
    
    else if(type === "buyxgetycategory"){
      finalData = list.map((product) => ({
        product_name: product.collectionName,
      }));
    }
    else{
      finalData = list;
    }
    const dialogRef = this.dialog.open(
      PersonalOfferListViewDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "30%",
        data: {
          type: type,
          list: finalData,
        },
      }
    );
  }
  onRedeemClicked(code, j) {
    this.redeemapiLoading = true;
    this.showSpinner(j);
    let data: any = {};
    data.api_key = null;
    if (this.searchData.type === "EMAIL") {
      data.customer_email = this.searchData.value;
      data.customer_mobile = null;
    } else {
      data.customer_email = null;
      data.customer_mobile = this.searchData.value;
    }
    data.coupon_code = code;
    data.loyalty_points = null;
    this.redeemOfferApi(data).then(
      (result) => {
        // this.loading =  false;
        if (result) {
          console.log(result);
          this.redeemapiLoading = false;
          this.removeSpinner(j);
          const dialogRef = this.dialog.open(SuccessDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "20%",
            disableClose: true,
            data: {
              message: "Offer sucessfully redeemed",
              buttonText: "Done",
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            this.cards = [];
            this.activeOffers = [];
            this.inactiveOffers = [];
            this.searchOffer();
          });
        } else {
          this.redeemapiLoading = false;
        }
      },
      (err) => {
        this.redeemapiLoading = false;
        if (err.status === 403) {
          const dialogRef = this.dialog.open(ContactUsDialogComponent, {
            panelClass: "no-padding-dialog-popup",
            width: "380px",
            height: "auto",
            disableClose: true,
            // this.dialogRef.componentInstance.data : "You will be logged out!"
            data: {
              header: "Permission Denied",
              body: "Access Denied: You do not have the necessary permissions to perform this action. Please contact your system administrator for assistance.",
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            // this.submitted = false;
          });
        } else {
          this.snackBar.openSnackBar(
            this._i18nDynamicTranslate.transform("Error", ["POS_PAGE"]),
            2000
          );
        }
      }
    );
  }
  checkIfValid(type, value) {
    if (type === "EMAIL") {
      if (value.match(this.emailPattern.trim()) != null) {
        return true;
      } else {
        this.snackBar.openSnackBar("Enter Valid email address.", 2000);
        return false;
      }
    } else {
      return true;
      // if(this.mobilenumberPattern.test(value)){
      //   return true;
      // }
      // else{
      //   this.snackBar.openSnackBar('Enter Valid phone number.', 2000);
      //   return false;
      // }
    }
  }
  redeemOfferApi(data) {
    return new Promise((resolve, reject) => {
      this.apiCall.redeemCustomerOffer(data).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err);
        }
      );
    });
  }
  showSpinner(j) {
    let i = "spinner" + j;
    let k = "text" + j;
    let element = document.getElementById(i);
    let element2 = document.getElementById(k);
    element.setAttribute("style", "display:flex");
    element2.setAttribute("style", "display:none");
  }
  removeSpinner(j) {
    let i = "spinner" + j;
    let k = "text" + j;
    let b = "button" + j;
    let element = document.getElementById(i);
    let element2 = document.getElementById(k);
    let buttonElement = document.getElementById(b);
    element2.setAttribute("style", "display:flex");
    element2.innerText = "Offer Redeemed";
    buttonElement.className = "obj-offer-btn qb-redeemed-button";
    element.setAttribute("style", "display:none");
  }
  date;
  time;
  addDate() {
    for (let item in this.cards) {
      let dates = this.cards[item]["expiryDate"];
      this.date = this.datepipe.transform(
        new Date(dates.replace(" ", "T")).toISOString(),
        "dd MMM yyyy"
      );
      let x = dates.split(" ");
      let y = x[1].split(":");
      if (x[1][0] + x[1][1] > 12)
        this.time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      else this.time = x[1][0] + x[1][1] + ":" + x[1][3] + x[1][4];
      this.cards[item]["date"] = this.date;
      this.cards[item]["time"] = this.time;
    }
  }
  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }
}
