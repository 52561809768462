import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-collection-list-dialog",
  templateUrl: "./collection-list-dialog.component.html",
  styleUrls: ["./collection-list-dialog.component.css"],
})
export class CollectionListDialogComponent implements OnInit {
  search = "";
  selectedItems = []; //array with collectionIds only
  selectedObj = []; //array with collection object
  loading = false;
  databean;
  parentProductIdArray = [];
  parentProductIdAll = []; //Array of parent category Id. Can have more than 1 entry.
  varaintProductIdArray = []; //Array of subcategory id. Can have max 1 entry.
  selectedVariantObj = []; //Array of selected variant object
  @Input() dataBean: any;
  @Output() getDataBeanData = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<CollectionListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  async ngOnInit() {
    this.databean = this.dataBean ? this.dataBean : JSON.parse(JSON.stringify(this.data)); //deep copy
    var k = this.databean.selectedCollectionList;

    if (this.databean.searchString != null) {
      this.search = this.databean.searchString; //search string
    }
    // this.setSelected(k);
    await this.generateVariantArray(k);
  }

  setAll(checked, product) {
    if (checked) {
      this.selectedItems.push(product.collectionId); //add to array
      this.selectedObj.push(product);
    } else {
      this.selectedItems.splice(
        this.selectedItems.indexOf(product.collectionId), //remove from array
        1
      );
      this.selectedObj.splice(this.selectedItems.indexOf(product), 1);
    }
  }

  save() {
    let obj = { variantIdArray: this.selectedVariantObj, search: this.search };//send selected collection object
    if(this.dataBean){
      this.getDataBeanData.emit(obj);
    }
    else{
      this.dialogRef.close(obj);
    }
  }
  cancel() {
    this.dialogRef.close(undefined); //close dialog
  }

  // setSelected(items) {
  //   for (let i = 0; i < items.length; i++) {
  //     this.selectedItems.push(items[i].collectionId); //add to array
  //     this.selectedObj.push(items[i]);
  //   }
  //   this.loading = false;
  // }

  selectAll(checked) {
    var i = 0;
    let product = this.databean.allCollection;
    for (i = 0; i < product.length; i++) {
      if (checked) {
        if (
          this.varaintProductIdArray.indexOf(
            product[i]["subCategory"][0].collectionId
          ) === -1
        ) {
          for (let j = 0; j < product[i]["subCategory"].length; j++) {
            this.updateSelection(
              product[i]["subCategory"][j].collectionId,
              product[i].collectionId,
              1,
              product[i]["subCategory"][j]
            );
          }
        }
      } else {
        if (
          this.varaintProductIdArray.indexOf(product[i]["subCategory"][0].collectionId) !==
          -1
        ) {
          for (let j = 0; j < product[i]["subCategory"].length; j++) {
            this.updateSelection(
              product[i]["subCategory"][j].collectionId,
              product[i].collectionId,
              0,
              product[i]["subCategory"][j]
            );
          }
        }
      }
    }
  }

  parentArrowClicked(i) {
    document.getElementById("category-section-" + i).classList.toggle("active"); // toggle variant section
  }

  generateVariantArray(obj) {
    this.selectedVariantObj = obj;
    var variantArray = [];
    var i = 0;
    for (i = 0; i < obj.length; i++) {
      //  variantArray.push(obj.variants[i].id);
      // if (this.databean.actionType === null) {
      this.varaintProductIdArray.push(obj[i].collectionId); // push variant id to array
      this.parentProductIdAll.push(
        this.getParentCollectionId(obj[i].collectionId)
      );
      console.log(this.varaintProductIdArray, this.parentProductIdAll);
      // } else {
      //   this.parentProductIdAll.push(obj[i].collectionId);
      // }
    }
  }

  getParentCollectionId(id) {
    let result = null;
    this.databean.allCollection.forEach((category) => {
      category.subCategory?.forEach((subCategory) => {
        if (subCategory.collectionId == id) {
          result = category.collectionId;
          return;
        }
      });
    });
    return result ? result : id;
  }

  getDataForTable($event: any) {
    $event.target.classList.toggle("active"); // toggle variant section
    // get data for table since we are opening the div to show the body
  }

  setAllChecked(event, variantArray, parentId) {
    var i = 0;
    let data = {};
    if(variantArray?.length > 0){
      for (i = 0; i < variantArray.length; i++) {
        if (
          1 == event &&
          this.varaintProductIdArray.indexOf(variantArray[i].collectionId) === -1
        ) {
          this.updateSelection(
            variantArray[i].collectionId,
            parentId.collectionId,
            1,
            variantArray[i]
          );
        } else if (
          0 == event &&
          this.varaintProductIdArray.indexOf(variantArray[i].collectionId) != -1
        ) {
          this.updateSelection(
            // if unchecked, remove the parentgfid from the parentProductIdArray and remove the variant object from selectedVariantObj
            variantArray[i].collectionId,
            parentId.collectionId,
            0,
            variantArray[i]
          );
        }
      }
    }
    else{
      if (
        1 == event &&
        this.varaintProductIdArray.indexOf(parentId) === -1
      ) {
        this.updateSelection(
          parentId.collectionId,
          parentId.collectionId,
          1,
          parentId
        );
      } else if (
        0 == event &&
        this.varaintProductIdArray.indexOf(parentId.collectionId) != -1
      ) {
        this.updateSelection(
          // if unchecked, remove the parentgfid from the parentProductIdArray and remove the variant object from selectedVariantObj
          parentId.collectionId,
          parentId.collectionId,
          0,
          parentId
        );
      }
    }
  }

  updateSelection(variantGfId, parentGfId, event, variant) {
    // update selected variant
    if (event) {
      this.parentProductIdAll.push(parentGfId);
      this.varaintProductIdArray.push(variantGfId);
      this.selectedVariantObj.push(variant);
    } else {
      const index: number = this.parentProductIdAll.indexOf(parentGfId);
      if (index !== -1) {
        this.parentProductIdAll.splice(index, 1);
      }
      const index1: number = this.varaintProductIdArray.indexOf(variantGfId);
      if (index1 !== -1) {
        this.varaintProductIdArray.splice(index1, 1);
      }
      const index2: number = this.selectedVariantObj.indexOf(variant);
      if (index1 !== -1) {
        this.selectedVariantObj.splice(index1, 1);
      }
    }
    console.log(this.parentProductIdAll, this.varaintProductIdArray);
  }

  ifProductChecked(product) {
    if(product.subCategory){
      return (
        product?.subCategory?.length == this.countInArray(product.collectionId)
      );
    }
    else{
      return this.parentProductIdAll.indexOf(product.collectionId) !== -1
    }
  }

  ifProductPartialyChecked(product) {
    return (
      product?.subCategory?.length > this.countInArray(product.collectionId) &&
      this.countInArray(product.collectionId) != 0
    );
  }

  countInArray(what) {
    let array = this.parentProductIdAll;
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      // loop through array
      if (array[i] === what) {
        count++;
      }
    }
    return count;
  }
}
