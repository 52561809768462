<div class="qb-offer-summary-component">
    <!-- Header Section -->
    <div class="qb-offer-summary-heading">
      <div class="heading-text">{{ 'SHARED_PAGE.OFFER_SUMMARY' | i18nTranslate }}</div>
      <mat-icon class="close-icon" (click)="closedialog()">close</mat-icon>
    </div>
  
    <!-- Main Details Section -->
    <div class="qb-offer-main-details">
      <!-- Discount Details -->
      <div class="qb-offer-discount-details">
        <!-- Offer Header -->
        <div class="offer-header">
          <!-- Percentage Off -->
          <span *ngIf="hurdleRewardType == 'PERCENTAGE_OFF' && percentageOff" class="offer-percentage">
            {{ percentageOff }}% OFF
          </span>
          <!-- Fixed Amount Off -->
          <span *ngIf="hurdleRewardType == 'FIXED_AMOUNT_OFF' && amountOff" class="offer-percentage">
            {{ currencySymbol }}{{ amountOff }} OFF
          </span>
          <!-- Buy X Get Y -->
          <span *ngIf="hurdleRewardType == 'BUY_X_GET_Y'" class="offer-percentage">
            {{ 'SHARED_PAGE.BUY' | i18nTranslate }} {{ hurdleProductCount }}
            <span *ngIf="hurdleProductCount > 1">{{ 'SHARED_PAGE.ITEMS' | i18nTranslate }}</span>
            <span *ngIf="hurdleProductCount == 1">{{ 'SHARED_PAGE.ITEM' | i18nTranslate }}</span>,
            {{ 'SHARED_PAGE.GET' | i18nTranslate }} {{ hurdleRewardProductCount }}
            <span *ngIf="hurdleRewardProductCount > 1">{{ 'SHARED_PAGE.ITEMS' | i18nTranslate }}</span>
            <span *ngIf="hurdleRewardProductCount == 1">{{ 'SHARED_PAGE.ITEM' | i18nTranslate }}</span>
            <span *ngIf="atADiscountedValue=='PERCENTAGE_OFF'&&rewardpercentageOff>0">&nbsp;{{'SHARED_PAGE.FOR_OFF' | i18nTranslate : rewardpercentageOff}} </span>
            <span *ngIf="rewardamountOff>0">&nbsp;for {{ currencySymbol }}{{ rewardamountOff }} OFF</span>
            <span *ngIf="atADiscountedValue=='FREE'">&nbsp;{{'SHARED_PAGE.FOR_FREE' | i18nTranslate}}</span>

          </span>
        </div>

        <div class="discounted-products-title" *ngIf="hurdleRewardType == 'BUY_X_GET_Y'">
          <span class="material-icons-outlined">local_offer</span>
          <span *ngIf="prerequisiteType === 'PRODUCT'">Eligible Products</span>
          <span *ngIf="prerequisiteType === 'COLLECTION'">Eligible Categories</span>
        </div>

             <!-- Eligible Products/ Category Section -->
             <div class="discounted-products" *ngIf="hurdleRewardType == 'BUY_X_GET_Y'">
              <div class="product-tags">
                <ng-container *ngIf="selectedBarrierProductList?.length > 0 && barrierParamType!='PERSONALIZED'">
                  <span *ngFor="let product of selectedBarrierProductList" class="tag">
                    {{ product.variantName }}
                  </span>
                </ng-container>
                <span class="tag" *ngIf="barrierParamType==='PERSONALIZED' && prerequisiteType === 'PRODUCT'">
                  AI Personalised Products
                </span>
                <ng-container *ngIf="selectedBarrierCollectionList?.length > 0 && barrierParamType!='PERSONALIZED'">
                  <span *ngFor="let collection of selectedBarrierCollectionList" class="tag">
                    {{ collection.collectionName }}
                  </span>
                </ng-container>
                <span class="tag" *ngIf="barrierParamType==='PERSONALIZED' && prerequisiteType === 'COLLECTION'">
                  AI Personalised Category
                </span>
              </div>
            </div>
            
      
        <div class="discounted-products-title"  >
          <span class="material-icons-outlined">local_offer</span>
          <span *ngIf="(selectedRewardProductList?.length > 0 || rewardPersonalizationType == 'PERSONALIZED') || (selectedRewardProductList?.length == 0 && discountAndShippingType == 'ORDER')">Discounted Products</span>
          <span *ngIf="selectedRewardCollectionList?.length > 0">Discounted Categories</span>
        </div>
      
        <!-- Discounted Products Section -->
        <div class="discounted-products" *ngIf="selectedRewardProductList?.length > 0 || rewardPersonalizationType=='PERSONALIZED' || discountAndShippingType == 'ORDER' || selectedRewardCollectionList?.length > 0">
          <div class="product-tags">
            <ng-container *ngIf="selectedRewardProductList?.length > 0">
              <span *ngFor="let product of selectedRewardProductList" class="tag">
                {{ product.variantName }}
              </span>
            </ng-container>
            <span class="tag" *ngIf="discountAndShippingType == 'ORDER'">
              All Products
            </span>
            <span class="tag" *ngIf="rewardPersonalizationType=='PERSONALIZED'">
              AI Personalised products
            </span>
            <ng-container *ngIf="selectedRewardCollectionList?.length > 0">
              <span *ngFor="let collection of selectedRewardCollectionList" class="tag">
                {{ collection.collectionName }}
              </span>
            </ng-container>
          </div>
        </div>
      </div>
      
  
      <!-- Discount Conditions -->
      <div class="qb-offer-discount-conditions-container">
        <div class="qb-offer-validity">
            <span class="material-symbols-outlined icons-color">
                calendar_clock
                </span>
          <div class="qb-offer-conditions-details">
            <h5>Validity</h5>
            <span *ngIf="rewardTimeToComplete && !isLImitedTime">
                Valid for {{ rewardTimeToComplete }} day<span *ngIf="rewardTimeToComplete > 1">s</span>
              </span>
              
              <!-- Case 2: Limited time validity -->
              <span *ngIf="isLImitedTime">
                Valid from {{ startDate.value | date: getDateFormat("MMM/dd/yyyy") }} {{ transformTo24HourFormat(startTime.value) | customTime }} - 
                 {{ endDate.value | date: getDateFormat("MMM/dd/yyyy") }} {{ transformTo24HourFormat(endTime.value) | customTime }}
              </span>
          </div>
        </div>
        
        <div class="qb-offer-validity">
            <span class="material-symbols-outlined icons-color">
              shopping_cart
            </span>
            <div class="qb-offer-conditions-details">
              <h5>Minimum Requirements</h5>
          
              <!-- Case 1: No Minimum Requirement -->
              <span *ngIf="rewardMinReqs == 'NONE'">
                {{ 'SHARED_PAGE.NO_MINIMUM_REQ' | i18nTranslate }}
              </span>
          
              <!-- Case 2: Minimum Order Amount -->
              <span *ngIf="rewardMinReqs == 'MIN_ORDER_AMOUNT' && rewardMinReqsAmount > 0">
                {{ 'SHARED_PAGE.MINUIMUM_PURCHASE' | i18nTranslate }} {{ currencySymbol }} {{ rewardMinReqsAmount }}
              </span>
          
              <!-- Case 3: Minimum Order Quantity -->
              <span *ngIf="rewardMinReqs == 'MIN_ORDER_QUANTITY' && rewardMinReqsQuantity > 0">
                {{ 'SHARED_PAGE.MINIMUM_PURCHASE_QUANTITY' | i18nTranslate : rewardMinReqsQuantity }}
              </span>
            </div>
          </div>
          
        
        <div class="qb-offer-validity">
            <span class="material-symbols-outlined icons-color">
              schedule
            </span>
            <div class="qb-offer-conditions-details">
              <h5>Usage Limit</h5>
          
              <!-- Case 1: Limit of uses with personalized logic -->
              <span *ngIf=" !((hurdleRewardType != 'FREE_SHIPPING' && hurdleRewardType != 'BUY_X_GET_Y' && rewardPersonalizationType === 'PERSONALIZED') || 
                        (hurdleRewardType === 'BUY_X_GET_Y' && barrierParamType === 'PERSONALIZED'))">
                Limit of {{ noOfTry }} use<span *ngIf="noOfTry > 1">s</span>
              </span>
          
              <!-- Separator for multiple conditions -->
              <span
                *ngIf="islimitNoOfTry && noOfTry && islimitToOneUsePerCustomer &&
                      !((hurdleRewardType != 'FREE_SHIPPING' && hurdleRewardType != 'BUY_X_GET_Y' && rewardPersonalizationType === 'PERSONALIZED') || 
                        (hurdleRewardType === 'BUY_X_GET_Y' && barrierParamType === 'PERSONALIZED'))">
                ,&nbsp;
              </span>
          
              <!-- Case 2: One use per customer -->
              <span *ngIf="islimitToOneUsePerCustomer">
                {{ 'SHARED_PAGE.ONE_USE_PER_CUSTOMER' | i18nTranslate }}.
              </span>
            </div>
          </div>
          

      </div>
    </div>
  
    <!-- Stores Availability Section -->
    <div class="qb-offer-stores-availability">
        <!-- Redeemable Stores Section -->
        <div class="qb-offer-stores-redeemable" *ngIf="redeemableAtStores?.length > 0">
          <div class="icon-heading">
            <span class="material-symbols-outlined" style="color: #007e3c;">storefront</span>
            <h5 style="color: #007e3c;"> {{ redeemableAtStores.includes('All Stores') ? 'Redeemable at all stores' : 'Redeemable at:' }}
            </h5>
          </div>
          <div class="qb-container-wrapper" *ngIf="!redeemableAtStores.includes('All Stores')">
            <div class="store-list">
              <!-- Show other stores, excluding 'All Stores' -->
              <ng-container *ngIf="!redeemableAtStores.includes('All Stores') || redeemableAtStores.length > 1">
                <ng-container *ngFor="let storeName of redeemableAtStores">
                  <span *ngIf="storeName !== 'All Stores'" class="store-item">{{ storeName }}</span>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      
        <!-- Non-Redeemable Stores Section -->
        <div class="qb-offer-stores-non-redeemable" *ngIf="notredeemableAtStores?.length > 0">
          <div class="icon-heading">
            <span class="material-symbols-outlined" style="color: #c0392b;">storefront</span>
            <h5 style="color: #c0392b;">Not redeemable at:</h5>
          </div>
          <div class="qb-container-wrapper">
            <div class="store-list">
              <ng-container *ngFor="let storeName of notredeemableAtStores">
                <span class="store-item non-redeemable">{{ storeName }}</span>
              </ng-container>
            </div>
          </div>
          
        </div>
      </div>
      
      
      
  
    <!-- Exclusions Section -->
    <div class="qb-offer-exclusion-container">
        <div class="qb-offer-product-exclusion" *ngIf="excludedProductList?.length > 0">
            <div class="icon-heading">
              <span class="material-symbols-outlined not-allowed-icon icons-color">
                block
              </span>
              <h5>Excluded Products:</h5>
            </div>
            <div class="qb-container-wrapper">
              <div class="exclusion-list">
                <ng-container *ngFor="let product of excludedProductList">
                  <span class="exclusion-item">{{ product.productName }}</span>
                </ng-container>
              </div>
            </div>
          
          </div>
          

      <div class="qb-offer-category-exclusion"  *ngIf="excludedCategoryList?.length > 0">
        <div class="icon-heading">
            <span class="material-symbols-outlined not-allowed-icon icons-color">
                block
                </span>
            <h5>Excluded Categories</h5>
        </div>
        <div class="qb-container-wrapper">
          <div class="exclusion-list">
            <ng-container *ngFor="let category of excludedCategoryList">
              <span class="exclusion-item">{{ category.productCategory }}</span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  