<!-- <div class="node-block-container" id="node-block" style="display: none">
  <div class="node">
    <div class="node-block"></div>
    <div class="node-connectors"></div>
  </div>
</div> -->
<div class="node-block-container" id="dummy-node" style="display: none">
  <div class="node" style="margin-bottom: 20px">
    <div class="node-block">
      <div class="dummy-node"><span> {{'CAMPAIGN_PAGE.CHOOSE_NEXT_ACTION' | i18nTranslate}} </span></div>
    </div>
  </div>
</div>
<div
  class="node-block-container"
  id="trigger-node-block-dummy"
  style="display: none"
>
  <div class="node">
    <div class="node-block trigger-node-block">
      <div class="node-header">
        <span class="material-icons-outlined node-header-icon icons-primary">
          play_circle
        </span>
        <span>{{'CAMPAIGN_PAGE.CAMP_TRIGGER' | i18nTranslate}}</span>
      </div>
      <div class="node-content" data-widget-name="CREATE NEW CAMPAIGN">
        <div style="display: flex; justify-content: center; padding: 15px">
          <div class="get-started-button" data-button-name="Get Started">
            <span>{{'CAMPAIGN_PAGE.GET_STARTED' | i18nTranslate}}</span>
          </div>
        </div>
      </div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
</div>
<div class="node-block-container" id="trigger-node-block" style="display: none">
  <div class="node">
    <div class="node-block trigger-node-block">
      <div class="node-header">
        <span class="material-icons-outlined node-header-icon icons-primary">
          play_circle
        </span>
        <span>{{'CAMPAIGN_PAGE.CAMP_TRIGGER' | i18nTranslate}}</span>
      </div>
      <div class="node-content">
        <div
          style="
            display: flex;
            justify-content: center;
            font-size: 0.85rem;
            color: rgba(34, 34, 34, 0.4);
            margin: 16px 0px 30px 0px;
          "
        >
          <span>{{'CAMPAIGN_PAGE.CHOOSE_NEXT_ACTION' | i18nTranslate}}</span>
        </div>
      </div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="offer-node-block" style="display: none">
  <div class="node">
    <div class="node-block offer-node-block">
      <div class="node-header">
        <span class="material-icons-outlined node-header-icon icons-primary">
          local_offer
        </span>
        <!-- <mat-icon class="node-header-icon">local_offer</mat-icon> -->
        <span>{{'CAMPAIGN_PAGE.OFFER' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="comarketingoffer-node-block" style="display: none">
  <div class="node">
    <div class="node-block comarketingoffer-node-block">
      <div class="node-header">
        <span class="material-icons-outlined node-header-icon icons-primary">
          handshake 
        </span>
        <!-- <mat-icon class="node-header-icon">local_offer</mat-icon> -->
        <span>{{'CAMPAIGN_PAGE.CO_MARK_OFFER' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="audience-node-block" style="display: none">
  <div class="node">
    <div class="node-block audience-node-block">
      <div class="node-header">
        <span class="material-icons-outlined node-header-icon icons-primary">
          groups
        </span>
        <!-- <mat-icon class="node-header-icon">local_offer</mat-icon> -->
        <span>{{'CAMPAIGN_PAGE.SOCIAL_ADS' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="email-node-block" style="display: none">
  <div class="node">
    <div class="node-block email-node-block">
      <div class="node-header">
        <!-- <mat-icon class="node-header-icon">email</mat-icon> -->
        <span class="material-icons-outlined node-header-icon icons-primary">
          email
        </span>
        <span>{{'CAMPAIGN_PAGE.EMAIL' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="whatsapp-node-block" style="display: none">
  <div class="node">
    <div class="node-block whatsapp-node-block">
      <div class="node-header">
        <!-- <mat-icon class="node-header-icon">email</mat-icon> -->
        <img class="node-header-icon" width="20px" height="20px" style="color: #fff;" src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/whatsapp/whatsapp_logo_black.svg">
        <span>{{'CAMPAIGN_PAGE.WHATSAPP' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="sms-node-block" style="display: none"  *ngIf="(validScreens | screenConfig: 18)">
  <div class="node">
    <div class="node-block email-node-block">
      <div class="node-header">
        <!-- <mat-icon class="node-header-icon node-header-icon">sms</mat-icon> -->
        <span class="material-icons-outlined node-header-icon icons-primary">sms</span>
        <span>{{'CAMPAIGN_PAGE.SEND_SMS' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="delay-node-block" style="display: none">
  <div class="node">
    <div class="node-block delay-node-block">
      <div class="node-header">
        <!-- <mat-icon class="node-header-icon">hourglass_bottom</mat-icon> -->
        <span class="material-icons-outlined node-header-icon icons-primary">
          timer
        </span>
        <span>{{'CAMPAIGN_PAGE.TIME_DELAY' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="survey-node-block" style="display: none">
  <div class="node">
    <div class="node-block survey-node-block">
      <div class="node-header">
        <!-- <mat-icon class="node-header-icon">description</mat-icon> -->
        <span class="material-icons-outlined node-header-icon icons-primary">
          thumb_up_off_alt
        </span>
        <span>{{'CAMPAIGN_PAGE.SURVEY' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-block-container" id="loyalty-node-block" style="display: none">
  <div class="node">
    <div class="node-block loyalty-node-block">
      <div class="node-header">
        <span class="material-icons-outlined node-header-icon icons-primary">
          hotel_class
        </span>
        <span>{{'CAMPAIGN_PAGE.LOYALITY' | i18nTranslate}}</span>
      </div>
      <div class="node-content"></div>
      <div class="node-block-overlay"></div>
    </div>
  </div>
  <div class="button-container"></div>
</div>
<div class="node-connectors" id="node-connectors" style="display: none"></div>
<div
  class="add-node"
  id="add-button"
  style="display: none"
  data-widget-name="CANVAS"
  data-button-name="Add Block"
>
  <mat-icon class="add-node-icon">add</mat-icon>
  <div class="operation-blocker-tooltip">
    <span>{{'CAMPAIGN_PAGE.TO_ACESS_CLOSE' | i18nTranslate}}</span>
  </div>
</div>
<div class="end-flag" id="end-flag" style="display: none">
  <mat-icon style="font-size: 12px" class="add-node-icon">flag</mat-icon>
  <span>{{'CAMPAIGN_PAGE.END' | i18nTranslate}}</span>
</div>
<div class="move-button" id="move-button" style="display: none">
  <span
    class="button-content-align"
    data-widget-name="CANVAS"
    data-button-name="Move Here"
    >{{'CAMPAIGN_PAGE.MOVE_HERE' | i18nTranslate}}</span
  >
</div>
<div class="clone-button" id="clone-button" style="display: none">
  <span
    class="button-content-align"
    data-widget-name="CANVAS"
    data-button-name="Clone Here"
    >{{'CAMPAIGN_PAGE.CLONE_HERE' | i18nTranslate}}</span
  >
</div>
<div class="node-config" id="node-config" style="display: none">
  <mat-icon
    style="border-radius: 25px"
    id="node-config-icon"
    class="node-config-icon"
    >more_vert</mat-icon
  >
  <div id="node-operations" style="display: none" class="node-operations">
    <ul class="node-operation-list">
      <li
        id="editNode"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Edit"
      >
        {{'CAMPAIGN_PAGE.EDIT' | i18nTranslate}}
      </li>
      <!-- <div class="node-operation-list-separator"></div> -->
      <li
        id="cloneNode"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Clone"
      >
        {{'CAMPAIGN_PAGE.CLONE' | i18nTranslate}}
      </li>
      <!-- <div class="node-operation-list-separator"></div> -->
      <li
        id="moveNode"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Move"
      >
        {{'CAMPAIGN_PAGE.MOVE' | i18nTranslate}}
      </li>
      <li
        id="addNote"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Add Note"
      >
        {{'CAMPAIGN_PAGE.ADD_NOTE' | i18nTranslate}}
      </li>
      <!-- <div class="node-operation-list-separator"></div> -->
      <li
        class="node-operation-list-content delete-node-operation"
        (click)="deleteNode()"
        data-widget-name="BLOCK"
        data-button-name="Delete-"
      >
        {{'CAMPAIGN_PAGE.DELETE' | i18nTranslate}}
        <mat-icon class="delete-right-arrow">arrow_right</mat-icon>
        <div class="delete-options">
          <ul style="padding: 0px">
            <li
              id="deleteThis"
              class="node-operation-list-content"
              style="width: 140px"
              data-widget-name="BLOCK"
              data-button-name="Delete this"
            >
              {{'CAMPAIGN_PAGE.DELETE_THIS' | i18nTranslate}}
            </li>
            <!-- <div class="node-operation-list-separator"></div> -->
            <li
              id="deleteAllAfter"
              class="node-operation-list-content"
              style="width: 140px; padding: 22px 10px"
              data-widget-name="BLOCK"
              data-button-name="Delete this and all after this"
            >
              {{'CAMPAIGN_PAGE.DELETE_THIS_AND_AFTER' | i18nTranslate}}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div class="operation-blocker-tooltip">
    <span>{{'CAMPAIGN_PAGE.TO_ACESS_CLOSE' | i18nTranslate}}</span>
  </div>
</div>
<div class="node-config" id="trigger-node-config" style="display: none">
  <mat-icon
    style="border-radius: 25px"
    id="node-config-icon"
    class="node-config-icon"
    >more_vert</mat-icon
  >
  <div id="node-operations" style="display: none" class="node-operations">
    <ul class="node-operation-list">
      <li
        id="editNode"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Edit"
      >
        {{'CAMPAIGN_PAGE.EDIT' | i18nTranslate}}
      </li>
      <li
        id="addNote"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Add Note"
      >
        {{'CAMPAIGN_PAGE.ADD_NOTE' | i18nTranslate}}
      </li>
      <!-- <div class="node-operation-list-separator"></div> -->
      <!-- <li class="node-operation-list-content" (click)="deleteNode()">Delete</li> -->
    </ul>
  </div>
  <div class="operation-blocker-tooltip">
    <span>{{'CAMPAIGN_PAGE.TO_ACESS_CLOSE' | i18nTranslate}}</span>
  </div>
</div>
<div class="node-config" id="unconditional-node-config" style="display: none">
  <mat-icon
    style="border-radius: 25px"
    id="node-config-icon"
    class="node-config-icon"
    >more_vert</mat-icon
  >
  <div id="node-operations" style="display: none" class="node-operations">
    <ul class="node-operation-list">
      <li
        id="editNode"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Edit"
      >
        {{'CAMPAIGN_PAGE.EDIT' | i18nTranslate}}
      </li>
      <li
        id="addNote"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Add Note"
      >
        {{'CAMPAIGN_PAGE.ADD_NOTE' | i18nTranslate}}
      </li>
      <!-- <div class="node-operation-list-separator"></div> -->
      <li
        id="deleteNode"
        class="node-operation-list-content"
        data-widget-name="BLOCK"
        data-button-name="Delete"
      >
        {{'CAMPAIGN_PAGE.DELETE' | i18nTranslate}}
      </li>
    </ul>
  </div>
  <div class="operation-blocker-tooltip">
    <span>{{'CAMPAIGN_PAGE.TO_ACESS_CLOSE' | i18nTranslate}}</span>
  </div>
</div>
<div
  class="node-note"
  id="node-note"
  style="display: none"
  data-widget-name="BLOCK"
  data-button-name="Note Edit"
>
  <mat-icon id="node-note-icon" class="node-note-icon">note_add</mat-icon>
  <div id="node-note-input" style="display: none" class="node-note-input">
    <textarea
      [(ngModel)]="nodeNote"
      class="note-text"
      id="note-text"
    ></textarea>
    <div
      style="display: flex; justify-content: space-between; margin-top: 10px"
    >
      <span
        class="node-note-cancel-button"
        data-widget-name="BLOCK"
        data-button-name="Note Canceled"
        >{{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}</span
      >
      <span
        class="node-note-save-button"
        data-widget-name="BLOCK"
        data-button-name="Note Saved"
        >{{'CAMPAIGN_PAGE.SAVE' | i18nTranslate}}</span
      >
    </div>
  </div>
</div>
<div class="node-block-container ab-node-block" id="branch-node-block" style="display: none">
  <div class="node-block responseEvent-node-block">
    <div class="node-header">
      <!-- <mat-icon class="node-header-icon">device_hub</mat-icon> -->
      <span
        class="material-icons-outlined icons-primary node-header-icon"
        style="transform: rotate(180deg)"
      >
        alt_route
      </span>
      <span>{{'CAMPAIGN_PAGE.IF_ELSE_COND' | i18nTranslate}}</span>
    </div>
    <div class="node-content"></div>
    <div class="node-block-overlay"></div>
  </div>
  <div class="node-connector-split-content">
    <div class="node-connector-split-line qb-line-fix-event"></div>
  </div>
  <div>
    <div class="node-branch">
      <div class="left-branch-node">
        <div class="left-node-container">
          <!-- <div class="node-block-container-add">
            <div class="node-left">
              <div class="add-node-left-branch">
                <mat-icon>add</mat-icon>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="right-branch-node">
        <div class="right-node-container">
          <!-- <div class="node-block-container-add">
            <div class="node-right">
              <div class="add-node-right-branch">
                <mat-icon>add</mat-icon>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>


<div class="node-block-container ab-node-block" id="abtesting-node-block" style="display: none">
  <div class="node-block responseEvent-node-block">
    <div class="node-header">
      <!-- <mat-icon class="node-header-icon">device_hub</mat-icon> -->
      <span
        class="material-icons-outlined icons-primary node-header-icon"
        style="transform: rotate(180deg)"
      >
        alt_route
      </span>
      <span>{{'CAMPAIGN_PAGE.AB_TESTING' | i18nTranslate}}</span>
    </div>
    <div class="node-content"></div>
    <div class="node-block-overlay"></div>
  </div>
  <div class="node-connector-split-content">
    <div class="node-connector-split-line qb-line-fix"></div>
  </div>
  <div>
    <div class="node-branch">
      <div class="abtest-wrapper" id="abtest-wrapper">
        <div class="ab-branch-node-path">
          <div class="dynamic-split-horizontal-2"></div>
        </div>
        <div class="ab-node-container">
        </div>
        
      </div>
    </div>
  </div>
</div>

<div class="node-block-container ab-node-block" id="split-node-block" style="display: none">
  <div class="node-block responseEvent-node-block">
    <div class="node-header">
      <!-- <mat-icon class="node-header-icon">device_hub</mat-icon> -->
      <span
        class="material-icons-outlined icons-primary node-header-icon"
        style="transform: rotate(180deg)"
      >
        alt_route
      </span>
      <span>{{'CAMPAIGN_PAGE.AUD_SPLIT' | i18nTranslate}}</span>
    </div>
    <div class="node-content"></div>
    <div class="node-block-overlay"></div>
  </div>
  <div class="node-connector-split-content">
    <div class="node-connector-split-line qb-line-fix-split"></div>
  </div>
  <div>
    <div class="node-branch">
      <div class="split-wrapper" id="split-wrapper">
        <div class="split-branch-node-path">
          <div class="dynamic-split-horizontal"></div>
        </div>
        <div class="split-node-container">
        </div>
        
      </div>
    </div>
  </div>
</div>


<!-- <div class="node-block-container" id="abtesting-node-block" style="display: none">
  <div class="node-block responseEvent-node-block">
    <div class="node-header">
     
      <span
        class="material-icons-outlined icons-primary node-header-icon"
        style="transform: rotate(180deg)"
      >
        alt_route
      </span>
      <span>A/B Testing</span>
    </div>
    <div class="node-content"></div>
    <div class="node-block-overlay"></div>
  </div>
  <div class="node-connector-split-content">
    <div class="node-connector-split-line"></div>
  </div>
  <div>
    <div class="node-branch">
      <div class="ab-node-branch">
        <div class="abtest-node-container"></div>
      </div>
    </div>
  </div>
</div> -->
<div class="strategy-outline">
  <div id="campaign-header" class="strategy-header main-white-panel" *ngIf="isHeaderShown">
    <div class="strategy-data-header"  [ngStyle]="{
      'pointer-events': strategyStatus === 'ENDED' ? 'none' : 'auto'
    }">
      <div *ngIf="strategyData">
        <div style="display: flex; justify-content: space-between">
          <div
            class="strategy-name-div"
            [matTooltip]="strategyData.strategyName"
            matTooltipClass="ex-tooltip-red1"
            matTooltipShowDelay="500"
          >
            <span>{{ strategyData.strategyName }}</span>
          </div>
          <div
            *ngIf="appSubscriptionPlan && isCreatorOfJourney() && isJouneyInDraftStatus()"
            class="strategy-data-modify-button"
            [ngClass]="{ disabled: actionType }"
            (click)="actionType ? null : updateStrategyInfo()"
            [matTooltip]="
              (actionType ? 'Complete the operation on right pane' : 'Modify') | i18nDynamicTranslate : 'POS_PAGE'
            "
            data-widget-name="CAMPAIGN CANVAS"
            data-button-name="Modify"
          >
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.625 12.2812C1.75 11.8438 2 11.4375 2.3125 11.125L11.8125 1.625C12.5938 0.84375 13.875 0.84375 14.6562 1.625L15.875 2.84375C15.9688 2.9375 16.0625 3.0625 16.125 3.15625C16.6562 3.9375 16.5625 5 15.875 5.6875L6.375 15.1875C6.34375 15.2188 6.28125 15.25 6.25 15.3125C5.9375 15.5625 5.59375 15.75 5.21875 15.875L2.78125 16.5938L1.4375 17C1.1875 17.0625 0.90625 17 0.71875 16.7812C0.5 16.5938 0.4375 16.3125 0.53125 16.0625L0.90625 14.7188L1.625 12.2812ZM3.0625 12.7188L2.84375 13.4375L2.34375 15.1562L4.0625 14.6562L4.78125 14.4375C5 14.375 5.15625 14.2812 5.3125 14.125L12.4688 6.96875L10.5312 5.03125L3.375 12.1875C3.34375 12.1875 3.34375 12.2188 3.3125 12.25C3.1875 12.375 3.125 12.5312 3.0625 12.7188Z" fill="#4379EF"/>
            </svg>
          </div>
        </div>
        <div style="display: flex; margin-top: 5px">
          <div style="max-width: 50%; gap: 10px;" class="qb-d-flex">
            <div
              class="segment-name-container"
              *ngIf="strategyData.segments.length > 0"
            >
              <span
                class="align-center-header"
                *ngFor="let segment of strategyData.segments; let i = index"
                style="min-width: fit-content"
                >{{ segment["segmentName"]
                }}<span *ngIf="i < strategyData.segments.length - 1"
                  >,&nbsp;</span
                ></span
              >
            </div>
            <div
              *ngIf="
                strategyData.strategyName && strategyData.allCustomer
              "
            >
              <span class="align-center-header" style="min-width: fit-content"
                >{{'CAMPAIGN_PAGE.ALL_CUSTOMERS' | i18nTranslate}}</span
              >
            </div>
            <div
            *ngIf="
              strategyData.strategyName && strategyData.allLead
            "
          >
            <span class="align-center-header" style="min-width: fit-content"
              >{{'CAMPAIGN_PAGE.ALL_LEADS' | i18nTranslate}}</span
            >
          </div>
          </div>
          <span *ngIf="showMoreSegmentButtonStatus">...</span>
          <div
            *ngIf="showMoreSegmentButtonStatus"
            class="align-center-header more-segment-chevron"
          >
            <span
              (click)="showSegments($event)"
              class="chevron-icon-header align-center-header"
              data-widget-name="CANVAS"
              data-button-name="Show Segment"
              class="material-icons icons-primary chevron-icon"
            >
              double_arrow
            </span>
            <div class="segment-popup" id="segment-popup">
              <span
                style="padding: 5px 7px"
                *ngFor="let segment of strategyData.segments"
                >{{ segment["segmentName"] }}</span
              >
            </div>
          </div>
          <div class="header-seperator"></div>
          <div style="display: flex" class="align-center-header">
            <span class="material-icons-outlined person-icon icons-primary">
              person
            </span>
            <span style="margin-left: 5px; font-size: 13px">{{
              strategyData.customerCount
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="canvas-data-header" 
    [ngStyle]="{
      'pointer-events': strategyStatus === 'ENDED' ? 'none' : 'auto'
    }">
      <!-- <div class="undo-redo-button">
          <mat-icon class="undo-button">undo</mat-icon>
          <mat-icon class="redo-button">redo</mat-icon>
        </div> -->
      <div>
        <div class="magnifier-container">
          <mat-icon
            class="undo-button"
            [ngClass]="{ disabled: zoomAmount === 30 }"
            (click)="magnify('SUB')"
            data-widget-name="CANVAS"
            data-button-name="Zoom out"
            >remove</mat-icon
          >
          <div class="magnify-text-container">
            <span class="magnify-text">{{ zoomAmount }}%</span>
          </div>
          <mat-icon
            class="redo-button"
            [ngClass]="{ disabled: zoomAmount === 150 }"
            (click)="magnify('ADD')"
            data-widget-name="CANVAS"
            data-button-name="Zoom in"
            >add</mat-icon
          >
        </div>
      </div>
      <div
        *ngIf="appSubscriptionPlan"
        (click)="actionType ? null : resetCanvas()"
        class="reset-button-container"
        [ngClass]="{ disabled: actionType }"
        [matTooltip]="(actionType ? 'Complete the operation on right pane' : '') | i18nDynamicTranslate : 'POS_PAGE'"
        data-widget-name="CANVAS"
        data-button-name="Reset Canvas"
      >
        <mat-icon class="reset-button" style="border-radius: 5px"
          >refresh</mat-icon
        >
        <span style="margin-left: 5px">{{'CAMPAIGN_PAGE.RESET_CANVAS' | i18nTranslate}}</span>
      </div>
    </div>
    <div class="strategy-operation-header">
      <div [ngClass]="strategyStatus" class="status-text">
        <span class="material-icons icons-primary" style="color: inherit; font-size: 21px">
          flag
        </span>
        <span style="color:inherit;font-size: 16px;">{{strategyStatus | i18nDynamicTranslate : 'POS_PAGE' }}</span>
      </div>
      <div *ngIf="!isCreatorOfJourney()" class="view-only-container">
        <div class="material-symbols-outlined view-only-back" (click)="navigateBackToListing()">
          arrow_back_ios
        </div>
        <div class="view-only" [matTooltip]="'No permission to edit Journey created by ' +this.strategyData?.created_by?.storeName" >
          view-only
        </div>
      </div>
      <div style="display: flex; gap: 10px;align-items: center;" *ngIf="isCreatorOfJourney()">
        <div
          *ngIf="appSubscriptionPlan"
          [ngClass]="{ disabled: actionType || strategyStatus === 'ENDED'}"
          (click)="actionType || strategyStatus === 'ENDED'? null : saveCampaign()"
          [matTooltip]="
            (actionType
              ? 'Complete the operation on right pane'
              : 'Save and Exit') | i18nDynamicTranslate : 'POS_PAGE'
          "
          data-widget-name="CANVAS"
          data-button-name="Save and Exit"
          [style.cursor]="(strategyStatus === 'ENDED') ? 'not-allowed' : 'pointer'"
          style="height: fit-content;"
        >
        <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="28px" [ngStyle]="{ fill: strategyStatus === 'ENDED' ? '#989898' : '#4379EF' }"><path d="M816-672v456q0 29.7-21.15 50.85Q773.7-144 744-144H216q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h456l144 144Zm-72 30L642-744H216v528h528v-426ZM480-252q45 0 76.5-31.5T588-360q0-45-31.5-76.5T480-468q-45 0-76.5 31.5T372-360q0 45 31.5 76.5T480-252ZM264-552h336v-144H264v144Zm-48-77v413-528 115Z"/></svg>
          <!-- <span>Save and Exit</span> -->
        </div>
        <div
          *ngIf="appSubscriptionPlan"
          [ngClass]="{
            disabled: actionType || strategyData.strategyType === 'STANDARD'
          }"
          (click)="
            actionType || strategyData.strategyType === 'STANDARD'
              ? null
              : discardCamapign()
          "
          [matTooltip]="
            (actionType
              ? 'Complete the operation on right pane'
              : strategyData.strategyType === 'STANDARD'
              ? 'Cannot Delete Preset Journey'
              : 'Delete') | i18nDynamicTranslate : 'POS_PAGE'
          "
          data-widget-name="CANVAS"
          data-button-name="Delete"
          style="height: fit-content;"
          class="cursor-pointer"
        >
          <svg width="22px" height="22px" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.03125 1.5C5.9375 1.5 5.875 1.5625 5.8125 1.625L5.21875 2.5H9.75L9.15625 1.625C9.125 1.5625 9.03125 1.5 8.9375 1.5H6.03125ZM11.5625 2.5H11.9688H13.5H13.75C14.1562 2.5 14.5 2.84375 14.5 3.25C14.5 3.6875 14.1562 4 13.75 4H13.375L12.625 14.1562C12.5312 15.2188 11.6875 16 10.625 16H4.34375C3.28125 16 2.4375 15.2188 2.34375 14.1562L1.59375 4H1.25C0.8125 4 0.5 3.6875 0.5 3.25C0.5 2.84375 0.8125 2.5 1.25 2.5H1.5H3H3.40625L4.5625 0.78125C4.875 0.3125 5.4375 0 6.03125 0H8.9375C9.53125 0 10.0938 0.3125 10.4062 0.78125L11.5625 2.5ZM11.875 4H3.09375L3.84375 14.0625C3.875 14.3125 4.09375 14.5 4.34375 14.5H10.625C10.875 14.5 11.0938 14.3125 11.125 14.0625L11.875 4Z" fill="#4379EF"/>
          </svg>
        </div>
        <div
        *ngIf="(strategyStatus === 'DRAFT' || strategyStatus === 'ENDED') && appSubscriptionPlan"
        class="primary-button"
        [ngClass]="{
          'disabled': actionType,
          'button-ended': strategyStatus === 'ENDED'
        }"
        (click)="(actionType || strategyStatus === 'ENDED') ? null : activateStrategy()"
        [matTooltip]="
  (strategyStatus === 'ENDED'
    ? 'Ended Journey cant be activated'
    : (actionType ? 'Complete the operation on right pane' : '')) | i18nDynamicTranslate : 'POS_PAGE'
"
          data-widget-name="CANVAS"
          data-button-name="Activate Campaign"
          [style.cursor]="(strategyStatus === 'ENDED') ? 'not-allowed' : 'pointer'"
        >
          <span>{{'CAMPAIGN_PAGE.ACT_CAMPAIGN' | i18nTranslate}}</span>
        </div>
        <div
          *ngIf="strategyStatus === 'ACTIVE' || strategyStatus === 'SCHEDULED'"
          class="deactivate-button-header"
          (click)="deactivateStrategy()"
          data-widget-name="CANVAS"
          data-button-name="Deactivate Campaign"
        >
          <span>{{'CAMPAIGN_PAGE.DEACT_CAMPAIGN' | i18nTranslate}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="strategy-container" id="strategy-container">
    <div class="node-container" *ngIf="actionType">
      <app-campaign-strategy-node
        [strategyData]="strategyData"
        [previousNodeId]="previousNodeId"
        [type]="actionType"
        [nodeId]="nodeId"
        [nodeData]="nodeData"
        [nodeType]="nodeType"
        [nextNodeId]="nextNodeId"
        [previousNodeRelation]="previousNodeRelation"
        [strategyId]="strategyId"
        [isAllCustomers]="isAllCustomers"
        (nodeConfiguredData)="configureNodeData($event, false)"
        [allowABTestingNodeCreation]="allowABTestingNodeCreation"
        [isTimeEvent]="isTimeEvent"
        [allowAudienceSplitNodeCreation]="allowAudienceSplitNodeCreation"
        [loyaltyRuleEventsInNodes] = 'loyaltyRuleEventsInNodes'
      ></app-campaign-strategy-node>
    </div>
    <div class="canvas-container">
      <div class="canvas" id="canvas">
        <!-- <div class="node-block-container">
                    <div class="node-block"></div>
                    <div class="node-connectors"></div>
                    <div class="add-node" (click)="addNewNode($event,'UNBRANCHED')">
                        <mat-icon>add</mat-icon>
                    </div>
                </div>
            </div> -->
      </div>
      <div id="overlay"></div>
      <div id="notification-container"></div>
      <div id="notification" class="notification">
        <mat-icon class="notification-done-icon">done</mat-icon>
        <mat-icon class="notification-fail-icon">close</mat-icon>
        <span class="notificationn-content">{{'CAMPAIGN_PAGE.MESSAGE' | i18nTranslate}}</span>
        <mat-icon class="notification-close-icon">close</mat-icon>
      </div>
    </div>
    <div
      (click)="cancelMoveAndCloneOperations()"
      class="cancel-operation-button"
      id="cancel-operation-button"
    >
      <span>{{'CAMPAIGN_PAGE.CANCEL' | i18nTranslate}}</span>
    </div>
  </div>
  <div *ngIf="!buffer" id="buffer" class="buffer">
    <app-qubriux-loading [size]="65" [loadingText]="'Loading Journey...'"></app-qubriux-loading>
  </div>
</div>
<div class="trigger-condition" id="trigger-condition" style="display: none">
  <div class="trigger-condition-header">
    <div class="triggger-condition-count"></div>
    <div class="trigger-condition-toggle"></div>
  </div>
  <div class="trigger-condition-content">
    <div style="display: flex">
      <div class="trigger-condition-icon"></div>
      <div class="trigger-node-customer-text">
        <span>{{'CAMPAIGN_PAGE.CUSTOMER' | i18nTranslate}}</span>
      </div>
      <div class="trigger-event-name overflow-ellipsis"></div>
    </div>
    <div class="trigger-content-group">
      <div class="trigger-date-type"></div>
      <div class="trigger-start-date"></div>
      <div class="trigger-date-seperator"></div>
      <div class="trigger-end-date"></div>
    </div>
    <div class="trigger-content-group">
      <div class="trigger-day-type"></div>
      <div class="trigger-day"></div>
    </div>
    <div class="trigger-content-group">
      <div class="trigger-time-type"></div>
      <div class="trigger-start-time"></div>
      <div class="trigger-time-seperator"></div>
      <div class="trigger-end-time"></div>
    </div>
    <div class="le-display">
    </div>
  </div>
</div>
<div
  class="unconditional-node-content"
  id="unconditional-node-content"
  style="display: none"
>
<div class="response-condition-header">
  <div class="response-condition-count"></div>
  <div class="response-condition-toggle"></div>
</div>

<div class="trigger-condition-content" id="response-node-content">
  <div>
    <div style="display: flex">
      <div class="unconditional-node-content-icon"></div>
      <div style="display: flex; align-items: center; margin-right: 5px">
        <span class="if-customer"></span>
      </div>
      <div class="unconditional-node-content-event-name"></div>
    </div>
    <div style="display: flex; margin-top: 10px; margin-left: 24px">
      <div>
        <span class="by"></span>
      </div>
      <div class="unconditional-node-content-event-time"></div>
    </div>
  </div>
  <div>
    <div style="display: flex">
      <div style="display: flex; align-items: center; margin-right: 5px">
        <span class="select-segment"></span>
        <div class="trigger-event-name"></div>
      </div>
      <div class="unconditional-node-content-event-name"></div>
    </div>
  </div>
  <div class="le-display">
  </div>
</div>
</div>
<div class="abtesting-node-content" id="abtesting-node-content" style="display: none;">
  <div style="display: flex;">
    <div class="abtesting-node-content-time"></div>
    <div class="abtesting-node-waiting-time"></div>
  </div>
</div>
<div class="split-node-content" id="split-node-content" style="display: none;">
  <div class="split-condition-header">
    <div class="split-condition-count"></div>
    <div class="split-condition-toggle"></div>
  </div>
  <div class="trigger-condition-content">
    <div style="display: flex; flex-direction: column;">
      <div class="trigger-condition-icon"></div>
      <div class="trigger-node-customer-text">
        <span>Selected Segments(s)/Tier(s):</span>
      </div>
      <div class="trigger-event-name audience-split-mod">
      </div>
    </div>
  </div>
</div>
<div class="delay-node-content" id="delay-node-content" style="display: none">
  <div style="display: flex">
    <div style="display: flex; align-items: center; margin-right: 2px">
      <span>{{'CAMPAIGN_PAGE.WAIT_FOR' | i18nTranslate}}</span>
    </div>
    <div class="delay-node-content-time"></div>
  </div>
</div>
<div class="email-node-content" id="email-node-content" style="display: none">
  <div class="email-node-content-background">
    <div class="email-node-content-subject"></div>
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="email-node-template-name"></div>
    </div>
  </div>
</div>
<div class="whatsapp-node-content" id="whatsapp-node-content" style="display: none">
  <div class="whatsapp-node-content-background">
    <!-- <div class="whatsapp-node-content-subject"></div> -->
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="whatsapp-node-template-name"></div>
    </div>
  </div>
</div>
<div class="sms-node-content" id="sms-node-content" style="display: none"  *ngIf="(validScreens | screenConfig: 18)">
  <div class="sms-node-content-background">
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="sms-node-template-name"></div>
    </div>
  </div>
</div>
<div class="offer-node-content" id="offer-node-content" style="display: none">
  <div class="offer-node-content-background">
    <div class="offer-node-content-subject"></div>
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="offer-node-template-name"></div>
    </div>
  </div>
</div>
<div class="comarketingoffer-node-content" id="comarketingoffer-node-content" style="display: none">
  <div class="comarketingoffer-node-content-background">
    <div class="comarketingoffer-node-content-subject"></div>
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="comarketingoffer-node-template-name"></div>
    </div>
  </div>
</div>
<div class="audience-node-content" id="audience-node-content" style="display: none">
  <div class="audience-node-content-background">
    <div class="audience-node-content-subject"></div>
    <div class="audience-node-template-name"></div>
    <div class="audience-node-content-subject1"></div>
    <div class="audience-node-template-name1"></div>
    <div class="audience-node-content-subject2"></div>
    <div class="audience-node-template-name2"></div>
  </div>
</div>
<div class="survey-node-content" id="survey-node-content" style="display: none">
  <!-- <div class="survey-title-div">
    <span>Survey Email Subject</span>
    <div class="survey-node-content-subject"></div>
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="survey-node-email-template-name"></div>
    </div>
  </div> -->
  <div class="survey-node-content-background">
    <!-- <div > -->
    <!-- <span>Survey Title</span> -->
    <div class="survey-node-content-title"></div>
    <div style="display: flex">
      <mat-icon class="template-attach-icon">attach_file</mat-icon>
      <div class="survey-node-template-name"></div>

      <!-- To show the channels of survey node
      <div class="survey-node-channel"></div> -->
    </div>
    <!-- </div> -->
  </div>
</div>
<div id="add_shopping_cart" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon"
    >add_shopping_cart</mat-icon
  > -->
  <span class="material-icons-outlined node-header-icon icons-primary">
    add_shopping_cart
  </span>
</div>
<div id="credit_card" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">credit_card</mat-icon> -->
  <span class="material-icons-outlined icons-primary node-header-icon">
    shopping_cart
  </span>
</div>
<div id="person_add" style="display: none">
  <span class="material-icons-outlined icons-primary node-header-icon">
    person_add
  </span>
</div>
<div id="library_add_check" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon"
    >library_add_check</mat-icon
  > -->
  <span class="material-icons-outlined icons-primary node-header-icon"
    >check_circle</span
  >
</div>
<div id="visibility" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">visibility</mat-icon> -->
  <span class="material-icons-outlined icons-primary node-header-icon">
    visibility
  </span>
</div>
<div id="search" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">search</mat-icon> -->

  <span class="material-icons-outlined icons-primary node-header-icon">
    search
  </span>
</div>
<div id="apps" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-icons-outlined icons-primary node-header-icon">
    timer
  </span>
</div>
<div id="update_checkout" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-icons-outlined icons-primary node-header-icon">
    update
  </span>
</div>
<div id="keyboard_arrow_down" style="display: none">
  <mat-icon class="keyboard-down-icon">keyboard_arrow_down</mat-icon>
</div>
<div id="cake" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-icons-outlined icons-primary node-header-icon">
    cake
  </span>
</div>
<div id="celebration" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-icons-outlined icons-primary node-header-icon">
    celebration
  </span>
</div>
<div id="group" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-icons-outlined icons-primary node-header-icon">
    group
  </span>
</div>
<div id="calendar_clock" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-symbols-outlined">
    calendar_clock
  </span>
</div>
<div id="hotel_class" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-symbols-outlined">
    hotel_class
  </span>
</div>
<div id="beenhere" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-symbols-outlined">
    beenhere
  </span>
</div>
<div id="domain_verification" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-symbols-outlined">
    domain_verification
  </span>
</div>
<div id="contract_delete" style="display: none">
  <!-- <mat-icon class="trigger-condition-individual-icon">apps</mat-icon> -->

  <span class="material-symbols-outlined">
    contract_delete
  </span>
</div>
