import { DatePipe, getCurrencySymbol } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import * as echarts from 'echarts';
import { ApiCallService } from 'src/app/core/services/api-call-service';
import { getdateformatService } from 'src/app/core/services/get-date-format.service';
import { Globals } from 'src/app/core/services/global.service';
import { SnackbarCollection } from 'src/app/core/services/snackbar.service';
import { InsightGraphDialogBoxComponent } from 'src/app/shared/components/dialog-box';
import { TranslateDynamicText } from 'src/app/shared/pipes/dynamic-translation.pipe';

@Component({
  selector: 'app-trend-report',
  templateUrl: './trend-report.component.html',
  styleUrls: ['./trend-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TrendReportComponent implements OnInit {

  constructor(
    private _apiCall:ApiCallService, 
    private snackbar:SnackbarCollection, 
    private _i18nDynamicTranslate: TranslateDynamicText,
    private dialog: MatDialog,
    private globalService: Globals
  ) { 
    this.displayStringLabel = this.displayStringLabel.bind(this);
  }

  dataLoading = false;
  isNoDataAvailable = false;
  dashboardData;
  rewardRedamationRateStat = [];
  datasefor2 = [];
  totalCustomerRevenueFrameList = ['Quarter','Month', 'Week'];
  rangeList = ['quarter','month', 'week'];
  totalCustomerRevenueTimeFrame = 'Month';
  revCurrency = sessionStorage.getItem("serviceCurrency");
  currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
  totalSalesLinerChart: echarts.EChartsCoreOption;
  totalCustomerLineChart: echarts.EChartsCoreOption;
  totalCustomerComparison: echarts.EChartsCoreOption;
  totalSalesComparison: echarts.EChartsCoreOption;
  totalSalesPieChart: echarts.EChartsCoreOption;
  totalCustomerPieChart: echarts.EChartsCoreOption;
  totalProductPieChart: echarts.EChartsCoreOption;
  avgSpendPerVisitLinearChart: echarts.EChartsCoreOption;
  availableFilter: any = {};
  selectedFilter: any = {};
  selectedMonth = '';
  availableMonths = [];
  saleX1 =[];
  customerX1 = [];
  saleY1 = [];
  customerY1 = [];
  selectedStartPeriod;
  selectedEndPeriod;
  tempSelectedStart;
  tempSelectedEnd;
  previousYearDataSales = [];
  previousYearDataCustomer = [];
  totalRevenuePie =[
    {
      name: 'Current Year:',
      count: '0',
      color: '#8280FF',
    },
    {
      name: 'Previous Year:',
      count: '0',
      color: '#ADCCFF'
    }
  ];
  totalCustomerPie =[
    {
      name: 'Current Year:',
      count: '0',
      color: '#8280FF',
    },
    {
      name: 'Previous Year:',
      count: '0',
      color: '#ADCCFF'
    }
  ];
  totalProductsPie =[
    {
      name: 'Current Year:',
      count: '0',
      color: '#8280FF',
    },
    {
      name: 'Previous Year:',
      count: '0',
      color: '#ADCCFF'
    }
  ];
  @ViewChild("startPeriod") startPeriod: MatSelect;
  @ViewChild("endPeriod") endPeriod: MatSelect;

  ngOnInit(): void {
    this.getFilters();
  }

  startPeriodChange(){
    this.endPeriod.open();
  }
  endPeriodChange(isClosed){
    if(this.tempSelectedEnd !== this.selectedEndPeriod || this.tempSelectedStart !== this.selectedStartPeriod)
      this.getDateChangeData();
    if(!isClosed || this.tempSelectedStart !== this.selectedStartPeriod){
      this.tempSelectedEnd = this.selectedEndPeriod;
      this.tempSelectedStart = this.selectedStartPeriod;
    }
  }

  createBarCharts(data){
    // Initialize the echarts instance based on the prepared dom
    // var myChart = echarts.init(document.getElementById('graph1'));

    // Specify the configuration items and data for the chart

    let timeType = this.totalCustomerRevenueTimeFrame;
    function convertToMonthName(value, previosYear?) {
      if(value){
        if(timeType.toLowerCase() === 'month'){
          let [year, month] = value.split('-');
      
          // Convert numeric month to three-letter abbreviation
          let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
      
          // Format the label as 'AbbreviatedMonth-Year'
          return previosYear ? `${monthAbbreviation}-${year - 1}` : `${monthAbbreviation}-${year}`;
        }
        else if(timeType.toLowerCase() === 'week'){
          let [year, week] = value.split('-');
          return previosYear ? `Week-${week} ${year - 1}` : `Week-${week} ${year}`
        }
        else{
          let [year, quarter] = value.split('Q');
          return previosYear ? `${year - 1} Q${quarter}` : `${year} Q${quarter}`
        }
      }
      else{
        return null;
      }
    }

    let thisyearCustomerGrowthValue = this.getCustomerGrowthValue(data.customer.currentValue.legend, data.customer.previousValue.legend);
    let thisyearSalesGrowthValue = this.getCustomerGrowthValue(data.sales.currentValue.legend, data.sales.previousValue.legend);
    let previousyearCustomerGrowthValue = this.getCustomerGrowthValue(data.customer.currentValue.legend, data.customer.previousYear.legend);
    let previousyearSalesGrowthValue = this.getCustomerGrowthValue(data.sales.currentValue.legend, data.sales.previousYear.legend);
      console.log("ComparisonBarChart",data);
      this.totalCustomerComparison ={
        xAxis: {
          data: ['']
        },
        yAxis: {},
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            const formattedRevenue = params.value;
            let formattedTooltip :any;
            if(params.seriesName === convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousValue.value)){
              formattedTooltip = `${convertToMonthName(data.customer.currentValue.value)} Customer Count: ${data.customer.currentValue.legend} <br/> ${convertToMonthName(data.customer.previousValue.value)} Customer Count: ${data.customer.previousValue.legend}`
            }
            else if(params.seriesName === convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousYear.value, true)){
              formattedTooltip = `${convertToMonthName(data.customer.currentValue.value)} Customer Count: ${data.customer.currentValue.legend} <br/> ${convertToMonthName(data.customer.previousYear.value, true)} Customer Count: ${data.customer.previousYear.legend}`
            }
      
            return formattedTooltip;
          },
        },
        legend: {
          orient: 'horizontal',  
          left: 20,
          bottom: 10,
          data: [convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousValue.value), convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousYear.value, true)]
        },
        series: [
          {
            // name: data.customer.currentValue.value !== null ? data.customer.currentValue.value : '',
            name: convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousValue.value),
            type: 'bar',
            data: [thisyearCustomerGrowthValue],
            itemStyle: {
              color: '#ADCCFF' // Color for the first bar
            },
            label: {
              show: true,
              position: 'outside',
                formatter: function (params) {
          // Display the value of the bar with two decimal places
                  return params.value.toFixed(2) + "%";
                },
              color: 'black' // Text color
            },
            barGap: '0%',
            barCategoryGap: '40%'
          },
          {
            // name: data.customer.previousYear.value !== null ? data.customer.previousYear.value : '',
            name: data.customer.previousYear.value ? convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousYear.value, true) : '',
            type: 'bar',
            itemStyle: {
              color : '#8280FF'
            },
            label: {
              show: true,
              position: 'outside',
                formatter: function (params) {
          // Display the value of the bar with two decimal places
                  return params.value.toFixed(2) + "%";
                },
              color: 'black' // Text color
            },
            data: [previousyearCustomerGrowthValue]
            // data: [-20]
          }
        ]
      };
  
      // Display the chart using the configuration items and data just specified.
      // myChart.setOption(option);
      
      // var myChart2 = echarts.init(document.getElementById('graph2'));
      let currencySymbol = getCurrencySymbol(this.revCurrency, "narrow");
      this.totalSalesComparison = {
        xAxis: {
          data: ['']
        },
        yAxis: {},
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            const formattedRevenue = params.value;
            let formattedTooltip :any;
            if(params.seriesName === convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousValue.value)){
              formattedTooltip = `${convertToMonthName(data.customer.currentValue.value)} Revenue: ${currencySymbol} ${data.sales.currentValue.legend.toFixed(2)} <br/> ${convertToMonthName(data.customer.previousValue.value)} Revenue: ${currencySymbol} ${data.sales.previousValue.legend.toFixed(2)}`
            }
            else if(params.seriesName === convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousYear.value, true)){
              formattedTooltip = `${convertToMonthName(data.customer.currentValue.value)} Revenue: ${currencySymbol} ${data.sales.currentValue.legend.toFixed(2)} <br/> ${convertToMonthName(data.customer.previousYear.value, true)} Revenue: ${currencySymbol} ${data.sales.previousYear.legend.toFixed(2)}`
            }
            return formattedTooltip;
          },
        },
        legend: {
          // data: [data.sales.currentValue.value, data.sales.previousYear.value !== null ? data.sales.previousYear.value : '']
          orient: 'horizontal',  
          left: 20,
          bottom: 10,
          data: [convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousValue.value), convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousYear.value, true)]
        },
        series: [
          {
            // name: data.sales.currentValue.value !== null ? data.sales.currentValue.value : '',
            name: convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousValue.value),
            type: 'bar',
            data: [thisyearSalesGrowthValue],
            itemStyle: {
              color: '#ADCCFF'
            },
            label: {
              show: true,
              position: 'outside',
                formatter: function (params) {
          // Display the value of the bar with two decimal places
                  return params.value.toFixed(2) + "%";
                },
              color: 'black' // Text color
            },
            barGap: '0%',
            barCategoryGap: '40%'
          },
          {
            // name: data.sales.previousYear.value !== null ? data.sales.previousYear.value : '',
            name: data.customer.previousYear.value ? convertToMonthName(data.customer.currentValue.value) + ' v/s ' + convertToMonthName(data.customer.previousYear.value, true) : '',
            type: 'bar',
            itemStyle: {
              color: '#8280FF'
            },
            label: {
              show: true,
              position: 'outside',
                formatter: function (params) {
          // Display the value of the bar with two decimal places
                return params.value.toFixed(2) + "%";
              },
              color: 'black' // Text color
            },
            data: [previousyearSalesGrowthValue]
            // data: [-120]
          }
        ]
      };
    // myChart2.setOption(option2);

  }

  getCustomerGrowthValue(current, previous){
    if(current == null || previous == null || previous === 0){
      return; //if value is not there sending empty string.
    }
    else{
      return ((current - previous) * 100)/previous; //formula to find the growth rate.
    }
  }
  totalCustomerRevenueTimeFrameDropDownHandler(){
    console.log(this.totalCustomerRevenueTimeFrame);
    this.getFilters();
  }

  monthChangeDropdown(){
    let data: any ={};
    let xg1= this.saleX1;
    let xg2 = this.customerX1;
    let yg1 = this.saleY1;
    let yg2 = this.customerY1;
    let index1 = xg1.indexOf(this.selectedMonth); 
      let previousYearCustomer: any = null;
      let previousYearSale: any = null;
      data.sales = {
        currentValue: {
          value: xg1[index1],
          legend: yg1[index1]
        },
        previousValue: {
          value: xg1[index1 - 1],
          legend: yg1[index1 - 1]
        },
        previousYear: {
          value: null,
          legend: null
        }
      };
   
      previousYearSale = this.generatePreviousYearSameMonth(xg1[index1], xg1, 'sale');
      if(previousYearSale !== -1){
        // data.sales.previousYear.value = xg1[previousYearSale];
        // data.sales.previousYear.legend = yg1[previousYearSale];
        data.sales.previousYear.value = previousYearSale['group_value'];
        data.sales.previousYear.legend = previousYearSale['amount'];
      }
  
      data.customer = {
        currentValue: {
          value: xg2[index1],
          legend: yg2[index1]
        },
        previousValue: {
          value: xg2[index1 - 1],
          legend: yg2[index1 - 1]
        },
        previousYear: {
          value: null,
          legend: null
        }
      };
      previousYearCustomer = this.generatePreviousYearSameMonth(xg2[index1], xg2, 'customer');
      if(previousYearCustomer !== -1){
        // data.customer.previousYear.value = xg2[previousYearCustomer];
        // data.customer.previousYear.legend = yg2[previousYearCustomer];
        data.customer.previousYear.value = previousYearCustomer['group_value'];
        data.customer.previousYear.legend = previousYearCustomer['customer_count'];
      }
      this.createBarCharts(data);
  }

  getAnalyticsForTotalCustomerSales(isFiltered){
    this.dataLoading = true;
    let data = null;
      data = this.applyFilter();
      this.getAnalytics(data);
      this.getUniqueValueData();
  }

  previousYearDataFuntion(data):any {
    if(data != null){
      const dataMap = new Map(data.map(item => [item.group_value, item.amount]));
  
      const newArray = this.selectedFilter['group_value'].map(groupValue => ({
        group_value: groupValue,
        amount: dataMap.has(this.getpreviousYear(groupValue)) ? dataMap.get(this.getpreviousYear(groupValue)) : 0,
      }));
      console.log(newArray);
      return newArray;
    }
  }

  previousYearDataCustomerFuntion(data):any {

    if(data != null){
      const dataMap = new Map(data.map(item => [item.group_value, item.customer_count]));
  
      const newArray = this.selectedFilter['group_value'].map(groupValue => ({
        group_value: groupValue,
        customer_count: dataMap.has(this.getpreviousYear(groupValue)) ? dataMap.get(this.getpreviousYear(groupValue)) : 0,
      }));
      console.log(newArray);
      return newArray;
    }
  }

  getpreviousYear(date){
    if(this.totalCustomerRevenueTimeFrame.toLowerCase() === 'month' || this.totalCustomerRevenueTimeFrame.toLowerCase() === 'week'){
      const [year, month] = date?.split('-').map(Number); // Extract month and year
      const previousYear = year - 1; // Calculate the previous year
    
      // Generate 'MM-YYYY' format for previous year with the same month
      const previousYearSameMonth = `${previousYear}-${month.toString().padStart(2, '0')}`;
      return previousYearSameMonth;
    }
    else if (this.totalCustomerRevenueTimeFrame.toLowerCase() === 'quarter'){
      const [year, quarter] = date?.split('Q').map(Number); // Extract month and year
      const previousYear = year - 1; // Calculate the previous year
    
      // Generate 'MM-YYYY' format for previous year with the same month
      const previousYearSameMonth = `${previousYear}Q${quarter}`;
      return previousYearSameMonth;
    }
  }

  getAnalytics(data){
      this.totalCustomerSaleAnalyticsApi(data, "saleschart").then((result) => {
        this.totalCustomerSaleAnalyticsApi(data, "previoussaleschart").then((data)=>{
          this.previousYearDataSales =  this.previousYearDataFuntion(data['body']);
          this.previousYearDataCustomer = this.previousYearDataCustomerFuntion(data['body']);
          this.dataLoading =  false;
          if(result['body'] && result['body'].length > 0){
            this.createTotalCustomerSalesGraph(result['body'], false);
          }
          else{
            this.createTotalCustomerSalesGraph(result['body'], true);
          }
        });
      });
    }
    
    getUniqueValueData(){
      let data = this.applyTimeFrameFilters();
      this.totalCustomerSaleAnalyticsApi(data, "previousuniquecustomers").then((result)=>{
        this.createUniqueCustomerPieCharts(result['body']);
      });
    }

    createUniqueCustomerPieCharts(data){
      let totalyg2 = 0, totalpy2 =0, totalProductyg = 0, totalProductpy = 0;
      let timeType = this.totalCustomerRevenueTimeFrame;

      totalyg2 = data[0]['currentTotalCustomers'];
      totalProductyg = data[0]['currentProducts'];
      totalpy2 = data[1]['previousTotalCustomers'];
      totalProductpy = data[1]['previousProducts'];

      this.totalCustomerPie[0].count = totalyg2 >= 1000 ? (totalyg2 / 1000).toFixed(2) + 'K' : totalyg2.toString();
      this.totalCustomerPie[1].count = totalpy2 >= 1000 ? (totalpy2 / 1000).toFixed(2) + 'K' : totalpy2.toString();
      this.totalProductsPie[0].count = totalProductyg >= 1000 ? (totalProductyg / 1000).toFixed(2) + 'K' : totalProductyg.toString();
      this.totalProductsPie[1].count = totalProductpy >= 1000 ? (totalProductpy / 1000).toFixed(2) + 'K' : totalProductpy.toString();

      function getAggratedValue(value){
        return value >= 1000 ? (value / 1000).toFixed(2) + 'K' : value.toFixed(2);
      }

      this.totalCustomerPieChart = {
        title: {
          text: `${getAggratedValue(totalyg2)}`,
          left: 'center',
          top: 'center'
        },
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            console.log(params)
            const formattedRevenue = params.value >= 1000 ? (params.value / 1000).toFixed(2) + 'K' : params.value;
      
            const formattedTooltip = `${params.name}: ${formattedRevenue}`;
            return formattedTooltip;
          },
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: totalyg2,
                name: 'Current Year'
              },
              {
                value: totalpy2,
                name: 'Previous Year'
              }
            ],
            radius: ['70px', '85px'],
            color: ['#8280FF', '#ADCCFF']
          }
        ]
      };

      this.totalProductPieChart = {
        title: {
          text: `${getAggratedValue(totalProductyg)}`,
          left: 'center',
          top: 'center'
        },
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            console.log(params)
            const formattedRevenue = params.value >= 1000 ? (params.value / 1000).toFixed(2) + 'K' : params.value;
      
            const formattedTooltip = `${params.name}: ${formattedRevenue}`;
            return formattedTooltip;
          },
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: totalProductyg,
                name: 'Current Year'
              },
              {
                value: totalProductpy,
                name: 'Previous Year'
              }
            ],
            radius: ['70px', '85px'],
            color: ['#8280FF', '#ADCCFF']
          }
        ]
      };
    }

  createTotalCustomerSalesGraph(dataSalesCustomer, isNodata){
    let type = this.totalCustomerRevenueTimeFrame;
    let currencySymbol = this.currencySymbol;
    function convertToMonthName(value, previosYear?) {
      if(value){
        if(type.toLowerCase() === 'month'){
          let [year, month] = value.split('-');
      
          // Convert numeric month to three-letter abbreviation
          let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
      
          // Format the label as 'AbbreviatedMonth-Year'
          return previosYear ? `${monthAbbreviation}-${year - 1}` : `${monthAbbreviation}-${year}`;
        }
        else if(type.toLowerCase() === 'week'){
          let [year, week] = value.split('-');
          return previosYear ? `Week-${week} ${year - 1}` : `Week-${week} ${year}`
        }
        else{
          let [year, quarter] = value.split('Q');
          return previosYear ? `${year - 1} Q${quarter}` : `${year} Q${quarter}`
        }
      }
      else{
        return null;
      }
    }

    function getAggratedValue(value){
      return value >= 1000 ? (value / 1000).toFixed(2) + 'K' : value.toFixed(2);
    }
    if(!isNodata){
      dataSalesCustomer.sort((a, b) => a.group_value.localeCompare(b.group_value));

      let xg1 = [], xg2 = [], xg3 = [],  yg1 = [], yg2 = [], yg3 = [], py1 =[], py2 = [], totalyg1 = 0, totalpy1 = 0, totalpy2 = 0;;
  
      dataSalesCustomer.forEach((data)=>{
        //data['name'] = data.dateRange;
        xg1.push(data.group_value);
        yg1.push(data.amount);
        totalyg1 += data.amount;
        // data['value'] = data.count;
      });
      console.log(xg1,yg1)
      this.saleX1 = xg1;
      this.saleY1 = yg1;
      
      this.previousYearDataSales?.forEach((data)=>{
        py1.push(data.amount);
        totalpy1 += data.amount;
      });
      this.previousYearDataCustomer?.forEach((data)=>{
        py2.push(data.customer_count);
        totalpy2 += data.customer_count;
      })
      dataSalesCustomer.forEach((data=>{
        xg2.push(data.group_value);
        yg2.push(data.customer_count);
      }));
      dataSalesCustomer.forEach((data=>{
        xg3.push(data.group_value);
        yg3.push(data.avg_spend_visit);
      }));
      this.totalRevenuePie[0].count = totalyg1 >= 1000 ? (totalyg1 / 1000).toFixed(2) + 'K' : totalyg1.toFixed(2);
      this.totalRevenuePie[1].count = totalpy1 >= 1000 ? (totalpy1 / 1000).toFixed(2) + 'K' : totalpy1.toFixed(2);
      console.log(totalyg1);
      this.customerX1 = xg2;
      this.customerY1 = yg2;
      let type = this.totalCustomerRevenueTimeFrame;
      this.totalSalesPieChart = {
        title: {
          text: `${currencySymbol} ${getAggratedValue(totalyg1)}`,
          left: 'center',
          top: 'center'
        },
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            console.log(params)
            const formattedRevenue = params.value >= 1000 ? (params.value / 1000).toFixed(2) + 'K' : params.value;
      
            const formattedTooltip = `${params.name}: ${currencySymbol} ${formattedRevenue}`;
            return formattedTooltip;
          },
        },
        series: [
          {
            type: 'pie',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: totalyg1,
                name: 'Current Year'
              },
              {
                value: totalpy1,
                name: 'Previous Year'
              }
            ],
            radius: ['70px', '85px'],
            color: ['#8280FF', '#ADCCFF']
          }
        ]
      };
     
      this.totalSalesLinerChart = {
        xAxis: {
          type: 'category',
          name: type,
          nameLocation:'center',
          nameGap: 30,
          data: xg1,
          axisLabel: {
            formatter: function (value, index) {
              if(value){
                if(type.toLowerCase() === 'month'){
                  let [year, month] = value.split('-');
              
                  // Convert numeric month to three-letter abbreviation
                  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
              
                  // Format the label as 'AbbreviatedMonth-Year'
                  return `${monthAbbreviation}-${year}`;
                }
                else if(type.toLowerCase() === 'week'){
                  let [year, week] = value.split('-');
                  return `Week-${week} ${year}`
                }
                else{
                  let [year, quarter] = value.split('Q');
                  return `${year} Q${quarter}`
                }
              }
              else{
                return null;
              }
            }
          }
      
        },
        yAxis: {
          type: 'value',
          // name: `Revenue(${this.currencySymbol})`,
          // nameLocation:'center',
          axisLabel: {
            formatter: function (value) {
              if (value >= 1e9) {
                  return (value / 1e9) + 'B';
              }
              if (value >= 1e6) {
                  return (value / 1e6) + 'M';
              }
              if (value >= 1e3) {
                  return (value / 1e3) + 'k';
              }
              return value;
          },
          },
          // nameGap: 30,
        },
        legend: {
          data: ['Current Year', 'Previous Year'],
          orient: 'horizontal',  
          left: 0,
          bottom: 0,
          itemWidth: 15,
          itemHeight: 15,
          icon: 'rect' 
        },
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            let formattedTooltip : any = ``;
            const formattedRevenue = params.value >= 1000 ? (params.value / 1000).toFixed(2) + 'K' : params.value.toFixed(2);
            if(params.seriesName === 'Current Year'){
              formattedTooltip = `${convertToMonthName(xg1[dataIndex])} ${formattedRevenue} ${currencySymbol}`;
            }
            else{
              formattedTooltip = `${convertToMonthName(xg1[dataIndex], true)} ${formattedRevenue} ${currencySymbol}`;
            }
            return formattedTooltip;
          },
        },
        series: [
          {
            data: yg1,
            name: 'Current Year',
            type: 'line',
            itemStyle: {
              color: '#8280FF'
            }
          },
          {
            data: py1,
            name: py1.length !== 0 ? 'Previous Year' : '',
            type: 'line',
            itemStyle: {
              color: '#ADCCFF'
            }
          }
        ]
      };
      this.avgSpendPerVisitLinearChart = {
        xAxis: {
          type: 'category',
          name: type,
          nameLocation:'center',
          nameGap: 30,
          data: xg3,
          axisLabel: {
            formatter: function (value, index) {
              if(value){
                if(type.toLowerCase() === 'month'){
                  let [year, month] = value.split('-');
              
                  // Convert numeric month to three-letter abbreviation
                  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
              
                  // Format the label as 'AbbreviatedMonth-Year'
                  return `${monthAbbreviation}-${year}`;
                }
                else if(type.toLowerCase() === 'week'){
                  let [year, week] = value.split('-');
                  return `Week-${week} ${year}`
                }
                else{
                  let [year, quarter] = value.split('Q');
                  return `${year} Q${quarter}`
                }
              }
              else{
                return null;
              }
            }
          }
      
        },
        yAxis: {
          type: 'value',
          name: `Average Spend Per Visit (${this.currencySymbol})`,
          nameLocation: 'middle',
          nameRotate: 90,
          nameGap: 40,
          axisLabel: {
            formatter: function (value) {
              if (value >= 1e9) {
                  return (value / 1e9) + 'B';
              }
              if (value >= 1e6) {
                  return (value / 1e6) + 'M';
              }
              if (value >= 1e3) {
                  return (value / 1e3) + 'k';
              }
              return value;
          },
          },
          // nameGap: 30,
        },
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            let formattedTooltip : any = ``;
            const formattedRevenue = params.value >= 1000 ? (params.value / 1000).toFixed(2) + 'K' : params.value.toFixed(2);
            formattedTooltip = `${convertToMonthName(xg3[dataIndex])} ${formattedRevenue} ${currencySymbol}`;
            return formattedTooltip;
          },
        },
        series: [
          {
            data: yg3,
            name: 'Current Year',
            type: 'line',
            itemStyle: {
              color: '#8280FF'
            }
          }
        ]
      };
      let timeType = this.totalCustomerRevenueTimeFrame;
      this.totalCustomerLineChart = {
        xAxis: {
          type: 'category',
          name: type,
          nameLocation:'center',
          nameGap: 30,
          data: xg2,
          axisLabel: {
            formatter: function (value, index) {
              if(value){
                if(timeType.toLowerCase() === 'month'){
                  let [year, month] = value.split('-');
              
                  // Convert numeric month to three-letter abbreviation
                  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  let monthAbbreviation = monthNames[parseInt(month, 10) - 1];
              
                  // Format the label as 'AbbreviatedMonth-Year'
                  return `${monthAbbreviation}-${year}`;
                }
                else if(timeType.toLowerCase() === 'week'){
                  let [year, week] = value.split('-');
                  return `Week-${week} ${year}`
                }
                else{
                  let [year, quarter] = value.split('Q');
                  return `${year} Q${quarter}`
                }
              }
              else{
                return null;
              }
            }
          }
        },
        legend: {
          data: ['Current Year', 'Previous Year'],
          orient: 'horizontal',  
          left: 0,
          bottom: 0,
          itemWidth: 15,
          itemHeight: 15,
          icon: 'rect' 
        },
        tooltip: {
          formatter: function (params) {
            const dataIndex = params.dataIndex;
            const formattedRevenue = params.value;
            let formattedTooltip : any = ``;
            if(params.seriesName === 'Current Year'){
              formattedTooltip = `${convertToMonthName(xg1[dataIndex])} ${formattedRevenue}`;
            }
            else{
              formattedTooltip = `${convertToMonthName(xg1[dataIndex], true)} ${formattedRevenue}`;
            }
            return formattedTooltip;
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              if (value >= 1e9) {
                  return (value / 1e9) + 'B';
              }
              if (value >= 1e6) {
                  return (value / 1e6) + 'M';
              }
              if (value >= 1e3) {
                  return (value / 1e3) + 'k';
              }
              return value;
          },
          },
        },
        series: [
          {
            data: yg2,
            type: 'line',
            name: 'Current Year',
            itemStyle: {
              color: '#8280FF'
            }
          },
          {
            data: py2,
            name: py2.length !== 0 ? 'Previous Year' : '',
            type: 'line',
            itemStyle: {
              color: '#ADCCFF'
            }
          }
        ]
      };
  
      let data: any ={};
      let previousYearCustomer: any = null;
      let previousYearSale: any = null;
      data.sales = {
        currentValue: {
          value: xg1[xg1.length - 1],
          legend: yg1[yg1.length - 1]
        },
        previousValue: {
          value: xg1[xg1.length - 2],
          legend: yg1[yg1.length - 2]
        },
        previousYear: {
          value: null,
          legend: null
        }
      };
      this.selectedMonth = xg1[xg1.length - 1];
      previousYearSale = this.generatePreviousYearSameMonth(xg1[xg1.length - 1], xg1, 'sale');
      if(previousYearSale !== -1){
        // data.sales.previousYear.value = xg1[previousYearSale];
        // data.sales.previousYear.legend = yg1[previousYearSale];
        data.sales.previousYear.value = previousYearSale['group_value'];
        data.sales.previousYear.legend = previousYearSale['amount'];
      }
  
      data.customer = {
        currentValue: {
          value: xg2[xg2.length - 1],
          legend: yg2[yg2.length - 1]
        },
        previousValue: {
          value: xg2[xg2.length - 2],
          legend: yg2[yg2.length - 2]
        },
        previousYear: {
          value: null,
          legend: null
        }
      };
      previousYearCustomer = this.generatePreviousYearSameMonth(xg2[xg2.length - 1], xg2, 'customer');
      if(previousYearCustomer !== -1){
        // data.customer.previousYear.value = xg2[previousYearCustomer];
        // data.customer.previousYear.legend = yg2[previousYearCustomer];
        data.customer.previousYear.value = previousYearCustomer['group_value'];
        data.customer.previousYear.legend = previousYearCustomer['customer_count'];
      }
      this.createBarCharts(data);
    }
    else{
      this.totalSalesLinerChart = {
        graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
      };
      this.totalCustomerLineChart = {
        graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
      };
      this.totalCustomerComparison = {
        graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
      };
      this.totalSalesComparison = {
        graphic: {
          elements: [{
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                  text: 'No Data Available',
                  font: '18px Arial, sans-serif',
                  fill: '#999'
              }
          }]
        }
      };
      this.totalSalesPieChart = {
        graphic: {
          elements: [{
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                  text: 'No Data Available',
                  font: '18px Arial, sans-serif',
                  fill: '#999'
              }
          }]
        }
      };
      this.totalCustomerPieChart = {
        graphic: {
          elements: [{
              type: 'text',
              left: 'center',
              top: 'center',
              style: {
                  text: 'No Data Available',
                  font: '18px Arial, sans-serif',
                  fill: '#999'
              }
          }]
        }
      };
      this.avgSpendPerVisitLinearChart = {
        graphic: {
            elements: [{
                type: 'text',
                left: 'center',
                top: 'center',
                style: {
                    text: 'No Data Available',
                    font: '18px Arial, sans-serif',
                    fill: '#999'
                }
            }]
        }
      };
      this.totalRevenuePie[0].count = '0';
      this.totalRevenuePie[1].count = '0';
      this.totalCustomerPie[0].count = '0';
      this.totalCustomerPie[1].count = '0';
    }
  }

  generatePreviousYearSameMonth(targetDate, data, type) {

    //NEED TO CHECK SOME THING COMMENTING FOR NOW

    // if(this.totalCustomerRevenueTimeFrame.toLowerCase() === 'month' || this.totalCustomerRevenueTimeFrame.toLowerCase() === 'week'){
    //   const [year, month] = targetDate?.split('-').map(Number); // Extract month and year
    //   const previousYear = year - 1; // Calculate the previous year
    
    //   // Generate 'MM-YYYY' format for previous year with the same month
    //   const previousYearSameMonth = `${previousYear}-${month.toString().padStart(2, '0')}`;
    //   let index = data.indexOf(previousYearSameMonth);
    //   return index;
    // }
    // else if (this.totalCustomerRevenueTimeFrame.toLowerCase() === 'quarter'){
    //   const [year, quarter] = targetDate?.split('Q').map(Number); // Extract month and year
    //   const previousYear = year - 1; // Calculate the previous year
    
    //   // Generate 'MM-YYYY' format for previous year with the same month
    //   const previousYearSameMonth = `${previousYear}Q${quarter}`;
    //   let index = data.indexOf(previousYearSameMonth);
    //   return index;
    // }
    let previousData = type === 'customer' ? this.previousYearDataCustomer : this.previousYearDataSales;
    if(this.totalCustomerRevenueTimeFrame.toLowerCase() === 'month' || this.totalCustomerRevenueTimeFrame.toLowerCase() === 'week'){
      const [year, month] = targetDate?.split('-').map(Number); // Extract month and year    
      // Generate 'MM-YYYY' format for previous year with the same month
      const previousYearSameMonth = `${year}-${month.toString().padStart(2, '0')}`;
      let index = -1;
      if(previousData){
        index = previousData.findIndex(item => item.group_value === previousYearSameMonth)
      }
      return index !== -1 ? previousData[index] : index;
    }
    else if (this.totalCustomerRevenueTimeFrame.toLowerCase() === 'quarter'){
      const [year, quarter] = targetDate?.split('Q').map(Number); // Extract month and year
    
      // Generate 'MM-YYYY' format for previous year with the same month
      const previousYearSameMonth = `${year}Q${quarter}`;
      let index = previousData.findIndex(item => item.group_value === previousYearSameMonth);
      return index !== -1 ? previousData[index] : index;
    }
  }

  selectAllOptions(key, value) {
    if (this.selectedFilter[key].length === value.length) {
      this.selectedFilter[key] = [];
    } else {
      if(key !== 'Segment')
        this.selectedFilter[key] = [...value];
      else {
        let valueSegment = [];
        value.forEach((data)=>{
          valueSegment.push(data.id);
        });
        this.selectedFilter[key] = valueSegment;
      }
    }
  }

  getFilters(){
    this.dataLoading = true;
    this.availableFilter = {};
    this.selectedFilter = {};
     this.getAllFiltersApi(this.totalCustomerRevenueTimeFrame.toLowerCase()).then((result) => {
      this.dataLoading = false;
      if (result) {
        let data = result["body"];
        // this.createTotalCustomerSalesGraph(result['body']['totalSales'], result['body']['totalCustomers']);
        for (let d in data) {
          console.log(d, data[d]);
          if (d !== "Segment") {
            this.availableFilter[d] = data[d].sort();
            this.selectedFilter[d] = [];
            if(d == 'group_value'){
              this.availableMonths = data[d];
            }
          } else {
              this.availableFilter[d] = this.getSegmentData(data[d]).sort();
              this.selectedFilter[d] = [];
          }
        }
        this.selectedStartPeriod = this.availableFilter['group_value'][this.availableFilter['group_value'].length >12 ? this.availableFilter['group_value'].length - 12 - 1 : 0];
        this.tempSelectedStart = this.selectedStartPeriod;
        this.selectedEndPeriod = this.availableFilter['group_value'][this.availableFilter['group_value'].length - 1];
        this.tempSelectedEnd = this.selectedEndPeriod;
        for(let i = this.availableFilter['group_value'].indexOf(this.selectedStartPeriod), j = this.availableFilter['group_value'].indexOf(this.selectedEndPeriod); i<=j; i++){
            this.selectedFilter['group_value']?.push(this.availableFilter['group_value'][i]);
        }
        // this.selectedFilter['group_value']?.push(this.availableFilter['group_value'][0]);
        // this.selectedFilter['group_value']?.push(this.availableFilter['group_value'][this.availableFilter['group_value'].length >12 ? 12 : this.availableFilter['group_value'].length - 1]);
        console.log(this.availableFilter, this.selectedFilter);
        this.getAnalyticsForTotalCustomerSales(false);
      }
    }, (error)=>{
        this.dataLoading = false;
        this.isNoDataAvailable = true;
    });
  }

  getDateChangeData(){
    this.selectedFilter['group_value'] = [];
        for(let i = this.availableFilter['group_value'].indexOf(this.selectedStartPeriod), j = this.availableFilter['group_value'].indexOf(this.selectedEndPeriod); i<=j; i++){
          this.selectedFilter['group_value']?.push(this.availableFilter['group_value'][i]);
        }
      // this.selectedFilter['group_value']?.push(this.availableFilter['group_value'][0]);
      // this.selectedFilter['group_value']?.push(this.availableFilter['group_value'][this.availableFilter['group_value'].length >12 ? 12 : this.availableFilter['group_value'].length - 1]);
      console.log(this.availableFilter, this.selectedFilter);
      this.getAnalyticsForTotalCustomerSales(false);
  }

  getSegmentData(data) {
    let customerSegment = [];
    this._apiCall.getAllCustomerSegments().subscribe(
      (response) => {
        if (response["message"] == "success") {
          let segment = (response["body"]);
          for (let i = 0; i < segment.length; i++) {
            if(data.indexOf(segment[i]["id"]) !== -1){
              customerSegment.push({
                id: segment[i]["id"],
                name: segment[i]["segmentName"],
                // isActive: segment[i]["isActive"],
                // status: false,
                // category: segment[i]["category"],
              });
            }
          }

        } else {
          this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform("Failed to load customer segments", ['POS_PAGE']), 1000);
          return [];
        }
      },
      (err) => { 
        this.snackbar.openSnackBar(this._i18nDynamicTranslate.transform(err.error.body, ['POS_PAGE']), 1000);
        return [];
      }
    );
    return customerSegment;
  }

  applyFilter(){
    let data = {
      stringFilters: [],
      stringValuesToCheck: [],
      doubleFilters: [],
      doubleValuesToCheck: [],
      listFilters: [],
      listValuesToCheck: []
    };

    for (let value in this.selectedFilter) {
      if (this.selectedFilter[value].length !== 0) {
        if (value !== "tier_level" && value !== "Segment") {
          data.stringFilters.push(value);
          data.stringValuesToCheck.push(this.selectedFilter[value]);
        } else if(value ==='Segment'){
          data.listFilters.push(value);
          data.listValuesToCheck.push(this.selectedFilter[value]);
        } 
        else {
          data.doubleFilters.push(value);
          data.doubleValuesToCheck.push(this.selectedFilter[value]);
        }
      }
    }

    console.log(data);
    return data;

  }

  applyTimeFrameFilters(){
    let data = {
      stringFilters: [],
      stringValuesToCheck: [],
      doubleFilters: [],
      doubleValuesToCheck: [],
      listFilters: [],
      listValuesToCheck: []
    };

    for (let value in this.selectedFilter) {
      if (this.selectedFilter[value].length !== 0) {
        if (value !== "tier_level" && value !== "Segment") {
          if(value ==='group_value'){
            data.stringFilters.push(this.totalCustomerRevenueTimeFrame.toLowerCase());
          }
          else{
            data.stringFilters.push(value);
          }
          data.stringValuesToCheck.push(this.selectedFilter[value]);
        } else if(value ==='Segment'){
          data.listFilters.push(value);
          data.listValuesToCheck.push(this.selectedFilter[value]);
        } 
        else {
          data.doubleFilters.push(value);
          data.doubleValuesToCheck.push(this.selectedFilter[value]);
        }
      }
    }

    console.log(data);
    return data;
  }

  getDisplayFilterName(value){
    switch(value){
      case "PointsBucket" :
        return "Loyalty Points Bucket";
      case "Segment" :
        return "Segment Name";
      case "age_bucket" :
        return "Age Bucket";
      case "customerAge" :
        return "Customer's Age";
      case "tier_level" :
        return "Tier Level";
      case "tier_name":
        return "Tier Name";
      case "storeName":
        return "Store Name";
      case "category":
        return "Product Category";
      case "cltv_bucket": 
        return "Cltv Bucket"
      default:
        return value.toLowerCase().replace(/\b\w/g, function(char) {
          return char.toUpperCase();
        });;
    }
  }

  onSliderInput(){
    
  }

  // get selectedStringRange(): string {
  //   const startValue = this.availableFilter['month'][this.selectedStartIndex];
  //   const endValue = this.availableFilter['month'][this.selectedEndIndex];
  //   return `${startValue} - ${endValue}`;
  // }

  displayStringLabel(index: number): string {
    return this.availableFilter['month'][index];
  }

    convertToMonthName(value) {
    if (value) {
      if (this.totalCustomerRevenueTimeFrame.toLowerCase() === "month") {
        let [year, month] = value.split("-");

        // Convert numeric month to three-letter abbreviation
        let monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        let monthAbbreviation = monthNames[parseInt(month, 10) - 1];

        // Format the label as 'AbbreviatedMonth-Year'
        return `${monthAbbreviation}-${year}`;
      } else if (this.totalCustomerRevenueTimeFrame.toLowerCase() === "week") {
        let [year, week] = value.split("-");
        let monthConvertedData = this.globalService.getStartOfWeekByYearWeek(
          year,
          week
        );
        return `${this.globalService.formatDateToCustomDateString(monthConvertedData)} (Week-${week} ${year})`;
      } else {
        let [year, quarter] = value.split("Q");
        return `${year} Q${quarter}`;
      }
    } else {
      return null;
    }
  }
  openInsight(type){
    const dialogref = this.dialog.open(
      InsightGraphDialogBoxComponent,
      {
        panelClass: "no-padding-dialog-popup",
        width: "500px",
        height : "500px",
        data: {
          type : type
        }
      }
    );
  }

  totalCustomerSaleAnalyticsApi(data, type){
    return new Promise((resolve, reject) => {
      this._apiCall.getReportChart(data, type, "POST", this.totalCustomerRevenueTimeFrame.toLowerCase()).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

  getAllFiltersApi(type){
    return new Promise((resolve, reject) => {
      this._apiCall.getFilterForAnalytics(type).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);

          reject(false);
        }
      );
    });
  }

}
