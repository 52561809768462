<div style="width: 100%">
  <div
    style="height: 30px; width: 100%; overflow: hidden"
    *ngIf="
      syncMsg.length > 0 && lastSyncBannerDismissed === false && !dataLoading
    "
  >
    <div
      style="display: flex; justify-content: space-between; padding: 8px"
      [style.background]="bannerColor"
    >
      <div></div>
      <div style="color: white; text-align: center; font-size: 13px">
        {{ syncMsg }}
      </div>
      <mat-icon
        class="banner-icon"
        data-widget-name="LASTSYNC"
        data-button-name="last sync-close"
        attr.data-item-name="Safari banner "
        (click)="updateLastsyncBanner()"
      >
        close</mat-icon
      >
    </div>
  </div>
</div>
