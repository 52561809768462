<div class="qb-h-100">
    <app-last-sync-barriers [type]="'analytics_dashboard'" [dataLoading]="dataLoading"></app-last-sync-barriers>
    <div class="activity-spinner-container" *ngIf="dataLoading">
        <mat-spinner diameter="45"></mat-spinner>
    </div>
    <div class="qb-analytics-main-container" *ngIf="!dataLoading">
        <div class="qb-analytics-main-sub-container">
            <app-breadcrumb></app-breadcrumb>
            <div class="graph-filters">
                <div class="qb-each-filter">
                    <h6 class="graph-options-text">View By:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="timeFrame" (selectionChange)="totalCustomerRevenueTimeFrameDropDownHandler()">
                            <mat-option *ngFor="let val of totalCustomerRevenueFrameList" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-each-filter" *ngIf="availableOrderType.length > 0 && (availableOrderType.length !== 1 && availableOrderType[0].toLowerCase() !== 'unknown')">
                    <h6 class="graph-options-text">Selected Type:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="selectedType" (selectionChange)="typeChangeHandler()">
                            <mat-option *ngFor="let val of availableOrderType" [value]="val" selected>{{val}}</mat-option>
                        </mat-select>
                    </div>
                </div>
                <div class="qb-each-filter" *ngIf="availableMonths.length > 0">
                    <h6 class="graph-options-text">Selected {{timeFrame}}:</h6>
                    <div class="matSelect-filters">
                        <mat-select class="margin-right-10" [(ngModel)]="selectedMonth" (selectionChange)="monthChangeHandler()">
                            <mat-option *ngFor="let val of availableMonths" [value]="val" selected>{{convertToMonthName(val)}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
            <div class="qb-analytics-data-container">
                <div  class="qb-analytics-graph-container qb-flex-1">
                    <h6 class="qb-analytics-heading">Top 10 Products by Order<span class="qb-i-div"
                        popoverclass="qb-my-custom-class"
                        placement="auto"
                        [ngbPopover]="popTierIamgeSetup1"
                        triggers="mouseenter:mouseleave"
                        >i
                    </span></h6>
                    <ng-template #popTierIamgeSetup1>
                        <div class="qb-ng-template-text">
                            Explore sales impact with our Top 10 Products Graph. Each bar on the y-axis represents a top-performing product, showcasing its total sales on the x-axis. Quickly identify and analyze the most lucrative products during the specified time period for strategic decision-making
                        </div>
                    </ng-template>
                    <div echarts [options]="top10Customer" style="height: 400px; width: 100%;"></div>               
                </div>
            </div>
        </div>
    </div>
</div>