<div style="display: flex;flex-direction:column;justify-content: space-between;height: 100%">

  <div  *ngIf="!dataBean"  style="    display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px 15px;
  height: 40px;">
    <div class=" tree-title" >{{'SHARED_PAGE.ADD_FOR_SPECIFIC_CATEGORY' | i18nTranslate}}</div>
    <mat-icon style="  color: #efefef;
    background: #a9a9a9;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          display: flex;
          justify-content: center;
          align-items: center;cursor: pointer;" (click)="cancel()" data-button-name="Close">cancel</mat-icon>
  </div>

  <div
    class="formDiv-search"
    style="
      border-radius: 4px;
      background: #f8f8f8;
      margin: 0px 15px;
      height: 40px;
    "
  >
    <input
      class="formInput"
      autocomplete="off"
      matInput
      [(ngModel)]="search"
      placeholder="Search by name"
    />

    <span
      class="material-icons-outlined icons-primary cursor-pointer"
      *ngIf="search.length == 0"
    >
      search
    </span>
    <span
      class="material-icons-outlined icons-primary cursor-pointer"
      style="font-size: 19px"
      *ngIf="search.length > 0"
      (click)="search = ''"
    >
      close
    </span>
  </div>

  <div
    style="
      flex-grow: 1;
      overflow: auto;
      height: calc(100% - 100px);
      padding: 0px 15px;
      margin: 10px 0px;
    "
    *ngIf="!loading"
  >
    <div
      style="margin-bottom: 15px"
      *ngIf="
        search.length < 3 &&
        (databean.allCollection | labelPipe : search : 'collectionName')
          .length > 0 &&
        !loading
      "
    >
      <mat-checkbox
        class="example-margin"
        [checked]="
          selectedItems.length === databean.allCollection.length &&
          databean.allCollection.length != 0
        "
        (change)="selectAll($event.checked)"
        color="primary"
      >
        <div style="display: flex">
          <div>{{ "SHARED_PAGE.SELECT_ALL" | i18nTranslate }}</div>

          <!-- <span
        class="i-div"
        popoverClass="my-custom-class"
        placement="right"
        [ngbPopover]="popSelctAll"
        triggers="mouseenter:mouseleave"
        >i
        </span> -->
          <ng-template #popSelctAll>
            <div style="font-size: 12px; color: #fff">
              {{ "SHARED_PAGE.SELECT_ALL_INFO" | i18nTranslate }}
            </div>
          </ng-template>
        </div>
      </mat-checkbox>
    </div>
    <hr
      *ngIf="
        search.length < 3 &&
        (databean.allCollection | labelPipe : search : 'collectionName')
          .length > 0 &&
        !loading
      "
    />
    <div
      *ngFor="
        let product of databean.allCollection
          | labelPipe : search : 'collectionName';let i= index
      "
    >
      <!-- <mat-checkbox
        class="example-margin"
        [checked]="selectedItems.indexOf(product.collectionId) != -1"
        (change)="setAll($event.checked, product)"
        color="primary"
      >
        {{ product.collectionName }}
      </mat-checkbox> -->
      <div style="display: flex">
        <div *ngIf="product?.subCategory && product?.subCategory.length > 0">
          <span
            class="material-icons-outlined parent-arrow"
            (click)="parentArrowClicked(i); getDataForTable($event)"
          >
            chevron_right
          </span>
        </div>

        <!-- [indeterminate]="someComplete(product)"  -->
        <section class="example-section">
          <span class="parent-product-ln">
            <mat-checkbox
              class="example-margin"
              #myCheckbox
              [checked]="ifProductChecked(product)"
              [indeterminate]="ifProductPartialyChecked(product)"
              (change)="
                setAllChecked($event.checked, product.subCategory, product)
              "
              color="primary"
            >
            {{ product.collectionName }}
            </mat-checkbox>
          </span>
          <span class="variant-product-ln active" [id]="'category-section-' + i">
            <div>
              <div *ngFor="let variant of product['subCategory']">
                <mat-checkbox
                  color="primary"
                  [checked]="varaintProductIdArray.indexOf(variant.collectionId) != -1"
                  (change)="
                    updateSelection(
                      variant.collectionId,
                      product.collectionId,
                      $event.checked,
                      variant
                    )
                  "
                >
                  {{ variant.collectionName }}
                </mat-checkbox>
              </div>
            </div>
          </span>
        </section>
      </div>
    </div>
    <div
      *ngIf="
        (databean.allCollection | labelPipe : search : 'collectionName')
          .length < 1 && !loading
      "
    >
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(34, 34, 34, 0.6);
          font-style: italic;
        "
      >
        <span style="font-size: 14px">No Category Found</span>
      </div>
    </div>

    <div
      *ngIf="loading"
      style="
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
      "
    >
      <mat-spinner style="margin: auto" diameter="15"></mat-spinner>
    </div>
  </div>

  <div
    style="
      height: 60px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      padding: 0px 15px;
      box-shadow: 2px -2px 20px rgb(0 0 0 / 8%);
    "
  >
    <div class="cancel-btn" (click)="cancel()">
      {{ "SHARED_PAGE.CANCEL" | i18nTranslate }}
    </div>
    <div class="primary-button" (click)="save()">
      {{ "SHARED_PAGE.SAVE" | i18nTranslate }}
    </div>
  </div>
  <div></div>
</div>
