<div style="height: 100%; width: 100%; position: relative; overflow: hidden">
  <div id="header-tab">
    <div class="header">
      <span style="text-align: center">Email Template Creation</span>
      <mat-icon
        class="closeIcon"
        (click)="editorSatus.toLowerCase() === 'saved!' && closeEditor()"
        [matTooltip]="
          editorSatus.toLowerCase() !== 'saved!'
            ? 'Saving in progress.'
            : 'Close'
        "
        [ngStyle]="{
          'pointer-events':
            editorSatus.toLowerCase() !== 'saved!' ? 'none' : 'auto',
          opacity: editorSatus.toLowerCase() !== 'saved!' ? '0.5' : '1'
        }"
      >
        close
      </mat-icon>
    </div>
  </div>
  <div
    #fullContainer
    style="
      height: 100%;
      overflow-x: hidden;
      width: 100%;
      padding: 30px 20px 10px 20px;
    "
    id="full-container-email"
  >
    <div id="sample-listing" style="display: none">
      <app-email-sample-listing
        (templateData)="shwoTemplate($event)"
        [isEditorLoaded]="isEditorLoaded"
      ></app-email-sample-listing>
    </div>
    <form
      [formGroup]="templateForm"
      style="width: 100%; height: 100%; display: none"
      id="qb-bee-editor-container"
    >
      <div class="header-container">
        <div
          style="
            width: 40%;
            margin-left: 2%;
            display: flex;
            flex-direction: column;
          "
          *ngIf="urlData.type !== 'REWARD' && urlData.type !== 'SURVEYEMAIL'"
        >
          <!-- <div style="display: flex; align-items: baseline; height: 90%">
            <span
              class="fontExo2"
              style="color: #fff; font-size: 13px; margin-right: 1%"
              >{{'SHARED_PAGE.EMAIL_TEMPLATE_NAME' | i18nTranslate}}</span
            >
            <mat-form-field class="nameLabel" appearance="outline">
              <mat-label></mat-label>
              <input
                matInput
                #inpName
                style="text-align: center"
                class="fontExo2"
                type="text"
                formControlName="TemplateName"
              />
              <mat-error
                class="validationText"
                *ngIf="
                  !submitclicked &&
                  templateForm.controls.TemplateName.touched &&
                  templateForm.controls.TemplateName.hasError('required')
                "
              >
                {{'SHARED_PAGE.EMAIL_TEMPLATE_NAME_REQ' | i18nTranslate}}</mat-error
              >
              <mat-error
                class="validationText"
                *ngIf="
                  submitclicked &&
                  templateForm.controls.TemplateName.hasError('required')
                "
                >{{'SHARED_PAGE.NAME_IS_REQ' | i18nTranslate}}
              </mat-error>
              <mat-error
                class="validationText"
                *ngIf="
                  templateForm.controls.TemplateName.dirty &&
                  templateForm.controls.TemplateName.errors?.minlength
                "
              >
              {{'SHARED_PAGE.MINIMUM_CHARACTER' | i18nTranslate}}
              </mat-error>
              <mat-error
                class="validationText"
                *ngIf="
                  templateForm.controls.TemplateName.dirty &&
                  templateForm.controls.TemplateName.errors?.maxlength
                "
              >
                {{'SHARED_PAGE.MAXIMUM_ALLOWED' | i18nTranslate}}
              </mat-error>
            </mat-form-field>
          </div> -->

          <div style="display: flex; height: 100%">
            <div *ngIf="!isTemplateNameEdit" class="qb-d-flex">
              <button class="qb-stale" (click)="isTemplateNameEdit = true">
                <span class="qb-name-stale">
                  {{ templateName }}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5F6368"
                >
                  <path
                    d="M200-200h57l391-391-57-57-391 391v57Zm-80 80v-170l528-527q12-11 26.5-17t30.5-6q16 0 31 6t26 18l55 56q12 11 17.5 26t5.5 30q0 16-5.5 30.5T817-647L290-120H120Zm640-584-56-56 56 56Zm-141 85-28-29 57 57-29-28Z"
                  />
                </svg>
              </button>
            </div>

            <div *ngIf="isTemplateNameEdit" class="qb-d-flex qb-ai-center">
              <div>
                <div class="qb-template-name-container">
                  <input
                    matInput
                    #inpName
                    style="text-align: center"
                    class="fontExo2 qb-template-name-class"
                    type="text"
                    formControlName="TemplateName"
                  />

                  <mat-error
                    class="validationText"
                    *ngIf="
                      !submitclicked &&
                      templateForm.controls.TemplateName.touched &&
                      templateForm.controls.TemplateName.hasError('required')
                    "
                  >
                    {{
                      "SHARED_PAGE.EMAIL_TEMPLATE_NAME_REQUIRED" | i18nTranslate
                    }}</mat-error
                  >
                  <mat-error
                    class="validationText"
                    *ngIf="
                      submitclicked &&
                      templateForm.controls.TemplateName.hasError('required')
                    "
                    >{{ "SHARED_PAGE.NAME_REQUIRED" | i18nTranslate }}
                  </mat-error>
                  <mat-error
                    class="validationText"
                    *ngIf="
                      templateForm.controls.TemplateName.dirty &&
                      templateForm.controls.TemplateName.errors?.minlength
                    "
                  >
                    {{ "SHARED_PAGE.MINIMIMUM_3_CHARACTER" | i18nTranslate }}
                  </mat-error>
                  <mat-error
                    class="validationText"
                    *ngIf="
                      templateForm.controls.TemplateName.dirty &&
                      templateForm.controls.TemplateName.errors?.maxlength
                    "
                  >
                    {{ "SHARED_PAGE.MAXIMUM_50_CHARACTER" | i18nTranslate }}
                  </mat-error>
                  <mat-error
                    class="validationText"
                    *ngIf="
                      templateForm.controls.TemplateName.dirty &&
                      templateForm.controls.TemplateName.errors?.pattern
                    "
                  >
                    {{ "SHARED_PAGE.VALID_TEMPLATE_NAME" | i18nTranslate }}
                  </mat-error>
                </div>
              </div>
              <div class="qb-actions">
                <button class="qb-action-confirm" (click)="nameChange(true)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#fff"
                  >
                    <path
                      d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"
                    />
                  </svg>
                </button>
                <button class="qb-action-cancel" (click)="nameChange(false)">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path
                      d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="qb-d-flex qb-ai-center qb-action-icons">
              <div class="qb-icon-divider"></div>
              <span>
                <button
                  (click)="previewEmailTemplate()"
                  class="qb-preview-email"
                  matTooltip="Preview Template"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5F6368"
                  >
                    <path
                      d="M480-320q75 0 127.5-52.5T660-500q0-75-52.5-127.5T480-680q-75 0-127.5 52.5T300-500q0 75 52.5 127.5T480-320Zm0-72q-45 0-76.5-31.5T372-500q0-45 31.5-76.5T480-608q45 0 76.5 31.5T588-500q0 45-31.5 76.5T480-392Zm0 192q-146 0-266-81.5T40-500q54-137 174-218.5T480-800q146 0 266 81.5T920-500q-54 137-174 218.5T480-200Zm0-300Zm0 220q113 0 207.5-59.5T832-500q-50-101-144.5-160.5T480-720q-113 0-207.5 59.5T128-500q50 101 144.5 160.5T480-280Z"
                    />
                  </svg>
                </button>
              </span>
              <span>
                <button
                  (click)="openSendTestEmailDialog()"
                  class="qb-send-test-email"
                  matTooltip="Send Test Email"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5F6368"
                  >
                    <path
                      d="M120-160v-640l760 320-760 320Zm80-120 474-200-474-200v140l240 60-240 60v140Zm0 0v-400 400Z"
                    />
                  </svg>
                </button>
              </span>
              <span>
                <button
                  (click)="createSharableLink()"
                  class="qb-send-test-email"
                  matTooltip="Email Preview Link"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path
                      d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z"
                    />
                  </svg>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            margin-left: 2%;
            display: flex;
            flex-direction: column;
          "
          *ngIf="urlData.type === 'REWARD'"
        >
          <div style="display: flex; align-items: center; width: 50%">
            <span
              class="fontExo2"
              style="color: #fff; font-size: 13px; margin-right: 1%"
              >{{ "SHARED_PAGE.EMAIL_SUBJECT" | i18nTranslate }}</span
            >
            <div
              style="width: 100%"
              class="formDiv fontExo2"
              [ngClass]="{
                active: templateForm['controls']['subject']['errors']
                  ? (templateForm['controls']['subject']['errors'][
                      'required'
                    ] &&
                      templateForm['controls']['subject']['touched']) ||
                    submitClicked
                  : false
              }"
            >
              <input
                #inpSubject
                class="formInput fontExo2"
                type="text"
                formControlName="subject"
                maxlength="50"
              />
            </div>
          </div>
          <div
            style="
              display: flex;
              height: 30px;
              width: 50%;
              align-items: flex-end;
            "
          >
            <span
              class="fontExo2"
              style="
                margin-left: 5px;
                font-size: 12px;
                color: #ff5f31;
                justify-content: flex-end;
              "
              *ngIf="
                templateForm['controls']['subject']['errors']
                  ? (templateForm['controls']['subject']['errors'][
                      'required'
                    ] &&
                      templateForm['controls']['subject']['touched']) ||
                    submitClicked
                  : false
              "
              >{{ "SHARED_PAGE.FIELD_CANNOT_BE_EMPTY" | i18nTranslate }}</span
            >
          </div>
        </div>
        <div
          style="width: 40%; display: flex; justify-content: flex-end"
          *ngIf="urlData.id === 'null'"
        >
          <!-- <div
            style="
              height: 35px;
              width: 80%;
              background: #505659;
              display: flex;
              margin-right: 3%;
              font-size: 12px;
            "
          >
            <div
              style="border-radius: 4px 0px 0px 4px"
              (click)="selectTemplate('T1')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T1' }"
            >
              <span class="fontExo2">{{'SHARED_PAGE.LAYOUT' | i18nTranslate}} 1</span>
            </div>
            <div
              (click)="selectTemplate('T2')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T2' }"
            >
              <span class="fontExo2">{{'SHARED_PAGE.LAYOUT' | i18nTranslate}} 2</span>
            </div>
            <div
              (click)="selectTemplate('T3')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T3' }"
            >
              <span class="fontExo2">{{'SHARED_PAGE.LAYOUT' | i18nTranslate}} 3</span>
            </div>
            <div
              style="border-radius: 0px 4px 4px 0px"
              (click)="selectTemplate('T4')"
              class="template-selector"
              [ngClass]="{ active: selectedTemplate === 'T4' }"
            >
              <span class="fontExo2">{{'SHARED_PAGE.LAYOUT' | i18nTranslate}} 4</span>
            </div>
          </div> -->
        </div>
        <div class="close-container qb-save-status"></div>
        <div
          class="close-container"
          style="gap: 10px"
          [ngStyle]="urlData.id !== 'null' ? { width: '50%' } : null"
        >
          <!-- <div
            style="
              color: #68b8e5;
              display: flex;
              margin: 23px 30px 20px 0;
              cursor: pointer;
            "
          >
            <div>
              <div
                class="material-icons"
                style="margin: -1px 10px; font-size: 12px"
              >
                thumb_up_alt
              </div>
            </div>
            <div style="font-size: 12px">
              <u
                ><a
                  href="https://www.skellam.ai/blog/10-ways-to-avoid-sending-spam-emails"
                  target="_blank"
                  class="spam-link"
                  >How to avoid sending spam emails</a
                ></u
              >
            </div>
          </div> -->
          <!-- <button 
           style="
            display: flex;
            background: #4cb9ea;
            border: none;
            color: white;
            padding: 7px 12px;
            border-radius: 5px;
            font-size: 12px;
            margin-right: 20px;
            gap: 5px;
        "
                 (click)="createEmail()" *ngIf="!router.url.includes('/app/email/layout-editor;') && tokenStorage.getChatGPT() === 'true'">
                  <div>
                    <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/icons/chat-gpt/chat-bot.png" width="15">
                  </div>
                  <div>{{'POS_PAGE.GENERATE_EMAIL_USING_AI' | i18nTranslate}}</div>
          </button>
          <button
            (click)="openSendTestEmailDialog()"
            style="
              background: #4cb9ea;
              border: none;
              color: white;
              padding: 7px 12px;
              border-radius: 5px;
              font-size: 12px;
              margin-right: 20px;
            "
          >
            {{'SHARED_PAGE.SEND_TEST_EMAIL' | i18nTranslate}}
          </button> -->
          {{ editorSatus }}
          <mat-icon (click)="closeEditor()" class="closeIcon"> close </mat-icon>
        </div>
      </div>
      <div
        style="width: 100%; height: calc(100% - 80px); min-height: 570px"
        id="bee-plugin-container"
      ></div>
    </form>
  </div>
  <div *ngIf="showSpinner" class="loader-class">
    <mat-spinner diameter="25"></mat-spinner>
  </div>
</div>
