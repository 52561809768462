import { DatePipe } from "@angular/common";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { ApiCallService } from "src/app/core/services/api-call-service";
import { getdateformatService } from "src/app/core/services/get-date-format.service";
import { Globals } from "src/app/core/services/global.service";

@Component({
  selector: "app-last-sync-barriers",
  templateUrl: "./last-sync-barriers.component.html",
  styleUrls: ["./last-sync-barriers.component.css"],
})
export class LastSyncBarriersComponent implements OnInit {
  constructor(
    private _apiCall: ApiCallService,
    private globalService: Globals,
    private datePipe: DatePipe,
    private date_format: getdateformatService
  ) {}

  syncMsg = "";
  lastSyncBannerDismissed = false;
  bannerColor = "#4880FF";

  @Input() type: string;
  @Input() dataLoading: boolean;
  ngOnInit(): void {
    this.getLastSyncData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["type"]) {
      this.lastSyncBannerDismissed = false;
      this.getLastSyncData();
    }
  }

  getDateFormat(type?) {
    return this.date_format.getDateFormat(type);
  }

  getLastSyncData() {
    this.getLastSyncDataApi(this.type).then(
      (result) => {
        console.log(result);
        let date = this.globalService.convertArrayToDateString(result["body"]);
        this.syncMsg = `Last update date: ${this.datePipe.transform(
          date,
          this.getDateFormat("MMM dd yyyy")
        )}`;
        this.bannerColor = this.globalService.isDataSyncedWithin7Days(date)
          ? "#4880FF"
          : "#FF4C4C";
      },
      (err) => {
        this.syncMsg = err.body;
        this.bannerColor = "#FF4C4C";
      }
    );
  }

  updateLastsyncBanner(){
    this.lastSyncBannerDismissed = true;
  }

  getLastSyncDataApi(type) {
    return new Promise((resolve, reject) => {
      this._apiCall.getLastSync(type).subscribe(
        (res) => {
          console.log(res);
          resolve(res);
        },
        (err) => {
          console.log(err.error);
          reject(err.error);
        }
      );
    });
  }
}
