<div *ngIf="isLoading" class="loading-overlay">
  <app-qubriux-loading [size]="65" [loadingText]="'Loading data...'"></app-qubriux-loading>
</div>

<div class="page-container" *ngIf="!isLoading" >
    <div class="page-actions-row">
        <app-breadcrumb></app-breadcrumb>
        <button
              *ngIf="(validWidgets | widgetConfig: 11)"
              class="primary-button create-button-2"
              (click)="createCampaign()"
              data-button-name="Create New Journey">
                <mat-icon
                style="color: #fff; margin: 0; font-size: 16px; height: 18px">add
                </mat-icon>
                Create New Journey
        </button>
    </div>

    <div class="select-kpi-container">
        <div class="kpi-header">
          <div>
            <h6 class="kpi-title">Select KPI</h6>
            <p class="kpi-description">Choose the key performance indicator to analyze</p>
          </div>
          <mat-select
            class="kpi-dropdown"
            [(ngModel)]="selectedKPIforOverallAnalytics"
            (selectionChange)="KPIChange()"
            [placeholder]="hasData ? 'Select KPI' : 'No KPI found'"
          >
            <mat-option *ngFor="let metric of trackedMetrices" [value]="metric.value">
              {{ metric.displayName }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div *ngIf="hasData">

        <div class="path-analysis-results" *ngIf="winnerDetails">
          <div class="results-container">
            <div class="icon-column">
              <span class="material-symbols-outlined">emoji_events</span>
            </div>
  
            <div class="content-column">
              <h6 class="kpi-title">Path Analysis Results</h6>
              <p class="winner-text"> {{ winnerDetails.winnerText }}</p>
              <p class="uncertainty-text">
                {{ winnerDetails.improvementText }}
              </p>
            </div>
          </div>
        </div>
  
      <div class="main-list-container pos-rel main-white-panel">
          <div class="list-title-row">
              <div class="list-header-title">
                  {{ strategyData.strategyName }}
              </div>
          </div>
          <div class="table-view">
              <div class="list-column-header-row">
                  <div class="col-header wide-column-width">
                     Paths
                  </div>
                  <div class="col-header wide-column-width">
                      Unique conversions / Total customers
                  </div>
                  <div class="col-header">
                      Initial Conversion Rate 
                      <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="auto"
                      [ngbPopover]="popInitialConvertionrate"
                      triggers="mouseenter:mouseleave"
                      >i
                  </span>
                  </div>
                  <div class="col-header ">
                      Total Conversion Rate
                      <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="auto"
                      [ngbPopover]="popTotalConversionRate"
                      triggers="mouseenter:mouseleave"
                      >i
                  </span>
                  </div>
                  <div class="col-header ">
                      Total KPI Value
                      <span class="qb-i-div"
                      popoverclass="qb-my-custom-class"
                      placement="auto"
                      [ngbPopover]="popKPIValue"
                      triggers="mouseenter:mouseleave"
                      >i
                  </span>
                  </div>
              </div>
              <div class="main-list">
                  <!-- <div class="list-loader-container" *ngIf="listLoading">
                      <app-qubriux-loading  [size]="65" [loadingText]="'Fetching your list...'"></app-qubriux-loading>
                  </div> -->
                  <!-- <div *ngIf="!listLoading && abTestingCampaignList.length == 0" class="empty-list-indicator">
                      No Journey found
                  </div> -->
                  <!-- *ngIf="!listLoading && abTestingCampaignList.length > 0" -->
                  <div >
                      <!-- *ngFor="let abTestCamp of abTestingCampaignList" -->
                      <div *ngFor="let path of pathAnalysisList" class="list-item-row" [ngClass]="{'winner-row': path.isWinner}">
                          <div class="list-item wide-column-width">{{ path.pathName }}
                              <span *ngIf="path.isWinner" class="winner-tag">Winner</span>
                          </div>
                          
                          <div class="list-item wide-column-width">{{ path.uniqueConversions }} / {{ path.totalCustomers }}</div>
                          <div class="list-item">{{ path.initialConversionRate }}%</div>
                          <div class="list-item">{{ path.totalConversionRate }}%</div>
                          <div class="list-item">{{ path.totalConversions }}</div>
                      </div>
                  </div>
                  <!-- <div *ngIf="pathAnalysisList.length === 0" class="empty-list-indicator">
                      No paths found for the selected strategy.
                  </div> -->
              </div> 
          </div>
          <ng-template #popInitialConvertionrate>
              <div class="qb-ng-template-text">
                  The percentage of conversions from the initially unique customers who explored the path.
              </div>
          </ng-template>
          <ng-template #popTotalConversionRate>
              <div class="qb-ng-template-text">
                  The percentage of conversions from all unique customers who engaged with the path.
              </div>
          </ng-template>
          <ng-template #popKPIValue>
              <div class="qb-ng-template-text">
                  The cumulative value of the KPI generated by all customers in the path.
              </div>
          </ng-template>
  
            <div class="graph-analytics-container">
               <div class="graph-selector-container">
                  <div class="graph-type-selector">
                      <button
                        *ngFor="let option of graphOptions; let i = index"
                        class="graph-type-button"
                        [class.active]="selectedGraphIndex === i"
                        (click)="selectGraph(i)"
                      >
                        {{ option }}
                      </button>
                    </div>
               </div>
              <div class="graph-content">
                <div class="graph-info">
                  <div>
                      <h3 class="graph-title">{{ selectedGraphTitle }}</h3>
                      <p class="graph-description">{{ selectedGraphDescription }}</p>
                      
                  </div>
                  <div class="qb-targeting-tactics-icon" *ngIf="selectedGraphIndex === 1 || selectedGraphIndex === 2" >
                      <span
                      class="qb-button"
                      appButtonDebounce
                      [throttleTime]="600"
                      (throttledClick)="openUnderstandGraphDialog()"
                      >
                      <span class="material-icons-outlined icons-primary qb-segmet-buttons qb-rotated"> wb_incandescent </span>
                      &nbsp;&nbsp; Understand the Graph
                      </span>
                  </div>
                </div>
            
                <div class="graph-display">
                  <div echarts [options]="chartOptions" class="echart"></div>
                  <div class="graph-no-data" *ngIf="isgraphDataEmpty">
                    <div class="material-symbols-outlined font-size-90">snowing</div>
                    <div>No data available.</div>
                    <!-- <div class="center-text">
                      Try adjusting the Date range from the graph setting.
                    </div> -->
                  </div>
                </div>
                <div class="note-message" *ngIf="selectedGraphIndex === 1 || selectedGraphIndex === 2">
                  <span>**Note : The Graph will not be accurate for less number of data points</span>
                </div>
              </div>
            </div>
            
      </div>

      </div>

        <div class="no-data-container" *ngIf="!hasData">
          <img src="https://cdn-greyfox.s3.ap-south-1.amazonaws.com/greyfox-assets/pyg-assets/graphNoData.svg" alt="" style="width: 450px;">
            <h6 style="margin-top: 15px;color: orangered;">
                No data available for analysis
            </h6>
        </div>

</div>
