import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { WidgetRouteGaurdService } from "src/app/core/services/widget-route-gaurd.service";
import { OfferAnalyticsViewComponent } from "./pages";

const routes: Routes = [
    {
        path: "",
        component: OfferAnalyticsViewComponent,
        canActivate: [WidgetRouteGaurdService],
        data: {
          titleheading: "Offer Analytics",
          title: "Offer Analytics",
          minPath: "Basic",
          id: 59
        },
      },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OfferAnalyticsRoutingModule {}
